export const STORIES ="/stories"
export const Update_SOURCE ="/articleInfo/source"
export const Update_LOCATION ="/articleInfo/location"
export const PROJECT_STATUS ="/projectStatus"
export const PROJECT_ARTICLE_STATUS ="/articleprojectStatus"
export const PROJECT_STATUS_TYPE = "/projectStatusType"
export const PROJECT_VALUES ="/projectValues"
export const PROJECT_ATTRIBUTES ="/projectAttributes"
export const PROJECT_SIZE ="/projectSize"
export const ASSETTYPE ="/assetType"
export const AMETIES ="/amenities"
export const USERS ="/typeUsers"
export const Update_DETAILS ="/articleInfo/project-details"
export const Update_PROJECT_STATUS ="/articleInfo/project-status"
export const Update_PROJECT_TEAM ="/articleInfo/project-teams"
export const PUBLISH ="/articleInfo/publish"
export const JOBLEVELOPTIONS ="/joblevels"
export const JOBFUNCTIONOPTIONS ="/jobfunctions"
export const SUBJOBFUNCTIONOPTIONS ="/subjobfunctions"
export const SIGNUP ="/v1/auth/signup"
export const FORGOTPASS ="/v1/auth/forgot-password"
export const LOGIN ="/v1/auth/login"
export const ARTICLEREVIEW ="/articleInfo/review"
export const GETROLES ="/roles/get"
export const DELETEROLE ="/roles/delete"
export const ADDROLE ="/roles/create"
export const UPDATEROLE ="/roles/update"
export const FEEDBACK ="/feedback"
export const SOFTDELETE ="/articleInfo/soft-delete"
export const PROJECTLIST= "/projects"
export const CREATE_NEW_PROJECT = "/projects/create-project"
export const UPDATE_NEW_PROJECT = "/projects/update-project"
export const SAVE_AS_DRAFT = "/projects/save-draft-project"
export const PROJEPUBLICHEARINGS= "/projects/save-public-hearings"
export const UPDATEPROJEPUBLICHEARINGS= "/projects/update-public-hearings"
export const DELETEPROJEPUBLICHEARINGS= "/projects/delete-public-hearings"
export const UPDATEPROJECTLOCATION= "/projects/location"
export const MOVEPROJECT= "/projects/move"
export const SOFTDELETEROJECT= "/projects/soft-delete"
export const GETFILLTERLOCATION= "/projects/location"
export const UPDATEPROFILE= "/v1/auth/user-update"
export const ADDPREFERENCES= "/v1/preference"
export const CHANGEPASSWORD= "/v1/auth/change-password"
export const ADDTOFAV= "/articleInfo/favorite"
export const REMOVETOFAV= "/articleInfo/removefavorite"
export const STORYNOTIFY= "/articleInfo/notify"
export const STORYUNNOTIFY= "/articleInfo/removenotify"
export const CITIES= "/cities"
export const FAVOURITEARTICLE = "/articleInfo/favorites"
export const FOLLOWEDARTICLE = "/articleInfo/follows"
export const VIEWCOUNT ="/stories/view-counts"
export const REFRESHTOKEN ="/auth/refresh-token"
export const GETLOCATIONBYCORDINATES ="/get-location-story"
export const GETPROJECTBYCORDINATES ="/projects/get-location-project"
export const GETVIEWCOUNT ="/get-view-counts"
export const VERIFY ="/v1/auth/verify-email"
export const PROJECTTITLES ="/projects/suggestion"
export const PUBLISHPROJECT ="/projects/project-publish"
export const NEXTARTICLE ="/story-next"
export const ADD_TO_FAVORITE="/projects/add-to-favorite"
export const REMOVE_TO_FAVORITE="/projects/remove-to-favorite"
export const UPLOAD_IMAGES = "/projects/upload-images"
export const UPLOAD_PDF = "/projects/upload-pdf"