import React, { useEffect, useState } from "react";
import Table from "../functionality/table/Table";
import moment from "moment";
import ActionCol from "../functionality/table/ActionCol";
import { useHistory } from "react-router-dom";
import { PROJECT_STATUS_TYPE } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../ui/showMessage";
import Loader from "../ui/Loader";

export default function AllProjectStatusDB(props) {
  let history = useHistory();
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showRecord, setShowRecord] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();
  const [selectedOptions, setSelectedOptions] = useState("All");

  const [isShort] = useState(false);

  // console.log(props,"props")
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    GET_PROJECT_STATUS_TYPE(); // eslint-disable-next-line
  }, [
    showRecord,
    pageNo,
    selectedOptions,
    isShort,
    setSelectedOptions,
    setLoading,
    history,
  ]);

  useEffect(() => {
    localStorage.removeItem("rowId");
  }, [history]);

  // Get API
  const GET_PROJECT_STATUS_TYPE = () => {
    axiosInstance
      .get(`${PROJECT_STATUS_TYPE}?limit=${showRecord}&page=${pageNo}`)
      .then((response) => {
        setData(response?.data?.getArticleProjectStatusType);
        setTotalRecord(response?.data?.pagination?.totalCount);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        setTotalRecord(1);
        setCurrentPage(1);
        setTotalPage(1);
        setData([]);
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.message,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  const columns = [
    {
      Header: "Sr No",
      accessor: (row, index) => index + 1, // Auto-incremented serial number
      headerStyle: { textAlign: "center" },
      width: 100, // Adjust the width as needed
      disableSortBy: true,
    },

    {
      Header: (
        <div className="flex flex-row gap-2">
          <span>Name</span>
        </div>
      ),
      accessor: "at_name",
      Cell: ({ value, row }) => {
        return (
          <p className="line-clamp-1 cursor-pointer text-left	ml-4">{value}</p>
        );
      },
      headerStyle: { textAlign: "center" },
      minWidth: 200,
      disableSortBy: true,
    },

    {
      Header: "Marker Color",
      accessor: "MarkerColor",
      Cell: ({ value, row }) => {
        return (
          <div className="flex flex-row justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width={"15px"}
              height={"15px"}
              viewBox="0 0 256 256"
              xmlSpace="preserve"
            >
              <defs></defs>
              <g
                style={{
                  stroke: "none",
                  strokeWidth: 0,
                  strokeDasharray: "none",
                  strokeLinecap: "butt",
                  strokeLinejoin: "miter",
                  strokeMiterlimit: 10,
                  fill: "none",
                  fillRule: "nonzero",
                  opacity: 1,
                }}
                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
              >
                <path
                  d="M 45 0 c 15.103 0 27.389 12.287 27.389 27.389 C 72.389 46.616 46.147 66.607 45 90 c -1.147 -23.393 -27.389 -43.384 -27.389 -62.611 C 17.611 12.287 29.897 0 45 0 z"
                  style={{
                    stroke: "none",
                    strokeWidth: 1,
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    fill: value, // Dynamic color based on prop
                    fillRule: "nonzero",
                    opacity: 1,
                  }}
                  transform="matrix(1 0 0 1 0 0)"
                  strokeLinecap="round"
                />
                <circle
                  cx="45.004999999999995"
                  cy="26.575000000000003"
                  r="9.205"
                  style={{
                    stroke: "none",
                    strokeWidth: 1,
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    fill: value, // Dynamic color based on prop
                    fillRule: "nonzero",
                    opacity: 1,
                  }}
                  transform="matrix(1 0 0 1 0 0)"
                />
              </g>
            </svg>
          </div>
        );
      },

      disableSortBy: true,
    },
    {
      Header: "Date",
      accessor: "created_at",
      Cell: ({ cell: { value } }) => (
        <span>
          {value
            ? moment(value, "YYYY-MM-DD hh:mm:ss").format("MM-DD-YYYY")
            : "--"}
        </span>
      ),
      disableSortBy: true,
    },

    {
      id: "action",

      accessor: "",
      disableSortBy: true,
      Cell: (row) => (
        <ActionCol
          data={row.row.original}
          editRowProjectStatus
          deleteProjectStatus
          isDelete
          type="projectstatus"
          GET_API={GET_PROJECT_STATUS_TYPE}
        />
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Table
          setPageNo={setPageNo}
          pageNo={pageNo}
          showRecord={showRecord}
          setShowRecord={setShowRecord}
          totalRecord={totalRecord}
          currentPage={currentPage}
          totalPage={totalPage}
          columns={columns}
          data={!props?.project ? data : props?.data}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          maxHeight={props?.height}
          noPagination={props?.project}
          noSearch
        />
      )}
    </div>
  );
}
