import { Card, CardBody, Typography, Button } from "@material-tailwind/react";
import { useFormik } from "formik";
import { useState } from "react";
// import { USERTAB } from "../utils/Constant";
import { useHistory } from "react-router-dom";
import Select from "react-dropdown-select";
import { PROJECT_STATUS_TYPE } from "../../../../services/APIGlobal";
import InputField from "../../../../components/ui/InputField";
import { assetSchema } from "../../../../store/Validations/Validation";
import axiosInstance from "../../../../services/APIInstance";
import { showMessage } from "../../../../components/ui/showMessage";
import Loader from "../../../../components/ui/Loader";

const RoleOptions = [
  { label: "Active", value: 1 },
  { label: "Deactive", value: 0 },
];
const Label = (props) => (
  <span className="flex flex-row text-gray-500 text-xs font-medium">
    {props.label}
    {props.isRequired && (
      <>
        {" "}
        <span className="ml-1 text-warn-error block text-sm font-medium ">
          *
        </span>
      </>
    )}
  </span>
);

export default function AddProjectStatus(props) {
  let history = useHistory();
  // const [userValue, setUserValue] = useState("Reviewer");
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [role, setRole] = useState([
    {
      label:
        props?.location?.title === "edit"
          ? props?.location?.data?.Status === 1
            ? "Active"
            : "Deactive"
          : "-- Select status Type --",
      value:
        props?.location?.title === "edit"
          ? props?.location?.data?.Status
          : "-- Select status Type --",
    },
  ]);

  const formik = useFormik({
    initialValues: {
      Name: props?.location?.data?.at_name,
      markerColor: props?.location?.data?.MarkerColor,
      role: "",
    },
    validationSchema: assetSchema,
    onSubmit: (values) => {
      setFormErrors({});
      if (!!role.length === false) {
        setFormErrors({ role: true });
        return;
      }
      setLoading(true);
      if (props?.location?.title !== "edit") {
        axiosInstance
          .post(PROJECT_STATUS_TYPE, {
            name: values?.Name,
            status: role[0].value,
            markerColor: values?.markerColor,
          })
          .then((response) => {
            setLoading(false);
            showMessage(
              response?.data?.message,
              "success",
              "#576d69",
              false,
              false,
              false
            );
            history.push("/ProjectStatus");
          })
          .catch((error) => {
            setLoading(false);
            showMessage(
              error?.response?.data?.error
                ? error?.response?.data?.error
                : error?.message,
              "error",
              "red",
              false,
              false,
              false
            );
          });
      } else if (props?.location?.title === "edit") {
        let updateData = {
          name: values?.Name,
          markerColor: values?.markerColor,
          status: role[0].label === "Active" ? 1 : 0,
        };
        axiosInstance
          .put(
            `${PROJECT_STATUS_TYPE}/${props?.location?.data?.PStatusTypeId}`,
            updateData
          )
          .then((response) => {
            setLoading(false);
            showMessage(
              response?.data?.message,
              "success",
              "#576d69",
              false,
              false,
              false
            );
            history.push("/ProjectStatus");
          })
          .catch((error) => {
            setLoading(false);
            showMessage(
              error?.response?.data?.error,
              "error",
              "red",
              false,
              false,
              false
            );
          });
      }
    },
  });

  return (
    <div className={`flex items-center justify-center  z-0`}>
      <Loader loading={loading} />
      <Card className={` lg:m-0 w-full border-t-2`} shadow={true}>
        <CardBody className="w-full">
          {!props.update && (
            <Typography
              variant="h4"
              className="text-primary-primary font-black"
            >
              Add ProjectStatus
            </Typography>
          )}
          <form
            className="flex flex-col gap-2 w-full pt-4"
            onSubmit={formik.handleSubmit}
          >
            <section className="w-full flex md:flex-row flex-col gap-4">
              {/*Email */}
              <div className="md:w-1/2 lg:w-1/2">
                <InputField
                  name="Name"
                  label="ProjectStatus"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.Name}
                  error={formik.touched.Name && Boolean(formik.errors.Name)}
                  helpertext={formik.touched.Name && formik.errors.Name}
                  isRequired
                />
              </div>
              <section className="mt-1 md:w-1/2 lg:w-1/2 flex-col">
                <Label label="Status" isRequired />
                <Select
                  required={true}
                  values={role}
                  onChange={(value) => {
                    setFormErrors({ role: false });
                    setRole(value);
                  }}
                  options={RoleOptions}
                  color="#576d69"
                  style={{
                    borderRadius: "5px",
                  }}
                  className="font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                />
                {formErrors?.role && (
                  <p className="text-xs text-warn-error font-extrabold">
                    Please select Type
                  </p>
                )}
              </section>
            </section>
            <section className="w-full flex md:flex-row flex-col gap-4  items-center">
              {/*Email */}
              <div className="">
                <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start mb-2">
                  Marker color
                  <span className="ml-1 pb-0 text-warn-error  text-xs font-medium ">
                    *
                  </span>
                </p>
                <input
                  type="color"
                  name="markerColor"
                  id="markerColor"
                  label="Marker color"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.markerColor}
                />
                {/* {formik.values.markerColor}
                <InputField
                  name="markerColor"
                  id="markerColor"
                  label="Marker color"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.markerColor}
                  error={
                    formik.touched.markerColor &&
                    Boolean(formik.errors.markerColor)
                  }
                  helpertext={
                    formik.touched.markerColor && formik.errors.markerColor
                  }
                  isRequired
                /> */}
                {formErrors?.markerColor && (
                  <p className="text-xs text-warn-error font-extrabold">
                    Please select Marker color
                  </p>
                )}
              </div>
            </section>
            <div>
              <div className="flex justify-center">
                <Button
                  variant="filled"
                  type="submit"
                  className="bg-primary-primary mt-5 text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                >
                  {props?.location?.title !== "edit" ? "SUBMIT" : "Update"}
                </Button>
              </div>
            </div>
          </form>
          {/* )}
          </Formik> */}
        </CardBody>
      </Card>
    </div>
  );
}
