import { ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Chip,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";

const AssetTypeFilter = ({
  options,
  onCheckboxChange,
  onSelectChange,
  location,
  defaultValues,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedValues, setSelectedValues] = useState(
    defaultValues ? defaultValues : []
  );
  const [filteredOptions, setFilteredOptions] = useState(options); // eslint-disable-next-line
  const [openPopover, setOpenPopover] = useState(false);

  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => {
      setOpenPopover(false);
      setSearchValue("");
      setFilteredOptions(options);
    },
  };
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    // Filter the options based on the search value
    const filtered = options.filter((option) =>
      !location
        ? option?.Name.toLowerCase().includes(value?.toLowerCase())
        : option?.City.toLowerCase().includes(value?.toLowerCase())
    );
    setFilteredOptions(filtered);
  };
  useEffect(() => {
    // console.log(defaultValues,"defaultValues")
    setSelectedValues(defaultValues?defaultValues:[]);
  }, [defaultValues]);
  const handleCheckboxChange = (value) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];

    setSelectedValues(updatedValues);
    onCheckboxChange(updatedValues);
  };

  // const handleSelectChange = (e) => {
  //   const selectedOptions = Array.from(e.target.options)
  //     .filter((option) => option.selected)
  //     .map((option) => option.value);

  //   setSelectedValues(selectedOptions);
  //   onSelectChange(selectedOptions);
  // };

  const handleSelectAll = () => {
    const allValues = options?.map((option) =>
      !location ? option.Name : option.City + ", " + option.state_code
    );
    setSelectedValues(allValues);
    onSelectChange(allValues);
  };
  const handleUnselectAll = () => {
    setSelectedValues([]);
    onSelectChange([]);
  };

  return (
    <Popover placement="bottom">
      <PopoverHandler>
        <Button
          variant="outlined"
          size="sm"
          className="text-xs justify-between  h-10 md:w-44 w-44 sm:w-44 flex items-center gap-2 text-primary-primary border-primary-primary focus:ring-0"
        >
          <span className="text-sm flex flex-row gap-1 items-center normal-case text-primary-revado-dark hover:text-primary-revado-dark">
            <p> {location ? " Location" : " Asset Type"}</p>
            {selectedValues?.length > 0 && (
              <Chip
                className="w-min rounded-full tracking-wider font-bold antialiased text-xs  px-2 bg-primary-light text-primary-revado-dark hover:text-primary-revado-dark"
                variant="filled"
                color="gray"
                size="sm"
                value={selectedValues?.length}
              />
            )}
          </span>
          <ChevronUpDownIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </PopoverHandler>
      <PopoverContent {...triggers} className="  flex flex-col gap-2 z-100 w-72">
        <div className="flex flex-row justify-end gap-2">
          <button
            className="border border-primary-primary p-1 rounded-sm"
            onClick={handleSelectAll}
          >
            Select All
          </button>
          <button
            className="border border-primary-revado-white p-1 rounded-sm"
            onClick={handleUnselectAll}
          >
            Unselect All
          </button>
        </div>
        <div className="w-full  relative">
          <div className="flex justify-center w-full">
            <div className=" w-full flex-row flex  ">
              <div className="flex flex-row w-72 ">
                <input
                  // type="search"
                  value={searchValue}
                  onChange={handleSearchChange}
                  className={`${searchValue ? "" : " border-r rounded-r-lg"
                    }  border-primary-primary
                        form-control
                        rounded-l-lg
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                       text-primary-primary
                       bg-white bg-clip-padding
                        transition
                        ease-in-out
                        m-0
                        border-y
                        border-l
                       focus:text-gray-700 focus:bg-white  focus:outline-none`}
                  placeholder={
                    location ? " Enter Location" : "Enter Asset Type"
                  }
                />
                {searchValue && (
                  <button
                    className="-ml-px relative inline-flex items-center space-x-2 pr-2 border-y border-r border-primary-primary text-sm font-medium rounded-r-md text-primary-primary"
                    onClick={(e) => {
                      setSearchValue("");
                      setFilteredOptions(options);
                    }}
                  >
                    <XMarkIcon className="w-5" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
        <Scrollbars
          universal
          autoHeight
        // autoHeightMax={300}
        >
          <ul className="flex flex-col gap-2 ">
            <li>
              <p
                className={`${selectedValues?.length === 0
                    ? "bg-primary-hover text-white  rounded-sm"
                    : " border-r rounded-r-lg"
                  }mr-2 align-top cursor-pointer p-2`}
              >
                All (Including without{location ? " Location" : " Asset Type"})
              </p>
            </li>
            {filteredOptions?.map((filltered) => (
              <li
                key={
                  !location
                    ? filltered.Name
                    : filltered.City + ", " + filltered.state_code
                }
                onClick={() =>
                  handleCheckboxChange(
                    !location
                      ? filltered.Name
                      : filltered.City + ", " + filltered.state_code
                  )
                }
              >
                <div className="cursor-pointer  flex flex-row items-center gap-1">
                  <div className="mt-1">
                    <input
                      className=" cursor-pointer h-4 w-4 rounded accent-primary-hover	text-white"
                      type="checkbox"
                      value={
                        !location
                          ? filltered.Name
                          : filltered.City + ", " + filltered.state_code
                      }
                      checked={selectedValues && selectedValues?.includes(
                        !location
                          ? filltered.Name
                          : filltered.City + ", " + filltered.state_code
                      )}
                      onChange={() =>
                        handleCheckboxChange(
                          !location
                            ? filltered.Name
                            : filltered.City + ", " + filltered.state_code
                        )
                      }
                    />
                  </div>
                  <span className="">
                    {!location
                      ? filltered.Name
                      : filltered.City + ", " + filltered.state_code}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </Scrollbars>
        {/* <select multiple value={selectedValues} onChange={handleSelectChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.Name}
          </option>
        ))}
      </select> */}
      </PopoverContent>
    </Popover>
  );
};

export default AssetTypeFilter;
