import { Card, CardBody, Button } from "@material-tailwind/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
// import { USERTAB } from "../utils/Constant";
import { SignupValidationSchema } from "../../store/Validations/Validation";
import { useHistory } from "react-router-dom";
import Loader from "../../components/ui/Loader";
import InputField from "../../components/ui/InputField";
import { showMessage } from "../../components/ui/showMessage";
import axiosInstance from "../../services/APIInstance";
import {
  JOBFUNCTIONOPTIONS,
  JOBLEVELOPTIONS,
  SIGNUP,
  SUBJOBFUNCTIONOPTIONS,
} from "../../services/APIGlobal";
import Select from "react-dropdown-select";

const Label = (props) => (
  <span className="flex flex-row text-gray-500 text-xs font-medium">
    {props.label}
    {props.isRequired && (
      <>
        {" "}
        <span className="ml-1 text-warn-error block text-sm font-medium ">
          *
        </span>
      </>
    )}
  </span>
);

export default function SignUp(props) {
  let history = useHistory();
  // const [userValue, setUserValue] = useState("Reviewer");
  const [loading, setLoading] = useState(false);
  const [togglePasswordType, setTogglePasswordType] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [jobOptions, setJobOptions] = useState({});
  const [jobSelect, setJobSelect] = useState([]);
  const [jobFunctionalityOptions, setJobFunctionalityOptions] = useState({});
  const [jobFunctionalitySelect, setJobFunctionalitySelect] = useState({});
  const [subJobFunctionalityOptions, setSubJobFunctionalityOptions] = useState(
    {}
  );
  const [subJobFunctionalitySelect, setSubJobFunctionalitySelect] = useState(
    {}
  );

  function togglePasswordVisibility() {
    setTogglePasswordType((prevState) => !prevState);
  }

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    GET_OPTIONS(); // eslint-disable-next-line
  }, []);

  // Get API
  const GET_OPTIONS = () => {
    setLoading(true);
    axiosInstance
      .get(`${JOBLEVELOPTIONS}`)
      .then((response) => {
        const Options = response?.data?.jobLevels.map((item) => {
          return { label: item.Name, value: item.JobId };
        });
        setJobOptions(Options);
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    axiosInstance
      .get(`${JOBFUNCTIONOPTIONS}`)
      .then((response) => {
        const Options = response?.data?.jobFunctions.map((item) => {
          return { label: item.Name, value: item.JobfunctionId };
        });
        setJobFunctionalityOptions(Options);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    axiosInstance
      .get(`${SUBJOBFUNCTIONOPTIONS}`)
      .then((response) => {
        const Options = response?.data?.subJobFunctions.map((item) => {
          return { label: item.Name, value: item.SubJobfunctionId };
        });
        setSubJobFunctionalityOptions(Options);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  const formik = useFormik({
    initialValues: {
      F_Name: "",
      L_Name: "",
      // Age: "",
      JobLevel: "",
      JobFunctionality: "",
      SubJobFunctionality: "",
      Organization: "",
      // Industry: "",
      // SubIndustry: "",
      // CompanySize: "",
      employerZip: "",
      UserName: "",
      Password: "",
    },
    validationSchema: SignupValidationSchema,
    onSubmit: (values) => {
      setFormErrors({});
      if (!!jobSelect.length === false) {
        setFormErrors({ JobLevel: true });
        return;
      } else if (!!jobFunctionalitySelect.length === false) {
        setFormErrors({ JobFunctionality: true });
        return;
      } else if (!!subJobFunctionalitySelect.length === false) {
        setFormErrors({ SubJobFunctionality: true });
        // alert(`error`);
        return;
      }
      const subJobs = subJobFunctionalitySelect.map((value) => value.label);
      let apiSubJob = subJobs.toString();
      // setLoading(true);
      const DATA = {
        firstName: values?.F_Name,
        lastName: values?.L_Name,
        jobLevelId: jobSelect[0].value,
        jobFunctionId: jobFunctionalitySelect[0].value,
        subJobFunctionId: subJobFunctionalitySelect[0].value,
        zipcode: values?.employerZip,
        organization: values?.Organization,
        email: values?.UserName,
        password: values?.Password,
        confirmPassword: values?.Password,
        jobLevelName: jobSelect[0].label,
        jobFunctionName: jobFunctionalitySelect[0].label,
        subJobFunctionName: apiSubJob,
        roleId: 3,
      };
      axiosInstance
        .post(SIGNUP, DATA)
        .then((response) => {
          setLoading(false);
          showMessage(
            response?.data?.message,
            "success",
            "#576d69",
            false,
            false,
            false
          );
          setTimeout(() => {
            setLoading(true);
          }, 1600);
          setTimeout(() => {
            setLoading(true);
          }, 1750);
          setTimeout(() => {
            setLoading(false);
            history.push("/signin");
          }, 2500);
        })
        .catch((error) => {
          setLoading(false);
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        });
    },
  });

  return (
    <div
      className={`flex  pb-4 items-center justify-center ${
        !props.update ? "lg:m-5" : "lg:m-2"
      } z-0 `}
    >
      <Loader loading={loading} />
      {!loading && (
        <Card
          className={` ${
            !props.update && "lg:w-3/5"
          } lg:m-0 w-full border-t-2`}
          shadow={true}
        >
          <CardBody className="w-full">
            {/* {!props.update && (
              <Typography
                variant="h4"
                className="text-primary-primary font-black"
              >
                Create an account
              </Typography>
            )} */}
            <form
              className="flex flex-col gap-2 w-full pt-4"
              onSubmit={formik.handleSubmit}
            >
              <section className="w-full flex md:flex-row flex-col gap-4">
                {/* First Name */}
                <div className="md:w-1/2 lg:w-1/2">
                  <InputField
                    name="F_Name"
                    label="First Name"
                    onChange={formik.handleChange}
                    value={formik.values.F_Name}
                    error={
                      formik.touched.F_Name && Boolean(formik.errors.F_Name)
                    }
                    helpertext={formik.touched.F_Name && formik.errors.F_Name}
                    isRequired
                  />
                </div>
                {/* Last Name */}
                <div className="md:w-1/2 lg:w-1/2">
                  <InputField
                    name="L_Name"
                    label="Last Name"
                    onChange={formik.handleChange}
                    value={formik.values.L_Name}
                    error={
                      formik.touched.L_Name && Boolean(formik.errors.L_Name)
                    }
                    helpertext={formik.touched.L_Name && formik.errors.L_Name}
                    isRequired
                  />
                </div>
              </section>
              <section className="w-full flex md:flex-row flex-col gap-4">
                {/*Age */}
                {/* <div className="md:w-1/2 lg:w-1/2 ">
              <InputField
                name="Age"
                label="Age"
                onChange={formik.handleChange}
                value={formik.values.Age}
              />
            </div> */}
                <div className="md:w-1/2 lg:w-1/2"></div>
              </section>
              <section className="mt-1 flex-col">
                <Label label="Job Level" isRequired />
                <Select
                  required={true}
                  values={[
                  ]}
                  onChange={(value) => {
                    setFormErrors({ JobLevel: false });
                    setJobSelect(value);
                  }}
                  options={jobOptions}
                  color="#576d69"
                  style={{
                    borderRadius: "5px",
                  }}
                  className="font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                />
                {formErrors?.JobLevel && (
                  <p className="text-xs text-warn-error font-extrabold">
                    Please select job level
                  </p>
                )}
              </section>
              <section className="mt-2 w-full flex md:flex-row flex-col gap-4">
                {/* Job Functionality  */}
                <div className="md:w-1/2 lg:w-1/2">
                  <Label label="Job Functionality" isRequired />
                  <Select
                    required={true}
                    values={[]}
                    onChange={(value) => {
                      setFormErrors({ JobFunctionality: false });
                      setJobFunctionalitySelect(value);
                    }}
                    options={jobFunctionalityOptions}
                    color="#576d69"
                    style={{
                      borderRadius: "5px",
                    }}
                    placeholder="-- Select Job Functionality --"
                    className="font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                  />
                  {formErrors?.JobFunctionality && (
                    <p className="text-xs text-warn-error font-extrabold">
                      Please select job Functionality
                    </p>
                  )}
                </div>
                {/* Sub Job  */}
                <div className="md:w-1/2 lg:w-1/2">
                  <Label label="Sub Job Functionality" isRequired />
                  <Select
                    multi
                    required={true}
                    values={[]}
                    onChange={(value) => {
                      setFormErrors({ SubJobFunctionality: false });
                      setSubJobFunctionalitySelect(value);
                    }}
                    options={subJobFunctionalityOptions}
                    color="#576d69"
                    style={{
                      borderRadius: "5px",
                    }}
                    placeholder="-- Select Job sub Job Functionality --"
                    className="font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                  />
                  {formErrors?.SubJobFunctionality && (
                    <p className="text-xs text-warn-error font-extrabold">
                      Please select job sub Job Functionality
                    </p>
                  )}
                </div>
              </section>
              {/*Organization */}
              <section className="w-full mt-2 flex-col">
                <InputField
                  name="Organization"
                  label="Organization"
                  onChange={formik.handleChange}
                  value={formik.values.Organization}
                />
              </section>
              {/* <section className="mt-1 w-full flex md:flex-row gap-4 flex-col">
            Industry 
            <div className="md:w-1/2 lg:w-1/2">
              <DropDown
                name="Industry"
                onChange={formik.handleChange}
                value={formik.values.Industry}
                options={JobLevels}
                error={formErrors.Industry}
                label="Industry"
              />
            </div>
            Sub Industry 
            <div className="md:w-1/2 lg:w-1/2">
              <DropDown
                name="SubIndustry"
                onChange={formik.handleChange}
                value={formik.values.SubIndustry}
                options={JobLevels}
                error={formErrors.SubIndustry}
                label="Sub Industry"
              />
            </div>
          </section> */}
              <section className="mt-2 w-full flex md:flex-row gap-4 flex-col">
                {/* Company Size  */}
                {/* <div className="md:w-1/2 lg:w-1/2 mt-1">
              <DropDown
                name="CompanySize"
                onChange={formik.handleChange}
                value={formik.values.CompanySize}
                options={JobLevels}
                error={formErrors.CompanySize}
                label="Company Size"
              />
            </div> */}
                {/* Employer Zipcode  */}
                <div className="md:w-1/2 lg:w-1/2">
                  <InputField
                    name="employerZip"
                    label="Zip code of employer"
                    onChange={formik.handleChange}
                    value={formik.values.employerZip}
                  />
                </div>
              </section>
              <section className="w-full flex md:flex-row gap-4 flex-col">
                {/* First Name */}
                <div className="md:w-1/2 lg:w-1/2">
                  <InputField
                    name="UserName"
                    label="Email"
                    onChange={formik.handleChange}
                    value={formik.values.UserName}
                    error={
                      formik.touched.UserName && Boolean(formik.errors.UserName)
                    }
                    helpertext={
                      formik.touched.Password && formik.errors.Password
                    }
                    isRequired
                  />
                </div>
                <div className="md:w-1/2 lg:w-1/2 flex flex-col gap-1">
                  <Label label="Password" isRequired />
                  <div className="relative ">
                    <input
                      type={togglePasswordType ? "text" : "password"}
                      placeholder="Password"
                      name="Password"
                      label="Password"
                      onChange={formik.handleChange}
                      value={formik.values.Password}
                      error={
                        formik.touched.Password &&
                        Boolean(formik.errors.Password)
                      }
                      helpertext={
                        formik.touched.Password && formik.errors.Password
                      }
                      className={`${
                        props.error
                          ? "border-2 border-warn-error"
                          : "border border-gray-300"
                      } peer w-full px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                      onClick={togglePasswordVisibility}
                    >
                      {togglePasswordType ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </section>
              <div>
                <div className="flex justify-center">
                  <Button
                  disabled
                    variant="filled"
                    type="submit"
                    className="bg-primary-primary mt-5 text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                  >
                    Sign Up
                  </Button>
                </div>
                <p className="mt-2 text-xs text-warn-error font-bold">Note: Signups are temporarily paused for updates.</p>
              </div>
            </form>
            {/* )}
        </Formik> */}
          </CardBody>
        </Card>
      )}
    </div>
  );
}
