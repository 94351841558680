import { Accordion, AccordionBody, AccordionHeader, Card } from "@material-tailwind/react";
import UpdateProfile from "../auth/UpdateProfile";
import { useState } from "react";
import TopicSetting from "./TopicSetting";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}
export default function Setting() {
  const [open, setOpen] = useState(1);
  const [openSecond, setOpenSecond] = useState(2);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const handleOpenSecond = (value) => {
    setOpenSecond(openSecond === value ? 0 : value);
  };

  // const userDetails =
  //   JSON.parse(localStorage.getItem("authDetails")).roles[0] === "User";

  return (
    <div className="flex justify-center w-full   p-4  text-sm   z-0">
      <Card className="lg:m-0 w-full border-none shadow-none flex flex-col gap-4">
        {JSON.parse(localStorage.getItem("authDetails")).roles[0] !==
          "User" && (
          <div>
            <UpdateProfile update ></UpdateProfile>
          </div>
        )}
        {/* <div>
          <ChangePassword update></ChangePassword>
        </div> */}
        {JSON.parse(localStorage.getItem("authDetails")).roles[0] === "User" && (
          <>
            <Accordion
              open={open === 1}
              icon={<Icon id={1} open={open} className="  rounded-lg mb-2" />}
            >
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className={` rounded-lg p-2 border-2 transition-colors text-sm  ${
                  open === 1 ? "border-b-0 rounded-b-none " : ""
                }`}
              >
                Profile Setting
              </AccordionHeader>
              <AccordionBody
                className={` rounded-lg p-4 border-2 transition-colors ${
                  open === 1
                    ? "border-t-0 rounded-t-none "
                    : "border-b-0 rounded-b-none"
                }`}
              >
                <UpdateProfile update openNext={setOpen} />
              </AccordionBody>
            </Accordion>
            <Accordion
              open={openSecond === 2}
              icon={<Icon id={2} openSecond={openSecond} />}
              className="  rounded-lg mb-2"
            >
              <AccordionHeader
                className={`rounded-lg p-2 border-2 transition-colors text-sm ${
                  openSecond === 2 ? "border-b-0 rounded-b-none " : ""
                }`}
                onClick={() => handleOpenSecond(2)}
              >
                Topic Setting
              </AccordionHeader>
              <AccordionBody
                className={` rounded-lg p-4 border-2 transition-colors ${
                  openSecond === 2
                    ? "border-t-0 rounded-t-none "
                    : "border-b-0 rounded-b-none"
                }`}
              >
                <TopicSetting openNext={setOpenSecond} />
              </AccordionBody>
            </Accordion>
          </>
        )}
        {/* <Accordion
          open={open === 2}
          icon={<Icon id={2} open={open} />}
          className="  rounded-lg mb-2"
        >
          <AccordionHeader
            className={` rounded-lg p-2 border-2 transition-colors text-sm ${
              open === 2 ? "border-b-0 rounded-b-none " : ""
            }`}
            onClick={() => handleOpen(2)}
          >
            Password Setting
          </AccordionHeader>
          <AccordionBody
            className={` rounded-lg p-4 border-2 transition-colors ${
              open === 2
                ? "border-t-0 rounded-t-none "
                : "border-b-0 rounded-b-none"
            }`}
          >
            <ChangePassword openNext={setOpen} />
          </AccordionBody>
        </Accordion> */}
      </Card>
    </div>
  );
}
