import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import Loader from "../../components/ui/Loader";
import { showMessage } from "../../components/ui/showMessage";
import axiosInstance from "../../services/APIInstance";
import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    
  
  } from "@material-tailwind/react";

import { CHANGEPASSWORD } from "../../services/APIGlobal";
export default function ChangePassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [toggleNewPasswordType, setTogglePasswordType] = useState(false);
  const [toggleConfirmPasswordType, setToggleConfirmPasswordType] = useState(false);




  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
        setLoading(true);
        const urlSearchParams = new URLSearchParams(window.location.search);
        const token = urlSearchParams.get('token');
        const newPassword = values.newPassword; // Get the new password from the form values
      
        if (token && newPassword) {
          // Create a request payload that includes the token and new password
          const requestData = {
          
            newPassword: newPassword,
          };
         
          axiosInstance
            .post(`${CHANGEPASSWORD}?${urlSearchParams.toString()}`, requestData)
            .then((response) => {
              setLoading(false); // Set loading to false when the request is complete
      
              if (response.data) {
                showMessage("Your password has been successfully changed!", "success", "#576d69", false, false, false);
                setTimeout(() => {
                  history.push("/signIn");
                }, 3000);
              } else {
                showMessage("Token verification failed.", "error", "red", false, false, false);
              }
            })
            .catch((error) => {
              setLoading(false); // Set loading to false when there's an error
      
              showMessage(error?.response?.data?.error, "error", "red", false, false, false);
              setTimeout(() => {
                history.push("/");
              }, 3000);
            });
        } else {
          setLoading(false); // Set loading to false if there's no token in the URL or new password in the form
          showMessage("Token or new password not found.", "error", "red", false, false, false);
        }
      },
  });


  function toggleNewPasswordVisibility() {
    setTogglePasswordType((prevState) => !prevState);
  }
  function toggleConfirmPasswordVisibility() {
    setToggleConfirmPasswordType((prevState) => !prevState);
  }

  return (
    <div className="flex items-center justify-center md:m-5 lg:m-5">
      <Loader loading={loading} />
      <Card className="w-96 m-2 lg:m-0 border-t-2" shadow={true}>
        <CardHeader
          floated={false}
          variant="gradient"
          shadow={true}
          className="bg-primary-light  mb-1 grid h-28 place-items-center"
        >
          <Typography
            variant="h3"
            className="text-primary-primary font-black text-center"
          >
            Change Password
          </Typography>
        </CardHeader>
        <CardBody>
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-1">
            <div>
              {/* New Password */}
              <label htmlFor="newPassword" className="font-semibold">
                New Password
              </label>
              <div className="relative">
                <input
                  type={toggleNewPasswordType ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  className="peer w-full px-3 py-2 border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                  onClick={toggleNewPasswordVisibility}
                >
                  {toggleNewPasswordType ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                    <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                      {/* Eye icon when password is visible */}
                      {/* ... */}
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                                               <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                    </svg>
                  )}
                </button>
              </div>
              {/* Display validation error */}
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.newPassword}
                </div>
              )}
            </div>
            <div>
              {/* Confirm Password */}
              <label htmlFor="confirmPassword" className="font-semibold">
                Confirm Password
              </label>
              <div className="relative">
                <input
                  type={toggleConfirmPasswordType ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  className="peer w-full px-3 py-2 border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {toggleConfirmPasswordType ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                                                <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                    </svg>
                  )}
                </button>
              </div>
              {/* Display validation error */}
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.confirmPassword}
                  </div>
                )}
            </div>
            {/* Action Button */}
            <div className="flex justify-between gap-5 mt-5">
              <button
                type="submit"
                className="bg-primary-primary text-white w-full py-2 rounded-md hover:bg-primary-dark"
                disabled={loading}
              >
                {loading ? "Changing Password..." : "Change Password"}
              </button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}
