import React from "react";
import OnlyWhiteLogo from "../../assets/Logo/OnlyWhiteLogo.png";

export default function Loader({ loading }) {

  //   const triggers = {
  //     onMouseEnter: () => setOpenPopover(true),
  //     onMouseLeave: () => setOpenPopover(false),
  //   };
  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center z-100">
        <div className="e p-4 rounded-lg text-center">
              <img
                alt="Loading"
                className="animate-spin-slow"
                src={OnlyWhiteLogo}
                width="200"
                height="200"
              />
           </div>
    </div>
      )}{" "}
    </>
  );
}
