/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { debounce } from "lodash"; // Import debounce function from lodash
import "mapbox-gl/dist/mapbox-gl.css";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { render } from "react-dom";
import Map, {
  FullscreenControl,
  // Source,
  // Layer,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
} from "react-map-gl";
import logo from "../../assets/Logo/OnlyBlacklogo.png";
import MapLedgend from "../../components/ui/Map/MapLedgend";
import MapStyle from "../../components/ui/Map/MapStyle";
import ProjectPin from "../../components/ui/Map/ProjectPin";
import { showMessage } from "../../components/ui/showMessage";
import FilterContext from "../../context/FilterContext";
import {
  ADD_TO_FAVORITE,
  PROJECTLIST,
  PROJECT_STATUS_TYPE,
  REMOVE_TO_FAVORITE,
} from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { ArrayToString } from "../../store/helper/Helper";
import { MAP_STREETS } from "../../utils/Constant";
import ProjectDetails from "./ProjectDetails";

const ProjectMap = ({
  stories,
  setStories,
  APItype,
  projectHighlightId,
  GETPROJECTS,
}) => {
  const mapRef = React.useRef(null);
  const componentRef = React.useRef(null);

  const [popupInfo, setPopupInfo] = useState(null);
  const [zoomInfo, setZoomInfo] = useState(false);
  const [zoom, setZoom] = useState(
    localStorage.getItem("zoom") || 7.080879827490506
  );
  const [showPopup, setShowpopup] = useState(false);
  const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const [mapStyle, setMapStyle] = useState(MAP_STREETS);
  const [projectById, setProjectById] = useState("");
  const [projectStatusOptions, setProjectStatusOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [map, setMap] = useState(null);

  let filteredStories = [];
  if (APItype === "Project") {
    filteredStories = stories.filter(
      (story) =>
        story.location.Longitude !== null && story.location.Latitude !== null
    );
  }

  const { filterArray, suggestionLocation, isSuggestion, setFilterArray } =
    React.useContext(FilterContext);
  useEffect(() => {
    if (mapRef.current ) {
      handleMapZoom();
    }
    GET_PROJECT_STATUS();
  }, [mapRef]);

  useEffect(() => {
    // console.log(suggestionLocation, "suggestionLocation");
    if (isSuggestion) {
      setTimeout(() => {
        handleFlyTo({
          longitude: suggestionLocation?.longitude,
          latitude: suggestionLocation?.latitude,
        },1000);
      })
    }
  }, [isSuggestion, suggestionLocation]);

  const handleMapZoom = React.useCallback(
    debounce(() => {
      if (mapRef.current) {
        localStorage.setItem("zoom", mapRef.current.getMap().getZoom());
        const userId = localStorage.getItem("userId");
        const bounds = mapRef.current.getMap().getBounds();
        localStorage.setItem("bounds", JSON.stringify(bounds));

        const searchQuery = filterArray?.SEARCH
          ? `&searchQuery=${filterArray.SEARCH}`
          : "";
        const assetType =
          filterArray?.ASSETTYPE?.length > 0
            ? `&assetType=${ArrayToString(filterArray.ASSETTYPE)}`
            : "";
        const projectStatus =
          filterArray?.PROJECTSTATUS?.length > 0
            ? `&projectStatus=${ArrayToString(filterArray.PROJECTSTATUS)}`
            : "";
        const isFav = filterArray?.FAVOURITE === true ? "&isFav=1" : "";
        const boundQuery = bounds ? `&bounds=${JSON.stringify(bounds)}` : "";

        const endpoint = `${PROJECTLIST}?flag=1&page=1&isDraft=no&limit=1000&isPublish=Yes&userId=${userId}${searchQuery}${boundQuery}${
          filterArray?.DATE ? `&dateRange=${filterArray.DATE}` : ""
        }${assetType}${projectStatus}${isFav}`;

        axiosInstance
          .get(endpoint)
          .then((response) => {
            setStories(response.data.projects);
          })
          .catch((error) => {
            showMessage(
              error?.response?.data?.error,
              "error",
              "red",
              false,
              false,
              false
            );
          });
      }
    }, 1000),
    [filterArray, axiosInstance, setStories, mapRef]
  );
  // outside click
  // useEffect(() => {
  //   const handleOutsideClick = (e) => {
  //     if (showPopup && !componentRef.current?.contains(e.target)) {
  //       // Click occurred outside of the component, so close the popup
  //       setShowpopup(false);
  //     }
  //   };

  //   // Add event listener when the component mounts
  //   document.addEventListener('click', handleOutsideClick);

  //   // Remove event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('click', handleOutsideClick);
  //   };
  // }, [showPopup]);
  const GET_PROJECT_STATUS = () => {
    axiosInstance
      .get(PROJECT_STATUS_TYPE)
      .then((response) => {
        setProjectStatusOptions(response?.data?.getArticleProjectStatusType);
      })
      .catch((error) => {
        // Handle error
      });
  };

  const handleCardOpertaion = (card, operationValue, operation) => {
    let APICALL, DATA;
    if (operation === "Favorite") {
      // console.log(props?.APItype);

      APICALL = operationValue === 0 ? ADD_TO_FAVORITE : REMOVE_TO_FAVORITE;
      if (APItype === "Project") {
        DATA = {
          projectId: card.projectId,
          userId: localStorage.getItem("userId"),
        };
      }
      if (APItype === "Favourite") {
        // console.log("here");
        DATA = {
          projectId: card.projectId,
          userId: localStorage.getItem("userId"),
          ProjectDetailsId: card.ProjectDetailsId,
          favoriteId: card.projectId,
        };
      }
    }
    axiosInstance
      .put(APICALL, DATA)
      .then((response) => {
        showMessage(
          response?.data?.message,
          "success",
          "#576d69",
          false,
          false,
          false
        );
        setPopupInfo((prevPopupInfo) => ({
          ...prevPopupInfo,
          favorite: operationValue === 0 ? 1 : 0,
        }));
        GETPROJECTS();
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };
  // Project by id show
  const handleOpen = (strory) => {
    setProjectById(strory);
    setOpen(!open);
  };

  const handleFlyTo = React.useCallback(({ longitude, latitude }) => {
    handleMapZoom();
    mapRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 });
  }, []);

  const handleViewportChange = (newViewport) => {
    // Access the zoom value
    const newZoom = newViewport.zoom;
    setZoom(newZoom);
  };

  return (
    <div className="w-full" ref={componentRef}>
      <>
        <Map
          ref={mapRef}
          // ref={(ref) => {
          //   if (ref) {
          //     setMap(ref.getMap());
          //   }
          // }}
          // ref={mapRef}

          initialViewState={{
            latitude: 32.790260898226535,
            longitude: -96.85111224441096,
            zoom: zoom,
          }}
          onMove={(evt) => {
            handleMapZoom(evt);
          }}
          onViewportChange={handleViewportChange}
          mapStyle={mapStyle}
          mapboxApiAccessToken={TOKEN}
          onClick={() => {
            setShowpopup(false);
            setZoomInfo(false);
          }}
        >
          {stories.map((marker, index) => (
            <div key={`marker-${index}`}>
              {marker?.location?.Longitude && (
                <Marker
                  longitude={marker?.location?.Longitude}
                  latitude={marker?.location?.Latitude}
                  anchor="bottom"
                  style={{
                    zIndex: projectHighlightId === marker.projectId ? 1 : 0,
                  }}
                  onClick={(e) => {
                    setZoomInfo(false);
                    // If we let the click event propagates to the map, it will immediately close the popup
                    // with `closeOnClick: true`
                    // console.log(data);
                    e.originalEvent.stopPropagation();
                    setPopupInfo(null);
                    setShowpopup(true);
                    setPopupInfo(marker);
                  }}
                >
                  <ProjectPin
                    data={marker}
                    projectHighlightId={projectHighlightId}
                    projectStatusOptions={projectStatusOptions}
                  />
                  {showPopup && (
                    <Popup
                      anchor="top"
                      longitude={Number(popupInfo?.location?.Longitude)}
                      latitude={Number(popupInfo?.location?.Latitude)}
                      onClose={() => setShowpopup(false)}
                      className="m-0 p-0 md:z-0 z-75"
                      // style={{
                      //   zIndex: 100,
                      // }}
                    >
                      <Card
                        className=" m-0 p-0 shadow-none border-none  hover:cursor-pointer "
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpen(popupInfo);
                        }}
                      >
                        <CardHeader
                          floated={false}
                          shadow={false}
                          color="transparent"
                          className="m-0 rounded-none p-0 h-40 relative"
                        >
                          {popupInfo && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className={`absolute top-1 right-1 w-6 max-w-6 min-h-6 max-h-6 hover:cursor-pointer ${
                                popupInfo?.favorite === 1
                                  ? "text-primary-redova-favorite"
                                  : "text-gray-400"
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();

                                handleCardOpertaion(
                                  popupInfo,
                                  popupInfo?.favorite,
                                  "Favorite"
                                );
                              }}
                            >
                              <path
                                fillRule="evenodd"
                                stroke="white"
                                d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                          <div className="h-full flex items-center justify-center">
                            <img
                              src={
                                popupInfo?.imageUrls[0]
                                  ? popupInfo?.imageUrls[0]
                                  : logo
                              }
                              alt="project map"
                              className={`h-full  ${
                                !popupInfo?.imageUrls[0]
                                  ? "py-2 px-5 w-auto"
                                  : "w-full"
                              }`}
                            />
                          </div>
                        </CardHeader>
                        <CardBody className="p-2 m-0">
                          <div className="flex flex-col gap-2 ">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="leading-snug text-black font-semibold font-serif"
                            >
                              {popupInfo?.title}
                            </Typography>
                            <p className="text-xs text-gray-600 ">
                              Posted Date:{" "}
                              {moment(
                                popupInfo?.created_at,
                                "YYYY-MM-DD hh:mm:ss"
                              ).format("MM/DD/YYYY")}
                              {popupInfo?.Status && (
                                <> | Project Status: {popupInfo?.Status}</>
                              )}
                            </p>
                            <div className=" ">
                              {popupInfo?.AssetType !== null &&
                                popupInfo?.AssetType !== undefined && (
                                  <div className="flex flex-col max-w-fit ">
                                    <p
                                      className=" rounded-md p-1 tracking-wider font-medium antialiased text-xs bg-primary-light text-black"
                                      variant="filled"
                                      color="gray"
                                    >
                                      {popupInfo.AssetType.replace(/,/g, ", ")}
                                    </p>
                                  </div>
                                )}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Popup>
                  )}
                </Marker>
              )}
            </div>
          ))}
          <div>
            <GeolocateControl
              position="top-right"
              style={{
                zIndex: 99999,
              }}
            />
            <FullscreenControl position="top-right" />
            <NavigationControl position="top-right" />
          </div>
        </Map>

        {/* {zoomInfo && APItype !== "Favourite" && (
              <ZoomStories
                latitude={latitude}
                longitude={longitude}
                assetFillter={assetFillter}
                dateRangeValue={dateRangeValue}
                startDateValue={startDateValue}
                endDateValue={endDateValue}
                searchValue={searchValue}
              />
            )} */}
        <div className="relative w-full">
          <MapStyle
            setMapStyle={setMapStyle}
            mapStyle={mapStyle}
            setShowpopup={setShowpopup}
          />
        </div>

        <div className="relative w-full">
          <MapLedgend />
        </div>

        <ProjectDetails
          open={open}
          handleOpen={handleOpen}
          projectDetails={projectById}
          handleCardOpertaion={handleCardOpertaion}
        />
      </>
    </div>
  );
};

export default ProjectMap;
