import React from 'react'
import { Card } from "@material-tailwind/react"
import PublishArticlesDB from '../../../../components/datatables/PublishArticlesDB'

export default function PublishArticle() {
  return (
    <Card className="h-full w-full border-t">
    <PublishArticlesDB />
  </Card>
  )
}
