import React, { useState } from 'react';
import { appendSourceLogo} from '../../store/helper/Helper';

const ImageWithFallback = ({ key, url, fallbackUrl,className,style,source }) => {
  const [imageError, setImageError] = useState(false);

  const onError = () => {
    // Handle the image loading error by setting the error state to true.
    setImageError(true);
  };

  return (
    <div>
      {imageError ? (
        <img src={appendSourceLogo(source)} alt={source} className={className} style={style}  />
      ) : (
        <img key={key} src={url} alt={key} onError={onError}  className={className} style={style}/>
      )}
    </div>
  );
};

export default ImageWithFallback;