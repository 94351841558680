import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Dialog,
  // TabsHeader,
  // Tabs,
  // Tab,
} from "@material-tailwind/react";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
// import { USERTAB } from "../utils/Constant";
// import { useHistory } from "react-router-dom";
import Loader from "../components/ui/Loader";
import {
  FeedBackContent,
  FeedBackTitle,
  txtLabel1,
  txtLabel2,
  txtLabel3,
} from "../utils/Constant";
import { showMessage } from "../components/ui/showMessage";
import axiosInstance from "../services/APIInstance";
import { FEEDBACK } from "../services/APIGlobal";

export default function FeedBackForm() {
  // let history = useHistory();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);
  // const [userValue, setUserValue] = useState("Reviewer");
  const [loading, setLoading] = useState(false);
  //   Login Button Click
  const FeedBackClick = (e) => {
    if (e.comment !== "" || e.add !== "" || e.improve !== "") {
      axiosInstance
        .post(FEEDBACK, {
          comments: e.comment,
          newsoutlet: e.add,
          missing_feature: e.improve,
        })
        .then((response) => {
          setLoading(false);
          showMessage(
            "Thank you for your feedback!",
            "success",
            "#576d69",
            false,
            false,
            false
          );
          // history.push("/home");
          handleOpen();
        })
        .catch((error) => {
          setLoading(false);
          showMessage(
            error?.response?.data?.error
              ? error?.response?.data?.error
              : error?.message,
            "error",
            "red",
            false,
            false,
            false
          );
          handleOpen();
        });
    } else {
      showMessage(
        "Please fill one of the field to submit feedback form",
        "error",
        "red",
        false,
        false,
        false
      );
    }
  };

  return (
    <div className="flex items-center justify-center ">
      <Button
        onClick={handleOpen}
        size="sm"
        variant="outlined"
        className="text-xs h-10 justify-between md:w-40  flex items-center gap-2 text-primary-primary border-primary-primary focus:ring-0">
        <span className="text-sm flex flex-row gap-1 items-center normal-case text-primary-revado-dark hover:text-primary-revado-dark">
          <p> Give Us Feedback</p>
        </span>
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <Card className="" shadow={true}>
          <CardHeader
            floated={false}
            variant="gradient"
            shadow={true}
            className="bg-primary-light flex h-20  items-center justify-center p-3"
          >
            <Typography
              variant="h5"
              className="text-primary-primary font-black place-self-center "
            >
              {FeedBackTitle}
            </Typography>
          </CardHeader>
          <CardBody className="flex flex-col gap-4">
            <Typography
              variant="small"
              className=" font-medium place-self-center "
            >
              {FeedBackContent}
            </Typography>
            <Formik
              initialValues={{
                comment: "",
                add: "",
                improve: "",
              }}
              onSubmit={(e) => FeedBackClick(e)}
            >
              {() => (
                <Form className="flex flex-col gap-1">
                  {/* Comment */}
                  <div>
                    <label className=" text-xs" htmlFor="email">
                      {txtLabel1}
                    </label>
                    <Field
                      as="textarea"
                      id="comment"
                      name="comment"
                      className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                    />
                  </div>
                  {/* Add feature */}
                  <div>
                    <label className=" text-xs" htmlFor="email">
                      {txtLabel2}
                    </label>
                    <Field
                      as="textarea"
                      id="improve"
                      name="improve"
                      className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                    />
                  </div>
                  <div className="mt-2">
                    <p className="mb-1 text-xs leading-3	" htmlFor="email">
                      {txtLabel3}
                    </p>
                    <Field
                      as="textarea"
                      id="add"
                      name="add"
                      className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                    />
                  </div>

                  {/* Action Button */}
                  <div className="flex justify-center gap-5 mt-5">
                    <Button
                      variant="filled"
                      type="submit"
                      className="bg-primary-primary text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                    >
                      SUBMIT{" "}
                    </Button>
                    {/* <Button variant="text">text</Button> */}
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Dialog>
      <Loader loading={loading} />
    </div>
  );
}
