import React, { useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "@material-tailwind/react";
import { TooltipStlye, TooltipStlyeForData } from "../../utils/Constant";
import Table from "../functionality/table/Table";
import ActionCol from "../functionality/table/ActionCol";
import { useHistory } from "react-router-dom";
import { STORIES } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../ui/showMessage";
import Loader from "../ui/Loader";
import moment from "moment";
import StatusChip from "../functionality/StatusChip";
import { ArrayToString } from "../../store/helper/Helper";
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/24/outline";
import { useLastLocation } from "react-router-last-location";
import FilterParaContext from "../../context/FilterParaContext";

export default function AllArticlesDB(props) {
  let history = useHistory();
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [showRecord, setShowRecord] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();
  const [selectedOptions, setSelectedOptions] = useState("All");
  const [assetFillter, setAssetFillter] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const lastLocation = useLastLocation();
  const [isFilterAvailable, setIsFilterAvailable] = useState(false);
  const prevDependency = useRef(isFilterAvailable);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");// eslint-disable-next-line
  const {filterCategory, setFilterCategory} = useContext(FilterParaContext)


  useEffect(() => {
    setFilterCategory({
      search:globalFilter,
      status:selectedOptions,
      assetType:assetFillter
    })
    setIsFilterAvailable(!isFilterAvailable); // eslint-disable-next-line
  }, [globalFilter, selectedOptions, setSelectedOptions, assetFillter]);
  useEffect(() => {
    if (prevDependency.current !== isFilterAvailable) {
      // Dependency changed from the previous value
      if (isFilterAvailable === true) {
        // Do something when dependency becomes true
        localStorage.removeItem("lastVisitedRow");
        localStorage.removeItem("pageNoArticle");
        localStorage.removeItem("showRecords");
        setPageNo(1);
        GET_STORIES(); // eslint-disable-next-line
        // console.log('Dependency changed to true');
      } else {

        GET_STORIES(); // eslint-disable-next-line
        // Do something when dependency becomes false
        // console.log('Dependency changed to false');
      }
      prevDependency.current = isFilterAvailable; // Update the previous value
    } // eslint-disable-next-line
  }, [isFilterAvailable]);
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    if (lastLocation?.pathname.includes("/Article/Update")) {
      // localStorage.removeItem("pageNoArticle");
      //     localStorage.removeItem("lastVisitedRow")
    } else if (!lastLocation?.pathname.includes("/Article/Update")) {
      localStorage.removeItem("lastVisitedRow");
      // localStorage.removeItem("pageNoArticle");
    }
    GET_STORIES(); // eslint-disable-next-line
  }, [
    showRecord,
    pageNo,
    selectedOptions,
    setSelectedOptions,
    assetFillter,
    setLoading,
    history,
    globalFilter,
    sortColumn,
    sortOrder,
  ]);
  useEffect(() => {
    // lastVisitedRow
    setLoading(true);
    setIsFilterAvailable(false); // eslint-disable-next-line
    if (lastLocation?.pathname.includes("/Article/Update")) {
      // localStorage.removeItem("lastVisitedRow");
      // localStorage.setItem("pageNoArticle", pageNo);
      //     localStorage.removeItem("lastVisitedRow")
    } else if (!lastLocation?.pathname.includes("/Article/Update")) {
      localStorage.removeItem("lastVisitedRow");
      localStorage.removeItem("pageNoArticle");
      localStorage.removeItem("showRecords");
    } // eslint-disable-next-line
  }, []);

  // Get API
  const GET_STORIES = () => {
    // Define the query parameters as an object
    // console.log( Number(localStorage.getItem("pageNoArticle")) ,"page No")

    setShowRecord(
      Number(localStorage.getItem("showRecords"))
        ? Number(localStorage.getItem("showRecords"))
          ? Number(localStorage.getItem("showRecords"))
          : 10
        : 10
    );
    const queryParams = {
      limit: Number(localStorage.getItem("showRecords"))
        ? Number(localStorage.getItem("showRecords"))
          ? Number(localStorage.getItem("showRecords"))
          : 10
        : 10,
      status: selectedOptions === "All" ? "" : selectedOptions,
      assetType: assetFillter.length > 0 ? ArrayToString(assetFillter) : "",
      page: Number(localStorage.getItem("pageNoArticle"))
        ? Number(localStorage.getItem("pageNoArticle"))
          ? Number(localStorage.getItem("pageNoArticle"))
          : pageNo
        : 1,
      flag: 1,
      filter: globalFilter == null ? "" : globalFilter,
      sortColumn: sortColumn ? sortColumn : "created_at",
      sortOrder: sortOrder ? sortOrder : "desc",
    };

    const queryParamsArray = Object.entries(queryParams)
      .filter(([key, value]) => value !== "") // Filter out empty values
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`);

    // Construct the full URL with the query parameters
    const apiUrl = `${STORIES}?${queryParamsArray.join("&")}`;
    axiosInstance
      .get(apiUrl)
      .then((response) => {
        setData(response?.data?.stories);
        setTotalRecord(response?.data?.pagination?.totalStories);
        // console.log(response?.data?.pagination?.currentPage);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
        localStorage.setItem(
          "totalArticles",
          response?.data?.pagination?.totalStories
        );
        setPageNo(response?.data?.pagination?.currentPage);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        setTotalRecord(1);
        setCurrentPage(1);
        setTotalPage(1);
        setData([]);
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.message,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const columns = [
    {
      Header: (
        <div className="flex flex-row gap-2 cursor-pointer">
          <Tooltip
            className={TooltipStlye}
            content="Click to Sort by Article Title"
          >
            <span onClick={() => handleSort("article_title")}>
              Article Title
            </span>
          </Tooltip>

          {sortColumn === "article_title" ? (
            sortOrder === "asc" ? (
              <ArrowLongUpIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("article_title")}
              />
            ) : (
              <ArrowLongDownIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("article_title")}
              />
            )
          ) : null}
        </div>
      ),
      accessor: "article_title",
      disableSortBy: true,
      Cell: ({ value, row }) => {
        return (
          <Tooltip style={{maxWidth:"350px"} }  placement="top-start" offset={15} className={TooltipStlyeForData} content={value}>
            <p
              className="line-clamp-1 cursor-pointer text-left	ml-4"
              onClick={(e) => handleClick(e, row)}
            >
              {value}
            </p>
          </Tooltip>
        );
      },
      headerStyle: { textAlign: "center" },
      minWidth: 350,
    },
    {
      Header: (
        <div className="flex flex-row gap-2 cursor-pointer">
          <Tooltip className={TooltipStlye} content="Click to Sort by Date">
            <span onClick={() => handleSort("created_at")}>Date</span>
          </Tooltip>

          {sortColumn === "created_at" ? (
            sortOrder === "asc" ? (
              <ArrowLongUpIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("created_at")}
              />
            ) : (
              <ArrowLongDownIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("created_at")}
              />
            )
          ) : null}
        </div>
      ),
      accessor: "created_at",
      headerStyle: { textAlign: "center" },
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <span>
          {value
            ? moment(value, "YYYY-MM-DD hh:mm:ss").format("MM-DD-YYYY")
            : "--"}
        </span>
      ),
      minWidth: 100,
    },
    {
      Header: "Project Name",
      accessor: "project_title",
      disableSortBy: true,
      Cell: ({ value, row }) => {
        return (
          <Tooltip offset={15} className={TooltipStlyeForData} content={value}>
            <p className="line-clamp-1 cursor-pointer text-left w-52 mr-4">
              {value ? value : "--"}
            </p>
          </Tooltip>
        );
      },
      minWidth: 150,
      maxWidth: 150,
    },
    {
      Header: (
        <div className="flex flex-row gap-2 cursor-pointer">
          <Tooltip className={TooltipStlye} content="Click to Sort by Location">
            <span onClick={() => handleSort("StreetAddress")}>Location</span>
          </Tooltip>

          {sortColumn === "StreetAddress" ? (
            sortOrder === "asc" ? (
              <ArrowLongUpIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("StreetAddress")}
              />
            ) : (
              <ArrowLongDownIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("StreetAddress")}
              />
            )
          ) : null}
        </div>
      ),
      accessor: "StreetAddress",
      disableSortBy: true,
      headerStyle: { textAlign: "right" },
      Cell: ({ cell: { value } }) => (
        <Tooltip
          className={`${TooltipStlyeForData} max-w-screen-md`}
          content={value ?value:"-"}
        >
          <span className="capitalize line-clamp-1 text-left">
            {value ? value : "--"}
          </span>
        </Tooltip>
      ),
      minWidth: 120,
      maxWidth:150
    },
    {
      Header: (
        <div className="flex flex-row gap-2 cursor-pointer">
          <Tooltip className={TooltipStlye} content="Click to Sort by Source">
            <span onClick={() => handleSort("weburl")}>Source</span>
          </Tooltip>

          {sortColumn === "weburl" ? (
            sortOrder === "asc" ? (
              <ArrowLongUpIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("weburl")}
              />
            ) : (
              <ArrowLongDownIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("weburl")}
              />
            )
          ) : null}
        </div>
      ),
      accessor: "weburl",
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <span className="line-clamp-1 cursor-pointer text-left	mx-4">
          {value ? (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "rgb(87 109 105)" }} // Change 'blue' to your desired color
            >
              {value.trim()}
            </a>
          ) : (
            "--"
          )}
        </span>
      ),

      minWidth: 100,
    },
    {
      Header: (
        <div className="flex flex-row gap-2 cursor-pointer">
          <Tooltip
            className={TooltipStlye}
            content="Click to Sort by AssetType"
          >
            <span onClick={() => handleSort("AssetType")}>Asset Type</span>
          </Tooltip>

          {sortColumn === "AssetType" ? (
            sortOrder === "asc" ? (
              <ArrowLongUpIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("AssetType")}
              />
            ) : (
              <ArrowLongDownIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("AssetType")}
              />
            )
          ) : null}
        </div>
      ),
      accessor: "AssetType",
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <Tooltip

          className={`${TooltipStlyeForData}  max-w-xs`}
          content={value ?value:"-"}
        >
          <span className="capitalize line-clamp-1 text-left">
          {value ? value.replace(",", ", ") : "--"}
          </span>
        </Tooltip>
      ),
      minWidth: 150,
      maxWidth:150
    },

    {
      Header: (
        <div className="flex flex-row gap-2 cursor-pointer">
          <Tooltip className={TooltipStlye} content="Click to Sort by Status">
            <span onClick={() => handleSort("Status")}>Status</span>
          </Tooltip>
          {sortColumn === "Status" ? (
            sortOrder === "asc" ? (
              <ArrowLongUpIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("Status")}
              />
            ) : (
              <ArrowLongDownIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("Status")}
              />
            )
          ) : null}
        </div>
      ),
      accessor: "Status",
      disableSortBy: true,
      Cell: ({ cell: { value, row } }) => <div className="mx-2"><StatusChip values={value} /></div>,
      minWidth: 170,
      maxWidth: 170,
    },
    {
      Header: (
        <div className="flex flex-row gap-2 cursor-pointer">
          <Tooltip className={TooltipStlye} content="Click to Sort by Comment">
            <span onClick={() => handleSort("comment")}>Comment</span>
          </Tooltip>
          {sortColumn === "comment" ? (
            sortOrder === "asc" ? (
              <ArrowLongUpIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("comment")}
              />
            ) : (
              <ArrowLongDownIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
                onClick={() => handleSort("comment")}
              />
            )
          ) : null}
        </div>
      ),
      accessor: "comment",
      Cell: ({ cell: { value } }) => {
        if (value === null || value === "null") {
          return "--";
        } else {
          return value;
        }
      },
      width: 50,
      disableSortBy: true,
    },
    {
      id: "action",
      accessor: "",
      disableSortBy: true,
      Cell: (row) => (
        <ActionCol
          shiftToProject
          deletedStories
          jumpToArticle
          // publish
          // unPublished
          //   showResubmit
          data={row.row.original}
          GET_API={GET_STORIES}
        />
      ),
      width: 50,
    },
  ];

  // Handle on title click
  const handleClick = (e, row) => {
    localStorage.setItem(
      "rowId",
      JSON.stringify(row?.original?.ProjectDetailsId)
    );
    localStorage.setItem("lastVisitedRow", row.id);

    history.push({
      pathname: `/Article/Update/${row?.original?.ProjectDetailsId}`,
      data: row,
    });
  };
  return (
    <div>
      <Loader loading={loading} />
      {!loading && (
        <Table
          setPageNo={setPageNo}
          pageNo={pageNo}
          showRecord={showRecord}
          setShowRecord={setShowRecord}
          totalRecord={totalRecord}
          currentPage={currentPage}
          totalPage={totalPage}
          columns={columns}
          data={data}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          statusFillter
          maxHeight={props?.height}
          noSearch={props?.noSearch}
          assetFillter={assetFillter}
          setAssetFillter={setAssetFillter}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          noPagination={props?.project}
        />
      )}
    </div>
  );
}
