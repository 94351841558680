import React, { useContext, useEffect, useState } from "react";
import JumpToTop from "../ui/JumpToTop";
import axiosInstance from "../../services/APIInstance";
import { PROJECTLIST } from "../../services/APIGlobal";
import MapHome from "../../pages/home/MapHome";
import FilterContext from "../../context/FilterContext";
import ProjectListCard from "../ui/ProjectListCard";
import { useLocation } from "react-router-dom";
import { ArrayToString } from "../../store/helper/Helper";
import { showMessage } from "../ui/showMessage";
import MobileNewHomeOutline from "./MobileNewHomeOutline";

export default function NewHomePageOutline() {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [stories, setStories] = useState([]);
  const [projectHighlightId, setProjectHighlightId] = useState("");
  const { filterArray, isFavouriteTab } = useContext(FilterContext);
  const [projectTotalCount, setProjectTotalCount] = useState("0");

  const location = useLocation();
  useEffect(() => {
    GET_STORIES(); // eslint-disable-next-line
  }, [location, isFavouriteTab, filterArray]);

  // Get API

  const GET_STORIES = () => {
    const bounds = localStorage.getItem("bounds");
    setLoading(true);
    axiosInstance
      .get(
        `${PROJECTLIST}?flag=1&page=1&limit=1000&isDraft=no&isPublish=Yes&userId=${localStorage.getItem(
          "userId"
        )} ${bounds ? "&bounds=" + bounds : ""
        }${filterArray?.SEARCH ? "&searchQuery=" + filterArray?.SEARCH : ""
        }&sort=new&dateRange=${filterArray?.DATE ? filterArray?.DATE : 0}${filterArray?.ASSETTYPE?.length > 0
          ? `&assetType=${encodeURIComponent(
            ArrayToString(filterArray?.ASSETTYPE)
          )}`
          : ""
        }${filterArray?.PROJECTSTATUS?.length > 0
          ? `&projectStatus=${ArrayToString(filterArray?.PROJECTSTATUS)}`
          : ""
        }${filterArray?.FAVOURITE === true ? "&isFav=1" : ""}`
      )
      .then((response) => {
        // console.log(response,"esponse?.data.projects")

        setStories(response?.data.projects);
        setProjectTotalCount(response?.data?.pagination?.totalProjects);
        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  return (
    <section id="view" className="w-full h-full">
      <JumpToTop />
      <div className="md:flex hidden min-w-full md:flex-row flex-col gap-4 h-full">
        {/* Map */}
        <div className="md:w-3/5 ">
          <MapHome
            APItype={isFavouriteTab ? "Favourite" : "Project"}
            stories={stories}
            setStories={setStories}
            assetFillter=""
            dateRangeValue="0"
            projectHighlightId={projectHighlightId}
            GET_STORIES={GET_STORIES}
            projectTotalCount={projectTotalCount}
            filterArray={filterArray}
          />
           {/* <FlyToMap /> */}
        </div>
        {/* Listing */}
        <div className="md:w-2/5 md:max-w-2/5">
          <ProjectListCard
            APItype={isFavouriteTab ? "Favourite" : "Project"}
            setProjectHighlightId={setProjectHighlightId}
            stories={stories}
            GET_STORIES={GET_STORIES}
            projectTotalCount={projectTotalCount}
            filterArray={filterArray}
          />
        </div>
      </div>
      <div className="md:hidden block h-full">
        <MobileNewHomeOutline />
      </div>{" "}
    </section>
  );
}
