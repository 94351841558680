import React from "react";
import MainHeader from "./header/MainHeader";

export default function PublicLayout({ children }) {
  return (
    <>
      <div className="sticky inset-0  rounded-none z-10 bg-white">
        <MainHeader />
        {/* <div className="relative m-2 w-full-10 md:hidden block">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-primary-black"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            className="block w-full p-2 pl-10 text-primary-revado-black  border border-gray-300 rounded-lg bg-primary-revado-white focus:ring-4 focus:ring-primary-hover focus:outline-offset-0"
            placeholder="Search..."
          />
        </div> */}
      </div>

      <div className=" public-content overflow-y-auto p-2 -z-0">
        <main>{children}</main>
      </div>
    </>
  );
}
