import React from "react";
import {
  Card,
  // Tab,
  // TabPanel,
  // Tabs,
  // TabsBody,
  // TabsHeader,
} from "@material-tailwind/react";
import ReviewArticlesDB from "../../../../components/datatables/ReviewArticlesDB";

// const data = [
//   {
//     label: "New Articles",
//     value: "new",
//     desc: <ReviewArticlesDB />,
//   },
//   {
//     label: "Resubmitted Articles",
//     value: "updated",
//     desc: <ReviewArticlesDB isResubmitted />,
//   },
// ];

export default function Review() {
  return (
    <Card className="h-full w-full border-t">
      {/* <Tabs value="new">
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab key={value} value={value}>
              <div className="flex items-center gap-2">
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="flex flex-col">
          <p className="pl-3 pt-2 text-xs text-gray-600">Note: To change Status click on status value</p>
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs> */}
      <ReviewArticlesDB />
    </Card>
  );
}
