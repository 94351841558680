import Switch from "react-switch";
import React, { useState } from "react";
import { Tooltip } from "@material-tailwind/react";
import { TooltipStlye } from "../../utils/Constant";
import { useHistory } from "react-router-dom";
/* styles.css */

//    const react-switch = {
//     vertical-align: middle;
//     margin-left: 4px;
//   }
const ToggleView = ({ label, value, role }) => {
  const history = useHistory();
  const [checked, setChecked] = useState(value);
  const handleChange = (nextChecked) => {
    // console.log(nextChecked);
    localStorage.removeItem("zoom");
    localStorage.removeItem("bounds");
    setChecked(nextChecked);
    if (nextChecked === false) {
      role !== "Admin" ? history.push("/Projects") : history.push("/Projects");
    } else if (nextChecked === true) {
      history.push("/projectList");
    }
  };

  return (
    <div className=" flex flex-row gap-2 items-center ">
      <p className="   text-center text-white   bg-primary-revado   focus:ring-primary-hover md:font-bold rounded-lg lg:text-md md:text-sm text-sm">
        {role}
      </p>
      <Tooltip content={`Changed to ${label} Mode`} className={TooltipStlye}>
        <label htmlFor="small-radius-switch" className="mt-2">
          <Switch
            checked={checked}
            onChange={handleChange}
            onColor="#667471"
            onHandleColor="#eceeed"
            offColor="#d6e2e2"
            offHandleColor="#f4f7f7"
            handleDiameter={15}
            borderRadius={6}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 10)"
            height={20}
            width={48}
            className="react-switch "
            id="material-switch"
          />
        </label>
      </Tooltip>
      <p className="   text-center text-white   bg-primary-revado   focus:ring-primary-hover md:font-bold rounded-lg lg:text-md md:text-sm text-sm">
        User
      </p>
    </div>
  );
};

export default ToggleView;
