import React, { createContext, useEffect } from "react";
import { useState } from "react";

const SearchContext = createContext({});

export const SearchProvider = ({ children }) => {
  const [searchDefaultValue, setSearchDefaultValue] = useState("");
  const [searchValue, setSearchValue] = useState(searchDefaultValue);
  useEffect(() => {
    setSearchValue(searchDefaultValue);
  }, [searchDefaultValue]);
  // console.log(searchValue,"searchValue")
  return (
    <SearchContext.Provider
      value={{
        searchValue,
        setSearchValue,
        searchDefaultValue,
        setSearchDefaultValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
