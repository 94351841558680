import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { Button, Card } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import ImageUploader from "../../../../components/functionality/ImageUploader";
import PdfUploader from "../../../../components/functionality/PdfUploader";
import InputField from "../../../../components/ui/InputField";
import Loader from "../../../../components/ui/Loader";
import DragableMarker from "../../../../components/ui/Map/DragableMarker";
import MarkerLocationChange from "../../../../components/ui/Map/MarkerLocationChange";
import ProjectSummaryTextarea from "../../../../components/ui/ProjectSummary";
import { showMessage } from "../../../../components/ui/showMessage";
import {
  CREATE_NEW_PROJECT,
  PROJEPUBLICHEARINGS,
  SAVE_AS_DRAFT,
  UPLOAD_IMAGES,
  UPLOAD_PDF,
} from "../../../../services/APIGlobal";
import axiosInstance from "../../../../services/APIInstance";
import multiPartAxiosInstance from "../../../../services/ApiMultipartInstance";
import { TransformData, getFormJSON } from "../../../../store/helper/Helper";
import { errorFieldMessage } from "../../../../utils/Constant";
import ArticleProjectTeam from "../Articles/ArticleProjectTeam";
import AdminSideProjectDetails from "./AdminSideProjectDetails";
import ProjectAssetsType from "./ProjectAssetsType";
import ProjectAttribute from "./ProjectAttribute";
import ProjectStatus from "./ProjectStatus";
import PublicHearings from "./PublicHearings";

export default function NewCreateProject(props) {
  const history = useHistory();
  const [location, setLocation] = useState({});
  const [textSummary, setTextSummary] = useState("");
  const [openInfo, setOpenInfo] = useState();
  const [meetings, setMeetings] = useState([]);
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null); // eslint-disable-next-line
  const [addressText, setAddressText] = useState([]);
  const [images, setImages] = useState([]);
  const [assetType, setAssetType] = useState([]);
  const [projectStatusType, setProjectStatusType] = useState([]);
  const [defaultMarker] = useState({});
  const [attributeValue, setAttributeValue] = useState([]);
  const [attributeValue2, setAttributeValue2] = useState("");
  const [attributeValue3, setAttributeValue3] = useState("");
  const [attributeValue4, setAttributeValue4] = useState("");
  const [attributeName, setAttributeName] = useState("");
  const [attributeName2, setAttributeName2] = useState("");
  const [attributeName3, setAttributeName3] = useState("");
  const [attributeName4, setAttributeName4] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectById, setProjectById] = useState("");
  const [showImageError, setShowImageError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const textareaRefs = useRef([]);
  const [buttonLabel, setButtonLabel] = useState("Create and Publish");
  const [pdfFiles, setPdfFiles] = useState([]);

  useEffect(() => {
    setMeetings([
      {
        MeetingType: "",
        status: "",
        MeetingDate: "",
        UrlLink: "",
      },
      {
        MeetingType: "",
        status: "",
        MeetingDate: "",
        UrlLink: "",
      },
      {
        MeetingType: "",
        status: "",
        MeetingDate: "",
        UrlLink: "",
      },
      {
        MeetingType: "",
        status: "",
        MeetingDate: "",
        UrlLink: "",
      },
    ]);
  }, [history]);

  // * COMPONENT LABEL *
  const Label = (props) => (
    <label className="flex flex-row items-center  text-gray-500 text-sm font-medium">
      {props.text}
      {props.subtext && (
        <span className="text-gray-500 pl-1">{props.subtext}</span>
      )}
      {props.isRequired && <span className="ml-1 text-red-500">*</span>}
    </label>
  );

  const handleOpen = (story) => {
    setOpen(!open);
    setProjectById(story);
  };
  // * PROJECT LIST *
  const PROJECT_LIST = [
    {
      label: "Public Hearings",
      value: "Public Hearings",
      desc: (
        <div className="p-2">
          <div className="flex md:flex-row flex-col gap-4 mb-2">
            <div className=" md:w-1/2 ">
              <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start">
                Meeting Type
              </p>
            </div>
            <div className=" md:w-1/2 ">
              <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start">
                Status{" "}
              </p>
            </div>
            <div className=" md:w-1/2 ">
              <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start">
                Meeting Date{" "}
              </p>
            </div>
            <div className=" md:w-1/2 ">
              <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start">
                URL link
              </p>
            </div>
          </div>
          <div className="flex  flex-col gap-3 w-full">
            <PublicHearings meetings={meetings} setMeetings={setMeetings} />
          </div>
        </div>
      ),
    },
    {
      label: "Project Team",
      value: "article",
      desc: (
        <ArticleProjectTeam
          setOpen={setOpenInfo}
          open={openInfo}
          loading={false}
        // API={useApiGETRequest(`${PROJECTLIST}/${ProjectId}`)}
        />
      ),
    },
  ];

  // * HANDLE SELECT LOCATION *
  const handleSelectLocation = (cordinates) => {
    const location = {
      StreetAddress: cordinates.streetNumber,
      StreetIntersections: cordinates.streetIntersections,
      ParcelId: "",
      City: cordinates.city,
      ZipCode: cordinates.zipCode,
      LocationId: "",
      Latitude: cordinates.latitude,
      Longitude: cordinates.longitude,
      State: cordinates?.state,
    };

    const streetAddress =
      cordinates.streetNumber !== "Undefined" ? cordinates.streetNumber : "";
    setAddressText(
      `${streetAddress}, ${cordinates?.streetIntersections
        ? cordinates.streetIntersections + ", "
        : ""
      }${cordinates?.city} , ${cordinates?.state}- ${cordinates?.zipCode}`
    );
    setLocation(location);
  };

  // * HANDLE SUBMIT FORM  *
  const handleSubmitForm = (e) => {
    const buttonName = e.nativeEvent.submitter.name;
    const payload = getFormJSON(e.target);
    e.preventDefault();
    if (buttonName !== "saveAsDraft") {
      if (payload?.title === " ") {
        setFormErrors({ title: true });
        return;
      } else if (!location?.Longitude) {
        setFormErrors({ marker: true });
        return;
      }
      // console.log(btnName, "btnName");
      else if (images.length === 0) {
        setShowImageError(true);
        return;
      } else if (payload.StreetAddress === " ") {
        setFormErrors({ address: true });
        textareaRefs.current[0].focus();
        // Scroll to the textarea element
        textareaRefs.current[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      } else if (textSummary === " ") {
        setFormErrors({ summaray: true });
        textareaRefs.current[1].focus();
        // Scroll to the textarea element
        textareaRefs.current[1].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      } else if (!assetType || assetType.length === 0) {
        setFormErrors({ assetTypeError: true });
        textareaRefs.current[2].focus();
        // Scroll to the textarea element
        textareaRefs.current[2].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      } else if (!projectStatusType || projectStatusType.length === 0) {
        setFormErrors({ projectstatusError: true });
        textareaRefs.current[3].focus();
        // Scroll to the textarea element
        textareaRefs.current[3].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    const selectedAssetType = Array.isArray(assetType)
      ? assetType.join(",")
      : assetType || null;

    const selectedProjectStatusType = Array.isArray(projectStatusType)
      ? projectStatusType.join(",")
      : projectStatusType || null;

    const selectedAttributesNames = [
      attributeName,
      attributeName2,
      attributeName3,
      attributeName4
    ].filter(name => name);

    const projectAttributes = [
      attributeValue,
      attributeValue2,
      attributeValue3,
      attributeValue4
    ];

    const selectedAttributesValues = projectAttributes
      .filter(item => typeof item === "object" && item !== null && Object.keys(item).length > 0)
      .map(item => Object.values(item)[0])
      .join(",");

    const cleanedMeetings = meetings
      .filter((meeting) =>
        Object.values(meeting).some((value) => value !== null && value !== "")
      )
      .map((meeting) =>
        Object.fromEntries(
          Object.entries(meeting).filter(([_, v]) => v !== null && v !== "")
        )
      );
    setMeetings([]);
    const projectDetails = {
      title: payload.title,
      ProjectSummary: textSummary,
      AssetType: selectedAssetType,
      project_status_type: selectedProjectStatusType,
      Developer: payload.Developer,
      GeneralContractor: payload.GeneralContractor,
      Contractor: payload.Contractor,
      Owner: payload.Owner,
      Architect: payload.Architect,
      Planner: payload.Planner,
      Investor: payload.Investor,
      Lender: payload.Lender,
      Bank: payload.Bank,
      CivilEngineer: payload.CivilEngineer,
      Engineer: payload.Engineer,
      Broker: payload.Broker,
      Brokerage: payload.Brokerage,
      Tenant: payload.Tenant,
      comment: payload.comment,
      TypeUser: payload.TypeUser,
      StreetAddress: payload.StreetAddress,
      // StreetIntersections: location.StreetIntersections,
      // ParcelId: location.ParcelId,
      // City: location.City,
      // ZipCode: location.ZipCode,
      Latitude: location.Latitude,
      Longitude: location.Longitude,
      State: location.State || null,
      IsPublish: "Yes",
      flag: 1,
      attribute_values: selectedAttributesValues.length > 0 ? selectedAttributesValues : null,
      attribute_names:
        selectedAttributesNames.length > 0
          ? selectedAttributesNames.join(",")
          : null,
      startdate: startdate,
      enddate: enddate,
      link_to_source: projectLink,
    };
    // * PROJECT IMAGES *
    const formData = new FormData();
    images.forEach((image) => {
      formData.append(`images`, image.file);
    });
    // ProjectPDF
    const pdfData = new FormData();
    pdfFiles.forEach((pdf) => {
      pdfData.append(`pdf`, pdf);
    });

    // if (!location.Longitude) {
    //   showMessage(
    //     "Please select marker",
    //     "error",
    //     "red",
    //     false,
    //     false,
    //     false
    //   );
    //   return;
    // } else
    // if (btnName === "submitButton") {  if (images.length === 0) {
    if (buttonName === "saveAsDraft") {
      if (images.length > 0) {
        setLoading(true);
        fileUpload(formData)
          .then((imageUrls) => {
            projectDetails.images = imageUrls;
          })

          .catch((error) => {
            setLoading(false);
            console.log(error);
            showMessage(
              "Could not upload image",
              "error",
              "red",
              false,
              false,
              false
            );
          });
      }
      if (pdfFiles.length > 0) {
        pdfUpload(pdfData)
          .then((pdfData) => {
            projectDetails.pdfUrls = pdfData;
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            showMessage(
              "Could not upload pdf",
              "error",
              "red",
              false,
              false,
              false
            );
          });
      }
      multiPartAxiosInstance
        .post(`${SAVE_AS_DRAFT}`, projectDetails)
        .then((response) => {
          // console.log(response?.data);
          // eslint-disable-next-line
          let data = TransformData(response?.data);
          history.push({
            pathname: `/Projects`,
          });
          localStorage.setItem(
            "project",
            JSON.stringify(response?.data?.project?.projectId)
          );
          showMessage(
            "Project saved successfully",
            "success",
            "#576d69",
            false,
            false,
            false
          );
          let MEETINGDATA = {
            publicHearingsDataArray: cleanedMeetings,
          };
          axiosInstance
            .put(
              `${PROJEPUBLICHEARINGS}?projectId=${response?.data?.project?.projectId}`,
              MEETINGDATA
            )
            .then((response) => { })
            .catch((error) => {
              setLoading(false);
              showMessage(
                error?.response?.data?.error,
                "error",
                "red",
                false,
                false,
                false
              );
            });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        });
    } else if (buttonName === "submitForm") {
      setLoading(true);
      if (pdfFiles.length > 0) {
        pdfUpload(pdfData)
          .then((pdfData) => {
            projectDetails.pdfUrls = pdfData;
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            showMessage(
              "Could not upload pdf",
              "error",
              "red",
              false,
              false,
              false
            );
          });
      }
      fileUpload(formData)
        .then((imageUrls) => {
          projectDetails.images = imageUrls;
          multiPartAxiosInstance
            .post(`${CREATE_NEW_PROJECT}`, projectDetails)
            .then((response) => {
              // console.log(response?.data);
              let data = TransformData(response?.data);
              localStorage.setItem(
                "project",
                JSON.stringify(response?.data?.project?.projectId)
              );

              history.push({
                pathname: `/ProjectDetail/${response?.data?.project?.projectId}`,
                data: data,
                title: "Edit",
              });
              setButtonLabel("Published!");
              setTimeout(() => {
                setButtonLabel("Create and Publish");
              }, 5000);
              showMessage(
                "Project saved successfully",
                "success",
                "#576d69",
                false,
                false,
                false
              );

              let MEETINGDATA = {
                publicHearingsDataArray: cleanedMeetings,
              };
              axiosInstance
                .put(
                  `${PROJEPUBLICHEARINGS}?projectId=${response?.data?.project?.projectId}`,
                  MEETINGDATA
                )
                .then((response) => { })
                .catch((error) => {
                  setLoading(false);

                  console.log(error);
                  showMessage(
                    error?.response?.data?.error,
                    "error",
                    "red",
                    false,
                    false,
                    false
                  );
                });
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
              showMessage(
                error?.response?.data?.error,
                "error",
                "red",
                false,
                false,
                false
              );
            });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        });
    } else if (buttonName === "projectPreview") {
      projectDetails.imageUrls = images.map((item) => item.data_url);
      projectDetails.pdfUrls = pdfFiles.map((item) => item);

      // if (pdfFiles.length > 0) {
      //   pdfUpload(pdfData)
      //     .then((pdfData) => {
      //       projectDetails.pdfUrls = pdfData;
      //     })
      //     .catch((error) => {
      //       setLoading(false);
      //       console.log(error);
      //       showMessage(
      //         "Could not upload pdf",
      //         "error",
      //         "red",
      //         false,
      //         false,
      //         false
      //       );
      //     });
      // }
      handleOpen(projectDetails);
    }
  };

  function fileUpload(formData) {
    return new Promise((resolve, reject) => {
      multiPartAxiosInstance
        .post(`${UPLOAD_IMAGES}`, formData)
        .then((response) => {
          resolve(response?.data?.imageUrl);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // eslint-disable-next-line
  function pdfUpload(formData) {
    return new Promise((resolve, reject) => {
      multiPartAxiosInstance
        .post(`${UPLOAD_PDF}`, formData)
        .then((response) => {
          resolve(response?.data?.pdfUrl);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const handleAddress = (e) => {
    setAddressText(e.target.value);
  };

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <form onSubmit={handleSubmitForm} className="flex flex-col gap-2 px-2 pt-2  pb-16 lg:pb-5 ">
          <Card className="border-t-2 m-0 ">
            <div className="shadow border-b border-gray-200 rounded-lg ">
              <section className="p-2 flex  flex-col gap-3 w-full">
                {/* Information */}
                <div className="flex justify-end gap-2">
                  {/* Form Button */}
                  <Button
                    name="submitForm"
                    size="sm"
                    color="white"
                    className={`max-w-fit flex items-center rounded-md text-primary-primary  border-primary-primary w-full shadow-none hover:bg-transparent hover:shadow-none hover:ring-0 focus:ring-0 focous:bg-transparent ${buttonLabel === "Published!" ? "bg-primary-primary" : ""
                      }`}
                    variant="outlined"
                    type="submit"
                    ripple={false}
                  >
                    {buttonLabel}
                  </Button>
                  <Button
                    name="projectPreview"
                    size="sm"
                    color="white"
                    className="max-w-fit flex items-center rounded-md text-white  bg-primary-primary w-full shadow-none hover:shadow-none hover:ring-0 focus:ring-0 focous:bg-primary-primary"
                    variant="outlined"
                    type="submit"
                    ripple={false}
                  >
                    Project Preview
                  </Button>
                  <Button
                    name="saveAsDraft"
                    size="sm"
                    color="white"
                    className="max-w-fit flex items-center rounded-md text-primary-primary border   w-full shadow-none hover:shadow-none hover:ring-0 focus:ring-0 focous:bg-primary-primary"
                    variant="text"
                    type="submit"
                    ripple={false}
                  >
                    Save As Draft
                  </Button>
                </div>

                {/* Common Info */}
                <div className=" flex flex-col gap-1">
                  <Label text="Project Title" isRequired={true} />
                  <textarea
                    id="title"
                    name="title"
                    rows="1"
                    placeholder="Enter Project Title"
                    onChange={(e) => setFormErrors({ title: false })}
                    className={`mt-1 peer w-full px-3 py-2 z-0   placeholder-gray-500  border ${formErrors.title
                      ? "border-warn-error"
                      : "border-gray-300 "
                      } rounded-md focus:outline-none  focus:z-10 sm:text-sm 2xl:text-base`}
                  />
                  {formErrors.title && (
                    <span className={errorFieldMessage}>
                      Please enter Project Title
                    </span>
                  )}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
                  <div>
                    <Label text="Project Location" isRequired={true} />
                    <p className="text-xs text-gray-500 mb-2">
                      Location is not available. Please search and add new
                      "Marker"
                    </p>
                    {/* Map Section */}
                    <div
                      className={`hidden md:block rounded-md ${formErrors.marker && "border-warn-error border-2"
                        } `}
                    >
                      <MarkerLocationChange
                        cordinates={{
                          longitude: location?.Longitude,
                          latitude: location?.Latitude,
                        }}
                        setCordinates={handleSelectLocation}
                        style={{
                          width: "100%",
                          height: 285,
                          borderRadius: "0.375rem",
                        }}
                        defaultMarker={defaultMarker}
                        setFormErrors={setFormErrors}
                      />
                    </div>
                    <div className="md:hidden block">
                      <p className="text-xs text-gray-500 mb-2">
                        Drag Marker to change location
                      </p>
                      <DragableMarker
                        cordinates={{
                          longitude: location?.Longitude,
                          latitude: location?.Latitude,
                        }}
                        style={{
                          width: "100%",
                          height: 285,
                          borderRadius: "0.375rem",
                        }}
                        setCordinates={handleSelectLocation}
                      />
                    </div>
                    {formErrors.marker && (
                      <span className={errorFieldMessage}>
                        Please select location
                      </span>
                    )}
                  </div>

                  <div>
                    <Label
                      text="Project Images"
                      subtext="(maximum of 5)"
                      isRequired={true}
                    />
                    <div className="mt-6">
                      <ImageUploader
                        images={images}
                        setImages={setImages}
                        showImageError={showImageError}
                        setShowImageError={setShowImageError}
                      />
                    </div>

                    {showImageError && !images?.length > 0 && (
                      <span className={errorFieldMessage}>
                        Upload atleast one image
                      </span>
                    )}
                  </div>
                </div>

                <div className="">
                  <Label text="Address" isRequired={true} />
                  <textarea
                    ref={(el) => (textareaRefs.current[0] = el)}
                    //required={true}
                    //   onChange={(e) => setFormErrors({ title: false })}
                    onChange={(e) => {
                      handleAddress(e);
                      setFormErrors({ title: false });
                    }}
                    id="StreetAddress"
                    name="StreetAddress"
                    rows="1"
                    placeholder="Project Address"
                    className={`mt-1 peer w-full px-3 py-2 z-0 placeholder-gray-500  border ${formErrors.address
                      ? "border-warn-error"
                      : "border-gray-300 "
                      } rounded-md focus:outline-none  focus:z-10 sm:text-sm 2xl:text-base`}
                  />
                  {formErrors.address && (
                    <span className={errorFieldMessage}>Enter Address</span>
                  )}
                </div>

                <div className=" ">
                  <Label text="Project Summary" isRequired={true} />
                  <ProjectSummaryTextarea
                    textSummary={textSummary}
                    setTextSummary={setTextSummary}
                    setFormErrors={setFormErrors}
                  />
                  {formErrors.summaray && (
                    <span className={errorFieldMessage}>
                      Enter Project Summary
                    </span>
                  )}
                </div>

                <div>
                  <Label text="Asset Type" isRequired={true} />
                  <ProjectAssetsType
                    assetType={assetType}
                    setAssetType={setAssetType}
                    formErrors={formErrors}
                    textareaRefs={textareaRefs}
                  />
                  {formErrors?.assetTypeError && assetType.length === 0 && (
                    <span className={errorFieldMessage}>Select Asset Type</span>
                  )}
                </div>

                <div>
                  <Label text="Project Status" isRequired={true} />
                  <ProjectStatus
                    projectStatusType={projectStatusType}
                    setProjectStatusType={setProjectStatusType}
                    textareaRefs={textareaRefs}
                    formErrors={formErrors}
                  />
                  {formErrors?.projectstatusError &&
                    projectStatusType.length === 0 && (
                      <span className={errorFieldMessage}>
                        Select Project Status
                      </span>
                    )}
                </div>

                <div>
                  <Label text="Source link" />
                  <div className="relative">
                    <textarea
                      rows="1"
                      type="url"
                      placeholder="Enter project source link"
                      className="border border-gray-300 peer w-full  px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm p-2 "
                      onChange={(e) => setProjectLink(e.target.value)}
                    />

                    <div className="absolute right-2 top-3 h-4 w-4 cursor-pointer text-primary-primary   rounded">
                      {projectLink && (
                        <ArrowUpRightIcon
                          strokeWidth={2}
                          onClick={() => window.open(projectLink, "_blank")}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <Label text="Project PDFs" subtext="(maximum of 5)" />
                  <PdfUploader pdfFiles={pdfFiles} setPdfFiles={setPdfFiles} />
                </div>
                <hr className="my-8 h-1 bg-gray-400" />

                <div className="grid grid-cols-1 md:grid-cols-2 my-4">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="flex flex-col gap-6">
                      {/* Project Start Date */}
                      <div className="flex flex-col gap-1">
                        <Label text="Project Start Date" />
                        {/* <CustomDatePicker
                            id="startdate"
                            name="startdate"
                            startDate={startdate}
                            defaultValue={startdate}
                            onChange={(date) => setStartDate(date)}
                          /> */}
                        <input
                          className={`border border-gray-300 peer w-full  px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
                          type="date"
                          placeholder={`Start Date`}
                          // defaultValue={meeting?.MeetingDate || ""}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                        />
                      </div>

                      {/* Project Completion Date */}
                      <div className="flex flex-col gap-1">
                        <Label text="Project Completion Date" />
                        {/* <CustomDatePicker
                            id="enddate"
                            name="enddate"
                            endDate={enddate}
                            defaultValue={enddate}
                            onChange={(date) => setEndDate(date)}
                          /> */}

                        <input
                          className={`border border-gray-300 peer w-full  px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
                          type="date"
                          placeholder={`Start Date`}
                          // defaultValue={meeting?.MeetingDate || ""}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                          }}
                        />
                      </div>

                      {/* Type of Business */}
                      <div className="flex flex-col gap-1">
                        <Label text="Type of business" />
                        <InputField name="TypeUser" defaultValue={""} />
                      </div>
                    </div>
                  </div>

                  <div>
                    {/* Project Attributes */}
                    <Label text="Project Attributes" />
                    <ProjectAttribute
                      attributeValue={attributeValue}
                      attributeValue2={attributeValue2}
                      attributeValue3={attributeValue3}
                      attributeValue4={attributeValue4}
                      setAttributeValue={setAttributeValue}
                      setAttributeValue2={setAttributeValue2}
                      setAttributeValue3={setAttributeValue3}
                      setAttributeValue4={setAttributeValue4}
                      attributeName={attributeName}
                      attributeName2={attributeName2}
                      attributeName3={attributeName3}
                      attributeName4={attributeName4}
                      setAttributeName={setAttributeName}
                      setAttributeName2={setAttributeName2}
                      setAttributeName3={setAttributeName3}
                      setAttributeName4={setAttributeName4}
                    />
                  </div>
                </div>

                {/* Accordion */}
                <div className="grid grid-cols-1 gap-4 my-4">
                  {PROJECT_LIST.map(({ label, value, desc, icon }, i) => (
                    <section key={i}>
                      <div
                        className={` rounded-lg px-2 py-1  font-semibold text-sm  underline border-2 transition-colors border-b-0 rounded-b-none `}
                      >
                        {label}
                      </div>
                      <div
                        className={` rounded-lg p-1 border-2 transition-colors border-t-0 rounded-t-none `}
                      >
                        {desc}
                      </div>
                    </section>
                  ))}
                </div>

                <div className="grid grid-cols-1 gap-2 my-4">
                  <Label text="Admin Comment"></Label>
                  <textarea
                    id="comment"
                    name="comment"
                    rows="2"
                    placeholder="Add a comment"
                    // onChange={(e) => setTitle(e.target.value)}
                    className={` peer w-full px-3 py-2 z-0 border-2 border-green-800 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm 2xl:text-base`}
                  />
                </div>
              </section>
            </div>
          </Card>
        </form>
      )}
      {open && (
        <AdminSideProjectDetails
          open={open}
          handleOpen={handleOpen}
          projectDetails={projectById}
        />
      )}
    </>
  );
}
