import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SearchContext from "../../context/SearchContext";
import { Button } from "@material-tailwind/react";
import { ListBulletIcon, MapPinIcon } from "@heroicons/react/24/outline";
import ShowLayoutContext from "../../context/ShowLayoutContext";
import MainHeader from "./header/MainHeader";
import IpdadHeader from "./header/IpdadHeader";

export default function HomeLayout({ children }) {
  const location = useLocation();
  const { setSearchValue } = useContext(SearchContext);
  const { showLayout, setShowLayout } = useContext(ShowLayoutContext);

  useEffect(() => {
    setSearchValue(""); // eslint-disable-next-line
    localStorage.removeItem("zoom"); // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    // Disable scrolling on the body when this component mounts
    document.body.style.overflow = "hidden";
    localStorage.removeItem("zoom");

    // Re-enable scrolling when this component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="flex flex-col h-screen">
      <header className="w-full text-center border-b border-grey sticky ">
        <MainHeader />
      </header>
      <header className="w-full text-center border-b border-grey sticky z-100 ">
        {location?.pathname !== "/setting" && (
          <>
             <IpdadHeader />
          </>
        )}{" "}
      </header>
      <main className="flex-grow overflow-auto md:z-75 "><div className="h-full">{children}</div></main>
      <footer className="w-full md:hidden z-100 block sticky bottom-0">
        {" "}
       <div>
       {showLayout !== "map" && (
          <Button
            className="text-black font-extrabold text-lg rounded-none bg-white border min-w-full flex flex-row justify-center gap-2"
            onClick={(e) => {
              e.preventDefault();
              setShowLayout("map");
            }}
          >
            <MapPinIcon className="h-6 w-6 text-black  font-extrabold pt-1" />
            <p className="-pt-2"> Map</p>
          </Button>
        )}
        {showLayout !== "list" && (
          <Button
            className="text-black font-extrabold text-lg rounded-none bg-white border min-w-full flex flex-row justify-center gap-2"
            onClick={(e) => {
              e.preventDefault();
              setShowLayout("list");
            }}
          >
            <ListBulletIcon className="h-6 w-6 text-black  font-extrabold pt-1" />
            <p className="-pt-2"> List</p>
          </Button>
        )}
       </div>
      </footer>
    </div>
  );
}
