import React from "react";
export default function SearchField({
  label,
  buttonLeftIcon,
  buttonLabel,
  placeholder,
  buttonRightIcon,
  handleClick,
  handleChange,
  hasButton,
  filter,
  setfilter,
  defaultValue,
}) {
  return (
    <div className="w-full  relative ">
      {label && (
        <label
          htmlFor="company"
          className="block text-xs mb-1 text-gray-500 font-medium "
        >
          {label}
        </label>
      )}

      <div className="w-full">
        <div className="relative w-full" >
          <div className="absolute inset-y-0 left-0 pl-3 pt-2 ">
            <svg
              className="h-6 w-6 text-primary-primary mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            value={filter || ""}
            onChange={(e) => setfilter(e.target.value)}
            placeholder="Search..."
            defaultValue={defaultValue}
            className="w-full pl-10 pr-12 h-10 text-sm rounded-lg border focus:outline-none border-primary-primary "
          />
          {filter && (
            <div
              className="absolute inset-y-0 right-0 pr-3 pt-2 cursor-pointer"
              onClick={(e) => setfilter(null)}
            >
              <svg
                className="h-6 w-6 text-gray-400 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
