import { Button, Typography } from "@material-tailwind/react";
import React, { useEffect, useState, useRef, useCallback } from "react";
// import Loader from '../../components/ui/Loader'
import MapHome from "../../pages/home/MapHome";
import axiosInstance from "../../services/APIInstance";
import {
  ASSETTYPE,
  FAVOURITEARTICLE,
  FOLLOWEDARTICLE,
  PROJECTLIST,
  STORIES,
} from "../../services/APIGlobal";
import { showMessage } from "../ui/showMessage";
import { useApiGETRequest } from "../../hooks/useApiHook";
import { ArrayToString } from "../../store/helper/Helper";
import FillterDropDown from "../functionality/FillterMenu";
import JumpToTop from "../ui/JumpToTop";
import SearchContext from "../../context/SearchContext";
import { useContext } from "react";
import HomeCard from "../functionality/HomeCard";
import UpdateTimeFilter from "../functionality/UpdateTimeFilter";
import FilterContext from "../../context/FilterContext";

export default function HomePageOutline({
  APItype,
  lable,
  AssetFillter,
  timeFillter,
  followed,
  cityFilter,
  cityNav,
  Details,
}) {
  // const handleClick = (index) => {
  //   // console.log(index.main);
  //   // setItems(prevItems =>
  //   //    prevItems.map((e, i) => ({...e, isActive: i === index}))
  //   // );
  // };

  const [loading, setLoading] = useState(true);
  const [selectedCity, setSelectedCity] = useState(null); // State to store selected city
  const { filterArray, setFilterArray } = useContext(FilterContext); // eslint-disable-next-line
  const [filteredOptions, setFilteredOptions] = useState([]); // eslint-disable-next-line
  const [filterLoading, setFilterLoading] = useState(true); // eslint-disable-next-line
  const [viewType, setViewType] = useState(
    APItype === "Project" || APItype === "Favourite" ? "Map" : "List"
  ); // eslint-disable-next-line
  const [stories, setStories] = useState([]);
  const [newStories, setNewStories] = useState([]); // eslint-disable-next-line
  const [pageNo, setPageNo] = useState(1);
  const pnum = localStorage.getItem("userrecordPage");
  const [showRecord, setRecord] = useState(Number(pnum) ? Number(pnum) : 10);
  const [nextPage, setIsNextPage] = useState();
  const [assetFillter, setAssetFillter] = useState([]);
  const [defaultAssetValues, setDefaultAssetValues] = useState([]);
  const [city, setCity] = useState("All");
  const [latestNews, setLatestNews] = useState([]);
  const [dateRangeValue, setDateRangeValue] = useState(0);
  const [startDateValue, setStartDateValue] = useState();
  const [endDateValue, setEndtDateValue] = useState();
  const { isLoaded } = useApiGETRequest(ASSETTYPE);
  const { setSearchValue, searchValue } = useContext(SearchContext);

  const bottomRef = useRef();
  // const loadMore = () => {
  //   // Check if there's more data to load
  //   if (nextPage !== null) {
  //     setPageNo(1);
  //     setRecord(showRecord + 5); // Update the number of records to show
  //   }
  // };

  useEffect(() => {
    if (APItype === "Project") {
      let findProjectArray = filterArray.arrayOfFilters.find(
        (item) => item.type === "Project"
      );
      setDefaultAssetValues(findProjectArray ? findProjectArray.ASSETTYPE : []);
      setAssetFillter(findProjectArray ? findProjectArray.ASSETTYPE : []);
      setCity(findProjectArray ? findProjectArray.CITY : "All");
      setDateRangeValue(findProjectArray ? findProjectArray.DATE : 0);
      setSearchValue(findProjectArray ? findProjectArray.SEARCH : "");
    } else if (APItype === "Article") {
      let findArticleArray = filterArray.arrayOfFilters.find(
        (item) => item.type === "Article"
      );
      setAssetFillter(findArticleArray ? findArticleArray.ASSETTYPE : []);
      setDefaultAssetValues(findArticleArray ? findArticleArray.ASSETTYPE : []);
      setDateRangeValue(findArticleArray ? findArticleArray.DATE : 0);
      setSearchValue(findArticleArray ? findArticleArray.SEARCH : "");
    } else if (APItype === "Followed") {
      let findFollowedArray = filterArray.arrayOfFilters.find(
        (item) => item.type === "Followed"
      );
      setAssetFillter(findFollowedArray ? findFollowedArray.ASSETTYPE : []);
      setDefaultAssetValues(
        findFollowedArray ? findFollowedArray.ASSETTYPE : []
      );
      setDateRangeValue(findFollowedArray ? findFollowedArray.DATE : 0);
      setSearchValue(findFollowedArray ? findFollowedArray.SEARCH : "");
    } else if (APItype === "Favourite") {
      let findFavouriteArray = filterArray.arrayOfFilters.find(
        (item) => item.type === "Favourite"
      );
      setAssetFillter(findFavouriteArray ? findFavouriteArray.ASSETTYPE : []);
      setDefaultAssetValues(
        findFavouriteArray ? findFavouriteArray.ASSETTYPE : []
      );
      setDateRangeValue(findFavouriteArray ? findFavouriteArray.DATE : 0);
      setSearchValue(findFavouriteArray ? findFavouriteArray.SEARCH : "");
    } else if (APItype === "Cities") {
      let findCitiesArray = filterArray.arrayOfFilters.find(
        (item) => item.type === "Cities"
      );
      setAssetFillter(findCitiesArray ? findCitiesArray.ASSETTYPE : []);
      setDefaultAssetValues(findCitiesArray ? findCitiesArray.ASSETTYPE : []);
      setDateRangeValue(findCitiesArray ? findCitiesArray.DATE : 0);
      setSearchValue(findCitiesArray ? findCitiesArray.SEARCH : "");
    } // eslint-disable-next-line

    // else if(APItype === "Article"){
    //   findArray =filterArray.arrayOfFilters.find(item => item.type==="Article")
    //   // setDefaultAssetValues(findArray.ASSETTYPE)
    // }
    // eslint-disable-next-line
  }, []);

  // Context
  useEffect(() => {
    window.scrollTo(0, 0);
    const FilterAddArray = [
      {
        type: APItype,
        ASSETTYPE: assetFillter,
        CITY: city,
        DATE: dateRangeValue,
        SEARCH: searchValue,
      },
    ];
    const typeToMatch = FilterAddArray[0].type;
    const updatedArrayOfObjects = filterArray.arrayOfFilters.filter(
      (obj) => obj.type !== typeToMatch
    );
    updatedArrayOfObjects.push(...FilterAddArray);
    setFilterArray({
      ...filterArray,
      arrayOfFilters: updatedArrayOfObjects,
    }); // eslint-disable-next-line
  }, [assetFillter, city, dateRangeValue, searchValue, lable]);

  const loadMore = useCallback(() => {
    // Check if there's more data to load
    if (nextPage !== null) {
      setPageNo(1);
      setRecord(showRecord + 5); // Update the number of records to show
    }
  }, [nextPage, showRecord]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled to the bottom of the page
      if (
        bottomRef.current &&
        bottomRef.current.getBoundingClientRect().top <= window.innerHeight
      ) {
        // Trigger the loadMore function when scrolled to the bottom
        loadMore();
      }
    };
    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loadMore]); // Make sure to include loadMore as a dependency

  useEffect(() => {
    const GETCITIES = async () => {
      setLoading(true);
      try {
        const userDetailsData = localStorage.getItem("userDetails");
        const userDetails = userDetailsData
          ? JSON.parse(userDetailsData)
          : null;
        const zipcode =
          userDetails?.Zipcode !== undefined && userDetails?.Zipcode !== " "
            ? userDetails.Zipcode
            : "75201";

        const response = await axiosInstance.get(`/cities/${zipcode}`);
        setFilteredOptions(response?.data?.cities);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cities:", error);
        setLoading(false);
      }
    };
    GETCITIES();
  }, []);

  useEffect(() => {
    GET_STORIES(); // eslint-disable-next-line
  }, [
    viewType,
    pageNo,
    showRecord,
    nextPage,
    assetFillter,
    searchValue,
    APItype,
    dateRangeValue,
    startDateValue,
    endDateValue,
    selectedCity,
    city,
  ]);

  // Get API
  // eslint-disable-next-line
  const handleDropdownChange = (selectedItem) => {
    setSelectedCity(selectedItem); // Update selected city in state
    // Call your API here with the selectedItem if needed
    // Example: make an API call based on the selected city
    // axiosInstance.get(`/your-api-endpoint?city=${selectedItem}`)
    //   .then((response) => {
    //     // Handle the API response data
    //   })
    //   .catch((error) => {
    //     // Handle API error
    //   });
  };

  const GET_STORIES = () => {
    let APIURL;
    switch (APItype) {
      case "Article":
        APIURL = `${STORIES}?limit=${showRecord}&status=&isReview=&isPublish=Yes${
          assetFillter.length > 0
            ? `&assetType=${ArrayToString(assetFillter)}`
            : ""
        }&page=${pageNo}&userId=${localStorage.getItem("userId")}&filter=${
          searchValue === null ? "" : searchValue
        }&sortOrder=desc&sortColumn=created_at${
          dateRangeValue !== 0 ? `&dateRange=${dateRangeValue}` : ""
        }${
          dateRangeValue === "custom"
            ? `&customStartDate=${startDateValue}&customEndDate=${endDateValue}`
            : ""
        }
        `;
        break;
      case "Cities":
        APIURL = `${STORIES}?limit=${showRecord}&status=&isReview=&isPublish=Yes&assetType=${
          assetFillter.length > 0 ? ArrayToString(assetFillter) : ""
        }&page=${pageNo}&userId=${localStorage.getItem("userId")}&filter=${
          searchValue === null ? "" : searchValue
        }&location=${selectedCity}`;
        break;
      case "Favourite":
        APIURL = `${FAVOURITEARTICLE}/${localStorage.getItem(
          "userId"
        )}?page=${pageNo}&limit=${showRecord}&filter=${
          searchValue === null ? "" : searchValue
        }`;
        break;
      case "Followed":
        APIURL = `${FOLLOWEDARTICLE}/${localStorage.getItem(
          "userId"
        )}?page=${pageNo}&limit=${showRecord}&filter=${
          searchValue === null ? "" : searchValue
        }`;
        break;
      case "Project":
        APIURL = `${PROJECTLIST}?flag=1&page=${pageNo}&limit=${showRecord}${
          searchValue ? "&searchQuery=" + searchValue : ""
        }&sortOrder=desc&sortColumn=updated_at${
          dateRangeValue !== 0 ? `&dateRange=${dateRangeValue}` : ""
        }${
          dateRangeValue === "custom"
            ? `&customStartDate=${startDateValue}&customEndDate=${endDateValue}`
            : ""
        }${city !== "All" ? "&location=" + city : ""}${
          assetFillter.length > 0
            ? `&assetType=${ArrayToString(assetFillter)}`
            : ""
        }&type=user&isPublish=Yes&userId=${localStorage.getItem("userId")}`;
        break;
      default:
        if (Details === "Project") {
          APIURL = `${PROJECTLIST}?flag=1&page=${pageNo}&limit=${showRecord}${
            searchValue ? "&searchQuery=" + searchValue : ""
          }&sortOrder=desc&sortColumn=updated_at&dateRange=${dateRangeValue}${
            dateRangeValue === "custom"
              ? `&customStartDate=${startDateValue}&customEndDate=${endDateValue}`
              : ""
          }${city !== "All" ? "&location=" + city : ""}${
            assetFillter.length > 0
              ? `&assetType=${ArrayToString(assetFillter)}`
              : ""
          }&type=user&isPublish=Yes&userId=${localStorage.getItem("userId")}`;
        } else {
          APIURL = `${STORIES}?limit=${showRecord}&status=&isReview=&isPublish=Yes${
            assetFillter.length > 0
              ? `&assetType=${ArrayToString(assetFillter)}`
              : ""
          }&page=${pageNo}&userId=${localStorage.getItem("userId")}&filter=${
            searchValue === null ? "" : searchValue
          }&sortOrder=desc&sortColumn=created_at${
            dateRangeValue !== 0 ? `&dateRange=${dateRangeValue}` : ""
          }${
            dateRangeValue === "custom"
              ? `&customStartDate=${startDateValue}&customEndDate=${endDateValue}`
              : ""
          }
        `;
        }
    }
    // if (APItype === "Article") {
    axiosInstance
      .get(APIURL)
      .then((response) => {
        //  console.log(APIURL)
        let result;
        if (
          APItype === "Article" ||
          APItype === "Favourite" ||
          APItype === "Cities" ||
          APItype === "Followed" ||
          Details === "Articles"
        ) {
          if (
            APItype === "Article" ||
            APItype === "Cities" ||
            Details === "Articles"
          ) {
            result = response?.data?.stories;
          } else if (APItype === "Favourite") {
            result = response?.data?.projectsWithArticles;
          } else {
            result = response?.data?.followedStories;
          }
        } else {
          result = response?.data?.projects;
        }

        setStories(result);
        // setStories(
        //   APItype === "Article" ||
        //     APItype === "Favourite" || APItype === "Followed" ||
        //     APItype === "Cities"
        //     ? APItype === "Article" || APItype === "Cities"
        //       ? response?.data?.stories
        //       : response?.data?.favoriteStories
        //     : response?.data?.projects
        // );

        setIsNextPage(response?.data?.pagination?.nextPage);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    // }
    // else if (APItype === "Favourite") {
    //   axiosInstance
    //     .get(
    //       `${FAVOURITEARTICLE}/${localStorage.getItem(
    //         "userId"
    //       )}?page=${pageNo}&limit=${showRecord}`
    //     )
    //     .then((response) => {
    //       setStories(response?.data?.favoriteStories);
    //       setIsNextPage(response?.data?.pagination?.nextPage);
    //     })
    //     .catch((error) => {
    //       setTimeout(() => {
    //         setLoading(false);
    //       }, 1600);
    //       showMessage(
    //         error?.response?.data?.error,
    //         "error",
    //         "red",
    //         false,
    //         false,
    //         false
    //       );
    //     });
    // } else if (APItype === "Favourite") {
    //   axiosInstance
    //     .get(
    //       `${FAVOURITEARTICLE}/${localStorage.getItem(
    //         "userId"
    //       )}?page=${pageNo}&limit=${showRecord}`
    //     )
    //     .then((response) => {
    //       setStories(response?.data?.favoriteStories);
    //       setIsNextPage(response?.data?.pagination?.nextPage);
    //     })
    //     .catch((error) => {
    //       setTimeout(() => {
    //         setLoading(false);
    //       }, 1600);
    //       showMessage(
    //         error?.response?.data?.error,
    //         "error",
    //         "red",
    //         false,
    //         false,
    //         false
    //       );
    //     });
    // }
    axiosInstance
      .get(`${STORIES}?limit=5&isPublish=Yes&page=1&flag=1&sort=new `)
      .then((response) => {
        setNewStories(response?.data?.stories.splice(0, 5));
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    axiosInstance
      .get(`${STORIES}?limit=10&isPublish=Yes&page=1&flag=1&sort=trending`)
      .then((response) => {
        setLatestNews(response?.data?.stories);
        setTimeout(() => {
          setLoading(false);
          setFilterLoading(false);
        }, 200);
      })

      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  return (
    <div
      id="view"
      className="flex lg:flex-row flex-col gap-4 scrollbar-hide p-2 "
    >
      {!isLoaded && !loading && (
        <>
          <JumpToTop />
          <section className="md:w-3/4  h-min ">
            <div className="drop-shadow-sm border rounded-md border-gray w-full">
              {/* Header */}
              <div className=" flex lg:flex-row md:flex-row flex-col md:justify-end lg:justify-end gap-2 items-center  p-2 ml-2 ">
                {/* <Typography
                className="order-last md:order-first lg:order-first mt-2 lg:mt-0 md:mt-0"
                variant="h5"
              >
                {lable}
              </Typography> */}
                <div className="flex md:flex-row flex-col gap-3  ">
                  {/* {followed && (
                  <NavLink
                    to="/followed"
                    onClick={(e) => handleClick(e)}
                    exact
                    className={classes}
                    style={(isActive) => ({
                      color: "#576d69",
                      borderBottomColor: isActive ? "#576d69" : "#aabbcc",
                      fontWeight: "bolder",
                    })}
                  >
                    Followed
                  </NavLink>
                )} */}

                  <div className="flex flex-row  gap-2 ">
                    {/* {cityNav && (
                      <div>
                        {isLoaded ? (
                          <DotSpinner label="Loading" />
                        ) : (
                          <CitiesDP
                            filteredOptions={filteredOptions}
                            onDropdownChange={handleDropdownChange}
                          />
                        )}
                      </div>
                    )}
                    {cityFilter && viewType === "List" && (
                      <div>
                        {isLoaded ? (
                          <DotSpinner label="Loading" />
                        ) : (
                          <CityFilter
                            filteredOptions={filteredOptions}
                            onDropdownChange={handleDropdownChange}
                            setSelectedOptions={setCity}
                            selectedOptions={city}
                          />
                        )}
                      </div>
                    )} */}
                    {AssetFillter && (
                      <div>
                        <FillterDropDown
                          assetFillter={assetFillter}
                          setAssetFillter={setAssetFillter}
                          defaultValues={defaultAssetValues}
                        />
                      </div>
                    )}
                    {timeFillter && (
                      <div>
                        <UpdateTimeFilter
                          setDateRangeValue={setDateRangeValue}
                          dateRangeValue={dateRangeValue}
                          setStartDateValue={setStartDateValue}
                          setEndtDateValue={setEndtDateValue}
                        />
                      </div>
                    )}
                  </div>

                  {/* {APItype !== "Article" && (
                    <div>
                      <Tabs
                        value="Map"
                        className="w-full"
                        onClick={(e) => {
                          setViewType(e.target.textContent);
                        }}
                      >
                        <TabsHeader>
                          {VIEWTOGGLE.map(({ label, value, icon }) => (
                            <Tab key={value} value={value}>
                              <div className="flex items-center gap-2 text-sm">
                                {React.createElement(icon, {
                                  className: "w-4 h-4",
                                })}
                                {label}
                              </div>
                            </Tab>
                          ))}
                        </TabsHeader>
                      </Tabs>
                    </div>
                  )} */}
                </div>
              </div>
              {viewType === "List" ? (
                <>
                  {" "}
                  {(!isLoaded || !loading || !filterLoading) && stories && (
                    <>
                      <HomeCard
                        stories={stories}
                        GETAPI={GET_STORIES}
                        APItype={APItype ? APItype : Details}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                        showRecord={showRecord}
                        setRecord={setRecord}
                      />
                    </>
                  )}
                </>
              ) : (
                <MapHome
                  stories={stories}
                  APItype={APItype ? APItype : Details}
                  assetFillter={assetFillter}
                  dateRangeValue={dateRangeValue}
                  startDateValue={startDateValue}
                  endDateValue={endDateValue}
                  selectedCity={selectedCity}
                  city={city}
                />
              )}
            </div>
            {viewType === "List" && (
              <div className="flex justify-center mt-2">
                {stories?.length > 1 && nextPage !== null && (
                  <Button
                    ref={bottomRef}
                    size="sm"
                    variant="filled"
                    onClick={(e) => {
                      e.preventDefault();
                      setPageNo(1);
                      setRecord(showRecord + 10);
                    }}
                    className="bg-primary-primary text-white  shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                  >
                    Loading...
                  </Button>
                )}
              </div>
            )}
          </section>
          {/* Latest Stories*/}
          <div className=" md:w-1/4 h-min flex flex-col gap-2 bg-primary-revado-white">
            {/* Header */}
            <div className="bg-primary-revado-white p-2 drop-shadow-sm border rounded-md border-gray">
              <Typography variant="h5">New Stories</Typography>
              <HomeCard
                latestStories
                stories={newStories}
                GETAPI={GET_STORIES}
              />
            </div>
            {/* Advertisment Space */}
            <div
              style={{ height: "250px" }}
              className="bg-primary-revado-white"
            ></div>
            {/* Header */}
            {latestNews?.length > 0 && (
              <div className="bg-primary-revado-white p-2 drop-shadow-sm border rounded-md border-gray">
                <Typography variant="h5">Trending Stories</Typography>
                <HomeCard
                  latestStories
                  stories={latestNews}
                  GETAPI={GET_STORIES}
                  APItype="Article"
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
