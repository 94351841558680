import React from "react";
import InputField from "../../../../components/ui/InputField";

export default function ArticleProjectTeam(props) {
  // Form values into spread objects
  // const formValueConvertor = (formObj) => ({
  //   ...formObj,
  // });
  // const getFormJSON = (form) => {
  //   const data = new FormData(form);
  //   return Array.from(data.keys()).reduce((result, key) => {
  //     // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
  //     var value = data.get(key);
  //     if (value) result[key] = value;
  //     result[key] = value || " ";
  //     return result;
  //   }, {});
  // };

  // const onSumbitHandler = (e) => {
  //   e.preventDefault();
  //   const obj = formValueConvertor(getFormJSON(e.target));
  //   let projectTeam = {
  //     ProjectTeamId: props?.allData?.ProjectTeamId,
  //     Developer: obj.Developer,
  //     GeneralContractor: obj.GeneralContractor,
  //     Contractor: obj.Contractor,
  //     Owner: obj.Owner,
  //     Architect: obj.Architect,
  //     Planner: obj.Planner,
  //     Investor: obj.Investor,
  //     Lender: obj.Lender,
  //     Bank: obj.Bank,
  //     CivilEngineer: obj.CivilEngineer,
  //     Engineer: obj.Engineer,
  //     Broker: obj.Broker,
  //     Brokerage: obj.Brokerage,
  //   };
  //   axiosInstance
  //     .put(Update_PROJECT_TEAM, projectTeam)
  //     .then((response) => {
  //       showMessage(
  //         response?.data?.message,
  //         "success",
  //         "#576d69",
  //         false,
  //         false,
  //         false
  //       );
  //     })
  //     .catch((error) => {
  //       showMessage(
  //         error?.response?.data?.message,
  //         "error",
  //         "red",
  //         false,
  //         false,
  //         false
  //       );
  //     });
  //     props?.setOpen(5);
  // };
  return (
    <div className="grid md:gap-3 gap-2 md:grid-flow-row-dense md:grid-cols-3 2xl:grid-cols-5 px-2 mb-2">
      {/* <Loader loading={loading} /> */}
      <InputField
        name="Architect"
        type="text"
        id="Architect"
        defaultValue={props?.API?.Apidata?.project?.Architect}
        label="Architect"
      />
      <InputField
        name="Broker"
        type="text"
        id="Broker"
        defaultValue={props?.API?.Apidata?.project?.Broker}
        label="Broker"
      />
      <InputField
        name="CivilEngineer"
        type="text"
        id="CivilEngineer"
        defaultValue={props?.API?.Apidata?.project?.CivilEngineer}
        label="Civil Engineer"
      />
      <InputField
        name="Developer"
        id="Developer"
        defaultValue={
          props?.API?.Apidata?.project?.Developer
            ? props?.API?.Apidata?.project?.Developer
            : null
        }
        label="Developer"
      />
      <InputField
        name="GeneralContractor"
        type="text"
        id="GeneralContractor"
        defaultValue={props?.API?.Apidata?.project?.GeneralContractor}
        label="General Contractor"
      />
      <InputField
        name="Investor"
        type="text"
        id="Investor"
        defaultValue={props?.API?.Apidata?.project?.Investor}
        label="Investor"
      />
      <InputField
        name="Lender"
        type="text"
        id="Lender"
        defaultValue={props?.API?.Apidata?.project?.Lender}
        label="Lender/Bank"
      />
      <InputField
        name="Owner"
        type="text"
        id="Owner"
        defaultValue={props?.API?.Apidata?.project?.Owner}
        label="Owner"
      />
      <InputField
        name="Planner"
        type="text"
        id="Planner"
        defaultValue={props?.API?.Apidata?.project?.Planner}
        label="Planner"
      />
      <InputField
        name="Tenant"
        type="text"
        id="Tenant"
        defaultValue={props?.API?.Apidata?.project?.Tenant}
        label="Tenant"
      />
      {/* <div>
          <Button
            type="submit"
            variant="filled"
            className="w-max bg-primary-primary  text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 mb-2"
          >
            Update and Continue
          </Button>
        </div> */}
    </div>
  );
}
