import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-tailwind/react";
import { TooltipStlye } from "../../utils/Constant";
import Table from "../functionality/table/Table";

import { useHistory } from "react-router-dom";
import { GETVIEWCOUNT } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../ui/showMessage";
import Loader from "../ui/Loader";
import { ArrowLongDownIcon, ArrowLongUpIcon } from "@heroicons/react/24/outline";

export default function AllAnalyticsDB(props) {
  let history = useHistory();
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showRecord, setShowRecord] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();
  const [selectedOptions, setSelectedOptions] = useState("All");
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [isShort] = useState(false);

  // console.log(props,"props")
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    GET_STORIES(); // eslint-disable-next-line
  }, [
    showRecord,
    pageNo,
    selectedOptions,
    isShort,
    setSelectedOptions,
    setLoading,
    history,
    sortOrder
  ]);

  useEffect(() => {
    localStorage.removeItem("rowId");
  }, [history]);

  // Get API
  const GET_STORIES = () => {
    axiosInstance
      .get(`${GETVIEWCOUNT}?limit=${showRecord}&page=${pageNo}&sortOrder=${sortOrder}`)
      .then((response) => {
        setData(response?.data?.viewCounts);
        setTotalRecord(response?.data?.pagination?.totalCount);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        setTotalRecord(1);
        setCurrentPage(1);
        setTotalPage(1);
        setData([]);
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.message,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };
  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  const columns = [
    {
      Header: "Sr No",
      accessor: (row, index) => index + 1, // Auto-incremented serial number
      headerStyle: { textAlign: "center" },
      width: 100, // Adjust the width as needed
      disableSortBy: true,
    },
    {
      Header: "Articles",
      accessor: "article_title",
      Cell: ({ value, row }) => {
        return (
          <Tooltip className={TooltipStlye} content="Edit & view Article">
            <p
              className="line-clamp-1 cursor-pointer text-left	ml-4"
              onClick={(e) => handleClick(e, row)}
            >
              {value}
            </p>
          </Tooltip>
        );
      },
      headerStyle: { textAlign: "center" },
      width: 300,
      disableSortBy: true,
    },
    {
      Header: "FullName",
      accessor: "FullName",
      Cell: ({ cell: { value } }) => (
        <span className=" line-clamp-1">{value ? value : "--"}</span>
      ),
      disableSortBy: true,
    },

    {
      Header: "Website",
      accessor: "Weburl",
      Cell: ({ cell: { value } }) => (
        <span className="line-clamp-1">
          {value ? (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "rgb(87 109 105)" }} // Change 'blue' to your desired color
            >
              {value}
            </a>
          ) : (
            "--"
          )}
        </span>
      ),
      disableSortBy: true,
    },

    {
      Header: (<div className="flex flex-row gap-2 cursor-pointer">
      <Tooltip className={TooltipStlye} content="Click to Sort by Date">
        <span onClick={() => handleSort("count")}>View count</span>
      </Tooltip>

      {sortColumn === "count" ? (
        sortOrder === "asc" ? (
          <ArrowLongUpIcon
            strokeWidth={2}
            className="h-4 w-4 cursor-pointer"
            onClick={() => handleSort("count")}
          />
        ) : (
          <ArrowLongDownIcon
            strokeWidth={2}
            className="h-4 w-4 cursor-pointer"
            onClick={() => handleSort("count")}
          />
        )
      ) : null}
    </div>
  ),
      accessor: "view_count",
      Cell: ({ cell: { value } }) => (
        <span className="capitalize line-clamp-1">{value ? value : "--"}</span>
      ),
      disableSortBy: true,
    },
  ];

  // Handle on title click
  const handleClick = (e, row) => {
    localStorage.setItem(
      "rowId",
      JSON.stringify(row?.original?.ProjectDetailsId)
    );
    history.push({
      pathname: `/Article/Update/${row?.original?.ProjectDetailsId}`,
      data: row,
    });
  };
  return (
    <div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Table
          setPageNo={setPageNo}
          pageNo={pageNo}
          showRecord={showRecord}
          setShowRecord={setShowRecord}
          totalRecord={totalRecord}
          currentPage={currentPage}
          totalPage={totalPage}
          columns={columns}
          data={!props?.project ? data : props?.data}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          maxHeight={props?.height}
          noPagination={props?.project}
          noSearch
        />
      )}
    </div>
  );
}
