import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import checked from "../../assets/Images/check.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from "react";

const TimeFilterOptions = [
  // {
  //   label: "Any Time",
  //   value: "0",
  // },
  // {
  //   label: "Past Hour",
  //   value: "1",
  // },
  // {
  //   label: "Past 24 Hours",
  //   value: "2",
  // },
  // {
  //   label: "Past Week",
  //   value: "3",
  // },
  // {
  //   label: "Past Month",
  //   value: "4",
  // },
  {
    label: "Past Year",
    value: "5",
  },
  {
    label: "Older than one Year",
    value: "6",
  },
  // {
  //   label: "Custom",
  //   value: "custom",
  // },
];

const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <input
    value={value}
    dateFormat="YYYY-MM-dd"
    className="example-custom-input"
    onClick={onClick}
    onChange={onChange}
    ref={ref}
  ></input>
));

const UpdateTimeFilter = ({
  setDateRangeValue,
  setStartDateValue,
  setEndtDateValue,
  dateRangeValue,
  defaultTimeValue,
}) => {
  const [selectedValues, setSelectedValues] = useState(  ); // eslint-disable-next-line
  const [openPopover, setOpenPopover] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => {
      setOpenPopover(false);
      // setFilteredOptions(options);
    },
  };
  // eslint-disable-next-line
  useEffect(() => {
    if (dateRangeValue !== 0) {
      const defaultValue = TimeFilterOptions.find(
        (item) => item.value === dateRangeValue
      );
      setSelectedValues(defaultValue ? defaultValue.label : "Past Year");
    }
    else{
      setSelectedValues("Past Year");
    }
  });
  useEffect(() => {
    if (defaultTimeValue !== null && defaultTimeValue !=="") {
      const defaultValue = TimeFilterOptions.find(
        (item) => item.value === defaultTimeValue
      );
      setSelectedValues(defaultValue ? defaultValue.label : "Past Year");
    }else{
      setSelectedValues("Past Year");
    }
  }, [defaultTimeValue]);

  return (
    <Popover placement="bottom">
      <PopoverHandler>
        <Button
          variant="outlined"
          size="sm"
          className=" justify-between flex flex-row md:w-[160px] sm:w-[150px] xs:w-[160px] w-[175px] h-10 items-center gap-2 text-primary-primary border-primary-primary focus:ring-0"
        >
          <span className=" flex flex-row gap-1 items-center normal-case text-sm ">
            {selectedValues}
          </span>
          <ChevronUpDownIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </PopoverHandler>
      <PopoverContent
        {...triggers}
        className="  flex flex-col gap-2 z-100 w-52 h-fit"
      >
        {/* <Scrollbars
          universal
          autoHeightMin={290}
          autoHeight
          // autoHeightMax={300}
        > */}
        <div className="flex flex-col gap-4">
          {TimeFilterOptions.map((filltered) => (
            <p
              className={`cursor-pointer flex flex-row gap-2 items-center text-black ${
                selectedValues === filltered.label &&
                "font-bold text-primary-primary"
              }`}
              key={filltered.label}
              onClick={() => {
                setSelectedValues(filltered.label);
                setDateRangeValue(filltered.value);
                // setOpenPopover(false);
              }}
            >
              <span className="w-4">
                {" "}
                {selectedValues === filltered.label && (
                  <img alt="checkbox" src={checked} width={20} />
                )}
              </span>
              {filltered.label}
            </p>
          ))}
          {/* Date picker */}
          {selectedValues === "Custom" && (
            <section className=" flex  flex-col justify-between gap-5 mr-5 w-full">
              <div className="">
                <p className="flex flex-row text-gray-500 text-xs font-medium mb-1">
                  Start Date
                </p>
                <DatePicker
                  onChange={(date) => {
                    setStartDate(date);
                    setStartDateValue(moment.utc(date).format());
                  }}
                  selected={startDate}
                  customInput={<ExampleCustomInput />}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dayClassName={() => "example-datepicker-day-class"}
                  popperClassName="example-datepicker-class"
                />
              </div>
              <div className="mb-5">
                <p className="flex flex-row text-gray-500 text-xs font-medium mb-1">
                  End Date
                </p>
                <DatePicker
                  onChange={(date) => {
                    setEndDate(date);
                    setEndtDateValue(moment.utc(date).format());
                  }}
                  selected={endDate}
                  customInput={<ExampleCustomInput />}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  minDate={
                    startDate !== undefined
                      ? startDate
                      : moment(startDate).toDate()
                  }
                  dayClassName={() => "example-datepicker-day-class"}
                  popperClassName="example-datepicker-class"
                />
              </div>
            </section>
          )}
        </div>
        {/* </Scrollbars> */}
      </PopoverContent>
    </Popover>
  );
};

export default UpdateTimeFilter;
