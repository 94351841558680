import { MapPinIcon } from "@heroicons/react/24/outline";
import { Button, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import moment from "moment";
import SingleMarkerMap from "../ui/Map/SingleMarkerMap";
import {
  STORYNOTIFY,
  STORYUNNOTIFY,
  // VIEWCOUNT,
} from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../ui/showMessage";
import {
  appendSourceThumbnail,
  locationString,
  removeQueryParameters,
} from "../../store/helper/Helper";
import logo from "../../assets/Logo/OnlyBlacklogo.png";
import ImageWithFallback from "../ui/ImageWithFallback";
import ScrollToIndexContext from "../../context/ScrollToIndex";
import { useContext } from "react";
export default function FullContent({ content, record }) {
  let Id = localStorage.getItem("userprojectId");
  let pageNo = localStorage.getItem("userrecordPage");
  const { scrollToIndex, setScrollToIndex } = useContext(ScrollToIndexContext);

  // const history = useHistory()
  const [notify, setNofity] = useState(content?.notify === 1 ? false : true);
  // useEffect(() => {
  //   return () => {
  //     if (history.action === "POP") {
  //       history.location.pathname = "/home";
  //       history.replace("/home");
  //     }
  //   };
  // });

  // Notify toggle
  // eslint-disable-next-line
  const handleCardOpertaion = (e) => {
    let APICALL, DATA;
    APICALL = notify === true ? STORYNOTIFY : STORYUNNOTIFY;
    DATA = {
      ProjectDetailsId: content.ProjectDetailsId,
      userId: localStorage.getItem("userId"),
    };
    axiosInstance
      .post(APICALL, DATA)
      .then((response) => {
        setNofity((current) => !current);
        showMessage(
          response?.data?.message,
          "success",
          "#576d69",
          false,
          false,
          false
        );
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  // Jump to project
  const DetailsHeadler = () => {
    localStorage.setItem("userprojectId", Id);
    localStorage.setItem("userrecordPage", pageNo);
    const jumpToArray = [
      {
        type: "Article",
        userprojectId: Number(Id),
      },
    ];
    const typeToMatch = jumpToArray[0].type;
    const updatedArrayOfObjects = scrollToIndex.arrayOfFilters.filter(
      (obj) => obj.type !== typeToMatch
    );
    updatedArrayOfObjects.push(...jumpToArray);
    setScrollToIndex({
      ...scrollToIndex,
      arrayOfFilters: updatedArrayOfObjects,
    }); // eslint-disable-next-line
    let APIPATH = `/project/${content.projectId}`;
    window.open(APIPATH, "_blank");
  };

  return (
    <>
      {content && (
        <section className="w-full flex flex-col md:pr-2 gap-3">
          {/* Content type and notification */}
          <div className="flex md:flex-row flex-col items-start gap-2">
            <span
              color="black"
              className="leading-none font-serif text-3xl md:text-4xl 2xl:text-6xl font-extrabold"
            >
              {content.title ? content.title : ""}
            </span>
            <div className="flex  self-end md:self-start flex-row  md:order-last order-first w-fit">
              <img
                src={appendSourceThumbnail(content?.weburl)}
                alt={content?.weburl}
                className=" shadow-lg border-2 p-1 flex flex-row	 justify-end"
                style={{
                  height: "auto",
                  maxHeight: "80px",
                  width: "auto",
                  maxWidth: "120px",
                }}
              />
            </div>
            {/* <IconButton
              ripple={false}
              color="white"
              variant="text"
              size="sm"
              onClick={(e) => handleCardOpertaion(e)}
              className="text-primary-primary bg-transparent flex p-0 m-0  "
            >
              {notify !== true ? (
                <BellAlertIcon
                  strokeWidth={2}
                  className="2xl:h-10 2xl:w-10 w-5 h-5 md:w-7 md:h-7"
                />
              ) : (
                <BellIcon
                  strokeWidth={2}
                  className="2xl:h-10 2xl:w-10 w-5 h-5 md:w-7 md:h-7"
                />
              )}
            </IconButton> */}
          </div>
          {/* Type and Date */}
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col gap-1">
              <span className="w-fit  tracking-wider font-medium antialiased text-xs  bg-primary-light text-black rounded-xl p-2">
                {content?.AssetType !== null && content?.AssetType !== undefined
                  ? content.AssetType + " |"
                  : ""}{" "}
                DATE:
                {moment(content?.created_at, "YYYY-MM-DD hh:mm:ss").format(
                  "MM-DD-YYYY"
                )}
              </span>
              {/* Address */}
              <div className="flex w-full flex-col md:flex-row ">
                <div className="flex text-xs gap-1 w-full">
                  {(content?.StreetIntersections ||
                    content?.StreetAddres ||
                    content?.City ||
                    content?.State ||
                    content?.ZipCode) && (
                      <span className="flex flex-row break-keep w-full">
                        <MapPinIcon
                          strokeWidth={2}
                          className="h-3.5 w-3.5 mr-1"
                        />
                        <span className="w-full">
                        {locationString(
                          content?.StreetAddress,
                          content?.StreetIntersections,
                          content?.City,
                          content?.State,
                          content?.ZipCode
                        )}{" "}
                        </span>
                      </span>
                  )}
                </div>

              </div>
              {content?.weburl && (
                  <div className="flex text-xs gap-1 ml-1">
                    {/* reference */}
                    Reference:{" "}
                    <a
                      rel="noreferrer"
                      href={content.cleanedUrl}
                      target="_blank"
                      className="text-blue-700 text-xs underline"
                    >
                      {content?.weburl}
                    </a>
                  </div>
                )}
              {/* By */}

              {content?.author && (
                <span className="text-xs ml-1">{content?.author}</span>
              )}
            </div>

            {/* Button to jump on project */}
            <div className="flex justify-end items-end min-w-fit md:mt-0 mt-2 ">
              <Button
                size="sm"
                className={` text-primary-revado-white  flex items-center gap-1 bg-primary-primary border-primary-primary md:w-full shadow-none hover:shadow-lg hover:shadow-primary-primary/40 hover:ring-0 focus:ring-0 `}
                variant="filled"
                onClick={DetailsHeadler}
              >
                View project details
              </Button>
            </div>
          </div>
          <div className=" p-2 border-2  ">
            {/* Image and map */}
            <div className=" flex gap-2 flex-col md:flex-row ">
              <div className="md:w-1/2 order-last 2xl:hidden block md:order-first max-h-56  overflow-hidden	rounded-lg ">
                <ImageWithFallback
                  key={content?.ProjectDetailsId}
                  url={removeQueryParameters(
                    content?.articlegallery,
                    content?.weburl
                  )}
                  className="rounded-lg w-full"
                  style={{ minHeight: "240px", maxHeight: "240px" }}
                  fallbackUrl={logo}
                  source={content?.weburl}
                />
                {/* <img
                  src={
                    !imageerror
                      ? removeQueryParameters(content.articlegallery)
                      : logo
                  }
                  onError={()=>{setImageError(true)}}
                  alt={imageerror ? "logo" : "project logo"}
                  className="rounded-lg w-full"
                  style={{ minHeight: "240px", maxHeight: "240px" }}
                /> */}
              </div>
              <div className="md:w-1/2 order-last 2xl:block hidden md:order-first   overflow-hidden	rounded-lg ">
                <ImageWithFallback
                  key={content?.ProjectDetailsId}
                  url={removeQueryParameters(
                    content?.articlegallery,
                    content?.weburl
                  )}
                  className="rounded-lg w-full"
                  style={{ minHeight: 500, maxHeight: 500 }}
                  fallbackUrl={logo}
                  source={content?.weburl}
                />
              </div>
              {content?.Longitude ? (
                <>
                  <div className="md:w-1/2 2xl:hidden block max-h-56  overflow-hidden">
                    <SingleMarkerMap
                      style={{ width: 500, height: 220, borderRadius: "10px" }}
                      longitude={content?.Longitude}
                      latitude={content?.Latitude}
                      AssetType={content?.AssetType}
                      public_hearings={content?.public_hearings}
                    />
                  </div>
                  <div className="md:w-1/2 2xl:block hidden ">
                    <SingleMarkerMap
                      style={{ width: 800, height: 500, borderRadius: "10px" }}
                      longitude={content?.Longitude}
                      latitude={content?.Latitude}
                      AssetType={content?.AssetType}
                      public_hearings={content?.public_hearings}
                    />
                  </div>
                </>
              ) : (
                <div className=" rounded-lg w-1/2 flex-auto bg-white border-2 items-center justify-center overflow-hidden hidden md:flex">
                  <>
                    <div className=" md:w-1/2 2xl:hidden block  overflow-hidden">
                      <img
                        alt="Redova Logo"
                        style={{
                          width: 500,
                          height: 220,
                          borderRadius: "10px",
                        }}
                        src={logo}
                      />
                    </div>
                    <div className="md:w-1/2 2xl:block hidden ">
                      <img
                        alt="Redova Logo"
                        src={logo}
                        style={{
                          width: 800,
                          height: 500,
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </>
                </div>
              )}
            </div>
            {/* Caption */}
            <Typography className="pt-2 text-xs" variant="small">
              Article Image Caption: {content?.articlegallerydesc}
            </Typography>
          </div>
          {/* description */}
          <div>
            <p
              variant="small"
              color="black"
              className="after:content-['Hello_World'] text-justify text-sm leading-snug line-clamp-3	"
            >
              {content.description}
            </p>
            <a
              rel="noreferrer"
              href={content.cleanedUrl}
              target="_blank"
              className="font-semibold p-0 max-w-fit flex items-center text-primary-primary border-primary-primary w-full shadow-none hover:bg-transparent hover:shadow-none hover:ring-0 focus:ring-0 focous:bg-transparent"
            >
              Click for more…{" "}
            </a>
          </div>
        </section>
      )}
    </>
  );
}
