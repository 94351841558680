import * as React from "react";
import PinSvg from "../../../assets/Images/PinSvg.svg";
import PinOrange from "../../../assets/Images/PinOrange.svg";
import PinHighlight from "../../../assets/Images/PinHighlight.svg";
// const ICON = `M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z`;

// const pinStyle = {
//   cursor: 'pointer',
//   fill: '#052CA3',
//   stroke: '2'
// };

function ProjectPin({
  size = "30",
  data,
  projectHighlightId,
  projectStatusOptions,
}) {
  const isHighlighted = projectHighlightId === data?.projectId;
  // Check if comma exists in the string
  let numberOfWordsAfterComma = 0;

  // Check if comma exists in the string
  if (data?.project_status_type && data?.project_status_type.includes(",")) {
    const afterComma = data?.project_status_type.split(",")[1].trim();
    numberOfWordsAfterComma = afterComma ? afterComma.split(" ").length : 0;
  }

  let ProjectStatus = data?.projectstatus
    ? data?.projectstatus?.includes("Under Entitlement")
      ? true
      : false
    : data?.public_hearings?.length > 0
    ? true
    : false;
  // Function to return pin color based on at_name
  const getStatusPinColor = (statusName) => {
    const foundStatus = projectStatusOptions.find(
      (status) => status.at_name === statusName
    );

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width={size}
        height={size}
        viewBox="0 0 256 256"
        xmlSpace="preserve"
      >
        <defs></defs>
        <g
          style={{
            stroke: "none",
            strokeWidth: 0,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "none",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <path
            d="M 45 0 c 15.103 0 27.389 12.287 27.389 27.389 C 72.389 46.616 46.147 66.607 45 90 c -1.147 -23.393 -27.389 -43.384 -27.389 -62.611 C 17.611 12.287 29.897 0 45 0 z"
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              fill: foundStatus?.MarkerColor, // Dynamic color based on prop
              fillRule: "nonzero",
              opacity: 1,
            }}
            transform="matrix(1 0 0 1 0 0)"
            strokeLinecap="round"
          />
          <circle
            cx="45.004999999999995"
            cy="26.575000000000003"
            r="9.205"
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              fill: foundStatus?.MarkerColor, // Dynamic color based on prop
              fillRule: "nonzero",
              opacity: 1,
            }}
            transform="matrix(1 0 0 1 0 0)"
          />
        </g>
      </svg>
    );
  };
  return (
    // /projectStatusOptions
    <>
      {data !== undefined && (
        <>
          {isHighlighted ? (
            <img
              width={size}
              src={PinHighlight}
              alt="location pin"
              className="hover:cursor-pointer"
            />
          ) : data?.project_status_type && numberOfWordsAfterComma === 0 ? (
            getStatusPinColor(data?.project_status_type)
          ) : ProjectStatus ? (
            <img
              width={size}
              src={PinOrange}
              alt="location pin"
              className="hover:cursor-pointer"
            />
          ) : (
            <img
              width={size}
              src={PinSvg}
              alt="location pin"
              className="hover:cursor-pointer"
            />
          )}
        </>
      )}
    </>
    //           data?.ProjectStatus.includes("Under Entitlement") ?<img width={size} src={PinOrange} alt="location pin" />:<img width={size} src={PinSvg} alt="location pin" />}

    // <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
    //   <path d={ICON} />
    // </svg>
  );
}

export default React.memo(ProjectPin);
