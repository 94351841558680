import React, { useEffect, useState } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Button,  Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import Scrollbars from "react-custom-scrollbars-2";
import { PROJECT_STATUS_TYPE } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../ui/showMessage";

const ProjectStatusFilter = ({
  options,
  onSelectChange,
  defaultValues,
}) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    defaultValues ? defaultValues : ""
  );
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    GET_PROJECT_STATUS();
  }, []);
  const triggers =()=> {
     setOpenPopover(true)

  };
  useEffect(() => {
    setSelectedValue(defaultValues);
  }, [defaultValues]);

  const GET_PROJECT_STATUS = () => {
    axiosInstance
      .get(`${PROJECT_STATUS_TYPE}`)
      .then((response) => {
        setFilteredOptions(response?.data?.getArticleProjectStatusType);
      })
      .catch((error) => {
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    onSelectChange(value);
  };

  const handleUnselectAll = () => {
    setSelectedValue("");
    onSelectChange("");
    setOpenPopover(false);
  };

  return (
    <Popover placement="bottom" open={openPopover} handler={setOpenPopover}>
      <PopoverHandler {...triggers}>
        <Button
          variant="outlined"
          size="sm"
          className="text-xs h-10 justify-between md:w-[160px] sm:w-[150px] xs:w-[160px] w-[175px] flex items-center gap-2 text-primary-primary border-primary-primary focus:ring-0"
        >
          <span className="text-sm flex flex-row gap-1 items-center normal-case text-primary-revado-dark hover:text-primary-revado-dark">
            <p> Project Status</p>
          </span>
          <ChevronUpDownIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </PopoverHandler>
      <PopoverContent className="  flex flex-col gap-2  z-100 w-56"  {...triggers} >
        <div className="flex flex-row justify-end gap-2">
          <button
            className="border border-primary-revado-white p-1 rounded-sm"
            onClick={handleUnselectAll}
          >
            Unselect All
          </button>
        </div>
        <Scrollbars universal autoHeight>
          <ul className="flex flex-col gap-2 ">
            {filteredOptions?.map((filtered) => (
              <li key={filtered.at_name}>
                <div
                  className="cursor-pointer  flex flex-row items-center gap-1"
                  onClick={() => {
                    handleRadioChange(filtered.at_name);
                    onSelectChange(filtered.at_name); // Close the popover
                    // setOpenPopover(false);

                  }}
                >
                  <div className="mt-1">
                    <input
                      className=" cursor-pointer h-4 w-4 rounded accent-primary-hover	text-white"
                      type="radio"
                      value={filtered.at_name}
                      checked={selectedValue === filtered.at_name}
                      onChange={() => handleRadioChange(filtered.at_name)}
                    />
                  </div>
                  <span>{filtered.at_name}</span>
                </div>
              </li>
            ))}
          </ul>
        </Scrollbars>
      </PopoverContent>
    </Popover>
  );
};

export default ProjectStatusFilter;
