import { IconButton, Card, Collapse } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Whitelogo from "../../../assets/Logo/Whitelogo.png";
import { useLocation } from "react-router-dom";
import ProfileMenu from "./UserMenu";
import { CITIES } from "../../../services/APIGlobal";
import axiosInstance from "../../../services/APIInstance";
import { useContext } from "react";
// import { SearchContext } from "../../../context/SearchContext";
import SearchContext from "../../../context/SearchContext";
import ToggleView from "../ToggleView";
import { useHistory } from "react-router-dom";
import FeedBackForm from "../../../pages/FeedBackForm";

export default function MainHeader() {
  const location = useLocation();
  const history = useHistory();
  const [openNav, setOpenNav] = useState(false); // eslint-disable-next-line
  const [filteredOptions, setFilteredOptions] = useState(); // eslint-disable-next-line
  const [userDetails, setUserDetails] = useState(""); // eslint-disable-next-line
  const [loading, setLoading] = useState(true); // eslint-disable-next-line
  const { searchValue, setSearchValue } = useContext(SearchContext);

  useEffect(() => {
    try {
      const authDetails = localStorage.getItem("authDetails");

      if (!authDetails) {
        setUserDetails("");
        GETCITIES("75201");
      } else {
        const parsedAuthDetails = JSON.parse(authDetails);

        if (!parsedAuthDetails) {
          setUserDetails("");
          GETCITIES("75201");
        } else {
          setUserDetails(parsedAuthDetails);

          let zipcode =
            parsedAuthDetails.Zipcode !== undefined &&
            parsedAuthDetails.Zipcode !== " "
              ? parsedAuthDetails.Zipcode
              : "75201";

          GETCITIES(zipcode);
        }
      }
    } catch (error) {
      // Handle JSON parsing error (e.g., invalid JSON data in localStorage)
      console.error("Error parsing JSON:", error);
      // Handle the error in a way that makes sense for your application
    }

    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  useEffect(() => {
    setSearchValue(""); // eslint-disable-next-line
  }, [location]);

  // Get API
  const GETCITIES = (Zipcode) => {
    setLoading(true);
    axiosInstance
      .get(`${CITIES}/${Zipcode}`)
      .then((response) => {
        setFilteredOptions(response?.data?.cities);
        setLoading(false);
      })
      .catch((error) => {});
  };
  // Close popup
  const closePopup = () => {
    setOpenNav(false);
  };

  // Responsive nav list
  const navList = (
    <ul className=" divide-y flex flex-col lg:mb-0  lg:mt-0 lg:flex-row lg:items-center lg:gap-6 ">
      {location?.pathname !== "/home" && location?.pathname !== "/" && (
        <NavLink
          to="/"
          exact
          onClick={closePopup}
          className="p-2 text-primary-revado-black font-bold mt-1 "
          style={(isActive) => ({
            color: isActive ? "#40514e" : "",
            borderBottomColor: isActive ? "#40514e" : "",
          })}
        >
          Home
        </NavLink>
      )}
      {/* {userDetails !== "" &&
        location?.pathname !== "/signUp" &&
        location?.pathname !== "/setting" &&
        location?.pathname !== "/forgotpassword" &&
        userDetails.roles[0] !== "User" && (
          <NavLink
            to={userDetails.roles[0] !== "Admin" ? "/Articles" : "/Projects"}
            exact
            onClick={closePopup}
            className="p-2 text-primary-revado-black font-bold "
            style={(isActive) => ({
              color: isActive ? "#40514e" : "",
              borderBottomColor: isActive ? "#40514e" : "",
            })}
          >
            Dashboard
          </NavLink>
        )} */}
      {/* <NavLink
        to="/map"
        onClick={closePopup}
        exact
        className="p-2 text-primary-revado-black font-bold mt-1 "
        style={(isActive) => ({
          color: isActive ? "#40514e" : "",
          borderBottomColor: isActive ? "#40514e" : "",
        })}
      >
        Map
      </NavLink> */}

      {/* {!loading && (
        <>
          {filteredOptions.map((item, index) => {
            return (
              <NavLink
                onClick={closePopup}
                key={item}
                to={"/citiesNews/" + item}
                exact
                className="p-2 text-primary-revado-black font-bold"
                style={(isActive) => ({
                  color: isActive ? "#40514e" : "",
                  borderBottomColor: isActive ? "#40514e" : "",
                })}
              >
                {item}
              </NavLink>
            );
          })}{" "}
        </>
      )} */}
      {userDetails !== "" &&
        location?.pathname !== "/signUp" &&
        location?.pathname !== "/setting" &&
        location?.pathname !== "/forgotpassword" && (
          <NavLink
            to="/projectList"
            onClick={closePopup}
            exact
            className="p-2 text-primary-revado-black font-bold mt-1 text-center"
            style={(isActive) => ({
              color: isActive ? "#40514e" : "",
              borderBottomColor: isActive ? "#40514e" : "",
            })}
          >
            Projects
          </NavLink>
        )}
      {/* {userDetails !== "" &&
        location?.pathname !== "/signUp" &&
        location?.pathname !== "/setting" &&
        location?.pathname !== "/forgotpassword" && (
          <NavLink
            to="/home"
            onClick={closePopup}
            exact
            className="p-2 text-primary-revado-black font-bold mt-1 "
            style={(isActive) => ({
              color: isActive ? "#40514e" : "",
              borderBottomColor: isActive ? "#40514e" : "",
            })}
          >
            Stories
          </NavLink>
        )} */}
      {/* {userDetails !== "" &&
        location?.pathname !== "/signUp" &&
        location?.pathname !== "/setting" &&
        location?.pathname !== "/forgotpassword" && (
          <NavLink
            to="/favourites"
            onClick={closePopup}
            exact
            className="p-2 text-primary-revado-black font-bold mt-1 "
            style={(isActive) => ({
              color: isActive ? "#40514e" : "",
              borderBottomColor: isActive ? "#40514e" : "",
            })}
          >
            Favorites
          </NavLink>
        )} */}
      {/* <NavLink
        to="/feedBackForm"
        onClick={closePopup}
        exact
        className="p-2 text-primary-revado-black font-bold mt-1 "
        style={(isActive) => ({
          color: isActive ? "#40514e" : "",
          borderBottomColor: isActive ? "#40514e" : "",
        })}
      >
        Give us Feedback !
      </NavLink> */}
      <FeedBackForm />
    </ul>
  );

  return (
    <div className="w-full bg-primary-primary z-99 ">
      <div className="w-full flex  justify-between items-center px-4 lg:px-4 py-2">
        {/* Resposive toggle menu */}
        {location?.pathname !== "/signUp" &&
          location?.pathname !== "/forgotpassword" && (
            <IconButton
              variant="text"
              className="h-6 w-6 text-white hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden md:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          )}
        {/* Logo */}
        <span className="self-center text-xl font-semibold text-primary-revado-black whitespace-nowrap ">
          <img
            src={Whitelogo}
            className=" hidden lg:block cursor-pointer"
            alt="Revado Logo"
            width={150}
            onClick={() => {
              let path;
              if (userDetails) {
                path = userDetails?.roles[0] !== "Admin" ? "/projectList" : "/projectList";
              } else {
                path = "/projectList";
              }

              history.push(path);
            }}
          />
          <img
            src={Whitelogo}
            className=" lg:hidden block w-36 cursor-pointer"
            alt="Revado Logo"
            onClick={() => {
              let path;
              if (userDetails) {
                path = userDetails?.roles[0] !== "Admin" ? "/projectList" : "/";
              } else {
                path = "/";
              }

              history.push(path);
            }}
          />
        </span>

        {/* Searching */}
        {/* {location?.pathname !== "/signUp" &&
            location?.pathname !== "/setting" &&
            location?.pathname !== "/forgotpassword" &&
            location?.pathname !== "/" &&
            location?.pathname !== "/citiesNews/" && (
              <div className="md:flex justify-center w-1/3  hidden">
                <div className="relative w-full ">
                  <div className="absolute inset-y-0 left-0 pl-3 pt-2 ">
                    <svg
                      className=" h-6 w-6 text-primary-primary  mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    value={searchValue || ""}
                    className="w-full pl-10 pr-12 py-2 rounded-lg border focus:outline-none  focus:border-primary-primary"
                  />
                  {searchValue && (
                    <div
                      className="absolute inset-y-0 right-0 pr-3 pt-2"
                      onClick={(e) => setSearchValue(null)}
                    >
                      <svg
                        className="h-6 w-6 text-gray-400 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            )} */}

        {/* Action Buttons */}
        <div className="flex gap-1 items-center lg:order-2">
          {userDetails === "" ? (
            <>
              <NavLink
                to="/signIn"
                exact
                className=" bg-white text-primary-primary hover:bg-primary-revado-hover bg-primary-revado  focus:ring-4 focus:ring-primary-hover font-bold rounded-lg lg:text-md text-sm px-2 lg:px-5 py-2.5 lg:py-2.5 mr-2 "
              >
                Sign In
              </NavLink>
              {location?.pathname !== "/signUp" && (
                <NavLink
                  to="/signUp"
                  exact
                  className="text-white focus:ring-2 focus:ring-primary-revado-white font-bold rounded-lg lg:text-md text-sm px-1 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600  hover:bg-primary-hover/95"
                >
                  Sign up
                </NavLink>
              )}
            </>
          ) : (
            <div className="flex flex-row gap-5 mr-2">
              {(location?.pathname === "/setting" ||
                location?.pathname === "/") && (
                <NavLink
                  to="/projectList"
                  exact
                  className="hidden md:block lg:block  text-center border text-white hover:bg-primary-hover/95 hover:bg-primary-revado-hover bg-primary-revado  font-bold rounded-lg lg:text-md text-sm px-2 lg:px-5 py-2.5 lg:py-2.5  "
                >
                  Home
                </NavLink>
              )}
              {userDetails !== "" && userDetails.roles[0] !== "User" && (
                // <NavLink
                //   to={
                //     userDetails.roles[0] !== "Admin" ? "/Articles" : "/Projects"
                //   }
                //   exact
                //   className="lg:w-28 md:block  hidden lg:block md:w-28 text-center text-white  hover:bg-primary-hover/95 bg-primary-revado  focus:ring-4 focus:ring-primary-hover font-bold rounded-lg lg:text-md text-sm px-2 lg:px-5 py-2.5 lg:py-2.5  "
                // >
                //   Dashboard
                // </NavLink>
                <ToggleView
                  label={userDetails.roles[0]}
                  value={true}
                  role={userDetails.roles[0]}
                />
              )}
            </div>
          )}
          {userDetails !== "" &&
            location?.pathname !== "/signUp" &&
            location?.pathname !== "/forgotpassword" && <ProfileMenu />}

          {/* <button
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="main-grid-item-icon"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
              <path d="M13.73 21a2 2 0 0 1-3.46 0" />
            </svg>
          </button> */}
        </div>
      </div>

      {/* Collapse resposive menu */}
      <Collapse open={openNav}>
        <Card className="border m-1 p-1 flex flex-col ">{navList}</Card>
      </Collapse>
    </div>
  );
}
