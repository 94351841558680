import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { Chip } from "@material-tailwind/react";
import React from "react";

export default function StatusChip({ values, icon }) {

  return (
      <Chip
      className="opacity-80 font-medium text-xs "
      color={
          values === "2. Pending" ||values === "Pending"
            ? "orange"
            : values === "3. Accept "||values === "Accept"
            ? "blue"
            : values === "3. Denied"||values === "Denied"
            ? "red"
            : values === "4. Publish"||values === "Publish" || values === "Accept & Publish"
            ? "teal"
            : "gray"
        }
        value={
          (values  === "4. Publish"||values === "Publish" || values === "Accept & Publish" ?"Publish":values) ? (
            icon ? (
              <div className="flex flex-row gap-4 items-center justify-center 2xl:text-md">
                <span>{values} </span>
                <ArrowsRightLeftIcon className="w-4 h-4" />
              </div>
            ) : (
              values
            )
          ) : (
            "Not Submitted"
          )
        }
        variant="ghost"
        size="sm"
      />
  );
}
