/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { Button, Card } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from "react-router-dom";
import ImageUploader from "../../../../components/functionality/ImageUploader";
import PdfUploader from "../../../../components/functionality/PdfUploader";
import InputField from "../../../../components/ui/InputField";
import Loader from "../../../../components/ui/Loader";
import DragableMarker from "../../../../components/ui/Map/DragableMarker";
import MarkerLocationChange from "../../../../components/ui/Map/MarkerLocationChange";
import ProjectSummaryTextarea from "../../../../components/ui/ProjectSummary";
import { showMessage } from "../../../../components/ui/showMessage";
import { useApiGETRequest } from "../../../../hooks/useApiHook";
import {
  PROJECTLIST,
  UPDATEPROJEPUBLICHEARINGS,
  UPDATE_NEW_PROJECT,
  UPLOAD_IMAGES,
  UPLOAD_PDF,
} from "../../../../services/APIGlobal";
import axiosInstance from "../../../../services/APIInstance";
import multiPartAxiosInstance from "../../../../services/ApiMultipartInstance";
import { getFormJSON } from "../../../../store/helper/Helper";
import { DEFAULTMEETINGS, errorFieldMessage } from "../../../../utils/Constant";
import ArticleProjectTeam from "../Articles/ArticleProjectTeam";
import AdminSideProjectDetails from "./AdminSideProjectDetails";
import ProjectAssetsType from "./ProjectAssetsType";
import ProjectAttribute from "./ProjectAttribute";
import ProjectStatus from "./ProjectStatus";
import PublicHearings from "./PublicHearings";

export default function NewProjectDetails(props) {
  const history = useHistory();
  const ProjectId = JSON.parse(localStorage.getItem("project"));
  const [location, setLocation] = useState({});
  const LocationData = useLocation();
  const [openInfo, setOpenInfo] = useState();
  const [meetings, setMeetings] = useState([]);
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectById, setProjectById] = useState("");
  const [showImageError, setShowImageError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const textareaRefs = useRef([]);
  const [buttonLabel, setButtonLabel] = useState("Update and Publish");
  const [textSummary, setTextSummary] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [pdfFiles, setPdfFiles] = useState([]);
  const [defaultPdf, setDefaultPdf] = useState([]);

  const [addressText, setAddressText] = useState(() => {
    const streetAddress = LocationData?.data?.location?.StreetAddress;
    const streetIntersections =
      LocationData?.data?.location?.StreetIntersections || "";
    const city = LocationData?.data?.location?.City;
    const state = LocationData?.data?.location?.State;
    const zipCode = LocationData?.data?.location?.ZipCode;

    // Filter out empty parts of the address
    const addressParts = [
      streetAddress,
      streetIntersections,
      city,
      state,
      zipCode,
    ].filter((part) => part);

    return addressParts.join(", ");
  });
  const [images, setImages] = useState([]);
  const [existingImageUrls, setExistingImageUrls] = useState([]);

  // TODO: WHILE IMAGES ARE UPLOADING NEED TO DISABLE BUTTON.
  const [isFileUploading, setIsFileUploading] = useState(false);

  const [assetType, setAssetType] = useState(
    LocationData?.data?.AssetType ? LocationData?.data?.AssetType : []
  );
  const [projectStatusType, setProjectStatusType] = useState([]);
  const [defaultMarker] = useState(
    LocationData?.title === "Edit"
      ? {
        longitude: LocationData?.data?.location?.Longitude,
        latitude: LocationData?.data?.location?.Latitude,
      }
      : {}
  );
  const [attributeValue, setAttributeValue] = useState("");
  const [attributeValue2, setAttributeValue2] = useState("");
  const [attributeValue3, setAttributeValue3] = useState("");
  const [attributeValue4, setAttributeValue4] = useState("");
  const [attributeName, setAttributeName] = useState("");
  const [attributeName2, setAttributeName2] = useState("");
  const [attributeName3, setAttributeName3] = useState("");
  const [attributeName4, setAttributeName4] = useState("");

  useEffect(() => {
    const projectTopElement = document.getElementById("projectTop");
    if (projectTopElement) {
      projectTopElement.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, []);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await axiosInstance.get(PROJECTLIST + "/" + ProjectId);
        const publicHearings = response?.data?.project?.public_hearings;
        const defaultMeetings = publicHearings
          ? Array.from({ length: Math.max(publicHearings.length, 4) }, (_, index) => ({
            MarketingType: publicHearings[index]?.MarketingType || null,
            status: publicHearings[index]?.status || null,
            MeetingDate: publicHearings[index]?.MeetingDate || null,
            UrlLink: publicHearings[index]?.UrlLink || null,
            publicHearingID: publicHearings[index]?.publicHearingID || null,
            ProjectDetailsId: publicHearings[index]?.ProjectDetailsId || null,
            projectId: publicHearings[index]?.projectId || null,
          }))
          : DEFAULTMEETINGS;

        setMeetings(defaultMeetings);
        setDefaultPdf(response?.data?.project?.pdfUrls || []);
      } catch (error) {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      }
    };

    fetchProjectDetails();
  }, []);

  useEffect(() => {
    setExistingImageUrls(LocationData?.data?.imageUrls);
  }, [LocationData?.data?.imageUrls]);

  useEffect(() => {
    setProjectLink(LocationData?.data?.link_to_source || "");
    setTextSummary(LocationData?.data?.ProjectSummary || "");
    setDefaultPdf(LocationData?.data?.pdfUrls || []);

    const defaultMeetings = LocationData?.data?.public_hearings
      ? Array.from({ length: Math.max(LocationData?.data?.public_hearings.length, 4) }, (_, index) => ({
        MarketingType: LocationData?.data?.public_hearings[index]?.MarketingType || null,
        status: LocationData?.data?.public_hearings[index]?.status || null,
        MeetingDate: LocationData?.data?.public_hearings[index]?.MeetingDate || null,
        UrlLink: LocationData?.data?.public_hearings[index]?.UrlLink || null,
        publicHearingID: LocationData?.data?.public_hearings[index]?.publicHearingID || null,
        ProjectDetailsId: LocationData?.data?.public_hearings[index]?.ProjectDetailsId || null,
        projectId: LocationData?.data?.public_hearings[index]?.projectId || null,
      }))
      : DEFAULTMEETINGS;

    setMeetings(defaultMeetings);
  }, [LocationData?.data]);

  // * COMPONENT LABEL *
  const Label = (props) => (
    <label className="flex flex-row items-center  text-gray-500 text-sm font-medium">
      {props.text}
      {props.subtext && (
        <span className="text-gray-500 pl-1">{props.subtext}</span>
      )}
      {props.isRequired && <span className="ml-1 text-red-500">*</span>}
    </label>
  );

  // * PROJECT LIST *
  const PROJECT_LIST = [
    {
      label: "Public Hearings",
      value: "Public Hearings",
      desc: (
        <div className="p-2">
          <div className="flex md:flex-row flex-col gap-4 mb-2">
            <div className=" md:w-1/2 ">
              <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start">
                Meeting Type
              </p>
            </div>
            <div className=" md:w-1/2 ">
              <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start">
                Status{" "}
              </p>
            </div>
            <div className=" md:w-1/2 ">
              <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start">
                Meeting Date{" "}
              </p>
            </div>
            <div className=" md:w-1/2 ">
              <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start">
                URL link
              </p>
            </div>
          </div>
          <div className="flex  flex-col gap-3 w-full">
            <PublicHearings meetings={meetings} setMeetings={setMeetings} />
          </div>
        </div>
      ),
    },
    {
      label: "Project Team",
      value: "article",
      desc: (
        <ArticleProjectTeam
          setOpen={setOpenInfo}
          open={openInfo}
          loading={false}
          API={useApiGETRequest(`${PROJECTLIST}/${ProjectId}`)}
        />
      ),
    },
  ];
  const handleOpen = (story) => {
    setOpen(!open);
    setProjectById(story);
  };
  // * HANDLE SELECT LOCATION *
  const handleSelectLocation = (cordinates) => {
    const location = {
      StreetAddress: cordinates.streetNumber,
      StreetIntersections: cordinates.streetIntersections,
      ParcelId: "",
      City: cordinates.city,
      ZipCode: cordinates.zipCode,
      LocationId: "",
      Latitude: cordinates.latitude,
      Longitude: cordinates.longitude,
      State: cordinates?.state,
    };

    const streetAddress =
      cordinates.streetNumber !== "Undefined" ? cordinates.streetNumber : "";
    setAddressText(
      `${streetAddress}, ${cordinates?.streetIntersections
        ? cordinates.streetIntersections + ", "
        : ""
      }${cordinates?.city} , ${cordinates?.state}- ${cordinates?.zipCode}`
    );
    setLocation(location);
  };

  // * HANDLE SUBMIT FORM  *
  const handleSubmitForm = (e) => {
    const buttonName = e.nativeEvent.submitter.name;
    e.preventDefault();
    // setLoading(true);
    const payload = getFormJSON(e.target);
    if (payload?.title === " ") {
      setFormErrors({ title: true });
      return;
    } else if (
      !location.Longitude &&
      !LocationData?.data?.location?.Longitude
    ) {
      setFormErrors({ marker: true });
      return;
    }
    else if (existingImageUrls.length === 0) {
      setShowImageError(true);
      return;
    } else if (payload.StreetAddress === " ") {
      setFormErrors({ address: true });
      textareaRefs.current[0].focus();
      // Scroll to the textarea element
      textareaRefs.current[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    } else if (textSummary === " ") {
      setFormErrors({ summaray: true });
      textareaRefs.current[1].focus();
      // Scroll to the textarea element
      textareaRefs.current[1].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    } else if (!assetType || assetType.length === 0) {
      setFormErrors({ assetTypeError: true });
      textareaRefs.current[2].focus();
      // Scroll to the textarea element
      textareaRefs.current[2].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    } else if (!projectStatusType || projectStatusType.length === 0) {
      setFormErrors({ projectstatusError: true });
      textareaRefs.current[3].focus();
      // Scroll to the textarea element
      textareaRefs.current[3].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    const selectedAssetType = Array.isArray(assetType)
      ? assetType.join(",")
      : assetType || null;
    // console.log(projectStatusType, "projectStatusType")
    // const selectedProjectStatusType = Array.isArray(projectStatusType)
    //   ? projectStatusType.join(",")
    //   : projectStatusType || null;

    // New values array obtained from projectAttributes
    let attribute_names =
      LocationData?.data?.projectAttributes?.attribute_names;
    const projectAttributesNames = [
      attributeName,
      attributeName2,
      attributeName3,
      attributeName4,
    ];
    // Iterate through the new values array
    projectAttributesNames.forEach((item, index) => {
      // Check if the item is a string and not empty
      if (typeof item === "string" && item.trim() !== "") {
        // Update the value in attribute_names if it exists at the same index
        if (attribute_names && attribute_names[index]) {
          attribute_names[index] = item;
        } else {
          // If the index doesn't exist, append the value to attribute_names
          if (!attribute_names) {
            // If attribute_names doesn't exist, initialize it as an empty array
            attribute_names = [];
          }
          attribute_names.push(item);
        }
      }
    });
    const attribute_values =
      LocationData?.data?.projectAttributes?.attribute_values;
    const projectAttributes = [
      attributeValue,
      attributeValue2,
      attributeValue3,
      attributeValue4,
    ];
    // Iterate through the new values array
    projectAttributes.forEach((item, index) => {
      // Check if the item is an object and not empty
      if (typeof item === "object" && Object.keys(item).length > 0) {
        // Get the value from the object
        const value = Object.values(item)[0];
        // Update the value in attribute_values if it exists at the same index
        if (attribute_values[index]) {
          attribute_values[index] = value;
        } else {
          // If the index doesn't exist, append the value to attribute_values
          attribute_values.push(value);
        }
      }
    });
    const cleanedMeetings = meetings
      .filter((meeting) =>
        Object.values(meeting).some((value) => value !== null && value !== "")
      )
      .map((meeting) =>
        Object.fromEntries(
          Object.entries(meeting).filter(([_, v]) => v !== null && v !== "")
        )
      );
    if (buttonName === "submitForm") {
      let projectDetails = {};
      // console.log(pdfFiles.length, "pdfFiles.length");
      const pdfData = new FormData();
      pdfFiles.forEach((pdf) => {
        pdfData.append(`pdf`, pdf);
      });

      // Upload PDFs and handle the promise
      if (pdfFiles.length > 0) {
        pdfUpload(pdfData).then((uploadedPdfUrls) => {
          projectDetails.pdfUrls = [...uploadedPdfUrls, ...defaultPdf];
          // Any other logic that needs to be done after pdfUrls is updated
          // Proceed to update project details
          updateProjectDetails({
            projectDetails,
            selectedAssetType,
            payload,
            attribute_values,
            attribute_names,
            cleanedMeetings,
          });
        });
      } else {
        // If no PDFs were uploaded, set pdfUrls to defaultPdf directly
        projectDetails.pdfUrls = [...defaultPdf];
        // Proceed to update project details
        updateProjectDetails({
          projectDetails,
          selectedAssetType,
          payload,
          attribute_values,
          attribute_names,
          cleanedMeetings,
        });
      }
    } else if (buttonName === "projectPreview") {
      const projectDetails = {
        title: payload.title,
        ProjectSummary: textSummary,
        link_to_source: projectLink,
        AssetType: selectedAssetType,
        project_status_type: projectStatusType,
        Developer: payload.Developer,
        GeneralContractor: payload.GeneralContractor,
        Contractor: payload.Contractor,
        Owner: payload.Owner,
        Architect: payload.Architect,
        Planner: payload.Planner,
        Investor: payload.Investor,
        Lender: payload.Lender,
        Bank: payload.Bank,
        CivilEngineer: payload.CivilEngineer,
        Engineer: payload.Engineer,
        Broker: payload.Broker,
        Brokerage: payload.Brokerage,
        Tenant: payload.Tenant,
        comment: payload.comment,
        TypeUser: payload.TypeUser,
        StreetAddress: payload.StreetAddress,
        // StreetIntersections: location.StreetIntersections,
        // ParcelId: location.ParcelId,
        // City: location.City,
        // ZipCode: location.ZipCode,
        Latitude: !location.Latitude
          ? LocationData?.data?.location?.Latitude
          : location.Latitude
            ? LocationData?.data?.location?.Latitude
            : location.Latitude,
        Longitude: !location.Longitude
          ? LocationData?.data?.location?.Longitude
          : location.Longitude,
        // State: location.State || null,
        IsPublish: "Yes",
        flag: 1,
        startdate: startdate ? startdate : LocationData?.data?.startdate,
        enddate: enddate ? enddate : LocationData?.data?.enddate,
        imageUrls: existingImageUrls,
        pdfUrls: [...pdfFiles, ...defaultPdf],
        public_hearings: cleanedMeetings,
        projectAttributes: {
          attribute_values: attribute_values
            ? attribute_values?.filter((item) => item !== "")
            : "",
          attribute_names: attribute_names
            ? attribute_names?.filter((item) => item !== "")
            : "",
        },
      };
      handleOpen(projectDetails);
    }
  };

  const handleImageUpload = async (images) => {
    setImages(images);
    setIsFileUploading(true);
    const newImageFiles = images.filter((image) => image.file);
    const formData = new FormData();

    newImageFiles.forEach((img) => {
      formData.append("images", img.file);
    });

    if (newImageFiles.length > 0) {
      try {
        const response = await multiPartAxiosInstance.post(
          UPLOAD_IMAGES,
          formData
        );
        const uploadedImages = response.data.imageUrl;

        // Replace file format images with uploaded image URLs
        const updatedImages = images.map((image) =>
          image.file ? { data_url: uploadedImages.shift() } : image
        );
        setExistingImageUrls(updatedImages.map((image) => image.data_url));
      } catch (error) {
        showMessage(
          error?.response?.data?.error || "Error uploading images",
          "error",
          "red",
          false,
          false,
          false
        );
      } finally {
        setIsFileUploading(false);
      }
    } else {
      setExistingImageUrls(images.map((image) => image.data_url));
      setIsFileUploading(false);
    }
  };
  const updateProjectDetails = ({
    projectDetails,
    selectedAssetType,
    payload,
    attribute_values,
    attribute_names,
    cleanedMeetings,
  }) => {
    projectDetails = {
      title: payload.title,
      ProjectSummary: textSummary,
      link_to_source: projectLink,
      AssetType: selectedAssetType,
      project_status_type: projectStatusType,
      Developer: payload.Developer,
      GeneralContractor: payload.GeneralContractor,
      Contractor: payload.Contractor,
      Owner: payload.Owner,
      Architect: payload.Architect,
      Planner: payload.Planner,
      Investor: payload.Investor,
      Lender: payload.Lender,
      Bank: payload.Bank,
      CivilEngineer: payload.CivilEngineer,
      Engineer: payload.Engineer,
      Broker: payload.Broker,
      Brokerage: payload.Brokerage,
      Tenant: payload.Tenant,
      comment: payload.comment,
      TypeUser: payload.TypeUser,
      StreetAddress: !payload.StreetAddress
        ? LocationData?.data?.location?.StreetAddress
        : payload.StreetAddress,
      Latitude: !location.Latitude
        ? LocationData?.data?.location?.Latitude
        : location.Latitude
          ? LocationData?.data?.location?.Latitude
          : location.Latitude,
      Longitude: !location.Longitude
        ? LocationData?.data?.location?.Longitude
        : location.Longitude,
      IsPublish: "Yes",
      flag: 1,
      attribute_values: attribute_values
        ? attribute_values?.filter((item) => item !== "")
        : "",
      attribute_names: attribute_names
        ? attribute_names?.filter((item) => item !== "")
        : "",
      startdate: startdate,
      enddate: enddate,
      images: existingImageUrls,
      // Add pdfUrls here
      pdfUrls: projectDetails.pdfUrls,
    };
    // console.log(projectDetails, "projectDetails");

    // Update project details
    setTimeout(() => {
      multiPartAxiosInstance
        .put(`${UPDATE_NEW_PROJECT}/${ProjectId}`, projectDetails)
        .then((response) => {
          let MEETINGDATA = {
            publicHearingsDataArray: cleanedMeetings,
          };
          axiosInstance
            .put(
              `${UPDATEPROJEPUBLICHEARINGS}?projectId=${ProjectId}`,
              MEETINGDATA
            )
            .then((response) => {
              setLoading(false);
              setButtonLabel("Published!");
              setTimeout(() => {
                setButtonLabel("Update and Publish");
              }, 5000);
              showMessage(
                "Project details updated successfully",
                "success",
                "#576d69",
                false,
                false,
                false
              );
            })
            .catch((error) => {
              setLoading(false);
              showMessage(
                error?.response?.data?.error
                  ? error?.response?.data?.error
                  : "Error updating project details",
                "error",
                "red",
                false,
                false,
                false
              );
            });
        })
        .catch((error) => {
          setLoading(false);
          showMessage(
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Error updating project details",
            "error",
            "red",
            false,
            false,
            false
          );
        });
    }, 3000);
  };
  const handleAddress = (e) => {
    setAddressText(e.target.value);
  };

  // eslint-disable-next-line
  function pdfUpload(formData) {
    return new Promise((resolve, reject) => {
      multiPartAxiosInstance
        .post(`${UPLOAD_PDF}`, formData)
        .then((response) => {
          resolve(response?.data?.pdfUrl);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <form
          onSubmit={handleSubmitForm}
          className="flex flex-col gap-2  px-2 pt-2  pb-16 lg:pb-5 "
        >
          <Card className="border-t-2 m-0" id="projectTop">
            <div className="shadow border-b border-gray-200 rounded-lg ">
              <section className="p-2 flex  flex-col gap-2 ">
                {/* Information */}

                <div className="flex justify-end gap-2">
                  {/* Form Button */}
                  <Button
                    name="submitForm"
                    size="sm"
                    color="white"
                    className={`
                    max-w-fit flex items-center rounded-md 
                    bg-primary-redova-favorite
                    text-white
                    w-full shadow-none hover:text-white hover:bg-primary-redova-favorite/100 hover:shadow-none hover:ring-0 focus:ring-0 focous:bg-transparent 
                    ${buttonLabel === "Published!"
                        ? "bg-primary-primary"
                        : "bg-primary-redova-favorite"
                      }`}
                    variant="outlined"
                    type="submit"
                    ripple={false}
                  >
                    {buttonLabel}
                  </Button>
                  <Button
                    name="projectPreview"
                    size="sm"
                    color="white"
                    className="max-w-fit flex items-center rounded-md text-white  bg-primary-primary w-full shadow-none hover:shadow-none hover:ring-0 focus:ring-0 focous:bg-primary-primary"
                    variant="outlined"
                    type="submit"
                    ripple={false}
                  >
                    Project Preview
                  </Button>
                </div>

                {/* Common Info */}
                <div className="">
                  <Label text="Project Title" isRequired={true} />

                  <textarea
                    id="title"
                    name="title"
                    rows="1"
                    placeholder="Enter Project Title"
                    defaultValue={LocationData?.data?.title}
                    // onChange={(e) => setTitle(e.target.value)}
                    onChange={(e) => setFormErrors({ title: false })}
                    className={`mt-1 peer w-full px-3 py-2 z-0   placeholder-gray-500  border ${formErrors.title
                      ? "border-warn-error"
                      : "border-gray-300 "
                      } rounded-md focus:outline-none  focus:z-10 sm:text-sm 2xl:text-base`}
                  />
                  {formErrors.title && (
                    <span className={errorFieldMessage}>
                      Please enter Project Title
                    </span>
                  )}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 my-4 gap-4">
                  <div>
                    <Label text="Project Location" isRequired={true} />

                    {/* Map Section */}
                    <div className="hidden md:block rounded-md">
                      <p className="text-xs text-gray-500 mb-2">
                        Location is not available. Please search and add new
                        "Marker"
                      </p>
                      <MarkerLocationChange
                        cordinates={
                          LocationData?.data?.location?.Longitude !==
                            "undefined"
                            ? {
                              longitude:
                                LocationData?.data?.location?.Longitude !==
                                  "undefined "
                                  ? LocationData?.data?.location?.Longitude
                                  : "",
                              latitude:
                                LocationData?.data?.location?.Latitude !==
                                  "undefined "
                                  ? LocationData?.data?.location?.Latitude
                                  : "",
                            }
                            : []
                        }
                        setCordinates={handleSelectLocation}
                        className={`hidden md:block rounded-md ${formErrors.marker && "border-warn-error border-2"
                          } `}
                        style={{
                          width: "100%",
                          height: 285,
                          borderRadius: "0.375rem",
                        }}
                        defaultMarker={defaultMarker}
                      />
                    </div>
                    <div className="md:hidden block">
                      <p className="text-xs text-gray-500 mb-2">
                        Drag Marker to change location
                      </p>
                      <DragableMarker
                        cordinates={
                          LocationData?.data?.location?.Longitude !==
                            "undefined"
                            ? {
                              longitude:
                                LocationData?.data?.location?.Longitude !==
                                  "undefined "
                                  ? LocationData?.data?.location?.Longitude
                                  : "",
                              latitude:
                                LocationData?.data?.location?.Latitude !==
                                  "undefined "
                                  ? LocationData?.data?.location?.Latitude
                                  : "",
                            }
                            : []
                        }
                        style={{
                          width: "100%",
                          height: 285,
                          borderRadius: "0.375rem",
                        }}
                        setCordinates={handleSelectLocation}
                      />
                    </div>
                    {formErrors.marker && (
                      <span className={errorFieldMessage}>
                        Please select location
                      </span>
                    )}
                  </div>

                  <div>
                    <Label
                      text="Project Images"
                      subtext="(maximum of 5)"
                      isRequired={true}
                    />
                    <div className="mt-6">
                      <ImageUploader
                        showImageError={showImageError}
                        setShowImageError={setShowImageError}
                        images={
                          LocationData?.data?.imageUrls?.length > 0
                            ? LocationData?.data?.imageUrls
                            : images
                        }
                        setImages={handleImageUpload}
                        defaultImages={
                          LocationData?.data?.imageUrls
                            ? LocationData?.data?.imageUrls
                            : []
                        }
                      />
                    </div>
                    {showImageError && !images?.length > 0 && (
                      <span className={errorFieldMessage}>
                        Upload atleast one image
                      </span>
                    )}
                  </div>
                </div>

                <div className="">
                  <Label text="Address" isRequired={true} />
                  <textarea
                    ref={(el) => (textareaRefs.current[0] = el)}
                    // value={addressText}
                    onChange={(e) => {
                      handleAddress(e);
                      setFormErrors({ title: false });
                    }}
                    id="StreetAddress"
                    name="StreetAddress"
                    rows="1"
                    defaultValue={LocationData?.data?.location?.StreetAddress}
                    placeholder="Project Address"
                    className={`mt-1 peer w-full px-3 py-2 z-0 placeholder-gray-500  border ${formErrors.address
                      ? "border-warn-error"
                      : "border-gray-300 "
                      } rounded-md focus:outline-none  focus:z-10 sm:text-sm 2xl:text-base`}
                  />
                  {formErrors.address && (
                    <span className={errorFieldMessage}>Enter Address</span>
                  )}
                </div>

                <div className="">
                  <div className="flex justify-between items-start py-2">
                    <Label text="Project Summary" isRequired={true} />
                  </div>
                  {/* 
                  <textarea
                    maxLength={1000}
                    ref={(el) => (textareaRefs.current[1] = el)}
                    id="projectSummary"
                    name="projectSummary"
                    defaultValue={
                      LocationData?.data?.ProjectSummary !== "" &&
                      LocationData?.data?.ProjectSummary
                    }
                    onChange={(e) => {
                      setCharacterCount(e.target.value?.length);
                      setFormErrors({ summaray: false });
                    }}
                    rows="4"
                    placeholder=""
                    className={`mt-1 peer w-full px-3 py-2 z-0 placeholder-gray-500  border ${
                      formErrors.summaray
                        ? "border-warn-error"
                        : "border-gray-300 "
                    }  rounded-md focus:outline-none  focus:z-10 sm:text-sm 2xl:text-base`}
                  /> */}
                  <ProjectSummaryTextarea
                    textSummary={textSummary}
                    setTextSummary={setTextSummary}
                    setFormErrors={setFormErrors}
                  />
                  {formErrors.summaray && (
                    <span className={errorFieldMessage}>
                      Enter Project Summary
                    </span>
                  )}
                </div>

                <div>
                  <Label text="Asset Type" isRequired={true} />
                  <ProjectAssetsType
                    assetType={assetType}
                    setAssetType={setAssetType}
                    data={LocationData?.data}
                    formErrors={formErrors}
                    textareaRefs={textareaRefs}
                  />
                  {formErrors?.assetTypeError &&
                    (assetType?.length === 0 || !assetType) && (
                      <span className={errorFieldMessage}>
                        Select Asset Type
                      </span>
                    )}
                </div>

                <div c>
                  <Label text="Project Status" isRequired={true} />

                  <ProjectStatus
                    projectStatusType={projectStatusType}
                    setProjectStatusType={setProjectStatusType}
                    data={LocationData?.data}
                    textareaRefs={textareaRefs}
                    formErrors={formErrors}
                  />
                  {formErrors?.projectstatusError &&
                    projectStatusType.length === 0 && (
                      <span className={errorFieldMessage}>
                        Select Project Status
                      </span>
                    )}
                </div>
                <div>
                  <Label text="Source link" />
                  <div className="relative">
                    <textarea
                      rows="1"
                      type="url"
                      defaultValue={projectLink}
                      placeholder="Type here..."
                      className="border border-gray-300 peer w-full  px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm p-2 "
                      onChange={(e) => setProjectLink(e.target.value)}
                    />

                    <div className="absolute right-2 top-3 h-4 w-4 cursor-pointer text-primary-primary   rounded">
                      {projectLink && (
                        <ArrowUpRightIcon
                          strokeWidth={2}
                          onClick={() => window.open(projectLink, "_blank")}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <Label text="Project PDFs" subtext="(maximum of 5)" />
                  <PdfUploader
                    pdfFiles={pdfFiles}
                    setPdfFiles={setPdfFiles}
                    defaultPdf={defaultPdf}
                    setDefaultPdf={setDefaultPdf}
                    pdfUploaderValue={LocationData?.data?.pdfUrls}
                  />
                </div>
                <hr className="my-8 h-1 bg-gray-400" />

                <div className="grid grid-cols-1 md:grid-cols-2 my-4">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="flex flex-col gap-6">
                      {/* Project Start Date */}
                      <div className="flex flex-col gap-1">
                        <Label text="Project Start Date" />
                        {/* <CustomDatePicker
                            id="startdate"
                            name="startdate"
                            startDate={startdate}
                            defaultValue={
                              (LocationData?.data?.startdate === undefined || LocationData?.data?.startdate === '')
                                ? (LocationData?.data?.startdate ? new Date(LocationData.data.startdate) : null)
                                : startdate || null
                            }
                            onChange={(date) => setStartDate(date)}
                          /> */}
                        <input
                          className={`border border-gray-300 peer w-full  px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
                          type="date"
                          placeholder={`Start Date`}
                          defaultValue={LocationData?.data?.startdate || ""}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                        />
                      </div>

                      {/* Project Completion Date */}
                      <div className="flex flex-col gap-1">
                        <Label text="Project Completion Date" />
                        {/* <CustomDatePicker
                            id="enddate"
                            name="enddate"
                            enddate={enddate}
                            defaultValue={
                              LocationData?.data?.enddate === undefined ||
                              LocationData?.data?.enddate === ""
                                ? LocationData?.data?.enddate
                                  ? new Date(LocationData.data.enddate)
                                  : null
                                : enddate || null
                            }
                            onChange={(date) => setEndDate(date)}
                          /> */}
                        <input
                          className={`border border-gray-300 peer w-full  px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
                          type="date"
                          placeholder={`Start Date`}
                          defaultValue={LocationData?.data?.enddate || ""}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                          }}
                        />
                      </div>

                      {/* Type of Business */}
                      <div className="flex flex-col gap-1">
                        <Label text="Type of business" />
                        <InputField
                          name="TypeUser"
                          defaultValue={LocationData?.data?.TypeUser}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    {/* Project Attributes */}
                    <Label text="Project Attributes" />
                    <ProjectAttribute
                      attributeValue={attributeValue}
                      attributeValue2={attributeValue2}
                      attributeValue3={attributeValue3}
                      attributeValue4={attributeValue4}
                      setAttributeValue={setAttributeValue}
                      setAttributeValue2={setAttributeValue2}
                      setAttributeValue3={setAttributeValue3}
                      setAttributeValue4={setAttributeValue4}
                      attributeName={attributeName}
                      attributeName2={attributeName2}
                      attributeName3={attributeName3}
                      attributeName4={attributeName4}
                      setAttributeName={setAttributeName}
                      setAttributeName2={setAttributeName2}
                      setAttributeName3={setAttributeName3}
                      setAttributeName4={setAttributeName4}
                      attributeDefaultName={
                        LocationData?.data?.projectAttributes?.attribute_names
                      }
                      attributeDefaultValues={
                        LocationData?.data?.projectAttributes?.attribute_values
                      }
                    />
                  </div>
                </div>

                {/* Accordion */}
                <div className="grid grid-cols-1 gap-4 my-4">
                  {PROJECT_LIST.map(({ label, value, desc, icon }, i) => (
                    <section key={i}>
                      <div
                        className={` rounded-lg px-2 py-1  font-semibold text-sm  underline border-2 transition-colors border-b-0 rounded-b-none `}
                      >
                        {label}
                      </div>
                      <div
                        className={` rounded-lg p-1 border-2 transition-colors border-t-0 rounded-t-none `}
                      >
                        {desc}
                      </div>
                    </section>
                  ))}
                </div>

                <div className="grid grid-cols-1 gap-2 my-4">
                  <Label text="Admin Comment"></Label>
                  <textarea
                    id="comment"
                    name="comment"
                    defaultValue={LocationData?.data?.comment}
                    rows="2"
                    placeholder="Add a comment"
                    // onChange={(e) => setTitle(e.target.value)}
                    className={` peer w-full px-3 py-2 z-0 border-2 border-green-800 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm 2xl:text-base`}
                  />
                </div>
              </section>
            </div>
          </Card>
          {open && (
            <AdminSideProjectDetails
              open={open}
              handleOpen={handleOpen}
              projectDetails={projectById}
            />
          )}
        </form>
      )}
    </>
  );
}
