import React, { useEffect, useState } from "react";

const ArticleAssetType = ({ options, defaultChecked, onChange,name,notApplicable }) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultChecked);
  const handleCheckboxChange = (option) => {
    let updatedOptions;

    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((item) => item !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(updatedOptions);
    onChange(updatedOptions,name);
  };
  useEffect(()=>{
    setSelectedOptions(defaultChecked)
  },[defaultChecked])

  return (
    <section className="my-2 mx-2">
      <div className="grid gap-3 md:grid-flow-row-dense md:grid-cols-4 2xl:grid-cols-8">
      {notApplicable && <label key="notApplicable" className="inline-block align-center">
            <input
              className="inline-block align-top cursor-pointer h-4 w-4 rounded accent-primary-hover	text-white"
              type="checkbox"
              name="NA"
              value="notApplicable"
              checked={selectedOptions.includes("notApplicable")}
              onChange={() => handleCheckboxChange("notApplicable")}
            />
            <span className="ml-2 ">NA</span>
          </label>}
        {options && <>
          {options.map(({ Name }) => (
          <label key={Name} className="inline-block align-center">
            <input
              className="inline-block align-top cursor-pointer h-4 w-4 rounded accent-primary-hover	text-white"
              type="checkbox"
              name={name}
              value={Name}
              checked={selectedOptions.includes(Name)}
              onChange={() => handleCheckboxChange(Name)}
            />
            <span className="ml-2 ">{Name}</span>
          </label>
        ))}</>}

      </div>
    </section>
  );
};

export default ArticleAssetType;
