import { Card } from "@material-tailwind/react";
import { useEffect } from "react";
import { ASSETTYPE } from "../../../../services/APIGlobal";
import { useState } from "react";
import axiosInstance from "../../../../services/APIInstance";
import { showMessage } from "../../../../components/ui/showMessage";
import AllAssetTypeDB from "../../../../components/datatables/AllAssetTypeDB";
import Loader from "../../../../components/ui/Loader";
import { Button } from "@material-tailwind/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useHistory } from "react-router-dom";
export default function AssetType() {
  const history = useHistory();
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]); // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showRecord, setShowRecord] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();
  useEffect(() => {
    GET_STORIES(); // eslint-disable-next-line
  }, []);
  const GET_STORIES = () => {
    setLoading(true);

    axiosInstance
      .get(`${ASSETTYPE}?limit=${showRecord}&page=${pageNo}`)
      .then((response) => {
        setData(response?.data?.getAssetType);
        setTotalRecord(response?.data?.pagination?.totalCount);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };
  return (
    <Card className="overflow-x-scroll h-full  border-t p-4">
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div>
          <div className="flex md:flex-row flex-col justify-end gap-2">
            <Button
              size="sm"
              variant="outlined"
              onClick={(e) => {
                history.push("/AssetType/addAssetType");
              }}
              className=" text-primary-primary border-primary-primary flex items-center gap-3  cursor-pointer ring-0 active:ring-0 focus:ring-0"
            >
              <PlusIcon strokeWidth={2} className="h-5 w-5" /> Add AssetType
            </Button>
          </div>
          <AllAssetTypeDB
            articles={data}
            GET_STORIES={GET_STORIES}
            setPageNo={setPageNo}
            pageNo={pageNo}
            showRecord={showRecord}
            setShowRecord={setShowRecord}
            totalRecord={totalRecord}
            currentPage={currentPage}
            totalPage={totalPage}
          />
        </div>
      )}
    </Card>
  );
}
