import Swal from "sweetalert2";
export const showMessage = (
  message,
  icon,
  iconColor,
  showConfirmButton,
  showCancelButton,
  showCloseButton,
  onClose,
) => {
  Swal.fire({
     iconColor:iconColor,
    icon: icon,
    text: message,
    didClose: onClose,
    showConfirmButton:showConfirmButton,
    showCloseButton: showCloseButton,
    showCancelButton:showCancelButton ,
    timer: 2000,
    customClass: {
      loader: "custom-loader",
      popup:"bg-swal"
    },
  });
};
