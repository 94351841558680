import React from "react";

const Checkbox = ({ handleClick, name, isChecked, label, checkHandler,index }) => {
  return (
    <div className="flex gap-2">
      <input
        type="checkbox"
        onClick={handleClick}
        checked={isChecked}
        onChange={checkHandler}
        id={`checkbox-${index}`}
        className="cursor-pointer h-4 w-4 inline-block rounded accent-primary-primary	text-white"
      />
      <label htmlFor={`checkbox-${index}`}>{index}</label>
    </div>
  );
};

export default Checkbox;
