import React, { useContext, useState } from "react";
import FilterContext from "../../context/FilterContext";
import { useLocation } from "react-router-dom";
import { showMessage } from "./showMessage";
import { ADD_TO_FAVORITE, REMOVE_TO_FAVORITE } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { removeQueryParameters } from "../../store/helper/Helper";
import {
  Card,
  IconButton,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import ImageWithFallback from "./ImageWithFallback";
import logo from "../../assets/Logo/OnlyBlacklogo.png";
import moment from "moment";
import ProjectDetails from "../../pages/home/ProjectDetails";

export default function ProjectListCard({
  APItype,
  setProjectHighlightId,
  stories,
  GET_STORIES,
}) {
  // eslint-disable-next-line
  const location = useLocation();
  const [open, setOpen] = useState(false);
  // const [stories, setStories] = useState([]); // eslint-disable-next-line
  // eslint-disable-next-line
  const { filterArray, isFavouriteTab } = useContext(FilterContext);
  const [projectById, setProjectById] = useState("");
  // useEffect(() => {
  //   GET_STORIES(); // eslint-disable-next-line
  //   // console.log(filterArray, "call");
  //   // eslint-disable-next-line
  // }, [location, APItype, filterArray]);
  const handleOpen = (story) => {
    setProjectById(story);
    setOpen(!open);
  };

  // Get API
  // const GET_STORIES = () => {
  //   setLoading(true);

  //   // if (APItype === "Project") {
  //   console.log(filterArray, "filterArray");
  //   axiosInstance
  //     .get(
  //       `${PROJECTLIST}?flag=1&page=1&limit=1000&isPublish=Yes&type=user&userId=${localStorage.getItem(
  //         "userId"
  //       )}${
  //         filterArray?.SEARCH ? "&searchQuery=" + filterArray?.SEARCH : ""
  //       }&sort=new&dateRange=${filterArray?.DATE ? filterArray?.DATE : 0}${
  //         filterArray?.ASSETTYPE?.length > 0
  //           ? `&assetType=${ArrayToString(filterArray?.ASSETTYPE)}`
  //           : ""
  //       }${
  //         filterArray?.PROJECTSTATUS?.length > 0
  //           ? `&projectStatus=${ArrayToString(filterArray?.PROJECTSTATUS)}`
  //           : ""
  //       }&isFav=${APItype === "Project" ? 0 : 1}`
  //     )
  //     .then((response) => {
  //       setStories(
  //         APItype === "Project"
  //           ? response?.data.projects
  //           : response?.data.projectsWithArticles
  //       );
  //       setProjectTotalCount(
  //         APItype === "Project"
  //           ?response?.data?.pagination?.totalProjects
  //           : response?.data?.pagination?.totalStories
  //       );
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1600);
  //       setTimeout(() => {
  //         showMessage(
  //           error?.response?.data?.error,
  //           "error",
  //           "red",
  //           false,
  //           false,
  //           false
  //         );
  //       }, 2500);
  //     });
  //   // }
  //   // else if (filterArray?.FAVOURITE) {
  //   //   targetObject = getObjectByType(filterArray?.arrayOfFilters, "favourites");

  //   //   // console.log(targetObject, "favouritestypeArray");
  //   //   axiosInstance
  //   //     .get(
  //   //       `${FAVOURITEARTICLE}/${localStorage.getItem(
  //   //         "userId"
  //   //       )}?page=1&limit=1000&filter=${
  //   //         targetObject === undefined || targetObject?.SEARCH === null
  //   //           ? ""
  //   //           : targetObject?.SEARCH
  //   //       }&dateRange=${targetObject?.DATE ? targetObject?.DATE : 0}${
  //   //         targetObject?.ASSETTYPE?.length > 0
  //   //           ? `&assetType=${ArrayToString(targetObject?.ASSETTYPE)}`
  //   //           : ""
  //   //       }${
  //   //         targetObject?.PROJECTSTATUS?.length > 0
  //   //           ? `&projectStatus=${ArrayToString(targetObject?.PROJECTSTATUS)}`
  //   //           : ""
  //   //       }`
  //   //     )
  //   //     .then((response) => {
  //   //       setStories(response?.data?.projectsWithArticles);
  //   //       setLoading(false);
  //   //     })
  //   //     .catch((error) => {
  //   //       setLoading(false);
  //   //       showMessage(
  //   //         error?.response?.data?.error,
  //   //         "error",
  //   //         "red",
  //   //         false,
  //   //         false,
  //   //         false
  //   //       );
  //   //     });
  //   // }
  // };
  // Favourite toggle
  const handleCardOpertaion = (card, operationValue, operation) => {
    let APICALL, DATA;
    if (operation === "Favorite") {
      // console.log(props?.APItype);

      APICALL = operationValue === 0 ? ADD_TO_FAVORITE : REMOVE_TO_FAVORITE;
      if (APItype === "Project") {
        DATA = {
          projectId: card.projectId,
          userId: localStorage.getItem("userId"),
        };
      }
      if (filterArray?.FAVOURITE) {
        // console.log("here");
        DATA = {
          projectId: card.projectId,
          userId: localStorage.getItem("userId"),
          ProjectDetailsId: card.ProjectDetailsId,
          favoriteId: card.projectId,
        };
      }
    }
    axiosInstance
      .put(APICALL, DATA)
      .then((response) => {
        showMessage(
          response?.data?.message,
          "success",
          "#576d69",
          false,
          false,
          false
        );
        GET_STORIES();
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  return (
    <div className="md:min-w-2/5 bg-gray-50 p-2 min-h-full h-full max-h-96 md:py-0 pb-10 overflow-y-scroll  md:max-w-2/5">
      <Typography variant="h3">Projects</Typography>
      <Typography variant="small">{stories?.length} Projects</Typography>

      <div className="flex flex-col gap-2 ">
        {stories?.map((story, index) => (
          <Card
            key={story.projectId}
            className="   m-0 shadow-sm rounded-md p-2"
            onClick={(e) => handleOpen(story)}
            onMouseEnter={() => {
              if (window.innerWidth > 768) {
                // Example breakpoint for avoiding mouse enter on smaller screens
                setProjectHighlightId(story.projectId);
              }
            }}
            onMouseLeave={() => {
              if (window.innerWidth > 768) {
                // Example breakpoint for avoiding mouse enter on smaller screens
                setProjectHighlightId("");
              }
            }}
          >
            {/* onMouseEnter={setProjectHighlightId(story.projectId)} */}
            <div
              className={`mx-0 flex lg:flex-row md:flex-row flex-row h-max  items-start gap-2 pr-4`}
            >
              <ImageWithFallback
                key={story?.ProjectDetailsId}
                url={
                  filterArray?.FAVOURITE
                    ? removeQueryParameters(story?.imageUrls[0], story?.weburl)
                    : removeQueryParameters(story?.imageUrls[0], story?.weburl)
                }
                className="rounded-lg hidden md:block lg:block object-cover  "
                style={{
                  maxHeight: "150px",
                  minWidth: "150px",
                  maxWidth: "150px",
                  minHeight: "150px",
                }}
                fallbackUrl={logo}
                source={story?.weburl}
              />
              <ImageWithFallback
                key={story?.ProjectDetailsId}
                url={
                  filterArray?.FAVOURITE
                    ? removeQueryParameters(story?.imageUrls[0], story?.weburl)
                    : removeQueryParameters(story?.imageUrls[0], story?.weburl)
                }
                style={{
                  maxHeight: "95px",
                  minWidth: "95px",
                  maxWidth: "95px",
                  minHeight: "95px",
                }}
                className=" rounded-lg  lg:hidden md:hidden object-cover "
                fallbackUrl={logo}
                source={story?.weburl}
              />

              <Tooltip
                className="border shadow-lg bg-white text-black w-fit "
                content={
                  story.favorite === 1
                    ? "Remove from Favorites"
                    : "Add to Favorites"
                }
                placement="top-start"
              >
                <IconButton
                  variant="text"
                  size="sm"
                  className={`!absolute  top-1 right-1 w-6 max-w-6 min-h-6 max-h-6 hover:bg-white ${
                    story?.favorite === 1
                      ? "text-primary-redova-favorite"
                      : "text-gray-400 "
                  }`}
                  // color={}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleCardOpertaion(story, story?.favorite, "Favorite");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6  stroke-2 p-0 m-0"
                  >
                    <path
                      fillRule="evenodd"
                      stroke="white"
                      d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                      clipRule="evenodd"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <div className="flex flex-col gap-1 pt-3 hover:cursor-pointer">
                <p className="md:text-xl text-base text-black font-semibold hover:text-primary-hover font-serif line-clamp-2 leading-snug">
                  {story.title}
                </p>

                <p className="text-xs text-gray-600 md:mt-2">
                  {moment(story?.updated_at, "YYYY-MM-DD hh:mm:ss").format(
                    "MM/DD/YYYY hh:mm"
                  ) ===
                  moment(story?.created_at, "YYYY-MM-DD hh:mm:ss").format(
                    "MM/DD/YYYY hh:mm"
                  )
                    ? "Date Published"
                    : "Updated"}
                  :
                  {moment(story?.updated_at, "YYYY-MM-DD hh:mm:ss").format(
                    "MM/DD/YYYY"
                  )}
                  {story?.Status && <> | Project Status: {story?.Status}</>}
                </p>
                <div className=" ">
                  {story?.AssetType !== null &&
                    story?.AssetType !== undefined && (
                      <div className="flex flex-col md:mt-2">
                        <p
                          className="max-w-fit rounded-md p-1 tracking-wider font-medium antialiased text-xs bg-primary-light text-black text-wrap"
                          variant="filled"
                          color="gray"
                        >
                          {story.AssetType.replaceAll(",", ", ")}{" "}
                        </p>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
      <ProjectDetails
        open={open}
        handleOpen={handleOpen}
        projectDetails={projectById}
        handleCardOpertaion={handleCardOpertaion}
      />
    </div>
  );
}
