import React, { useEffect, useState } from "react";
import { STORIES } from "../../../../services/APIGlobal";
import { showMessage } from "../../../../components/ui/showMessage";
import axiosInstance from "../../../../services/APIInstance";
import { RemovedField } from "../../../../utils/Constant";
import SingleMarkerMap from "../../../../components/ui/Map/SingleMarkerMap";
import Loader from "../../../../components/ui/Loader";
import { Button, Card } from "@material-tailwind/react";
import { useHistory } from "react-router-dom";
import { rephaseName } from "../../../../store/helper/Helper";

export default function ViewArticle(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState({});
  const [formArray, setFormArray] = useState({});
  const StoryId = JSON.parse(localStorage.getItem("rowId"));

  useEffect(() => {
    GET_STORIEById(); // eslint-disable-next-line
  }, []);

  // Get API
  const GET_STORIEById = () => {
    setLoading(true);
    axiosInstance
      .get(`${STORIES}/${StoryId}`)
      .then((response) => {
        // setStories(response?.data[0]);
        setAllData(response?.data);
        const data = response.data;
        // Remove unnecessary fields for edit
        RemovedField.forEach((e) => delete data[e]);
        // Remove null values
        Object.keys(data).forEach((key) => {
          if (data[key] === null) {
            delete data[key];
          }
        });
        const keys = Object.keys(data);
        const value = Object.values(data);
        const arrays = keys.map((keys, i) => ({ name: keys, value: value[i] }));
        setFormArray(
          arrays.filter(
            (e) =>
              e.name !== "ProjectDetailsId" &&
              e.name !== "LocationId" &&
              e.name !== "SourceId"
          )
        );
        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(error?.response?.data?.error, "error", "red", false, false, false);
        }, 2500);
      });
  };
  return (
    <>
      <Loader loading={loading} />
      <section className="flex gap-2 ">
        {allData?.Longitude && (
          <div className="w-1/2">
            <SingleMarkerMap
              style={{ width: 600, height: 500, borderRadius: "10px" }}
              longitude={allData?.Longitude}
              latitude={allData?.Latitude}
              public_hearings={allData?.public_hearings}
            />
          </div>
        )}
        <div className="w-1/2 h-96">
          <Card className="border-t-2 view-content overflow-y-scroll pb-2	">
            <div className="flex justify-end mt-1 mr-1">
              <Button
                size="sm"
                variant="outlined"
                className="p-2 tracking-wider border-2 flex lg:text-md text-xs items-center  text-primary-hover border-primary-hover shadow-none hover:shadow-lg hover:shadow-primary-primary/40 hover:ring-0 focus:ring-0 "
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: `/Article/Update/${StoryId}`,
                    data: allData,
                    api:GET_STORIEById
                  });
                }}
              >
                Edit Article
              </Button>
            </div>
            <div className="flex flex-col gap-2">
              {formArray.length > 0 &&
                formArray.map(({ label, name, type, value }, index) => (
                  <div key={index} className="px-2 flex flex-col gap-1">
                    <p className="flex flex-row capitalize text-gray-500 text-xs font-medium">
                      {rephaseName(name)}
                    </p>
                    {name !== "authImage" &&
                      name !== "articlegallery" &&
                      name !== "websitelogo" && (
                        <p className="break-all text-sm border-2 p-1 border-gray-400/50 rounded-md">
                          {value}
                        </p>
                      )}
                    {
                      (name === "authImage" ||
                        name === "articlegallery" ||
                        name === "websitelogo") && (
                        <img alt={value} src={value}></img>
                      )
                      // <p className="break-all text-sm border-2 p-1 border-gray-400/50 rounded-md">{value}</p>
                    }
                  </div>
                ))}
            </div>
          </Card>
        </div>
      </section>
    </>
  );
}
