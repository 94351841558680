import React from "react";
import SingleMarkerMap from "../../components/ui/Map/SingleMarkerMap";
import { IconButton, Tooltip, Typography } from "@material-tailwind/react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import {
  attachComma,
  isNotNull,
  locationString,
} from "../../store/helper/Helper";
import logo from "../../assets/Logo/OnlyBlacklogo.png";
import { ADDTOFAV, REMOVETOFAV } from "../../services/APIGlobal";
import { showMessage } from "../../components/ui/showMessage";
import axiosInstance from "../../services/APIInstance";

export default function ProjectContent(props) {
  const handleCardOpertaion = (operationValue, operation) => {
    let APICALL, DATA;
    // console.log(props?.APItype);

    if (operationValue === 0) {
      APICALL = ADDTOFAV;
    } else if (operationValue === 1) {
      APICALL = REMOVETOFAV;
    }
    // APICALL = (operationValue === 0 ? ADDTOFAV : REMOVETOFAV);

    DATA = {
      projectId: props?.project?.projectId,
      userId: localStorage.getItem("userId"),
      ProjectDetailsId: props?.project?.ProjectDetailsId,
      favoriteId: props?.project?.projectId,
    };
    axiosInstance
      .post(APICALL, DATA)
      .then((response) => {
        showMessage(
          response?.data?.message,
          "success",
          "#576d69",
          false,
          false,
          false
        );
        props.GETPROJECTS();
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  return (
    <div className="drop-shadow-sm border rounded-md border-gray p-2 m-2">
      {props?.project && (
        <>
          <section className="flex lg:flex-row flex-col lg:gap-4 gap-4   pb-4">
            <div className="lg:w-1/3 2xl:w-2/5">
              {props?.project?.location?.Longitude ? (
                <>
                  {" "}
                  <SingleMarkerMap
                    style={{ width: 750, height: 350, borderRadius: "10px" }}
                    longitude={props?.project?.location?.Longitude}
                    latitude={props?.project?.location?.Latitude}
                    noMapStyle
                    interactive={true}
                    AssetType={props?.project?.AssetType}
                    projectstatus={props?.project?.projectstatus}
                    public_hearings={props?.project?.public_hearings}

                  />
                </>
              ) : (
                <div className="flex items-center justify-center border-2">
                  <img
                    alt="Redova Logo"
                    style={{ width: 250, height: 350, borderRadius: "10px" }}
                    src={logo}
                  />
                </div>
              )}
            </div>

            <div className=" flex flex-col gap-2 h-auto flex-nowrap">
              <span
                color="black"
                className="leading-normal	 font-serif Pro md:text-4xl 2xl:text-6xl font-extrabold"
              >
                {props?.project?.title}
                <Tooltip
                  className="border shadow-lg bg-white text-black w-fit align-top"
                  content={
                    props?.project?.favorite === 1
                      ? "Remove from Favorites"
                      : "Add to Favorites"
                  }
                  placement="top-start"
                >
                  <IconButton
                    variant="text"
                    size="sm"
                    className="align-top mt-1"
                    color={props?.project?.favorite === 1 ? "teal" : "gray"}
                    onClick={() =>
                      handleCardOpertaion(props?.project?.favorite, "Favorite")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6  stroke-2"
                    >
                      <path
                        fillRule="evenodd"
                        stroke="white"
                        d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </IconButton>
                </Tooltip>
              </span>
              {/* <Typography
                variant="h1"
                color="black"
                className="mb-2 font-serif"
              >
                {props?.project?.title}
              </Typography> */}
              {(props?.project?.location?.StreetIntersections ||
                props?.project?.location?.StreetAddres ||
                props?.project?.location?.City ||
                props?.project?.location?.State ||
                props?.project?.location?.ZipCode) && (
                <Typography variant="small" color="gray">
                  {" "}
                  <span className="flex items-center text-xs md:text-base mt-1">
                    <MapPinIcon
                      strokeWidth={2}
                      className="md:h-3.5 md:w-3.5 h-3 w-3  mr-1 text-black"
                    />
                    {locationString(
                      props?.project?.location?.StreetAddress,
                      props?.project?.location?.StreetIntersections,
                      props?.project?.location?.City,
                      props?.project?.location?.State,
                      props?.project?.location?.ZipCode
                    )}
                  </span>
                </Typography>
              )}
              <div>
                {props?.project?.websiteUrl !== null &&
                  props?.project?.websiteUrl !== undefined &&
                  props?.project?.websiteUrl !== " " && (
                    <Typography
                      variant="small"
                      color="gray"
                      className="text-xs md:text-base"
                    >
                      {" "}
                      <span className="font-bold text-black">
                        <a
                          href={props?.project?.websiteUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-800 underline text-sm  cursor-pointer"
                        >
                          {" "}
                          Project link
                        </a>
                      </span>
                      {props?.project?.Status !== null &&
                        props?.project?.Status !== undefined}
                    </Typography>
                  )}
              </div>
              {/* Website and last updates */}
              <div className="flex flex-row gap-1 flex-wrap">
                {/* Project Status */}
                {/* {props?.project?.Status !== null &&
                  props?.project?.Status !== undefined && (
                    <Typography
                      variant="small"
                      color="gray"
                      className="text-xs md:text-base"
                    >
                      {" "}
                      <span className="font-bold text-black">
                        Project Status:{" "}
                      </span>
                      {props?.project?.Status}
                      {props?.project?.updated_at !== null &&
                        props?.project?.updated_at !== undefined && (
                          <span>,</span>
                        )}
                    </Typography>
                  )} */}
                {/* Last Updated */}
                {props?.project?.updated_at !== null &&
                  props?.project?.updated_at !== undefined &&
                  props?.project?.enddate === null && (
                    <Typography
                      variant="small"
                      color="gray"
                      className="text-xs md:text-base"
                    >
                      {" "}
                      <span className="font-bold text-black">
                        {" "}
                        Last updated:
                      </span>{" "}
                      {props?.project?.updated_at
                        ? moment(
                            props?.project?.updated_at,
                            "YYYY-MM-DD hh:mm:ss"
                          ).format("MM-DD-YYYY")
                        : "-"}
                      {props?.project?.startdate !== null &&
                        props?.project?.startdate !== undefined && (
                          <span>,</span>
                        )}
                    </Typography>
                  )}
                {/* Start Data */}
                {props?.project?.startdate !== null &&
                  props?.project?.startdate !== undefined &&
                  isNotNull(props?.project?.startdate) && (
                    <Typography
                      variant="small"
                      color="gray"
                      className="text-xs md:text-base"
                    >
                      {" "}
                      <span className="font-bold text-black">
                        {" "}
                        Start Date:
                      </span>{" "}
                      {isNotNull(props?.project?.startdate)
                        ? props?.project?.startdate
                        : // moment(
                          //     props?.project?.startdate,
                          //     "YYYY-MM-DD hh:mm:ss"
                          //   ).format("MM-DD-YYYY")
                          "- "}
                      {props?.project?.enddate !== null &&
                        props?.project?.enddate !== undefined && <span>,</span>}
                    </Typography>
                  )}
                {/* enddate Data */}
                {props?.project?.enddate !== null &&
                  props?.project?.enddate !== undefined &&
                  isNotNull(props?.project?.enddate) && (
                    <Typography
                      variant="small"
                      color="gray"
                      className="text-xs md:text-base"
                    >
                      {" "}
                      <span className="font-bold text-black">
                        {" "}
                        End Date:
                      </span>{" "}
                      {isNotNull(props?.project?.enddate)
                        ? props?.project?.enddate
                        : // moment(
                          //     props?.project?.enddate,
                          //     "YYYY-MM-DD hh:mm:ss"
                          //   ).format("MM-DD-YYYY")
                          "-"}
                    </Typography>
                  )}
              </div>
              {/* <div className="flex flex-row gap-1">
                {props?.project?.TypeUser !== null &&
                  props?.project?.TypeUser !== undefined && (
                    <Typography
                      variant="small"
                      color="gray"
                      className="text-xs md:text-base"
                    >
                      {" "}
                      <span className="font-bold text-black">
                        User type:
                      </span>{" "}
                      {props?.project?.TypeUser}
                    </Typography>
                  )}
              </div> */}
              {/* Asset Type */}
              <Typography
                variant="small"
                color="gray"
                className="text-xs md:text-base"
              >
                {" "}
                <span className="font-bold text-black"> Asset type:</span>{" "}
                {props?.project?.AssetType}
              </Typography>
              {isNotNull(props?.project?.ProjectSummary) && (
                <Typography
                  variant="small"
                  color="gray"
                  className="text-xs md:text-base"
                >
                  {" "}
                  <span className="font-bold text-black">
                    {" "}
                    Project Summary:
                  </span>{" "}
                  {props?.project?.ProjectSummary}
                </Typography>
              )}
              {/* Ameninties */}
              {props?.project?.Amenities !== null &&
                props?.project?.Amenities !== undefined &&
                props?.project?.Amenities !== " " && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      {" "}
                      Amenities:
                    </span>{" "}
                    {props?.project?.Amenities}
                  </Typography>
                )}
              {/* project status */}
              {props?.project?.projectstatus !== null &&
                props?.project?.projectstatus !== undefined && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      Project Status:
                    </span>{" "}
                    {props?.project?.projectstatus}
                  </Typography>
                )}
              {/* project Details */}
              <div className="flex flex-row gap-1 flex-wrap">
                {/* project Value */}
                {/* {props?.project?.ProjectValue !== null &&
                props?.project?.ProjectValue !== undefined && props?.project?.ProjectValue !== "" && props?.project?.ProjectValue !== " " && */}
                {isNotNull(props?.project?.ProjectValue) && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      Project Value:
                    </span>{" "}
                    {props?.project?.ProjectValue} {props?.project?.Valuetype}
                    {attachComma(props?.project?.ProjectSize)}
                    {/* {props?.project?.ProjectSize !== null &&
                      props?.project?.ProjectSize !== undefined && (
                        <span>,</span>
                      )} */}
                  </Typography>
                )}
                {/* project Value */}
                {isNotNull(props?.project?.ProjectSize) && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      Project Size:
                    </span>{" "}
                    {props?.project?.ProjectSize} {props?.project?.Sizetype}
                    {attachComma(props?.project?.ProjectUnit)}
                  </Typography>
                )}
                {/*  ProjectUnit */}
                {isNotNull(props?.project?.ProjectUnit) && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      Project Unit:
                    </span>{" "}
                    {props?.project?.ProjectUnit}
                    {attachComma(props?.project?.ProjectBeds)}
                  </Typography>
                )}
                {/*  ProjectBeds */}
                {isNotNull(props?.project?.ProjectBeds) && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      Project Beds:
                    </span>{" "}
                    {props?.project?.ProjectBeds}
                    {attachComma(props?.project?.ProjectDoors)}
                  </Typography>
                )}
                {/*  ProjectDoors */}
                {isNotNull(props?.project?.ProjectDoors) && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      Project Doors:
                    </span>{" "}
                    {props?.project?.ProjectDoors}
                    {attachComma(props?.project?.ProjectKeys)}
                  </Typography>
                )}
                {/*  ProjectKeys */}
                {isNotNull(props?.project?.ProjectKeys) && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      Project Keys:
                    </span>{" "}
                    {props?.project?.ProjectKeys}
                    {attachComma(props?.project?.ProjectBays)}
                  </Typography>
                )}
                {/*  ProjectBays */}
                {isNotNull(props?.project?.ProjectBays) && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      Project Bays:
                    </span>{" "}
                    {props?.project?.ProjectBays}
                    {attachComma(props?.project?.ProjectLots)}
                  </Typography>
                )}
                {/*  project Lots */}
                {isNotNull(props?.project?.ProjectLots) && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="text-xs md:text-base"
                  >
                    {" "}
                    <span className="font-bold text-black">
                      Project Lots:
                    </span>{" "}
                    {props?.project?.ProjectLots}
                  </Typography>
                )}
              </div>
              {/*  project type of TypeUser */}
              {isNotNull(props?.project?.TypeUser) && (
                <Typography
                  variant="small"
                  color="gray"
                  className="text-xs md:text-base"
                >
                  {" "}
                  <span className="font-bold text-black">
                    Type of business or user:
                  </span>{" "}
                  {props?.project?.TypeUser}
                </Typography>
              )}
              {props?.project?.public_hearings?.length > 0 && (
                <section>
                  {props?.project?.public_hearings?.map((Meetings, index) => (
                    <>
                      <div className="flex flex-row gap-1">
                        <Typography
                          variant="small"
                          color="gray"
                          className="text-xs md:text-base"
                        >
                          {" "}
                          <span className="font-bold text-black">
                            Public Hearing {index + 1}:
                          </span>
                        </Typography>
                        <span>
                          Type:{" "}
                          <a
                            href={Meetings?.UrlLink}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-800 underline  cursor-pointer"
                          >
                            {Meetings?.MarketingType}
                          </a>
                          <>,</>
                        </span>

                        <span>
                          Status:{" "}
                          {Meetings?.status}  <>,</>
                        </span>
                        <span>
                          Date:{" "}
                          {moment(Meetings?.MeetingDate, "YYYY-MM-DD").format(
                            "MM-DD-YYYY"
                          )} 
                        </span>
                      </div>
                    </>
                  ))}
                </section>
              )}
            </div>
          </section>
        </>
      )}
    </div>
  );
}
