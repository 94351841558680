import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "@material-tailwind/react";
import { TooltipStlyeForData } from "../../utils/Constant";
import Table from "../functionality/table/Table";
import ActionCol from "../functionality/table/ActionCol";
import { useHistory } from "react-router-dom";
import { PROJECTLIST } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../ui/showMessage";
import Loader from "../ui/Loader";
import moment from "moment";
import SearchField from "../ui/SearchField";
import FillterDropDown from "../functionality/FillterMenu";
import { useLastLocation } from "react-router-last-location";
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/24/outline";

export default function ProjectArchiveDB() {
  let history = useHistory();
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showRecord, setShowRecord] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();
  const [selectedOptions, setSelectedOptions] = useState("All");
  const [assetFillter, setAssetFillter] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const lastLocation = useLastLocation();
  const [isShort, setIsShort] = useState(true);
  const [isFilterAvailable, setIsFilterAvailable] = useState(false);
  const prevDependency = useRef(isFilterAvailable);
  // Using useEffect to call the API once mounted and set the data

  useEffect(() => {
    setIsFilterAvailable(!isFilterAvailable); // eslint-disable-next-line
  }, [selectedOptions, setSelectedOptions, assetFillter, globalFilter]);
  useEffect(() => {
    if (prevDependency.current !== isFilterAvailable) {
      // Dependency changed from the previous value
      if (isFilterAvailable === true) {
        // Do something when dependency becomes true
        setPageNo(1);
        GET_STORIES(); // eslint-disable-next-line
        // console.log('Dependency changed to true');
      } else {
        GET_STORIES(); // eslint-disable-next-line
        // Do something when dependency becomes false
        // console.log('Dependency changed to false');
      }
      prevDependency.current = isFilterAvailable; // Update the previous value
    } // eslint-disable-next-line
  }, [isFilterAvailable]);
  useEffect(() => {
    localStorage.setItem("component", "Project Archived");

    GET_STORIES(); // eslint-disable-next-line
    localStorage.removeItem("rowId");
    localStorage.removeItem("lastVisitedRow");

    if (!lastLocation?.pathname?.includes("/ProjectDetail")) {
      localStorage.removeItem("lastVisitedProjectRow");
    } else {
      setShowRecord(localStorage.getItem("showRecords"));
      setCurrentPage(localStorage.getItem("setPageNo"));
      setPageNo(localStorage.getItem("setPageNo"));
    } // eslint-disable-next-line
  }, [showRecord, pageNo, isShort]);

  useEffect(() => {
    localStorage.setItem("component", "Project Archived");
    localStorage.removeItem("rowId");
    localStorage.removeItem("lastVisitedRow");
    localStorage.removeItem("projectId");
    if (!lastLocation?.pathname?.includes("/ProjectDetail")) {
      localStorage.removeItem("lastVisitedProjectRow");
    } else {
      setShowRecord(localStorage.getItem("showRecords"));
      setCurrentPage(localStorage.getItem("setPageNo"));
      setPageNo(localStorage.getItem("setPageNo"));
    } // eslint-disable-next-line
  }, [history, globalFilter]);
  // Get API
  const GET_STORIES = () => {
    axiosInstance
      .get(
        `${PROJECTLIST}?flag=0&page=${pageNo}&limit=${
          showRecord !== null ? showRecord : 10
        }${
          globalFilter ? "&searchQuery=" + globalFilter : "&searchQuery="
        }&sortOrder=${
          isShort ? `desc` : `asc`
        }&sortColumn=created_at&type=admin`
      )
      .then((response) => {
        setData(response?.data?.projects);
        setTotalRecord(response?.data?.pagination?.totalProjects);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  const columns = [
    {
      Header: "Project Title",
      accessor: "title",
      Cell: ({ value, row }) => {
        return (
          <Tooltip
            style={{ maxWidth: "350px" }}
            placement="top-start"
            offset={15}
            className={TooltipStlyeForData}
            content={value}
          >
            <p
              className="line-clamp-1 cursor-pointer text-left	ml-4"
              onClick={(e) => handleClick(e, row)}
            >
              {value}
            </p>
          </Tooltip>
        );
      },
      headerStyle: { textAlign: "center" },
      minWidth: 250,
      maxWidth: 250,
    },
    {
      Header: (
        <div className="flex flex-row gap-2">
          <span>Date</span>
          {isShort ? (
            <ArrowLongUpIcon
              strokeWidth={2}
              className="h-4 w-4 cursor-pointer"
              onClick={() => setIsShort(false)}
            />
          ) : (
            <ArrowLongDownIcon
              strokeWidth={2}
              className="h-4 w-4 cursor-pointer"
              onClick={() => setIsShort(true)}
            />
          )}
        </div>
      ),
      accessor: "created_at",
      headerStyle: { textAlign: "center" },
      Cell: ({ cell: { value } }) => (
        <span>
          {value
            ? moment(value, "YYYY-MM-DD hh:mm:ss").format("MM-DD-YYYY")
            : "--"}
        </span>
      ),
      disableSortBy: true,
    },
    // {
    //   Header: "Time",
    //   accessor: "",
    //   Cell: ({ cell: { value } }) => {
    //     if (value === undefined) {
    //       return "NA";
    //     } else {
    //       return value;
    //     }
    //   },
    // },
    {
      Header: "Location",
      accessor: "City",
      headerStyle: { textAlign: "right" },
      Cell: ({ cell: { value } }) => (
        <span className="capitalize ">{value ? value : "--"}</span>
      ),
    },
    {
      Header: () => (
        <span className="flex justify-start text-left">Source</span>
      ),
      accessor: "websiteUrl",
      headerStyle: { textAlign: "left" },
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <span className="line-clamp-1 cursor-pointer text-center	mx-4">
          {value ? (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "rgb(87 109 105)" }} // Change 'blue' to your desired color
            >
              {value.trim()}
            </a>
          ) : (
            "--"
          )}
        </span>
      ),

      minWidth: 50,
      maxWidth: 50,
    },
    {
      Header: "Asset Type",
      accessor: "AssetType",
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <Tooltip
          className={`${TooltipStlyeForData}  max-w-xs`}
          content={value ? value : "-"}
        >
          <span className="capitalize line-clamp-1 text-left mx-2">
            {value ? value.replace(",", ", ") : "--"}
          </span>
        </Tooltip>
      ),
      minWidth: 200,
      maxWidth: 200,
    },
    // {
    //   Header: "Status",
    //   accessor: "Status",
    //   Cell: ({ cell: { value } }) => <StatusChip values={value} />,
    // },
    {
      Header: "Comment",
      accessor: "comment",
      Cell: ({ cell: { value } }) => {
        if (value === null || value === "null") {
          return "--";
        } else {
          return value;
        }
      },
      width: 50,
    },
    {
      id: "action",
      accessor: "",
      disableSortBy: true,
      Cell: (row) => (
        <ActionCol
          deletedStories
          jumpToArticle
          isDelete
          isRestore
          data={row.row.original}
          GET_API={GET_STORIES}
          project
        />
      ),
    },
  ];

  // Handle on title click
  const handleClick = (e, row) => {
    // console.log(row);
    localStorage.setItem("project", JSON.stringify(row?.original?.projectId));
    localStorage.setItem("lastVisitedProjectRow", row.id);
    history.push({
      pathname: `/ProjectDetail/${row?.original?.projectId}`,
      data: row,
    });
  };
  return (
    <div className="flex flex-col px-2">
      <Loader loading={loading} />
      <div className=" flex md:flex-row flex-col gap-2 justify-between ">
        <div className="md:w-72 md:order-first order-last">
          <SearchField filter={globalFilter} setfilter={setGlobalFilter} />
        </div>
        <FillterDropDown
          assetFillter={assetFillter}
          setAssetFillter={setAssetFillter}
          location
        />
      </div>
      <div className="">
        <Table
          maxHeight="100"
          setPageNo={setPageNo}
          pageNo={pageNo}
          showRecord={showRecord}
          setShowRecord={setShowRecord}
          totalRecord={totalRecord}
          currentPage={currentPage}
          totalPage={totalPage}
          columns={columns}
          data={data}
          noSearch
        />
      </div>
    </div>
  );
}
