import { Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ListViewStories from "./ListViewStories";
import FullContent from "../../components/functionality/FullContent";
import TitleDivider from "../../components/ui/TitleDivider";
import { STORIES } from "../../services/APIGlobal";
import { useState } from "react";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../../components/ui/showMessage";
import JumpToTop from "../../components/ui/JumpToTop";
import HomeCard from "../../components/functionality/HomeCard";
import { useContext } from "react";
import SearchContext from "../../context/SearchContext";
import HomePageOutline from "../../components/layouts/HomePageOutline";

export default function NewsDetailView() {
  // eslint-disable-next-line
  const [viewType] = useState("List"); // eslint-disable-next-line
  const [details, setDetails] = useState(); // eslint-disable-next-line
  const [assetType, setAssetType] = useState();
  const [releventStories, setReleventStories] = useState([]); // eslint-disable-next-line
  const [loading, setLoading] = useState(true); // eslint-disable-next-line
  const [pageNo, setPageNo] = useState(1); // eslint-disable-next-line
  const [showRecord, setRecord] = useState(10); // eslint-disable-next-line
  const [nextPage, setIsNextPage] = useState(); // eslint-disable-next-line
  const [assetFillter, setAssetFillter] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [newStories, setNewStories] = useState([]); // eslint-disable-next-line
  const location = useLocation();

  const { searchValue, setSearchValue } = useContext(SearchContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSearchValue("");
    setLoading(true);
    axiosInstance
      .get(`${STORIES}/${location.pathname.split("/")[2]}`)
      .then((response) => {
        setDetails(response?.data);
        setAssetType(response?.data?.AssetType);
        // console.log(response?.data?.AssetType);
        GET_STORIES(response?.data?.AssetType);
        // setTotalRecord(response?.data?.pagination?.totalProjects);
        // setCurrentPage(response?.data?.pagination?.currentPage);
        // setTotalPage(response?.data?.pagination?.totalPages);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      }); // eslint-disable-next-line
  }, [location]);

  // console.log(location, ";ocation");

  // Get API
  const GET_STORIES = (assetTypeValue) => {
    axiosInstance
      .get(
        `${STORIES}?limit=${showRecord}&status=&isReview=&isPublish=Yes&assetType=&page=${pageNo}&userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsNextPage(response?.data?.pagination?.nextPage);
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    axiosInstance
      .get(
        `${STORIES}?limit=100&isPublish=Yes&page=1&flag=1&assetType=${assetTypeValue}&sortOrder=desc&sortColumn=created_at`
      )
      .then((response) => {
        const updatedStories = response?.data?.stories.filter(
          (item) =>
            item.ProjectDetailsId !== Number(location.pathname.split("/")[2])
        );
        // console.log(updatedStories,"updatedStories")
        setReleventStories(updatedStories);
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    axiosInstance
      .get(`${STORIES}?limit=100&isPublish=Yes&page=1&flag=1&sort=trending`)
      .then((response) => {
        setLatestNews(response?.data?.stories.splice(0, 5));
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    axiosInstance
      .get(`${STORIES}?limit=100&isPublish=Yes&page=1&flag=1&sort=new`)
      .then((response) => {
        setLoading(false);
        setNewStories(response?.data?.stories.splice(0, 5));
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };
  // console.log(location, "location");
  // useEffect(() => {
  //   return () => {
  //     if (history.action === "POP") {
  //       history.location.pathname = "/home";
  //       history.replace("/home");
  //     }
  //   };
  // });

  return (
    <>
      {/* <Loader loading={loading} /> */}
      {!searchValue ? (
        !loading && (
          <div className="flex lg:flex-row flex-col gap-4 scrollbar-hide mx-2 p-2 ">
            <section className="flex flex-col lg:w-2/3  h-min lg:border-r-2 gap-2">
              <JumpToTop />
              <FullContent content={details} />
              {releventStories.length > 0 && (
                <>
                  <TitleDivider title="Relevant  Stories" />
                  <ListViewStories
                    APItype="Articles"
                    stories={releventStories}
                    GETAPI={GET_STORIES}
                  />
                </>
              )}
              {/* <div className="flex mt-4">
                <Button
                  size="lg"
                  variant="filled"
                  className="bg-primary-primary text-white  shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                  onClick={() => history.push("/projectStories")}
                >
                  More Relevant Articles about this project
                </Button>
              </div> */}
            </section>
            {/* Latest Stories*/}

            {/* Latest Stories*/}
            <div className="lg:w-1/3 h-min flex flex-col gap-2 bg-primary-revado-white">
              {/* Header */}

              <div className="bg-primary-revado-white p-2 drop-shadow-sm border rounded-md border-gray">
                <Typography variant="h5">New Stories</Typography>
                <HomeCard
                  latestStories
                  stories={newStories}
                  GETAPI={GET_STORIES}
                />
              </div>
              {/* Advertisment Space */}
              <div
                style={{ height: "250px" }}
                className="bg-primary-revado-white"
              ></div>
              {/* Header */}
              <div className="bg-primary-revado-white p-2 drop-shadow-sm border rounded-md border-gray">
                <Typography variant="h5">Trending Stories</Typography>
                <HomeCard
                  latestStories
                  stories={latestNews}
                  GETAPI={GET_STORIES}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <HomePageOutline Details="Articles" AssetFillter timeFillter />
      )}
    </>
  );
}
