import {
  Button,
  Card,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import AllProjectDB from "../../../../components/datatables/AllProjectDB";
import { useEffect } from "react";
import React from "react";
import { PROJECTLIST } from "../../../../services/APIGlobal";
import { useState } from "react";
import axiosInstance from "../../../../services/APIInstance";
import { showMessage } from "../../../../components/ui/showMessage";
import { MapIcon, QueueListIcon } from "@heroicons/react/24/outline";
import { ArrayToString } from "../../../../store/helper/Helper";
import { useRef } from "react";
import AdminProjectMap from "./AdminProjectMap";
import { useHistory } from "react-router-dom";
import SearchField from "../../../../components/ui/SearchField";

export default function Project() {
  const history = useHistory();
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]); // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(
    localStorage.getItem("lastProjectPage") || 1
  );
  const [lastPageNo, setLastPageNo] = useState();
  const [showRecord, setShowRecord] = useState(
    localStorage.getItem("lastProjectRecords") || 10
  );
  const [lastShowRecord, setLastShowRecord] = useState();
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();// eslint-disable-next-line
  const [globalFilter, setGlobalFilter] = useState([]);// eslint-disable-next-line
  const [locationFillter, setLocationFillter] = useState([]);
  const [isFilterAvailable, setIsFilterAvailable] = useState(false);
  const prevDependency = useRef(isFilterAvailable);

  // eslint-disable-next-line

  useEffect(() => {
    setLoading(true);
    setIsFilterAvailable(!isFilterAvailable); // eslint-disable-next-line
  }, [globalFilter, locationFillter]);
  useEffect(() => {
    setLoading(true);
    if (prevDependency.current !== isFilterAvailable) {
      // Dependency changed from the previous value
      if (isFilterAvailable === true) {
        // Do something when dependency becomes true
        setPageNo(1);
        GETPROJECTS(); // eslint-disable-next-line
        // console.log('Dependency changed to true');
      } else {
        localStorage.setItem("lastProjectPage", pageNo);
        GETPROJECTS(); // eslint-disable-next-line
        // Do something when dependency becomes false
        // console.log('Dependency changed to false');
      }
      prevDependency.current = isFilterAvailable; // Update the previous value
    } // eslint-disable-next-line
  }, [isFilterAvailable]);
  useEffect(() => {
    GETPROJECTS(); // eslint-disable-next-line
  }, [showRecord, currentPage, totalPage, pageNo, totalRecord]);

  useEffect(() => {
    setIsFilterAvailable(false); // eslint-disable-next-line
  }, []);
  // Attach an event listener to handle the beforeunload event

  const GETPROJECTS = () => {
    // console.log(locationFillter,"locationFillter")
    axiosInstance
      .get(
        `${PROJECTLIST}?flag=1&page=${pageNo}&limit=${showRecord}${
          globalFilter ? "&searchQuery=" + globalFilter : ""
        }${
          locationFillter.length > 0
            ? "&searchQuery=" + ArrayToString(locationFillter)
            : ""
        }&sortOrder=desc&sortColumn=created_at`
      )
      .then((response) => {
        setData(response?.data?.projects);
        setTotalRecord(response?.data?.pagination?.totalProjects);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
        setLoading(false);
        localStorage.setItem(
          "totalProjects",
          response?.data?.pagination?.totalProjects
        );
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  const category = [
    {
      label: "List",
      value: "list",
      icon: QueueListIcon,
      desc: (
        <div className="w-full  flex flex-col gap-4">
          {/* Fillter */}
          <div className="w-full flex md:flex-row flex-col justify-between gap-2 ">
            <div className="order-last md:order-first  w-full flex lg:flex-row flex-col gap-2 lg:items-center">
              <div className="lg:w-96 order-last lg:order-first">
                <SearchField
                  filter={globalFilter}
                  setfilter={setGlobalFilter}
                />
              </div>
              <div className="w-full">
                <Button
                  size="sm"
                  color="white"
                  onClick={() => {
                    history.push({
                      pathname: `/CreateProject`,
                      title: "New",
                    });
                  }}
                  className=" max-w-fit flex items-center text-primary-primary border-primary-primary w-full shadow-none hover:bg-transparent hover:shadow-none hover:ring-0 focus:ring-0 focous:bg-transparent"
                  variant="outlined"
                  ripple={false}
                >
                  Create New Project
                </Button>
              </div>
            </div>
            {/* <div className="md:absolute md:right-44 md:top-4.5">
              <FillterDropDown
                assetFillter={locationFillter}
                setAssetFillter={setLocationFillter}
                location
                functionality="OnClick"
              /> */}
            {/* </div> */}
          </div>

          {/* Project Table */}
          {!loading && (
            <>
              <AllProjectDB
                articles={data}
                GET_STORIES={GETPROJECTS}
                setPageNo={setPageNo}
                pageNo={pageNo}
                showRecord={showRecord}
                setShowRecord={setShowRecord}
                totalRecord={totalRecord}
                currentPage={currentPage}
                totalPage={totalPage}
                lastPageNo={lastPageNo}
                lastShowRecord={lastShowRecord}
                loading={loading}
              />

              {/* Pagination */}
              <div className="w-full text-sm 2xl:text-base flex   md:flex-row justify-between items-center  ">
                <div className="no-wrap md:w-1/2 flex md:flex-row flex-col  md:space-x-2  md:items-center">
                  <div className="text-left">
                    Page{" "}
                    <span className="font-semibold">
                      {currentPage} of {totalPage}
                    </span>{" "}
                  </div>
                  <div className="flex space-x-2 no-wrap items-center text-left">
                    <span>| Show </span>
                    <select
                      className=" rounded-md border border-gray-300 bg-white py-1 px-1 text-left shadow-sm focus:border-primary-primary focus:outline-none focus:ring-1 focus:ring-primary-primary sm:text-sm 2xl:text-base"
                      value={showRecord}
                      onChange={(e) => {
                        setShowRecord(Number(e.target.value));
                        setLastShowRecord(Number(e.target.value));
                      }}
                    >
                      {[10, 15, 50].map((showRecord) => (
                        <option
                          className="option hover:bg-primary-hover focous:bg-primary-primary"
                          key={showRecord}
                          value={showRecord}
                        >
                          {showRecord} of {totalRecord}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="  space-x-0">
                  <button
                    className="  rounded-l-md border border-gray-300 bg-white md:px-2 p-1 md:py-2 text-sm 2xl:text-base font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    onClick={() => {
                      setPageNo(1);
                      setLastPageNo(1);
                      localStorage.removeItem("lastVisitedProjectDetails");
                    }}
                  >
                    {"<<"}
                  </button>
                  <button
                    className=" relative inline-flex items-center border border-gray-300 bg-white p-1 md:px-4 md:py-2 text-sm 2xl:text-base font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    onClick={() => {
                      setPageNo(Number(pageNo) - 1);
                      setLastPageNo(Number(pageNo) - 1);
                      localStorage.removeItem("lastVisitedProjectDetails");
                    }}
                  >
                    Previous
                  </button>
                  <button
                    className="relative inline-flex items-center border border-gray-300 bg-white p-1 md:px-4 md:py-2 text-sm 2xl:text-base font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    onClick={() => {
                      setPageNo(Number(pageNo) + 1);
                      setLastPageNo(Number(pageNo) + 1);
                      localStorage.removeItem("lastVisitedProjectDetails");
                    }}
                  >
                    Next
                  </button>
                  <button
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white p-1 md:px-2 md:py-2 text-sm 2xl:text-base font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    onClick={() => {
                      setPageNo(totalPage);
                      setLastPageNo(totalPage);
                      localStorage.removeItem("lastVisitedProjectDetails");
                    }}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ),
      // desc: (
      //   <DragableMarker  />
      // ),
    },
    {
      label: "Map",
      value: "map",
      icon: MapIcon,
      desc: (
        <div className="md:mt-12 w-full">
          <AdminProjectMap APItype="Project" articles={data} />
        </div>
      ),
    },
  ];

  return (
    <Card className="w-full border-t">
      <Tabs value="list">
        <TabsHeader className="md:w-40 md:absolute md:right-3 md:top-4">
          {category.map(({ label, value, icon }) => (
            <Tab key={value} value={value}>
              <div className="flex items-center gap-2 text-sm 2xl:text-base">
                {React.createElement(icon, {
                  className: "w-4 h-4",
                })}
                <span className="text-sm 2xl:text-base">{label}</span>
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="pb-16 lg:pb-5">
          {category.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
      {/* <AllArticlesDB /> */}
    </Card>
  );
}
