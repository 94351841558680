import * as React from "react";
import { useState, useCallback } from "react";
import { render } from "react-dom";
import ReactMapGL, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
// import Pin from "./Pin";
import { MAP_SATELLITE_STREETS } from "../../../utils/Constant";
import AddMarkerMap from "./AddMarkerMap";

const TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; // Set your mapbox token here

export default function DragableMarker({ cordinates, setCordinates, style }) {
  const [marker, setMarker] = useState({}); // eslint-disable-next-line
  const [events, logEvents] = useState(); // eslint-disable-next-line
  const [viewport, setViewport] = useState({
    width: "100%", // Set the width to 100% initially
    height: "75vh", // Set the height to 100vh to fill the viewport height
    latitude: cordinates?.latitude,
    longitude: cordinates?.longitude,
    zoom: 17, // Initial zoom level
  });

  React.useEffect(() => {
    setMarker({
      latitude: cordinates?.latitude,
      longitude: cordinates?.longitude,
    }); // eslint-disable-next-line
  }, []);
  // const [locationInfo, setLocationInfo] = useState(null);
  const onMarkerDragStart = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragStart: event.lngLat }));
  }, []);

  const onMarkerDrag = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDrag: event.lngLat }));

    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  }, []);

  const onMarkerDragEnd = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragEnd: event.lngLat }));
    // console.log(event.lngLat, "onDragEnd");
    reverseGeocode(event.lngLat.lat, event.lngLat.lng);
    // setCordinates({ longitude: event.lngLat.lng, latitude: event.lngLat.lat });// eslint-disable-next-line
    // eslint-disable-next-line
  }, []);

  const reverseGeocode = async (latitude, longitude) => {
    try {
      // Make a request to a reverse geocoding API (e.g., Mapbox's Geocoding API)
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${TOKEN}`
      );
      const data = await response.json();

      // Parse the response to extract the desired location information
      const city = data.features[0].context.find((context) =>
        context.id.startsWith("place")
      ).text;
      const streetAddress =
        data.features[0].address + ", " + data.features[0].text;

      const streetIntersections = data.features[0].context
        .filter((context) => context.id.startsWith("intersection"))
        .map((context) => context.text)
        .join(", ");
      const zipCode = data.features[0].context.find((context) =>
        context.id.startsWith("postcode")
      ).text;
      // console.log(
      //   city,
      //   latitude,
      //   longitude,

      //   streetAddress,
      //   streetIntersections,
      //   zipCode
      // );
      // Set the location information in the state
      setCordinates({
        city,
        latitude,
        longitude,
        streetAddress,
        streetIntersections,
        zipCode,
      });
    } catch (error) {
      console.error("Error performing reverse geocoding:", error);
    }
  };

  return (
    <>
      {marker.longitude ? (
        <>
          <p className="text-xs text-gray-500 mb-5">
            Drag Marker to change location
          </p>
          <div style={{ width: viewport.width, height: viewport.height , ...style}}>
            <ReactMapGL
              {...viewport}
              mapStyle={MAP_SATELLITE_STREETS}
              mapboxAccessToken={TOKEN}
            >
              <Marker
                longitude={marker.longitude}
                latitude={marker.latitude}
                anchor="bottom"
                draggable
                onDragStart={onMarkerDragStart}
                onDrag={onMarkerDrag}
                onDragEnd={onMarkerDragEnd}
              >
                {/* <Pin /> */}
              </Marker>
              <GeolocateControl position="top-right" />
              <FullscreenControl position="top-right" />
              <NavigationControl position="top-right" />
              {/* <NavigationControl position="top-left" />
          <MapStyle setMapStyle={setMapStyle} mapStyle={mapStyle} /> */}
            </ReactMapGL>
          </div>
        </>
      ) : (
        <AddMarkerMap reverseGeocode={reverseGeocode}></AddMarkerMap>
      )}
    </>
  );
}

export function renderToDom(container) {
  render(<DragableMarker />, container);
}
