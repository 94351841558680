import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../../../../services/APIInstance";
import { PROJECT_STATUS_TYPE } from "../../../../services/APIGlobal";
import { showMessage } from "../../../../components/ui/showMessage";
import RadioButtonIcon from "../../../../components/functionality/RadioButtonIcon";

export default function ProjectStatus(props) {
  const [statusOptions, setStatusOptions] = useState([]);
  const [defaultStatus, setDefaultStatus] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    GET_STATUS(); // eslint-disable-next-line
  }, []);

  // Get API
  const GET_STATUS = () => {
    axiosInstance
      .get(`${PROJECT_STATUS_TYPE}`)
      .then((response) => {
        const Options = response?.data?.getArticleProjectStatusType.map(
          (item) => {
            return { Name: item.at_name, MarkerColor: item.MarkerColor };
          }
        );
        setStatusOptions(Options);
        if (props?.data?.project_status_type) {

          // let defaultValues = AssetDefaultValueListing.filter((item) => {
          //   return response?.data?.getArticleProjectStatusType.some((value) =>
          //     value.at_name.includes(item)
          //   );
          // });
          let defaultValues =  props?.data?.project_status_type
          // console.log(defaultValues, "defaultValues");
          props?.setProjectStatusType(defaultValues);
          setDefaultStatus(defaultValues);
        }

        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);

          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  // Handle the selected options in your parent component
  const handleCheckboxChange = (selectedOptions) => {
    let result = selectedOptions.length < 1 ? null : selectedOptions;
    props?.setProjectStatusType(result);
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <RadioButtonIcon
          textareaRefs={props?.textareaRefs}
          projectStatusType={props?.projectStatusType}
          formErrors={props?.formErrors}
          options={statusOptions}
          defaultChecked={defaultStatus ? defaultStatus : []}
          onChange={handleCheckboxChange}
          className="grid gap-6 md:!grid-cols-4 2xl:!grid-cols-2"
        />
      )}
    </>
  );
}
