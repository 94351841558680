import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../services/APIInstance";
import { showMessage } from "../components/ui/showMessage";

export const useApiGETRequest = (url,dependPara) => {
  const [Apidata, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    // console.log(Apidata,"call");
    setIsLoaded(true);
    const fetchData = () => {
      axiosInstance
        .get(url)
        .then((response) => {
          setData(response?.data);
          setIsLoaded(false);
        })
        .catch((error) => {
          setTimeout(() => {
            setIsLoaded(false);
          }, 1600);
          setTimeout(() => {
            showMessage(
              error?.response?.data?.error,
              "error",
              "red",
              false,
              false,
              false
            );
          }, 2500);
        });
    };
    fetchData();
  }, [url,dependPara]);

  return {  isLoaded, Apidata };
};
// const useApiPostRequest = (url,data) => {
//   const [data, setData] = useState([]);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = () => {
//       axios
//         .post(url,data)
//         .then((response) => {
//           setIsLoaded(true);
//           setData(response.data);
//         })
//         .catch((error) => {
//           setError(error);
//         });
//     };
//     fetchData();
//   }, [url]);

//   return { error, isLoaded, data };
// };

