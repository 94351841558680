import React, { useEffect, useState } from "react";
import ProjectContent from "./ProjectContent";
import axiosInstance from "../../services/APIInstance";
import { PROJECTLIST } from "../../services/APIGlobal";
import { showMessage } from "../../components/ui/showMessage";
import Loader from "../../components/ui/Loader";
import { useLocation } from "react-router-dom";
import ListViewStories from "./ListViewStories";
import TitleDivider from "../../components/ui/TitleDivider";
import { useContext } from "react";
import SearchContext from "../../context/SearchContext";
import HomePageOutline from "../../components/layouts/HomePageOutline";

export default function ProjectHome() {
  const location = useLocation();
  const [data, setData] = useState(); // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const { searchValue, setSearchValue } = useContext(SearchContext);

  useEffect(() => {
    setSearchValue("");
    window.scrollTo(0, 0);
    GETPROJECTS(); // eslint-disable-next-line
  }, [location]);

  const GETPROJECTS = () => {
    axiosInstance
      .get(`${PROJECTLIST}/${location.pathname.split("/")[2]}?type=user&userId=${localStorage.getItem("userId")} `)
      .then((response) => {
        setData(response?.data?.project);
        // setTotalRecord(response?.data?.pagination?.totalProjects);
        // setCurrentPage(response?.data?.pagination?.currentPage);
        // setTotalPage(response?.data?.pagination?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  return (
    <>
      {!searchValue ? (
        <div id="view">
          <Loader loading={loading} />
          {!loading && (
            <div>
              <ProjectContent project={data} GETPROJECTS={GETPROJECTS}/>
              <TitleDivider title="Articles" />
              {data?.articles.length > 0 ? (
                <ListViewStories stories={data.articles} GETAPI={GETPROJECTS} APItype="Article" />
              ) : (
                <p>No articles are added now</p>
              )}
            </div>
          )}
        </div>
      ) : (
        <HomePageOutline
        Details="Project"
          noImage
          AssetFillter
          timeFillter
        />
      )}
    </>
  );
}
