import { Card, CardBody, Typography, Button } from "@material-tailwind/react";
import { useFormik } from "formik";
import { useState } from "react";
// import { USERTAB } from "../utils/Constant";
import { useHistory } from "react-router-dom";
import Select from "react-dropdown-select";
import {  SIGNUP, UPDATEROLE } from "../../../../services/APIGlobal";
import InputField from "../../../../components/ui/InputField";
import { roleSchema } from "../../../../store/Validations/Validation";
import axiosInstance from "../../../../services/APIInstance";
import { showMessage } from "../../../../components/ui/showMessage";
import Loader from "../../../../components/ui/Loader";
import { RoleOptions } from "../../../../utils/Constant";

const Label = (props) => (
  <span className="flex flex-row text-gray-500 text-xs font-medium">
    {props.label}
    {props.isRequired && (
      <>
        {" "}
        <span className="ml-1 text-warn-error block text-sm font-medium ">
          *
        </span>
      </>
    )}
  </span>
);

export default function AddRole(props) {
  let history = useHistory();
  // const [userValue, setUserValue] = useState("Reviewer");
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [role, setRole] = useState([
    {
      label:
        props?.location?.title === "edit"
          ? props?.location?.data?.roleName
          : "-- Select Role Type --",
      value:
        props?.location?.title === "edit"
          ? props?.location?.data?.roleName
          : "-- Select Role Type --",
    },
  ]);

  const formik = useFormik({
    initialValues: {
      F_Name: props?.location?.data?.firstName,
      L_Name: props?.location?.data?.lastName,
      email: props?.location?.data?.email,
      role: "",
    },
    validationSchema: roleSchema,
    onSubmit: (values) => {
      setFormErrors({});
      if (!!role.length === false) {
        setFormErrors({ role: true });
        return;
      }
      setLoading(true);
      if (props?.location?.title !== "edit") {
        axiosInstance
          .post(SIGNUP, {
            firstName: values?.F_Name,
            lastName: values?.L_Name,
            jobLevelId: null,
            jobFunctionId: null,
            subJobFunctionId: null,
            zipcode: null,
            organization: null,
            email: values?.email,
            password: `${values?.F_Name}@123`,
            confirmPassword: `${values?.F_Name}@123`,
            jobLevelName: null,
            jobFunctionName: null,
            subJobFunctionName: null,
            roleId: role[0].value,
          })
          .then((response) => {
            setLoading(false);
            showMessage(
              response?.data?.message,
              "success",
              "#576d69",
              false,
              false,
              false
            );
            history.push("/Role");
          })
          .catch((error) => {
            setLoading(false);
            showMessage(
              error?.response?.data?.error ? error?.response?.data?.error : error?.message,
              "error",
              "red",
              false,
              false,
              false
            );
          });
      } else if (props?.location?.title === "edit") {
        let updateData = {
          firstName: values?.F_Name,
          lastName: values?.L_Name,
          roleId: role[0].label === "Reviewer" ? 1 : 2,
          email: values?.email,
        };
        axiosInstance
          .put(`${UPDATEROLE}/${props?.location?.data?.UserId}`, updateData)
          .then((response) => {
            setLoading(false);
            showMessage(
              response?.data?.message,
              "success",
              "#576d69",
              false,
              false,
              false
            );
            history.push("/Role");
          })
          .catch((error) => {
            setLoading(false);
            showMessage(
              error?.response?.data?.error,
              "error",
              "red",
              false,
              false,
              false
            );
          });
      }
    },
  });

  return (
    <div className={`flex items-center justify-center  z-0`}>
      <Loader loading={loading} />
      <Card className={` lg:m-0 w-full border-t-2`} shadow={true}>
        <CardBody className="w-full">
          {!props.update && (
            <Typography
              variant="h4"
              className="text-primary-primary font-black"
            >
              Create an account
            </Typography>
          )}
          <form
            className="flex flex-col gap-2 w-full pt-4"
            onSubmit={formik.handleSubmit}
          >
            <section className="w-full flex md:flex-row flex-col gap-4">
              {/* First Name */}
              <div className="md:w-1/2 lg:w-1/2">
                <InputField
                  name="F_Name"
                  label="First Name"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.F_Name}
                  error={formik.touched.F_Name && Boolean(formik.errors.F_Name)}
                  helpertext={formik.touched.F_Name && formik.errors.F_Name}
                  isRequired
                />
              </div>
              {/* Last Name */}
              <div className="md:w-1/2 lg:w-1/2">
                <InputField
                  name="L_Name"
                  label="Last Name"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.L_Name}
                  error={formik.touched.L_Name && Boolean(formik.errors.L_Name)}
                  helpertext={formik.touched.L_Name && formik.errors.L_Name}
                  isRequired
                />
              </div>
            </section>
            <section className="w-full flex md:flex-row flex-col gap-4">
              {/*Email */}
              <div className="md:w-1/2 lg:w-1/2">
                <InputField
                  name="email"
                  label="Email"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helpertext={formik.touched.email && formik.errors.email}
                  isRequired
                />
              </div>
              <section className="mt-1 md:w-1/2 lg:w-1/2 flex-col">
                <Label label=" Role Type" isRequired />
                <Select
                  required={true}
                  values={role}
                  onChange={(value) => {
                    setFormErrors({ role: false });
                    setRole(value);
                  }}
                  options={RoleOptions}
                  color="#576d69"
                  style={{
                    borderRadius: "5px",
                  }}
                  className="font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                />
                {formErrors?.role && (
                  <p className="text-xs text-warn-error font-extrabold">
                    Please select Role type
                  </p>
                )}
              </section>
            </section>
            <div>
              <div className="flex justify-center">
                <Button
                  variant="filled"
                  type="submit"
                  className="bg-primary-primary mt-5 text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                >
                 {props?.location?.title !== "edit" ? "SUBMIT": "Update"}  
                </Button>
              </div>
            </div>
          </form>
          {/* )}
          </Formik> */}
        </CardBody>
      </Card>
    </div>
  );
}
