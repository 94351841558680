import {
  Button,
  Card,
  CardBody,
  IconButton,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect } from "react";
import { showMessage } from "../ui/showMessage";
import moment from "moment";
import { ADDTOFAV, REMOVETOFAV, VIEWCOUNT } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { useHistory } from "react-router-dom";
import ScrollToIndexContext from "../../context/ScrollToIndex";
import { useContext } from "react";
import {
  appendSourceThumbnail,
  isNotNull,
  removeQueryParameters,
} from "../../store/helper/Helper";
import ImageWithFallback from "../ui/ImageWithFallback";
import logo from "../../assets/Logo/OnlyBlacklogo.png";
export default function HomeCard(props) {
  const history = useHistory();
  const { scrollToIndex, setScrollToIndex } = useContext(ScrollToIndexContext);
  // console.log(props.stories, "card");
  // console.log(props.APItype)
  const handleCardOpertaion = (card, operationValue, operation) => {
    let APICALL, DATA;
    if (operation === "Favorite") {
      // console.log(props?.APItype);

      APICALL = operationValue === 0 ? ADDTOFAV : REMOVETOFAV;
      // console.log(APICALL)
      if (props?.APItype === "Article") {
        DATA = {
          ProjectDetailsId: card.ProjectDetailsId,
          userId: localStorage.getItem("userId"),
        };
      } else if (props?.APItype === "Project") {
        DATA = {
          projectId: card.projectId,
          userId: localStorage.getItem("userId"),
        };
      }
      if (props?.APItype === "Favourite") {
        // console.log("here");
        DATA = {
          projectId: card.projectId,
          userId: localStorage.getItem("userId"),
          ProjectDetailsId: card.ProjectDetailsId,
          favoriteId: card.projectId,
        };
      }
    }
    axiosInstance
      .post(APICALL, DATA)
      .then((response) => {
        showMessage(
          response?.data?.message,
          "success",
          "#576d69",
          false,
          false,
          false
        );
        props.GETAPI();
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  const DetailsHeadler = (e, index) => {
    localStorage.setItem("userprojectId", index);
    localStorage.setItem("userrecordPage", props.showRecord);
    // console.log("click");
    const jumpToArray = [
      {
        type: props.APItype,
        userprojectId: index,
      },
    ];
    const typeToMatch = jumpToArray[0].type;
    const updatedArrayOfObjects = scrollToIndex.arrayOfFilters.filter(
      (obj) => obj.type !== typeToMatch
    );
    updatedArrayOfObjects.push(...jumpToArray);
    setScrollToIndex({
      ...scrollToIndex,
      arrayOfFilters: updatedArrayOfObjects,
    }); // eslint-disable-next-line

    axiosInstance
      .put(VIEWCOUNT, {
        ProjectDetailsId: e.ProjectDetailsId,
        UserId: localStorage.getItem("userId"),
      })
      .then(() => {})
      .catch((error) => {
        console.log(error, "error in count view");
      });
    let APIPATH =
      props.APItype !== "Project" && props.APItype !== "Favourite"
        ? `/details/${e.ProjectDetailsId}`
        : `/project/${e.projectId}`;
    history.push({
      pathname: APIPATH,
    });
    // window.open(APIPATH, '_blank');
    // const win = window.open(APIPATH, "_blank");
    // win.focus();
  };
  useEffect(() => {
    // Call scrollToRow inside useEffect to scroll to a specific row when the component mounts
    // userprojectId
    // details
    jumpToElemenet();
    // eslint-disable-next-line
  }, []);

  // jump to element
  const jumpToElemenet = () => {
    // console.log(localStorage.getItem("userprojectId"),"userprojectId")
    let element;
    if (props.APItype === "Project") {
      let findProjectArray = scrollToIndex.arrayOfFilters.find(
        (item) => item.type === "Project"
      );
      element = findProjectArray?.userprojectId;
      setScrollToIndex({ arrayOfFilters: [] });
    } else if (props.APItype === "Article") {
      let findProjectArray = scrollToIndex.arrayOfFilters.find(
        (item) => item.type === "Article"
      );
      element = findProjectArray?.userprojectId;
      setScrollToIndex({ arrayOfFilters: [] });
    } else if (props.APItype === "Favourite") {
      let findProjectArray = scrollToIndex.arrayOfFilters.find(
        (item) => item.type === "Favourite"
      );
      element = findProjectArray?.userprojectId;
      setScrollToIndex({ arrayOfFilters: [] });
    }
    const getId = document.getElementById(`${element}`);
    // const element2 = document.getElementById(
    //   `${localStorage.getItem("userprojectId")}`
    // );
    // console.log(element2, "element");

    if (getId) {
      // Calculate the offset to include space above the element (adjust this as needed)
      const offset = -120; // Adjust this value as needed
      const elementPosition = getId.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY + offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "auto",
      });
      localStorage.removeItem("userprojectId");
      localStorage.removeItem("userrecordPage");
    }
  };
  return (
    <div>
      {props?.stories?.map((card, index) => (
        <div
          key={card?.ProjectDetailsId}
          id={index}
          className={`  w-full hover:shadow-lg  
          ${
            index === 0
              ? "border-b"
              : `${card.length === index + 1 ? "border-t" : "border-y"}`
          }`}
        >
          <Card color="transparent" shadow={false} className="py-2 w-full p-2">
            <CardBody className=" p-0">
              <div
                className={`mx-0 flex lg:flex-row md:flex-row h-max ${
                  !props?.latestStories && "flex-col"
                } items-start gap-4`}
              >
                {/* Images */}
                {!props?.noImage && (
                  <div className=" ">
                    {props?.latestStories ? (
                      <ImageWithFallback
                        key={card?.ProjectDetailsId}
                        url={removeQueryParameters(
                          card?.articlegallery,
                          card?.weburl
                        )}
                        className="rounded-lg	to-bg-black-10 bg-gradient-to-tr from-transparent via-transparent to-black/60"
                        // width={150}
                        style={{
                          maxHeight: "100px",
                          minHeight: "100px",
                          minWidth: "120px",
                          maxWidth: "120px",
                        }}
                        fallbackUrl={logo}
                        source={card?.weburl}
                      />
                    ) : (
                      // <img
                      //   src={removeQueryParameters(card?.articlegallery)}
                      //   alt="article"
                      //   className="rounded-lg	to-bg-black-10 bg-gradient-to-tr from-transparent via-transparent to-black/60"
                      //   // width={150}
                      //   style={{
                      //     maxHeight: "80px",
                      //     minHeight: "80px",
                      //     minWidth: "100px",
                      //     maxWidth: "100px",
                      //   }}
                      // />
                      <>
                        <ImageWithFallback
                          key={card?.ProjectDetailsId}
                          url={
                            props?.APItype !== "Project"
                              ? props?.APItype === "Favourite"
                                ? removeQueryParameters(
                                    card?.projectgallery,
                                    card?.weburl
                                  )
                                : removeQueryParameters(
                                    card?.articlegallery,
                                    card?.weburl
                                  )
                              : removeQueryParameters(
                                  card?.articles[0]?.articlegallery,
                                  card?.articles[0]?.weburl
                                )
                          }
                          className="rounded-lg hidden md:block lg:block"
                          style={{
                            maxHeight: "170px",
                            minWidth: "250px",
                            maxWidth: "250px",
                            minHeight: "170px",
                          }}
                          fallbackUrl={logo}
                          source={
                            props?.APItype !== "Project"
                              ? card?.weburl
                              : card?.articles[0]?.weburl
                          }
                        />
                        {/* <img
                          src={
                            props?.APItype !== "Project"
                              ? removeQueryParameters(card?.articlegallery)
                              : removeQueryParameters(
                                  card?.articles[0]?.articlegallery
                                )
                          }
                          alt="ui/ux review check"
                          className="rounded-lg hidden md:block lg:block"
                          style={{
                            maxHeight: "170px",
                            minWidth: "250px",
                            maxWidth: "250px",
                            minHeight: "170px",
                          }}
                        /> */}
                        <ImageWithFallback
                          key={card?.ProjectDetailsId}
                          url={
                            props?.APItype !== "Project"
                              ? removeQueryParameters(
                                  card?.articlegallery,
                                  card?.weburl
                                )
                              : removeQueryParameters(
                                  card?.articles[0]?.articlegallery,
                                  card?.articles[0]?.weburl
                                )
                          }
                          className=" rounded-lg  lg:hidden md:hidden"
                          fallbackUrl={logo}
                          source={
                            props?.APItype !== "Project"
                              ? card?.weburl
                              : card?.articles[0]?.weburl
                          }
                        />
                        {/* <img
                          src={
                            props?.APItype !== "Project"
                              ? removeQueryParameters(card?.articlegallery)
                              : removeQueryParameters(
                                  card?.articles[0]?.articlegallery
                                )
                          }
                          alt="ui/ux review check"
                          className=" rounded-lg  lg:hidden md:hidden"
                        /> */}
                      </>
                    )}
                    {/* Favourite button */}
                    {/* {props.APItype !== "Article" && ( */}
                    {!props?.latestStories    &&<Tooltip
                      className="border shadow-lg bg-white text-black w-fit "
                      content={
                        card.favorite === 1
                          ? "Remove from Favorites"
                          : "Add to Favorites"
                      }
                      placement="top-start"
                    >
                      <IconButton
                        variant="text"
                        size="sm"
                        className={
                          props?.latestStories
                            ? "!absolute  top-3 left-20"
                            : "!absolute  top-3 lg:left-56 md:left-56 right-4 lg:right-0"
                        }
                        color={card?.favorite === 1 ? "teal" : "gray"}
                        onClick={() =>
                          handleCardOpertaion(card, card?.favorite, "Favorite")
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6  stroke-2"
                        >
                          <path
                            fillRule="evenodd"
                            stroke="white"
                            d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </IconButton>
                    </Tooltip>}
                    {/* )} */}
                  </div>
                )}
                <div className=" w-full flex-1 flex-col ">
                  <div
                    className=" hover:cursor-pointer"
                    onClick={() => DetailsHeadler(card, index)}
                  >
                    {/* Card title */}
                    <Typography
                      variant={props?.latestStories ? "small" : "h5"}
                      color="black"
                      className="leading-none hover:text-primary-hover font-serif line-clamp-2"
                    >
                      {props?.APItype !== "Project" &&
                      props?.APItype !== "Favourite"
                        ? card?.article_title
                        : card.title}
                    </Typography>
                    {/* Project Date */}
                    {props?.APItype !== "Project" &&
                    props?.APItype !== "Favourite" ? (
                      <p
                        className="text-xs text-gray-600 mt-2 "
                        color="blue-gray"
                      >
                        <span>
                          {moment(
                            card?.created_at,
                            "YYYY-MM-DD hh:mm:ss"
                          ).format("MM-DD-YYYY")}
                        </span>{" "}
                        {/* |{" "}
                        <span onClick={(e) => e.stopPropagation()}>
                          <a
                            rel="noreferrer"
                            href={card?.weburl}
                            target="_blank"
                            className="text-blue-700 text-xs underline"
                          >
                            {card?.weburl}
                          </a>
                        </span> */}
                      </p>
                    ) : (
                      <p
                        className="text-xs text-gray-600 mt-2 "
                        color="blue-gray"
                      >
                        <span>
                          {card?.startdate ? (
                            <>
                              Project Date:{" "}
                              {isNotNull(card?.startdate) &&
                                card?.startdate + " to "}
                              {/* {moment(
                                card?.startdate,
                                "YYYY-MM-DD hh:mm:ss"
                              ).format("MM-DD-YYYY")}{" "} */}
                            </>
                          ) : (
                            <>NA</>
                          )}
                          {card?.startdate && (
                            <>
                              {/* {moment(
                                card?.enddate,
                                "YYYY-MM-DD hh:mm:ss"
                              ).format("MM-DD-YYYY")} */}
                              {isNotNull(card?.enddate) ? card?.enddate : "-"}
                            </>
                          )}
                          {card?.Status && (
                            <> | Project Status: {card?.Status}</>
                          )}
                        </span>
                        <div className=" ">
                          {(props?.APItype === "Project" ||
                            props?.APItype === "Favourite") &&
                            card?.AssetType !== null &&
                            card?.AssetType !== undefined && (
                              <div className="flex flex-col mt-2">
                                <p
                                  className="line-clamp-2  max-w-fit rounded-md p-1 break-all tracking-wider font-medium antialiased text-xs  bg-primary-light text-black"
                                  variant="filled"
                                  color="gray"
                                >
                                  {" "}
                                  {card.AssetType.replace(",", ", ")}
                                </p>
                                {/* <span onClick={(e) => e.stopPropagation()}>
                              <a
                                rel="noreferrer"
                                href={
                                  card?.articles && card?.articles[0]?.weburl
                                }
                                target="_blank"
                                className="text-blue-700 text-xs underline"
                              >
                                {card?.articles && card?.articles[0]?.weburl}
                              </a>
                            </span> */}
                              </div>
                            )}
                        </div>
                      </p>
                    )}
                    {/* Project Description */}
                    {!props?.latestStories && (
                      <p
                        color="black"
                        className="text-sm text-primary-revado-dark font-extralight line-clamp-2 mb-6"
                      >
                        {card?.description}
                      </p>
                    )}
                    {/* Read More for project */}
                  </div>
                  {/* Load and read more */}
                  <div>
                    {props?.latestStories &&
                      props?.APItype !== "Project" &&
                      props?.APItype !== "Favourite" && (
                        <div className="mt-3 cursor-pointer">
                          <Button
                            size="sm"
                            color="white"
                            onClick={() => DetailsHeadler(card)}
                            className="p-0 max-w-fit flex items-center text-primary-primary border-primary-primary w-full shadow-none hover:bg-transparent hover:shadow-none hover:ring-0 focus:ring-0 focous:bg-transparent"
                            variant="text"
                            ripple={false}
                          >
                            Read More
                          </Button>
                        </div>
                      )}
                    {props?.APItype !== "Favourite" && (
                      <div className="flex flex-row justify-end items-end self-end -mt-5 md:mt-0">
                        <img
                          key={card?.ProjectDetailsId}
                          src={
                            props?.APItype !== "Project"
                              ? props?.APItype === "Favourite"
                                ? appendSourceThumbnail(card?.weburl)
                                : appendSourceThumbnail(card?.weburl)
                              : appendSourceThumbnail(card?.articles[0]?.weburl)
                          }
                          alt={
                            props?.APItype !== "Project"
                              ? props?.APItype === "Favourite"
                                ? card?.weburl
                                : card?.weburl
                              : card?.articles[0]?.weburl
                          }
                          style={{
                            height: "auto",
                            maxHeight: props?.latestStories ? "3vh" : "50px",
                            width: "auto",
                            maxWidth: "100px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {/* Home page Action buttons */}
                  {/* {!props?.latestStories && (
                    <div className="flex gap-1 justify-end lg:self-end md:self-end self-center items-end lg:mt-8 mt-4">

                      <div>
                        <Button
                          size="sm"
                          className={`${
                            card?.follow === 1
                              ? "bg-primary-primary text-white"
                              : "text-primary-primary "
                          } flex items-center gap-1 border-primary-primary w-full shadow-none hover:shadow-lg hover:shadow-primary-primary/40 hover:ring-0 focus:ring-0 `}
                          variant={
                            card?.follow === 0 || !card?.follow
                              ? `outlined`
                              : "filled"
                          }
                          onClick={() =>
                            handleCardOpertaion(card, card?.follow, "Follow")
                          }
                        >
                          <>
                            {card?.follow === 0 || !card?.follow ? (
                              <PlusIcon strokeWidth={2} className="h-5 w-5" />
                            ) : (
                              <CheckIcon strokeWidth={2} className="h-5 w-5" />
                            )}
                            <span>
                              {" "}
                              {card?.follow === 0 || !card?.follow
                                ? "Follow"
                                : "Following"}
                            </span>{" "}
                          </>
                        </Button>
                      </div>
                      <div>

                        <Tooltip
                          className="border shadow-lg bg-white text-black w-fit "
                          content={
                            card.notify === 1
                              ? "Unsubscribe for Alerts"
                              : "Subscribe for Alerts"
                          }
                          placement="top-start"
                        >
                          <Button
                            size="sm"
                            className={`${
                              card?.notify === 1
                                ? "bg-primary-primary text-white"
                                : "text-primary-primary "
                            } flex items-center gap-1 border-primary-primary w-full shadow-none hover:shadow-lg hover:shadow-primary-primary/40 hover:ring-0 focus:ring-0 `}
                            variant={
                              card?.notify === 0 || !card?.notify
                                ? `outlined`
                                : "filled"
                            }
                            onClick={() =>
                              handleCardOpertaion(card, card?.notify, "Notify")
                            }
                          >
                            <BellAlertIcon
                              strokeWidth={2}
                              className="h-5 w-5"
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  )} */}
                  {/* Read More for project */}
                  {/* {props?.APItype === "Project" && (
                    <div className="flex justify-end flex-row items-end self-end absolute bottom-5 right-5">
                      <Button
                        size="sm"
                        color="white"
                        onClick={() => DetailsHeadler(card)}
                        className="p-0 max-w-fit flex items-center text-primary-primary border-primary-primary w-full shadow-none hover:bg-transparent hover:shadow-none hover:ring-0 focus:ring-0 focous:bg-transparent"
                        variant="text"
                        ripple={false}
                      >
                        Read More
                      </Button>
                    </div>
                  )} */}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      ))}
    </div>
  );
}
