import { Card, CardBody, Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
// import { USERTAB } from "../utils/Constant";
import { useHistory } from "react-router-dom";
import Loader from "../../components/ui/Loader";
import InputField from "../../components/ui/InputField";
import { showMessage } from "../../components/ui/showMessage";
import axiosInstance from "../../services/APIInstance";
import {
  CHANGEPASSWORD,
  JOBFUNCTIONOPTIONS,
  JOBLEVELOPTIONS,
  SUBJOBFUNCTIONOPTIONS,
  UPDATEPROFILE,
} from "../../services/APIGlobal";
import Select from "react-dropdown-select";
import axios from "axios";
import { formValueConvertor, getFormJSON } from "../../store/helper/Helper";
import styled from "styled-components";

const Label = (props) => (
  <span className="flex flex-row text-gray-500 text-xs font-medium">
    {props.label}
    {props.isRequired && (
      <>
        {" "}
        <span className="ml-1 text-warn-error block text-xs font-medium ">
          *
        </span>
      </>
    )}
  </span>
);
const StyledSelect = styled(Select)`
  z-index: 1000;
  .react-dropdown-select-dropdown {
    overflow: initial;
  }

  .react-dropdown-select-dropdown {
    width: 400px;
    position: absolute;
    color: red;
  }
`;
export default function UpdateProfile(props) {
  let history = useHistory();
  // const [userValue, setUserValue] = useState("Reviewer");
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [jobOptions, setJobOptions] = useState({});
  const [jobSelect, setJobSelect] = useState([]);
  const [defaultJobValue, setDefaultJobValue] = useState();
  const [jobFunctionalityOptions, setJobFunctionalityOptions] = useState({});
  const [defaultJobFunctionValue, setDefaultJobFunctionValue] = useState();
  const [jobFunctionalitySelect, setJobFunctionalitySelect] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [subJobFunctionalityOptions, setSubJobFunctionalityOptions] = useState(
    {}
  );
  const [subJobFunctionalitySelect, setSubJobFunctionalitySelect] = useState(
    {}
  );
  const [defaultSubJobFunctionValue, setDefaultSubJobFunctionValue] =
    useState();
  const [togglePasswordType, setTogglePasswordType] = useState(false);
  const [toggleRePasswordType, setToggleRePasswordType] = useState(false);
  const [rePasswordRequired, setRePasswordRequired] = useState(false);

  const [error, setError] = useState(false);

  function togglePasswordVisibility() {
    setTogglePasswordType((prevState) => !prevState);
  }
  const authDetails = JSON.parse(localStorage.getItem("authDetails"));

  // function togglePasswordVisibility() {
  //   setTogglePasswordType((prevState) => !prevState);
  // }

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    if (localStorage.getItem("authDetails") !== "") {
      refreshToken();
    }
    // GET_USER();
    GET_OPTIONS(); // eslint-disable-next-line
  }, []);

  const refreshToken = () => {
    let refreshToken = {
      refreshToken: JSON.parse(localStorage.getItem("authDetails"))
        .RefreshToken,
    };
    axios
      .post(
        "https://dev-api.redova.net:3002/api/v1/auth/refresh-token",
        refreshToken
      )
      .then((response) => {
        let localData = JSON.parse(localStorage.getItem("authDetails"));
        let newData = {
          email: localData.email,
          pwd: localData.pwd,
          roles: localData.roles,
          Token: response?.data?.accessToken,
          UserId: localData.UserId,
          RefreshToken: localData.RefreshToken,
        };
        localStorage.setItem("authDetails", JSON.stringify(newData));
        //  ...value
        GET_USER(response?.data?.accessToken);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Get User
  const GET_USER = (token) => {
    const headers = { Authorization: `Bearer ${token}` }; // auth header with bearer token
    // props.update
    setLoading(true);
    axios
      .get("https://dev-api.redova.net:3002/api/v1/auth/getuser", {
        headers,
      })
      .then((response) => {
        let labelValueArray;
        // console.log(response?.data?.userDetails[0]);
        setUserDetails(response?.data?.userDetails[0]);
        if (response?.data?.userDetails[0]?.subJobFunctionName) {
          const dataArray =
            response?.data?.userDetails[0]?.subJobFunctionName.split(",");
          labelValueArray = dataArray.map((item) => {
            const trimmedItem = item.trim(); // Remove any leading/trailing spaces
            return { label: trimmedItem, value: trimmedItem };
          });
        }
        localStorage.setItem("userId", response?.data?.userDetails[0].UserId);
        response?.data?.userDetails[0]?.jobLevelName !== null
          ? setDefaultJobValue({
              value: response?.data?.userDetails[0]?.jobLevelId,
              label: response?.data?.userDetails[0]?.jobLevelName,
            })
          : setDefaultJobValue({
              value: "",
              label: "",
            });
        response?.data?.userDetails[0]?.jobFunctionName !== null
          ? setDefaultJobFunctionValue({
              value: response?.data?.userDetails[0]?.JobfunctionId,
              label: response?.data?.userDetails[0]?.jobFunctionName,
            })
          : setDefaultJobFunctionValue({
              value: "",
              label: "",
            });
        response?.data?.userDetails[0]?.subJobFunctionName !== null
          ? setDefaultSubJobFunctionValue(labelValueArray)
          : setDefaultSubJobFunctionValue({
              value: "",
              label: "",
            });
        setTimeout(() => {
          setLoading(false);
        }, 1600);
      });
  };
  // Get API
  const GET_OPTIONS = () => {
    setLoading(true);
    axiosInstance
      .get(`${JOBLEVELOPTIONS}`)
      .then((response) => {
        const Options = response?.data?.jobLevels.map((item) => {
          return { label: item.Name, value: item.JobId };
        });
        setJobOptions(Options);
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    axiosInstance
      .get(`${JOBFUNCTIONOPTIONS}`)
      .then((response) => {
        const Options = response?.data?.jobFunctions.map((item) => {
          return { label: item.Name, value: item.JobfunctionId };
        });
        setJobFunctionalityOptions(Options);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    axiosInstance
      .get(`${SUBJOBFUNCTIONOPTIONS}`)
      .then((response) => {
        const Options = response?.data?.subJobFunctions.map((item) => {
          return { label: item.Name, value: item.SubJobfunctionId };
        });
        setSubJobFunctionalityOptions(Options);
        setTimeout(() => {
          setLoading(false);
        }, 1600);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  const onChange = (e) => {
    setFormErrors({});
  };

  // Update API
  const onSumbitHandler = (e) => {
    e.preventDefault();
    const obj = formValueConvertor(getFormJSON(e.target));
    // console.log(defaultSubJobFunctionValue, "values");
    setFormErrors({});
    if (obj.F_Name === " ") {
      setFormErrors({ F_Name: true });
      return;
    } else if (obj.L_Name === " ") {
      setFormErrors({ L_Name: true });
      return;
    } else if (defaultJobValue.label === "" && !!jobSelect.length === false) {
      setFormErrors({ JobLevel: true });
      return;
    } else if (
      defaultJobValue.label === "" &&
      !!jobFunctionalitySelect.length === false
    ) {
      setFormErrors({ JobFunctionality: true });
      return;
    } else if (
      defaultSubJobFunctionValue.label === "" &&
      !!subJobFunctionalitySelect.length === false
    ) {
      setFormErrors({ SubJobFunctionality: true });
      // alert(`error`);
      return;
    } else if (obj.UserName === " ") {
      setFormErrors({ UserName: true });
      // alert(`error`);
      return;
    } else if (
      obj.currentPassword !== " " &&
      obj.currentPassword !== authDetails.pwd
    ) {
      return showMessage(
        `Current Password does not match`,
        "error",
        "red",
        false,
        false,
        false
      );
    } else if (obj.currentPassword !== " ") {
      if (obj.Password === " ") {
        return setFormErrors({ newPassword: true });
      }
    }
    let apiSubJob;
    if (!!subJobFunctionalitySelect.length === true) {
      const subJobs = subJobFunctionalitySelect.map((value) => value.label);
      apiSubJob = subJobs.toString();
    }
    if (obj.currentPassword !== " ") {
      let Data = {
        newPassword: obj.Password,
      };
      // props.update
      setLoading(true);
      axiosInstance
        .post(`${CHANGEPASSWORD}?token=${authDetails.Token}`, Data)
        .then((response) => {})
        .catch((error) => {
          setLoading(false);
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        });
    }
    // console.log(apiSubJob);
    setLoading(true);
    const UPDATEDATE = {
      FirstName: obj?.F_Name,
      LastName: obj?.L_Name,
      jobLevelId:
        !!jobSelect.length === true
          ? jobSelect[0].value
          : defaultJobValue.value,
      JobfunctionId:
        !!jobFunctionalitySelect.length === true
          ? jobFunctionalitySelect[0].value
          : defaultJobFunctionValue.value,
      SubJobfunctionId:
        !!subJobFunctionalitySelect.length === true
          ? subJobFunctionalitySelect[0].value
          : defaultSubJobFunctionValue.value,
      jobLevelName:
        !!jobSelect.length === true
          ? jobSelect[0].label
          : defaultJobValue.label,
      jobFunctionName:
        !!jobFunctionalitySelect.length === true
          ? jobFunctionalitySelect[0].label
          : defaultJobFunctionValue.label,
      subJobFunctionName:
        !!subJobFunctionalitySelect.length === true
          ? apiSubJob
          : defaultSubJobFunctionValue[0].label,
      Zipcode: obj?.employerZip,
      Organization: obj?.Organization,
      Email: obj?.UserName,
      Password: authDetails?.pwd,
      // jobLevelName: jobSelect[0].label,
      // jobFunctionName: jobFunctionalitySelect[0].label,
      // subJobFunctionName:  !!subJobFunctionalitySelect.length === true ?apiSubJob:defaultSubJobFunctionValue.label,
      UserId: authDetails?.UserId,
      roleId: authDetails?.roleId,
    };
    axiosInstance
      .put(UPDATEPROFILE, UPDATEDATE)
      .then((response) => {
        let localData = JSON.parse(localStorage.getItem("authDetails"));
        localStorage.setItem("userDetails", JSON.stringify(UPDATEDATE));
        let localAuthData = {
          email: obj?.UserName,
          pwd: obj.currentPassword !== " " ? obj.Password : localData.pwd,
          roles: localData.roles,
          Token: localData?.Token,
          UserId: localData.UserId,
          RefreshToken: localData.RefreshToken,
        };
        localStorage.setItem("authDetails", JSON.stringify(localAuthData));

        // setLoading(false);

        // setTimeout(() => {
        //   setLoading(true);
        // }, 1600);
        setTimeout(() => {
          showMessage(
            `${props.update ? "Sucessfully Update!" : response?.data?.message}`,
            "success",
            "#576d69",
            false,
            false,
            false
          );
          GET_USER();
        }, 1750);
        setTimeout(() => {
          setLoading(false);
          !props.update && history.push("/signIn");
        }, 2500);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  return (
    <div
      className={`flex w-full  pb-4 items-center justify-center ${
        !props.update ? "" : ""
      } z-0`}
    >
      <Loader loading={loading} />
      {!loading && (
        <Card
          className={` ${!props.update && "l"} lg:m-0 w-full border-t-2`}
          shadow={true}
        >
          <CardBody className="w-full">
            <form
              className="flex flex-col gap-2 w-full "
              onSubmit={onSumbitHandler}
            >
              <section className="flex md:flex-row flex-col md:gap-5 gap-3 flex-wrap">
                {/* First Name */}
                <div className="md:w-64">
                  <InputField
                    name="F_Name"
                    label="First Name"
                    // onChange={formik.handleChange}
                    // value={formik.values.F_Name}
                    defaultValue={userDetails?.FirstName}
                    error={formErrors?.F_Name}
                    helpertext={formErrors?.F_Name && "Please enter First Name"}
                    onChange={onChange}
                    isRequired
                  />
                </div>
                {/* Last Name */}
                <div className="md:w-64">
                  <InputField
                    name="L_Name"
                    label="Last Name"
                    defaultValue={userDetails?.LastName}
                    error={formErrors?.L_Name}
                    helpertext={formErrors?.L_Name && "Please enter Last Name"}
                    isRequired
                  />
                </div>
                <div className="md:w-80">
                  <InputField
                    name="UserName"
                    label="Email"
                    defaultValue={userDetails?.Email}
                    error={formErrors?.UserName}
                    helpertext={formErrors?.UserName && "Please enterEmail"}
                    isRequired
                  />
                </div>
                <div>
                  <div className="md:w-64 flex-col gap-1">
                    <Label label="Job Level" isRequired />
                    {formErrors?.JobLevel ? (
                      <StyledSelect
                        {...props}
                        // required={true}
                        values={[defaultJobValue]}
                        onChange={(value) => {
                          // setFormErrors({ JobLevel: false });
                          setJobSelect(value);
                          // setFormErrors({ JobLevel: false });
                        }}
                        dropdownHandleRenderer={
                          ({ state }) =>
                            // if dropdown is open show "–" else show "+"
                            // console.log(state, "state"),
                            state.dropdown && setFormErrors({ JobLevel: false })
                          // <span>{state.dropdown ? '–' : '+'}</span>
                        }
                        options={jobOptions}
                        color={formErrors?.JobLevel ? "red" : "#576d69"}
                        style={{
                          borderRadius: "5px",
                        }}
                        className="mt-1 font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                      />
                    ) : (
                      <Select
                        {...props}
                        // required={true}
                        values={[defaultJobValue]}
                        onChange={(value) => {
                          setFormErrors({ JobLevel: false });
                          setJobSelect(value);
                        }}
                        options={jobOptions}
                        color="#576d69"
                        style={{
                          borderRadius: "5px",
                        }}
                        className="mt-1 font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                      />
                    )}
                    {formErrors?.JobLevel && (
                      <p className="text-xs text-warn-error font-extrabold">
                        Please select job level
                      </p>
                    )}
                  </div>
                </div>
                {/* <section className="mt-2 w-full flex md:flex-row flex-col gap-4"> */}
                {/* Job Functionality  */}
                <div className="md:w-64">
                  <Label label="Job Functionality" isRequired />
                  {formErrors?.JobFunctionality ? (
                    <StyledSelect
                      {...props}
                      // required={true}
                      values={[defaultJobFunctionValue]}
                      onChange={(value) => {
                        // setFormErrors({ JobLevel: false });
                        setJobFunctionalitySelect(value);
                        // setFormErrors({ JobLevel: false });
                      }}
                      dropdownHandleRenderer={
                        ({ state }) =>
                          // if dropdown is open show "–" else show "+"
                          state.dropdown &&
                          setFormErrors({ JobFunctionality: false })
                        // <span>{state.dropdown ? '–' : '+'}</span>
                      }
                      options={jobFunctionalityOptions}
                      color="#576d69"
                      style={{
                        borderRadius: "5px",
                      }}
                      className="mt-1 font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                    />
                  ) : (
                    <Select
                      // required={true}
                      values={[defaultJobFunctionValue]}
                      // values={[]}
                      onChange={(value) => {
                        setFormErrors({ JobFunctionality: false });
                        setJobFunctionalitySelect(value);
                      }}
                      options={jobFunctionalityOptions}
                      color="#576d69"
                      style={{
                        borderRadius: "5px",
                      }}
                      placeholder="-- Select Job Functionality --"
                      className="mt-1 font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                    />
                  )}
                  {formErrors?.JobFunctionality && (
                    <p className="text-xs text-warn-error font-extrabold">
                      Please select job Functionality
                    </p>
                  )}
                </div>
                {/* Sub Job  */}
                <div className="md:w-64">
                  <Label label="Sub Job Functionality" isRequired />
                  {formErrors?.SubJobFunctionality ? (
                    <StyledSelect
                      {...props}
                      // required={true}
                      values={[]}
                      onChange={(value) => {
                        // setFormErrors({ JobLevel: false });
                        setSubJobFunctionalitySelect(value);
                        // setFormErrors({ JobLevel: false });
                      }}
                      dropdownHandleRenderer={
                        ({ state }) =>
                          // if dropdown is open show "–" else show "+"
                          state.dropdown &&
                          setFormErrors({ subJobFunctionalityOptions: false })
                        // <span>{state.dropdown ? '–' : '+'}</span>
                      }
                      options={subJobFunctionalityOptions}
                      color="#576d69"
                      style={{
                        borderRadius: "5px",
                      }}
                      className="mt-1 font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                    />
                  ) : (
                    <Select
                      multi
                      // required={true}
                      // values={[]}
                      values={
                        defaultSubJobFunctionValue?.label !== ""
                          ? defaultSubJobFunctionValue
                          : []
                      }
                      onChange={(value) => {
                        setFormErrors({ SubJobFunctionality: false });
                        setSubJobFunctionalitySelect(value);
                      }}
                      options={subJobFunctionalityOptions}
                      color="#576d69"
                      style={{
                        borderRadius: "5px",
                      }}
                      placeholder="-- Select Job sub Job Functionality --"
                      className="mt-1 font-thin text-xs border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                    />
                  )}
                  {formErrors?.SubJobFunctionality && (
                    <p className="text-xs text-warn-error font-extrabold">
                      Please select job sub Job Functionality
                    </p>
                  )}
                </div>
                {/* </section> */}
                {/*Organization */}
                <div className="md:w-64">
                  <section className="w-full flex-col ">
                    <InputField
                      name="Organization"
                      label="Organization"
                      defaultValue={userDetails?.Organization}
                    />
                  </section>
                </div>
                {/* <section className="mt-1 w-full flex md:flex-row gap-4 flex-col">
            Industry 
            <div className="md:w-1/2 lg:w-1/2">
              <DropDown
                name="Industry"
                onChange={formik.handleChange}
                value={formik.values.Industry}
                options={JobLevels}
                error={formErrors.Industry}
                label="Industry"
              />
            </div>
            Sub Industry 
            <div className="md:w-1/2 lg:w-1/2">
              <DropDown
                name="SubIndustry"
                onChange={formik.handleChange}
                value={formik.values.SubIndustry}
                options={JobLevels}
                error={formErrors.SubIndustry}
                label="Sub Industry"
              />
            </div>
          </section> */}
                {/* <section className="mt-2 w-full flex md:flex-row gap-4 flex-col"> */}
                {/* Company Size  */}
                {/* <div className="md:w-1/2 lg:w-1/2 mt-1">
              <DropDown
                name="CompanySize"
                onChange={formik.handleChange}
                value={formik.values.CompanySize}
                options={JobLevels}
                error={formErrors.CompanySize}
                label="Company Size"
              />
            </div> */}
                {/* Employer Zipcode  */}
                <div className="md:w-32">
                  <InputField
                    name="employerZip"
                    label="Zip code of employer"
                    defaultValue={userDetails?.Zipcode}
                  />
                </div>
              </section>

              <section className="flex md:flex-row flex-col gap-4 w-full md:mt-4">
                {/* Current pass */}
                <div className="md:w-80  flex flex-col gap-1 ">
                  <Label label="Current Password" />
                  <div className="relative ">
                    <input
                      type={togglePasswordType ? "text" : "password"}
                      placeholder="Password"
                      name="currentPassword"
                      label="Password"
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          setRePasswordRequired(true);
                        } else {
                          setRePasswordRequired(false);
                        }
                      }}
                      className={`${
                        error
                          ? "border-2 border-warn-error"
                          : "border border-gray-300"
                      } peer w-full px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                      onClick={togglePasswordVisibility}
                    >
                      {togglePasswordType ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                  {error && (
                    <p className="font-semibold text-warn-error text-xs">
                      Enter New Password
                    </p>
                  )}
                </div>
                {/* New pass */}
                <div className="md:w-80 flex flex-col gap-1">
                  <Label label="New Password" isRequired={rePasswordRequired} />
                  <div className="relative ">
                    <input
                      type={toggleRePasswordType ? "text" : "password"}
                      placeholder="Password"
                      name="Password"
                      label="Password"
                      onChange={(e) => {
                        setError(false);
                      }}
                      className={`${
                        error
                          ? "border-2 border-warn-error"
                          : "border border-gray-300"
                      } peer w-full px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                      onClick={() => {
                        setToggleRePasswordType((prevState) => !prevState);
                      }}
                    >
                      {toggleRePasswordType ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                  {formErrors.newPassword && (
                    <p className="font-semibold text-warn-error text-xs">
                      Enter New Password
                    </p>
                  )}
                </div>
              </section>
              <div>
                <div className="flex justify-center">
                  <Button
                    variant="filled"
                    type="submit"
                    className="bg-primary-primary mt-5 text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                  >
                    {!props.update ? "Sign up" : "Update"}
                  </Button>
                </div>
              </div>
            </form>
            {/* )}
        </Formik> */}
          </CardBody>
        </Card>
      )}
    </div>
  );
}
