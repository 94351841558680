import * as React from "react";
import { useState, useCallback } from "react";
import { render } from "react-dom";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
// import Pin from "./Pin";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // Import styles for the geocoder;
import { MAP_SATELLITE_STREETS } from "../../../utils/Constant";
import AddMarkerMap from "./AddMarkerMap";

const TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; // Set your mapbox token here

export default function MarkerLocationChange({
  cordinates,
  setCordinates,
  style,
  addressText,
  defaultMarker,
  setFormErrors
}) {
  const [marker, setMarker] = useState(defaultMarker?defaultMarker :{}); // eslint-disable-next-line
  const [events, logEvents] = useState(); // eslint-disable-next-line

  const mapRef = React.createRef();

  const reverseGeocode = async (latitude, longitude) => {
    try {
      // Make a request to a reverse geocoding API (e.g., Mapbox's Geocoding API)
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${TOKEN}`
      );
      const data = await response.json();
      // Parse the response to extract the desired location information
      const city = data.features[0].context.find((context) =>
        context.id.startsWith("place")
      ).text;
      const streetAddress =
        data.features[0].properties.address !== undefined
          ? data.features[0].properties.address + ", " + data.features[0].text
          : data.features[0].text;
      const streetIntersections = data.features[0].context
        .filter((context) => context.id.startsWith("intersection"))
        .map((context) => context.text)
        .join(", ");
      const zipCode = data.features[0].context.find((context) =>
        context.id.startsWith("postcode")
      ).text;
      const state = data.features[0].context.find((context) =>
        context.id.startsWith("region")
      ).text;
      let streetNumber = streetAddress !== undefined ? streetAddress : null;
      // Set the location information in the state
      setCordinates({
        city,
        latitude,
        longitude,
        streetNumber,
        streetIntersections,
        zipCode,
        state,
      });
    } catch (error) {
      console.error("Error performing reverse geocoding:", error);
    }
  };
// eslint-disable-next-line
  const searchGeocode = async (searchQuery) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json?access_token=${TOKEN}`
      );

      const data = await response.json();

      const latitude = data.features[0].geometry.coordinates[1];
      const longitude = data.features[0].geometry.coordinates[0];

      reverseGeocode(latitude, longitude);
    } catch (error) {
      console.error("Error performing search geocoding:", error);
    }
  };

  // React.useEffect(() => {
  //   if (addressText === "") return;

  //   let timer = setTimeout(() => {
  //     searchGeocode(addressText);
  //   }, 500);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [addressText]);

  React.useEffect(() => {
    setMarker({
      latitude: cordinates?.latitude,
      longitude: cordinates?.longitude,
    }); // eslint-disable-next-line
  }, []);
  const handleMapClick = (event) => {
    // Get the latitude and longitude of the clicked point
    // console.log(event.lngLat);
    // const { lngLat } = event;
    // const [longitude, latitude] = lngLat;
    setMarker({
      latitude: event.lngLat?.lat,
      longitude: event.lngLat?.lng,
    });
    reverseGeocode(event.lngLat?.lat, event.lngLat?.lng);

    // // Update the marker's location
    // setMarker({ latitude, longitude });
  };

  // Function to initialize the geocoder control
  const initGeocoder = (map) => {
    mapRef.current = map;
    const geocoder = new MapboxGeocoder({
      accessToken: TOKEN, // Replace with your Mapbox Access Token
      mapboxgl: mapboxgl,
      marker: {
        color: "transparent",
        scale: "0",
      },
    });
    // Add the geocoder to the map
    map.addControl(geocoder);
    // Handle geocoder result event
    geocoder.on("result", (event) => {
      // Handle the geocoder search result here
      setMarker({
        latitude: event.result.center[1],
        longitude: event.result.center[0],
      });
      reverseGeocode(event.result.center[1], event.result.center[0]);
    });
  };

  // Drag and drop
  const onMarkerDragStart = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragStart: event.lngLat }));
  }, []);
  const onMarkerDrag = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDrag: event.lngLat }));
    setFormErrors({ marker: false })
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });// eslint-disable-next-line
  }, []);

  const onMarkerDragEnd = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragEnd: event.lngLat }));
    // console.log(event.lngLat, "onDragEnd");
    reverseGeocode(event.lngLat.lat, event.lngLat.lng);
    // setCordinates({ longitude: event.lngLat.lng, latitude: event.lngLat.lat });// eslint-disable-next-line
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {marker.longitude ? (
        <>

          <Map
            ref={mapRef}
            initialViewState={{
              width: "100%",
              height: "75vh",
              latitude: cordinates?.latitude,
              longitude: cordinates?.longitude,
              zoom: 9,
            }}
            mapStyle={MAP_SATELLITE_STREETS}
            mapboxAccessToken={TOKEN}
            style={{ width: "100%", height: "75vh", ...style }}
            onClick={handleMapClick}
            onLoad={() => initGeocoder(mapRef.current.getMap())} // Initialize the geocoder when the map loads
          >
            {marker && (
              <Marker
                latitude={marker.latitude}
                longitude={marker.longitude}
                offsetLeft={-20}
                offsetTop={-10}
                color="blue"
                draggable
                onDragStart={onMarkerDragStart}
                onDrag={onMarkerDrag}
                onDragEnd={onMarkerDragEnd}
              >
                {/* <Pin /> */}
              </Marker>
            )}
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            {/* <NavigationControl position="top-left" />
          <MapStyle setMapStyle={setMapStyle} mapStyle={mapStyle} /> */}
          </Map>
        </>
      ) : (
        <AddMarkerMap
          reverseGeocode={reverseGeocode}
          style={style}
          setFormErrors={setFormErrors}
        ></AddMarkerMap>
      )}
    </>
  );
}

export function renderToDom(container) {
  render(<MarkerLocationChange />, container);
}
