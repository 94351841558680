import {
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogFooter,
  Tooltip,
} from "@material-tailwind/react";
import React, { useState } from "react";
import InputField from "../../../../components/ui/InputField";
import TextField from "../../../../components/ui/TextField";
import axiosInstance from "../../../../services/APIInstance";
import {
  ASSETTYPE,
  NEXTARTICLE,
  PUBLISH,
  STORIES,
  Update_SOURCE,
} from "../../../../services/APIGlobal";
import { showMessage } from "../../../../components/ui/showMessage";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
import DragableMarker from "../../../../components/ui/Map/DragableMarker";
import SingleMarkerMap from "../../../../components/ui/Map/SingleMarkerMap";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import MarkerLocationChange from "../../../../components/ui/Map/MarkerLocationChange";
import Switch from "react-switch";
import {
  ArrayToString,
  removeQueryParameters,
} from "../../../../store/helper/Helper";
import DotSpinner from "../../../../components/ui/DotSpinner";
import ArticleAssetType from "../../../../components/functionality/ArticleAssetType";
import FilterParaContext from "../../../../context/FilterParaContext";
import { useContext } from "react";
const TooltipStlye = "border shadow-lg bg-white text-black w-fit ";

export default function AddInformation(props) {
  const history = useHistory(); // eslint-disable-next-line
  const [open, setOpen] = useState(0); // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");// eslint-disable-next-line
  const [articleIds, setArticleIds] = React.useState();
  const [cordinates, setCordinates] = useState({});
  const [imageURL, setImageURL] = useState(); // Initialize the imageURL state with the default value
  const [responsiveOpen, setResponsiveOpen] = useState(false);
  const [isLocationAvailable, setIsLocationAvailable] = useState(false);
  const [isLocationLoad, setIsLocationLoad] = useState(true); // eslint-disable-next-line
  const [assetType, setAssetType] = React.useState([]);
  const [assetOption, setAssetOption] = React.useState();
  const [assetTypeNewValues, setAssetTypeNewValues] = React.useState([]);
  const [defaultAssetType, setDefaultAssetType] = React.useState([]); // eslint-disable-next-line
  const [isAssetTypeAvailble, setIsAssetTypeAvailable] = React.useState();
  const { filterCategory } = useContext(FilterParaContext);

  const handleOpen = () => setOpen(!open);
  const handleOpenResponsive = () => setResponsiveOpen(!open);
  useEffect(() => {
    GetArticles();
    setIsLocationAvailable(props?.allData?.Latitude ? true : false);
    setImageURL(
      removeQueryParameters(
        props?.allData?.articlegallery,
        props?.allData?.weburl
      )
    ); // eslint-disable-next-line
  }, []);

  const GetArticles = () => {
    setLoading(true);
    axiosInstance
      .get(
        `${STORIES}?limit=${localStorage.getItem(
          "totalArticles"
        )}&isReview=&isPublish=&page=1&flag=1&sortColumn=created_at&sortOrder=desc`
      )
      .then((response) => {
        setArticleIds(
          response?.data?.stories.map((item, index) => ({
            ProjectDetailsId: item.ProjectDetailsId,
            index: index,
          }))
        );
        axiosInstance.get(`${ASSETTYPE}`).then((response) => {
          setAssetOption(response?.data?.getAssetType);
          // Check assettype is available or not
          if (props?.allData?.AssetType) {
            let AssetDefaultValueListing = props?.allData?.AssetType.split(
              ","
            ).map((value) => value.trim());
            let checkAssetType = AssetDefaultValueListing.includes(
              response?.data?.getAssetType.Name
            );
            let NotIncludeValues = AssetDefaultValueListing.filter((item) => {
              return !response?.data?.getAssetType.some((value) =>
                value.Name.includes(item)
              );
            });
            let defaultValues = AssetDefaultValueListing.filter((item) => {
              return response?.data?.getAssetType.some((value) =>
                value.Name.includes(item)
              );
            });
            setIsAssetTypeAvailable(checkAssetType);
            setAssetTypeNewValues(NotIncludeValues);
            setDefaultAssetType(defaultValues);
            // console.log(NotIncludeValues);
          }

          // console.log(defaultValues,"checkAssetType")
        });
        // console.log(response?.data?.stories,"response?.data?.stories")

        setLoading(false);
        setIsLocationLoad(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const [projectValue, setProjectValue] = React.useState();
  // Form values into spread objects
  const handleCheckboxChange = (selectedOptions, name) => {
    let result = selectedOptions.length < 1 ? null : selectedOptions;
    setAssetType(result);
  };
  const handleInputChange = (event) => {
    setImageURL(removeQueryParameters(event.target.value)); // Update the imageURL state when the input changes
  };

  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  const openProject = () => {
    let APIPath = `/details/${props?.allData?.ProjectDetailsId}`;
    window.open(APIPath, "_blank");
    // const win = window.open(APIPath, "_blank");
    // win.focus();
  };
  // Form Submit
  const onSumbitHandler = (e) => {
    const buttonName = e.nativeEvent.submitter?.name; // Get the name attribute of the clicked button
    e.preventDefault();
    setLoading(true);
    const obj = formValueConvertor(getFormJSON(e.target));

    let sourceData = {
      ProjectDetailsId: props?.allData?.ProjectDetailsId,
      title: title === "" ? props?.allData?.title : title,
      content: props?.allData?.content ? props?.allData?.content : null,
      userId: props?.allData?.UserId ? props?.allData?.UserId : null,
      cleanedUrl: obj.cleanedUrl,
      domain: props?.allData?.domain ? props?.allData?.domain : null,
      author: obj.author,
      authorMail: obj.authorMail,
      description: obj.description,
      authordesc: obj.authordesc,
      authImage: props?.allData?.authImage,
      contributor: obj.contributor,
      Time: props?.allData?.Time ? props?.allData?.Time : null,
      websitelogo: props?.allData?.websitelogo
        ? props?.websitelogo?.Time
        : null,
      address: obj.StreetAddress,
      StreetIntersections: obj.StreetIntersections,
      ParcelId: obj.ParcelId,
      City: obj?.City,
      State: obj?.State,
      ZipCode: obj.ZipCode,
      Latitude: isLocationAvailable
        ? props?.allData?.Latitude
          ? props?.allData?.Latitude
          : null
        : null,
      Longitude: isLocationAvailable
        ? props?.allData?.Longitude
          ? props?.allData?.Longitude
          : null
        : null,
      SourceId: props?.allData?.SourceId ? props?.allData?.SourceId : null,
      LocationId: props?.allData?.LocationId
        ? props?.allData?.LocationId
        : null,
      AssetTypeId: props?.allData?.AssetTypeId
        ? props?.allData?.AssetTypeId
        : null,
      AssetType: Array.isArray(assetType)
        ? assetType.length < 1
          ? props?.allData?.AssetType
          : assetType.toString()
        : assetType,
      Status: props?.allData?.Status ? props?.allData?.Status : null,
      IsPublish: props?.allData?.IsPublish ? props?.allData?.IsPublish : null,
      comment: props?.allData?.comment ? props?.allData?.comment : null,
      asset_status: props?.allData?.asset_status
        ? props?.allData?.asset_status
        : null,
      articlegallery: imageURL,
    };
    axiosInstance
      .put(STORIES, sourceData)
      .then((response) => {
        // let publishStories = response?.data.filter(e => e.IsPublish !=="Yes");
        axiosInstance
          .put(Update_SOURCE, {
            cleanedUrl: obj.cleanedUrl !== " " ? obj.cleanedUrl : null,
            weburl: obj.weburl !== " " ? obj.weburl : null,
            Time: props?.allData?.Time ? props?.allData?.Time : null,
            author: obj.author !== " " ? obj.author : null,
            SourceId: props?.allData?.SourceId
              ? props?.allData?.SourceId
              : null,
          })
          .then((response) => {
            // let publishStories = response?.data.filter(e => e.IsPublish !=="Yes");
            setLoading(false);
            showMessage(
              `Article Source Successfully Update`,
              "success",
              "#576d69",
              false,
              false,
              false
            );
            if (buttonName === "Update") {
              props.api();
              // history.push("/Articles");
              // history.push("/Articles");
            } else if (buttonName === "UpdateAndContinue") {
              GotoNextProject();
            }
          })
          .catch((error) => {
            setLoading(false);
            showMessage("Getting Error", "error", "red", false, false, false);
          });
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  // Publish Article
  const publishArticle = () => {
    Swal.fire({
      icon: "warning",
      title: title,
      confirmButtonText: "Publish",
      showCancelButton: true,
      confirmButtonColor: "#40514e",
      customClass: "Custom_Cancel",
      cancelButtonColor: "#d6e2e2",
      cancelButtonText: "<span style='color:black'>Cancel</span>",
      hideClass: {
        popup: "", // disable popup fade-out animation
      }, // eslint-disable-next-line
      customClass: {
        loader: "custom-loader",
        popup: "bg-swal",
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // console.log(result, "result");
        // Publish event
        let DATA = {
          ProjectDetailsId: props?.allData?.ProjectDetailsId,
          Status: "Accept & Publish",
          comment: null,
          IsPublish: "Yes",
        };
        axiosInstance
          .put(PUBLISH, DATA)
          .then((response) => {
            setTimeout(() => {
              showMessage(
                "Article successfully publish",
                "success",
                "#576d69",
                false,
                false,
                false
              );
            }, 2500);
          })
          .catch((error) => {
            setTimeout(() => {
              showMessage(
                error?.response?.data?.error,
                "error",
                "red",
                false,
                false,
                false
              );
            }, 2500);
          });
      }
    });
  };
  // Jump to next project
  const GotoNextProject = (e) => {
    // console.log("here")
    const queryParams = {
      status: filterCategory?.status === "All" ? "" : filterCategory?.status,
      filter: filterCategory?.search,
    };
    const queryParamsArray = Object.entries(queryParams)
      .filter(([key, value]) => value !== "") // Filter out empty values
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`);
    axiosInstance
      .post(
        `${NEXTARTICLE}?limit=10&page=1&flag=1&sortColumn=created_at&sortOrder=desc&ProjectDetailsId=${
          props?.allData?.ProjectDetailsId
        }${
          filterCategory?.assetType?.length > 0
            ? "&assetType=" + ArrayToString(filterCategory?.assetType)
            : ""
        }${
          filterCategory !== undefined &&
          queryParamsArray &&
          "&" + queryParamsArray.join("&")
        }`
      )
      .then((response) => {
        if (response?.data?.message === "No next story found") {
          setTimeout(() => {
            showMessage(
              response?.data?.message,
              "error",
              "red",
              false,
              false,
              false
            );
          }, 2500);
        } else if (response?.data?.nextStoryId) {
          history.push({
            pathname: `/Article/Update/${response?.data?.nextStoryId}`,
          });
          localStorage.setItem("rowId", response?.data?.nextStoryId);
          props.api();
          props.setLoading(true);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          showMessage(
            error?.response?.data?.message,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
    // console.log(articleIds,"articleIds")
    // console.log(targetIndex,"targetIndex")
    // console.log(nextIndex?.ProjectDetailsId,"targetIndex")

    // localStorage.setItem("project","")
  };

  // Update Location
  // Form Submit
  const UpdateLocationFn = (e) => {
    e.preventDefault();
    setIsLocationLoad(true);
    let locationData = {
      ProjectDetailsId: props?.allData?.ProjectDetailsId,
      address: cordinates.streetNumber,
      StreetIntersections: cordinates.streetIntersections,
      City: cordinates.city,
      ZipCode: cordinates.zipCode,
      LocationId: props?.allData?.LocationId,
      Latitude: isLocationAvailable ? cordinates.latitude : null,
      Longitude: isLocationAvailable ? cordinates.longitude : null,
      State: cordinates?.state,
      // Api datas
      Status: props?.allData?.Status,
      ParcelId: props?.allData?.ParcelId,
      SourceId: props?.allData?.SourceId,
      title: props?.allData?.title,
      content: props?.allData?.content,
      userId: props?.allData?.UserId,
      cleanedUrl: props?.allData?.cleanedUrl,
      domain: props?.allData?.domain,
      author: props?.allData?.author,
      authorMail: props?.allData?.authorMail,
      description: props?.allData?.description,
      authordesc: props?.allData?.authordesc,
      authImage: props?.allData?.authImage,
      contributor: props?.allData?.contributor,
      Time: props?.allData?.Time,
      websitelogo: props?.allData?.websitelogo,
      AssetTypeId: props?.allData?.AssetTypeId,
      AssetType: Array.isArray(assetType)
        ? assetType.length < 1
          ? props?.allData?.AssetType
          : assetType.toString()
        : assetType,
      IsPublish: props?.allData?.IsPublish,
      comment: props?.allData?.comment,
      asset_status: props?.allData?.asset_statusl,
      articlegallery: imageURL,
    };
    axiosInstance
      .put(STORIES, locationData)
      .then(() => {
        // let publishStories = response?.data.filter(e => e.IsPublish !=="Yes");
        showMessage(
          `Location updated successfully`,
          "success",
          "#576d69",
          false,
          false,
          false
        );
        setTimeout(() => {
          props.api();
        }, 2500);
        setIsLocationLoad(false);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    setOpen(!open);
    setResponsiveOpen(!open);
  };

  // Handle location
  const handleLocationAvailable = (nextChecked) => {
    // console.log(nextChecked);
    setIsLocationAvailable(nextChecked);
  };

  return (
    <div className="flex justify-center w-full  pb-4   z-0">
      <Card className="p-2  lg:m-0 w-full border-t-2 flex flex-col gap-4">
        {/* Article Title */}
        <form onSubmit={onSumbitHandler} className="flex  flex-col gap-2">
          <section className="  flex md:flex-row flex-col gap-2 ">
            {/* Map Section */}
            <div className="hidden md:block">
              {!isLocationLoad ? (
                <SingleMarkerMap
                  style={{ width: 160, height: 160, borderRadius: "10px" }}
                  longitude={props?.allData?.Longitude}
                  latitude={props?.allData?.Latitude}
                  noControls
                  interactive={false}
                  public_hearings={props?.allData?.public_hearings}
                />
              ) : (
                <DotSpinner label="" />
              )}{" "}
              <div className="absolute top-5 z-50  left-36 bg-white">
                <Tooltip
                  placement="top"
                  className={TooltipStlye}
                  content="Update map"
                >
                  <PencilSquareIcon
                    onClick={handleOpen}
                    // onClick={onEditEvent}
                    className="w-5 cursor-pointer text-primary-primary"
                  />
                </Tooltip>
                <Dialog size="xl" open={open} handler={handleOpen}>
                  <DialogBody>
                    {/* {Apidata?.project && ( */}
                    <MarkerLocationChange
                      cordinates={{
                        longitude: props?.allData?.Longitude,
                        latitude: props?.allData?.Latitude,
                      }}
                      setCordinates={setCordinates}
                    />
                    {/* )} */}
                  </DialogBody>
                  <DialogFooter className="flex justify-center ">
                    <Button
                      variant="text"
                      color="red"
                      onClick={handleOpen}
                      className="mr-1"
                    >
                      <span>Cancel</span>
                    </Button>
                    <Button
                      className="bg-primary-primary  text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                      onClick={(e) => UpdateLocationFn(e)}
                    >
                      <span>Update</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              </div>
            </div>
            <div className="md:hidden block">
              <SingleMarkerMap
                style={{ width: 160, height: 160, borderRadius: "10px" }}
                longitude={props?.allData?.Longitude}
                latitude={props?.allData?.Latitude}
                noControls
                interactive={false}
                public_hearings={props?.allData?.public_hearings}
              />{" "}
              <div className="absolute top-5 z-50 left-36 bg-white">
                <Tooltip
                  placement="top"
                  className={TooltipStlye}
                  content="Update map"
                >
                  <PencilSquareIcon
                    onClick={handleOpen}
                    // onClick={onEditEvent}
                    className="w-5 cursor-pointer text-primary-primary"
                  />
                </Tooltip>
                <Dialog open={responsiveOpen} handler={handleOpen}>
                  <DialogBody>
                    <p className="text-xs text-gray-500">
                      Drag Marker to change location
                    </p>
                    {props?.allData && (
                      <DragableMarker
                        cordinates={{
                          longitude: props?.allData?.Longitude,
                          latitude: props?.allData?.Latitude,
                        }}
                        setCordinates={setCordinates}
                      />
                    )}
                  </DialogBody>
                  <DialogFooter className="flex justify-center">
                    <Button
                      variant="text"
                      color="red"
                      onClick={handleOpenResponsive}
                      className="mr-1"
                    >
                      <span>Cancel</span>
                    </Button>
                    <Button
                      className="bg-primary-primary  text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                      onClick={(e) => UpdateLocationFn(e)}
                    >
                      <span>Update</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              </div>
            </div>
            <div className="w-full">
              {/* Action Buttons */}
              <div className="w-full flex flex-row justify-between items-center">
                <div className="flex flex-col ">
                  <span className="text-xs text-gray-500">Location</span>
                  <label htmlFor="location-switch">
                    <Switch
                      checked={isLocationAvailable}
                      onChange={handleLocationAvailable}
                      onColor="#667471"
                      onHandleColor="#eceeed"
                      offColor="#d6e2e2"
                      offHandleColor="#f4f7f7"
                      handleDiameter={15}
                      borderRadius={6}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 10)"
                      height={20}
                      width={48}
                      className="react-switch "
                      id="location-switch"
                    />
                  </label>
                </div>
                <div className=" flex flex-row gap-2">
                  {props?.allData?.Status !== "Accept" &&
                    props?.allData?.Status !== "Publish" &&
                    props?.allData?.Status !== "Accept & Publish" && (
                      <Button
                        variant="outlined"
                        color="green"
                        type="button"
                        size="sm"
                        className="hover:outline-none hover:ring-0 focus:right-0 mt-2 shadow-none hover:shadow-lg"
                        onClick={publishArticle}
                      >
                        Publish
                      </Button>
                    )}
                  <Button
                    value="source"
                    variant="filled"
                    type="submit"
                    name="Update"
                    size="sm"
                    className=" bg-primary-primary mt-2 text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                  >
                    Update
                  </Button>
                  <Button
                    value="source"
                    variant="outlined"
                    type="button"
                    size="sm"
                    className="hover:outline-none hover:ring-0 focus:right-0 mt-2 text-primary-primary shadow-none hover:shadow-lg hover:shadow-primary-primary/40 border-primary-primary"
                    onClick={openProject}
                  >
                    View user side
                  </Button>

                  <Button
                    value="source"
                    variant="outlined"
                    type="button"
                    size="sm"
                    onClick={GotoNextProject}
                    name="UpdateAndContinue"
                    className="w-max bg-primary-light  text-primary-primary  border-primary-primary shadow-none hover:shadow-lg hover:shadow-primary-primary/40 mt-2 focus:ring-0 ocus:outline-0"
                  >
                    Next
                  </Button>
                </div>
              </div>
              {/* Article title */}
              <div>
                <p className="flex flex-row text-gray-500 text-xs font-medium">
                  Article Title
                </p>
                <textarea
                  id="title"
                  name="title"
                  rows={3}
                  onChange={(e) => setTitle(e.target.value)}
                  defaultValue={props?.allData?.title}
                  className={`mt-1 peer w-full px-3 py-2 z-0   placeholder-gray-500  border border-gray-300 rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
                />
              </div>
            </div>
          </section>
          <div className="">
            <p className="font-semibold ml-2 ">
              <span className="font-semibold text-sm  underline">
                Project or Asset Type
              </span>{" "}
              <span className="text-red-500">*</span>
              {assetTypeNewValues && ": " + assetTypeNewValues}{" "}
            </p>
            {isLocationLoad ? (
              <DotSpinner label="" />
            ) : (
              <ArticleAssetType
                name="Assets"
                options={assetOption}
                defaultChecked={defaultAssetType ? defaultAssetType : []}
                onChange={handleCheckboxChange}
              />
            )}
            <div onSubmit={onSumbitHandler} className="flex  flex-col gap-2">
              <section className="flex md:flex-row flex-col 2xl:gap-5 gap-2">
                <div className="md:w-2/5">
                  {/* Article website URL */}
                  <InputField
                    name="weburl"
                    type="text"
                    id="weburl"
                    defaultValue={props?.allData?.weburl}
                    label="Article Website URL"
                  />
                </div>
                <div className="md:w-1/2">
                  {/* Article URL */}
                  <InputField
                    name="cleanedUrl"
                    type="text"
                    id="cleanedUrl"
                    defaultValue={props?.allData?.cleanedUrl}
                    label="Article URL"
                  />
                </div>
              </section>

              {/* Article Image */}
              {/* <div>
              {" "}
              <p className="flex flex-row text-gray-500 text-xs font-medium">
                Article Image
              </p>
              <UpdateImage
                name="articlegallery"
                existingImages={props?.allData?.articlegallery}
                articlegallery={articlegallery}
                setArticlegallery={setArticlegallery}
              />
            </div> */}
            </div>
          </div>
          {/* Street Address */}
          {!isLocationLoad ? (
            <div className="flex md:flex-row flex-col md:gap-3 gap-3 flex-wrap">
              {/* Parcel Id*/}
              <InputField
                name="ParcelId"
                type="text"
                id="ParcelId"
                defaultValue={props?.allData?.ParcelId}
                label="Parcel Id"
                disable={!isLocationAvailable}
              />
              <div className=" md:w-80">
                <InputField
                  name="StreetAddress"
                  type="text"
                  id="StreetAddress"
                  defaultValue={props?.allData?.StreetAddress}
                  label="Street Address"
                  disable={!isLocationAvailable}
                />
              </div>
              <div className=" md:w-80">
                {/* Street Intersections */}
                <InputField
                  name="StreetIntersections"
                  type="text"
                  id="StreetIntersections"
                  defaultValue={props?.allData?.StreetIntersections}
                  label="Street Intersections"
                  disable={!isLocationAvailable}
                />
              </div>

              <div className=" md:w-48">
                {/* City */}
                <InputField
                  name="City"
                  type="text"
                  id="City"
                  defaultValue={props?.allData?.City}
                  label="City"
                  disable={!isLocationAvailable}
                />
              </div>

              <div className=" md:w-48">
                {/* State */}
                <InputField
                  name="State"
                  type="text"
                  id="State"
                  defaultValue={props?.allData?.State}
                  label="State"
                  disable={!isLocationAvailable}
                />
              </div>
              <div className=" md:w-24 ">
                {/* ZipCode */}
                <InputField
                  name="ZipCode"
                  type="ZipCode"
                  id="ZipCode"
                  defaultValue={props?.allData?.ZipCode}
                  label="Zip Code"
                  disable={!isLocationAvailable}
                />
              </div>
            </div>
          ) : (
            <DotSpinner label="" />
          )}

          {/* Article photo description */}
          <TextField
            name="description"
            rows={4}
            type="text"
            id="description"
            defaultValue={props?.allData?.description}
            label="Article Description"
          />
          {/* Article description */}

          <div className="md:flex-row flex flex-col md:gap-5 gap-2">
            <div className="md:w-80">
              {/* Author Name */}
              <InputField
                name="author"
                type="text"
                id="author"
                defaultValue={props?.allData?.author}
                label="Author Name"
              />
            </div>
            <div className="2xl:w-2/5 md:w-full">
              {/* Author Email */}
              <InputField
                name="authorMail"
                type="text"
                id="authorMail"
                defaultValue={props?.allData?.authorMail}
                label="Author Email"
              />
            </div>
          </div>
          {/* Author Image */}
          <div>
            {" "}
            {/* <p className="flex flex-row text-gray-500 text-xs font-medium">
                Author Image
              </p>
              <UpdateImage
                name="authImage"
                existingImages={props?.allData?.authImage}
                authImage={authImage}
                setAuthImage={setAuthImage}
              /> */}
            {/* Author Bio */}
            <TextField
              name="authordesc"
              type="text"
              rows={8}
              id="authordesc"
              defaultValue={props?.allData?.authordesc}
              label="Author Bio"
            />
          </div>
          {/* Contributor */}
          <div className="md:w-96	">
            <InputField
              name="contributor"
              type="text"
              id="contributor"
              defaultValue={props?.allData?.contributor}
              label="Contributor"
            />
          </div>

          <div className="flex gap-2 md:gap-2 lg:flex-row flex-col md:mt-5 ">
            <div className="md:w-80">
              {/* Display Image */}
              {imageURL ? (
                <img
                  src={imageURL}
                  alt=""
                  className="rounded-full w-72 h-72 border-2  flex items-center justify-center"
                />
              ) : (
                <img
                  src="your-fallback-image-url.png" // Replace with your actual fallback image URL
                  alt=""
                  className="rounded-full w-72 h-72 border-2   flex items-center justify-center"
                />
              )}
            </div>

            <div className=" flex flex-col md:gap-5 gap-2 md:w-4/5 ">
              {/* Article Image URL */}
              <InputField
                name="articlegallery"
                type="text"
                id="articlegallery"
                defaultValue={removeQueryParameters(
                  props?.allData?.articlegallery,
                  props?.allData?.weburl
                )}
                label="Article Image URL"
                onChange={handleInputChange}
              />
              <TextField
                rows={5}
                name="articlegallerydesc"
                type="text"
                id="articlegallerydesc"
                defaultValue={props?.allData?.articlegallerydesc}
                label="Article Photo Description"
              />
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

// else if (btnValue === "location") {
//   let locationData = {
//     StreetAddress: obj.StreetAddress !== " " ? obj.StreetAddress : null,
//     StreetIntersections:
//       obj.StreetIntersections !== " " ? obj.StreetIntersectionsnull : null,
//     ParcelId:
//       obj.StreetIntersections !== " " ? obj.StreetIntersections : null,
//     City: obj.City !== " " ? obj?.City : null,
//     ZipCode: obj.ZipCode !== " " ? obj?.ZipCode : null,
//     LocationId: props?.allData?.LocationId
//       ? props?.allData?.LocationId
//       : null,
//   };
//   axiosInstance
//     .put(Update_LOCATION, locationData)
//     .then((response) => {
//       // let publishStories = response?.data.filter(e => e.IsPublish !=="Yes");
//       setLoading(false);
//       showMessage(
//         `Article Location Successfully Update`,
//         "success",
//         "#576d69",
//         false,
//         false,
//         false
//       );
//     })
//     .catch((error) => {
//       setTimeout(() => {
//         setLoading(false);
//       });
//       setTimeout(() => {
//         showMessage(
//           error?.response?.data?.error,
//           "error",
//           "red",
//           false,
//           false,
//           false
//         );
//       });
//     });
// }
// {
/* {location?.type === "Project" ?
         <>
          {Project.map(({ label, value, desc, icon }, i) => (
            <Accordion
              key="label"
              open={open === i}
              icon={<Icon id={i} open={open} className="  rounded-lg mb-2" />}
            >
              <AccordionHeader
                onClick={() => handleOpen(i)}
                className={` rounded-lg px-2 py-1 text-md border-2 transition-colors ${
                  open === i ? "border-b-0 rounded-b-none " : ""
                }`}
              >
                {label}
              </AccordionHeader>
              <AccordionBody
                className={` rounded-lg p-1 border-2 transition-colors ${
                  open === i
                    ? "border-t-0 rounded-t-none "
                    : "border-b-0 rounded-b-none"
                }`}
              >
                {desc}
              </AccordionBody>
            </Accordion>
          ))}</>:
         <>
          {Article.map(({ label, value, desc, icon }, i) => (
            <Accordion
              key="label"
              open={open === i}
              icon={<Icon id={i} open={open} className="  rounded-lg mb-2" />}
            >
              <AccordionHeader
                onClick={() => handleOpen(i)}
                className={` rounded-lg px-2 py-1 text-md border-2 transition-colors ${
                  open === i ? "border-b-0 rounded-b-none " : ""
                }`}
              >
                {label}
              </AccordionHeader>
              <AccordionBody
                className={` rounded-lg p-1 border-2 transition-colors ${
                  open === i
                    ? "border-t-0 rounded-t-none "
                    : "border-b-0 rounded-b-none"
                }`}
              >
                {desc}
              </AccordionBody>
            </Accordion>
          ))} 
          </>
         } */
// }
// {
/* <Accordion open={open === 1} icon={<Icon id={1} open={open}         
      className="  rounded-lg mb-2"
      />}>
        <AccordionHeader onClick={() => handleOpen(1)} className={` rounded-lg px-2 py-1 text-md border-2 transition-colors ${
          open === 1 ? "border-b-0 rounded-b-none " : ""
        }`}>
          Source
        </AccordionHeader>
        <AccordionBody className={` rounded-lg p-1 border-2 transition-colors ${
          open === 1 ? "border-t-0 rounded-t-none " : "border-b-0 rounded-b-none"
        }`}>
          1
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 2} icon={<Icon id={2} open={open} />}
       className="  rounded-lg mb-2">
        <AccordionHeader className={` rounded-lg p-1 border-2 text-md transition-colors ${
          open === 2 ? "border-b-0 rounded-b-none " : ""
        }`} onClick={() => handleOpen(2)}>
          Location
        </AccordionHeader>
        <AccordionBody className={` rounded-lg p-1 border-2 transition-colors ${
          open === 2 ? "border-t-0 rounded-t-none " : "border-b-0 rounded-b-none"
        }`}>
          2
        </AccordionBody>
      </Accordion> */
// }
