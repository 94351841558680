import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import NewHomePageOutline from "../../components/layouts/NewHomePageOutline";

export default function Home() {
  const location = useLocation();

  useEffect(() => {
  }, [location]);
  return (
    <>
      {/* {location.pathname === "/home" && <HomePageOutline APItype="Article" lable="Stories"  followed AssetFillter timeFillter/>} */}
      {location.pathname === "/projectList" && <NewHomePageOutline noImage APItype="Project" lable="Projects"  AssetFillter  timeFillter/>}
      {location.pathname === "/favourites" && <NewHomePageOutline APItype="Favourite" lable="Favorite Stories"  AssetFillter  timeFillter/>}
      {/* {location.pathname === "/followed" && <HomePageOutline APItype="Followed" lable="Followed Stories" />}
      {location.pathname.includes("/citiesNews") && <HomePageOutline APItype="Cities" lable="Stories" cityNav AssetFillter timeFillter/>} */}
    </>
  );
}
