import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-tailwind/react";
import { TooltipStlyeForData } from "../../utils/Constant";
import Table from "../functionality/table/Table";
import ActionCol from "../functionality/table/ActionCol";
import { useHistory } from "react-router-dom";
import { STORIES } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../ui/showMessage";
import Loader from "../ui/Loader";
import moment from "moment";
import StatusChip from "../functionality/StatusChip";
import { ArrayToString } from "../../store/helper/Helper";
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/24/outline";
import { useLastLocation } from "react-router-last-location";
import { useRef } from "react";

export default function ArchivesDB() {
  let history = useHistory();
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showRecord, setShowRecord] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();
  const [selectedOptions, setSelectedOptions] = useState("All");
  const [assetFillter, setAssetFillter] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [isShort, setIsShort] = useState(true);
  const lastLocation = useLastLocation();
  const [isFilterAvailable, setIsFilterAvailable] = useState(false);
  const prevDependency = useRef(isFilterAvailable);
  useEffect(() => {
    setIsFilterAvailable(false)
  }, []);
  useEffect(() => {
    setIsFilterAvailable(!isFilterAvailable); // eslint-disable-next-line
  }, [selectedOptions, setSelectedOptions, assetFillter, globalFilter]);
  useEffect(() => {
    if (prevDependency.current !== isFilterAvailable) {
      // Dependency changed from the previous value
      if (isFilterAvailable === true) {
        // Do something when dependency becomes true
        setPageNo(1);
        GET_STORIES(); // eslint-disable-next-line
        // console.log('Dependency changed to true');
      } else {
        localStorage.setItem("component", "Article Archived");
        localStorage.removeItem("rowId");
        if (!lastLocation?.pathname?.includes("/Article/Update")) {
          localStorage.removeItem("lastVisitedRow");
        } 
        GET_STORIES(); // eslint-disable-next-line
        // Do something when dependency becomes false
        // console.log('Dependency changed to false');
      }
      prevDependency.current = isFilterAvailable; // Update the previous value
    } // eslint-disable-next-line
  }, [isFilterAvailable]);
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    localStorage.setItem("component", "Article Archived");
    GET_STORIES(); // eslint-disable-next-line
    if (!lastLocation?.pathname?.includes("/Article/Update")) {
      localStorage.removeItem("lastVisitedRow");
    } else {
      setShowRecord(localStorage.getItem("showRecords"));
      setCurrentPage(localStorage.getItem("setPageNo"));
      setPageNo(localStorage.getItem("setPageNo"));
    } // eslint-disable-next-line
  }, [showRecord, pageNo, isShort]);

  useEffect(() => {
    localStorage.setItem("component", "Article Archived");
    localStorage.removeItem("rowId");
    if (!lastLocation?.pathname?.includes("/Article/Update")) {
      localStorage.removeItem("lastVisitedRow");
    } else {
      setShowRecord(localStorage.getItem("showRecords"));
      setCurrentPage(localStorage.getItem("setPageNo"));
      setPageNo(localStorage.getItem("setPageNo"));
    } // eslint-disable-next-line
  }, [history]);
  // Get API
  const GET_STORIES = () => {
    axiosInstance
      .get(
        `${STORIES}?limit=${showRecord !== null ? showRecord : 10}&status=${
          selectedOptions === "All" ? "" : selectedOptions
        }&isReview=&isPublish=No&assetType=${
          assetFillter.length > 0 ? ArrayToString(assetFillter) : ""
        }&page=${pageNo}&flag=0&filter=${
          globalFilter == null ? "" : globalFilter
        }&sort=${isShort && `new`}`
      )
      .then((response) => {
        setData(response?.data?.stories);
        setTotalRecord(response?.data?.pagination?.totalStories);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        setTotalRecord(1);
        setCurrentPage(1);
        setTotalPage(1);
        setData([]);
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.message,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  const columns = [
    {
      Header: "Article Title",
      accessor: "article_title",
      Cell: ({ value, row }) => {
        return (
          <Tooltip
            style={{ maxWidth: "350px" }}
            placement="top-start"
            offset={15}
            className={TooltipStlyeForData}
            content={value}
          >
            <p
              className="line-clamp-1 cursor-pointer text-left	ml-4"
              onClick={(e) => handleClick(e, row)}
            >
              {value}
            </p>
          </Tooltip>
        );
      },
      headerStyle: { textAlign: "center" },
      minWidth: 350,
      disableSortBy: true,
    },
    {
      Header: (
        <div className="flex flex-row gap-2">
          <span>Date</span>
          {isShort ? (
            <ArrowLongUpIcon
              strokeWidth={2}
              className="h-4 w-4 cursor-pointer"
              onClick={() => setIsShort(false)}
            />
          ) : (
            <ArrowLongDownIcon
              strokeWidth={2}
              className="h-4 w-4 cursor-pointer"
              onClick={() => setIsShort(true)}
            />
          )}
        </div>
      ),
      accessor: "created_at",
      headerStyle: { textAlign: "center" },
      Cell: ({ cell: { value } }) => (
        <span>
          {value
            ? moment(value, "YYYY-MM-DD hh:mm:ss").format("MM-DD-YYYY")
            : "--"}
        </span>
      ),
      disableSortBy: true,
    },
    {
      Header: "Project Name",
      accessor: "projectName",
      Cell: ({ cell: { value } }) => (
        <span className="capitalize line-clamp-1">{value ? value : "--"}</span>
      ),
    },
    // {
    //   Header: "Time",
    //   accessor: "",
    //   Cell: ({ cell: { value } }) => {
    //     if (value === undefined) {
    //       return "NA";
    //     } else {
    //       return value;
    //     }
    //   },
    // },
    {
      Header: "Location",
      accessor: "City",
      headerStyle: { textAlign: "right" },
      Cell: ({ cell: { value } }) => (
        <span className="capitalize ">{value ? value : "--"}</span>
      ),
      disableSortBy: true,
    },
    {
      Header: () => (
        <span className="flex justify-start text-left">Source</span>
      ),
      accessor: "weburl",
      headerStyle: { textAlign: "left" },
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <span className="line-clamp-1 cursor-pointer text-center	mx-4">
          {value ? (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "rgb(87 109 105)" }} // Change 'blue' to your desired color
            >
              {value.trim()}
            </a>
          ) : (
            "--"
          )}
        </span>
      ),

      minWidth: 50,
      maxWidth: 50,
    },
    {
      Header: "Asset Type",
      accessor: "AssetType",
      Cell: ({ cell: { value } }) => (
        <span className="capitalize ">{value ? value : "--"}</span>
      ),
      disableSortBy: true,
    },
    {
      Header: "Status",
      accessor: "Status",
      Cell: ({ cell: { value } }) => <StatusChip values={value} />,
      disableSortBy: true,
    },
    {
      Header: "Comment",
      accessor: "comment",
      Cell: ({ cell: { value } }) => {
        if (value === null || value === "null") {
          return "--";
        } else {
          return value;
        }
      },
      width: 50,
      disableSortBy: true,
    },
    {
      id: "action",
      accessor: "",
      disableSortBy: true,
      Cell: (row) => (
        <ActionCol
          deletedStories
          jumpToArticle
          isDelete
          isRestore
          data={row.row.original}
          GET_API={GET_STORIES}
        />
      ),
    },
  ];

  // Handle on title click
  const handleClick = (e, row) => {
    // console.log(row);
    localStorage.setItem(
      "rowId",
      JSON.stringify(row?.original?.ProjectDetailsId)
    );
    localStorage.setItem("lastVisitedRow", row.id);

    history.push({
      pathname: `/Article/Update/${row?.original?.ProjectDetailsId}`,
      data: row,
    });
  };
  return (
    <div>
      <Loader loading={loading} />
      {!loading && (
        <Table
          setPageNo={setPageNo}
          pageNo={pageNo}
          showRecord={showRecord}
          setShowRecord={setShowRecord}
          totalRecord={totalRecord}
          currentPage={currentPage}
          totalPage={totalPage}
          columns={columns}
          data={data}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          statusFillter
          assetFillter={assetFillter}
          setAssetFillter={setAssetFillter}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
      )}
    </div>
  );
}
