import React, { createContext } from "react";
import { useState } from "react";

const ScrollToIndexContext = createContext({});

export const ScrollToIndexProvider = ({ children }) => {
  const [scrollToIndex,setScrollToIndex] =  useState({ arrayOfFilters: [], });
// console.log(scrollToIndex)
  return (
    <ScrollToIndexContext.Provider value={{ scrollToIndex, setScrollToIndex}}>
      {children}
    </ScrollToIndexContext.Provider>
  );
};

export default ScrollToIndexContext;
