export const STATES = [
  {
    label: "Alabama",
    value: "AL",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Alaska",
    value: "AK",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "American Samoa",
    value: "AS",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Arizona",
    value: "AZ",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Arkansas",
    value: "AR",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "California",
    value: "CA",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Colorado",
    value: "CO",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Connecticut",
    value: "CT",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Delaware",
    value: "DE",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "District Of Columbia",
    value: "DC",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Florida",
    value: "FL",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Georgia",
    value: "GA",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Guam",
    value: "GU",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Hawaii",
    value: "HI",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Idaho",
    value: "ID",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Illinois",
    value: "IL",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Indiana",
    value: "IN",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Iowa",
    value: "IA",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Kansas",
    value: "KS",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Kentucky",
    value: "KY",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Louisiana",
    value: "LA",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Maine",
    value: "ME",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Marshall Islands",
    value: "MH",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Maryland",
    value: "MD",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Massachusetts",
    value: "MA",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Michigan",
    value: "MI",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Minnesota",
    value: "MN",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Mississippi",
    value: "MS",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Missouri",
    value: "MO",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Montana",
    value: "MT",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Nebraska",
    value: "NE",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Nevada",
    value: "NV",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "New Hampshire",
    value: "NH",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "New Jersey",
    value: "NJ",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "New Mexico",
    value: "NM",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "New York",
    value: "NY",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "North Carolina",
    value: "NC",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "North Dakota",
    value: "ND",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Ohio",
    value: "OH",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Oklahoma",
    value: "OK",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Oregon",
    value: "OR",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Palau",
    value: "PW",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Pennsylvania",
    value: "PA",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Puerto Rico",
    value: "PR",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Rhode Island",
    value: "RI",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "South Carolina",
    value: "SC",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "South Dakota",
    value: "SD",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Tennessee",
    value: "TN",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Texas",
    value: "TX",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Utah",
    value: "UT",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Vermont",
    value: "VT",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Virgin Islands",
    value: "VI",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Virginia",
    value: "VA",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Washington",
    value: "WA",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "West Virginia",
    value: "WV",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Wisconsin",
    value: "WI",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    label: "Wyoming",
    value: "WY",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];
