import { Redirect } from "react-router-dom";

// import useAuth from "../../hooks/useAuth";


const RequireAuth = ({ allowedRoles,component,...rest  }) => {
  const Component = component;
  const details = localStorage.getItem("authDetails")
  let DetailArray = JSON.parse(details)

  return DetailArray?.roles?.find(role => allowedRoles?.includes(role)) && DetailArray?.Token ? (
    <Component {...rest}  />
  ) : (
    <Redirect to="/unauthorized" />
  );
};

export default RequireAuth;
