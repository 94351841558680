import { Button } from "@material-tailwind/react";
import * as React from "react";
import { MAP_SATELLITE_STREETS, MAP_STREETS } from "../../../utils/Constant";
import Street12 from "../../../assets/Images/Street12.png";
import Satellite from "../../../assets/Images/Satellite.png";

function MapStyle(props) {
  return (
    <div
      className="md:absolute fixed md:bottom-5 xs:bottom-14 sm:bottom-24 bottom-16   right-2 mt-2 md:z-75 z-25"

    >
      <div className="flex flex-col justify-center gap-2">
        {/* MAP_SATELLITE_STREETS */}

        <Button
          size="sm"
          onClick={() => {
            props?.setShowpopup(false);
            props?.setMapStyle(MAP_STREETS);
          }}
          color="white"
          className={`flex flex-col gap-2  p-1 text-xs rounded-md items-center ${
            props.mapStyle === MAP_STREETS
              ? "bg-primary-hover text-white"
              : "bg-white text-black"
          }`}
        >
          <img
            alt="mapstyle"
            src={Street12}
            className="w-10 h-10 rounded-full"
          />
          <p>
            Street <br /> View
          </p>{" "}
        </Button>
        <Button
          onClick={() => {
            props?.setShowpopup(false);
            props?.setMapStyle(MAP_SATELLITE_STREETS);
          }}
          color="white"
          size="sm"
          className={`flex flex-col gap-2 p-1 text-xs rounded-md items-center ${
            props?.mapStyle === MAP_SATELLITE_STREETS
              ? "bg-primary-hover text-white"
              : "bg-white text-black"
          }`}
        >
          <img
            alt="mapstyle"
            src={Satellite}
            className="w-10 h-10 rounded-full"
          />
          <p>
            Satellite <br /> View
          </p>{" "}
        </Button>
        {/* <SpeedDialAction  className="relative ">
            <img alt="mapstyle" src={DarkMap} onClick={()=>{props.setShowpopup(false); props?.setMapStyle(DARK)}} className="w-10 h-10 rounded-full" />
              <Typography {...labelProps}>Night Mode</Typography>
            </SpeedDialAction> */}
      </div>
    </div>
  );
}

export default React.memo(MapStyle);