import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton,
  Typography,
  Card,
  List,
  ListItem,
  Tooltip,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import foldermove from "../../assets/Images/foldermove.png";
import SearchField from "../ui/SearchField";
import { MOVEPROJECT,  PROJECTTITLES } from "../../services/APIGlobal";
import { useApiGETRequest } from "../../hooks/useApiHook";
import { showMessage } from "../ui/showMessage";
import axiosInstance from "../../services/APIInstance";

export default function MoveToAnotherProject({ data,GET_API,setProjectloading }) {
  const [open, setOpen] = React.useState(false);
  const [globalFilter, setGlobalFilter] = React.useState();
  const [showProjects, setShowProjects] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState(data?.project_title);
  const [projectId, setProjectId] = React.useState();
  const handleOpen = () => {
    setOpen((cur) => !cur);
    setGlobalFilter();
    setShowProjects(false);
  };
  const { Apidata, isLoaded } = useApiGETRequest(
    `${PROJECTTITLES}?flag=1${globalFilter ? `&searchQuery=${globalFilter}`: ""}&sortOrder=desc&sortColumn=created_at`
  );

  // MoveToAnotherProjectFn
  const MoveToAnotherProjectFn = () => {
    let project = {
      ProjectDetailsId: data?.ProjectDetailsId,
      projectId: projectId,
    };
    // let publishStories = response?.data.filter(e => e.IsPublish !=="Yes");
    axiosInstance
      .put(MOVEPROJECT, project)
      .then(() => {
        // let publishStories = response?.data.filter(e => e.IsPublish !=="Yes");
        showMessage(
          `successfully`,
          "success",
          "#576d69",
          false,
          false,
          false
        );
        GET_API()
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <div className="w-5 cursor-pointer">
      <Tooltip className="border shadow-lg bg-white text-black w-fit " content="Move to another Project">
        <img src={foldermove} onClick={handleOpen} alt="Move Article" />
        </Tooltip>
      </div>
      <Dialog size="md" className="w-72 2xl:h-2/5 md:h-5/6" open={open} handler={handleOpen}>
        <DialogHeader className="justify-between">
          <Typography variant="h5" color="blue-gray">
            Move to another Project
          </Typography>
          <IconButton
            className="text-primary-primary hover:bg-primary-light focus::bg-primary-light"
            size="sm"
            variant="text"
            onClick={handleOpen}
          >
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </DialogHeader>
        <DialogBody className="w-full flex flex-col gap-4 mt-0 pt-0">
          <Typography variant="h6" color="black">
            <span> Project Name: </span>
            {defaultValue}
          </Typography>
          <SearchField
            filter={globalFilter}
            setfilter={setGlobalFilter}
            label="Search Project by Name"
            handleClick={() => setShowProjects(true)}
          />
          {showProjects && (
            <Card className="w-full border-t">
              <List>
                {isLoaded ? (
                  <p> Loading...</p>
                ) : (
                  <div className="2xl:h-56 md:h-52 overflow-y-scroll">
                    {Apidata?.projects.length > 0 ? (
                      Apidata?.projects.map((project) => (
                        <>
                          <ListItem
                          ripple={false}
                          className={`${
                            defaultValue === project?.title ? "bg-primary-light" : " bg-transparent"
                          } hover:bg-white focus:bg-primary-light `}
                            key={project.projectId}
                            onClick={(e) => {
                              e.preventDefault();
                              setDefaultValue(project?.title);
                              setProjectId(project?.projectId)
                              setShowProjects(false)
                            }}
                          >
                            {project?.title}
                          </ListItem>
                        </>
                      ))
                    ) : (
                      <ListItem key="Nofound">No project found</ListItem>
                    )}
                  </div>
                )}
              </List>
            </Card>
          )}
        </DialogBody>
        <DialogFooter className="flex flex-row gap-4 justify-center absolute bottom-0 w-full">
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="bg-primary-primary  text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
            onClick={MoveToAnotherProjectFn}
          >
            <span>Update</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  );
}
