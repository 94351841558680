import React, { useState, useCallback } from "react";
import {
  FullscreenControl,
  GeolocateControl,
  Map,
  Marker,
  NavigationControl,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // Import styles for the geocoder;
import { MAP_SATELLITE_STREETS } from "../../../utils/Constant";
const TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; // Set your mapbox token here

function AddMarkerMap({ reverseGeocode, style, setFormErrors }) {
  const [marker, setMarker] = useState(null); // eslint-disable-next-line
  const mapRef = React.createRef(); // eslint-disable-next-line
  const [events, logEvents] = useState(); // eslint-disable-next-line

  // Function to initialize the geocoder control
  const initGeocoder = (map) => {
    mapRef.current = map;
    const geocoder = new MapboxGeocoder({
      accessToken: TOKEN, // Replace with your Mapbox Access Token
      mapboxgl: mapboxgl,
      marker: {
        color: "transparent",
        scale: "0",
      },
    });
    // Add the geocoder to the map
    map.addControl(geocoder);
    // Handle geocoder result event
    geocoder.on("result", (event) => {
      // Handle the geocoder search result here
      setMarker({
        latitude: event.result.center[1],
        longitude: event.result.center[0],
      });
      reverseGeocode(event.result.center[1], event.result.center[0]);
    });
  };
  const handleMapClick = (event) => {
    // Get the latitude and longitude of the clicked point
    // const { lngLat } = event;
    // const [longitude, latitude] = lngLat;
    if (setFormErrors) {
      setFormErrors({ marker: false });
    }
    setMarker({
      latitude: event.lngLat?.lat,
      longitude: event.lngLat?.lng,
    });
    reverseGeocode(event.lngLat?.lat, event.lngLat?.lng);

    // // Update the marker's location
    // setMarker({ latitude, longitude });
  };

  // Drag and drop
  const onMarkerDragStart = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragStart: event.lngLat }));
  }, []);
  const onMarkerDrag = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDrag: event.lngLat }));

    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  }, []);

  const onMarkerDragEnd = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragEnd: event.lngLat }));
    // console.log(event.lngLat, "onDragEnd");
    reverseGeocode(event.lngLat.lat, event.lngLat.lng);
    // setCordinates({ longitude: event.lngLat.lng, latitude: event.lngLat.lat });// eslint-disable-next-line
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Map
        initialViewState={{
          width: "100%",
          height: "75vh",
          latitude: 31.8868, // Initial latitude
          longitude: -97.0799, // Initial longitude
          zoom: 10, // Initial zoom level
        }}
        ref={mapRef}
        style={{ width: "100%", height: "75vh", ...style }}
        onClick={handleMapClick}
        mapStyle={MAP_SATELLITE_STREETS} // Replace with your desired map style
        mapboxApiAccessToken={TOKEN} // Replace with your Mapbox Access Token
        onLoad={() => initGeocoder(mapRef.current.getMap())} // Initialize the geocoder when the map loads
      >
        {marker && (
          <Marker
            latitude={marker.latitude}
            longitude={marker.longitude}
            offsetLeft={-20}
            offsetTop={-10}
            color="blue"
            draggable
            onDragStart={onMarkerDragStart}
            onDrag={onMarkerDrag}
            onDragEnd={onMarkerDragEnd}
          ></Marker>
        )}
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
      </Map>
    </div>
  );
}

export default AddMarkerMap;
