import React, { useContext } from "react";
import NavbarDashboard from "./header/NavbarDashboard";
import Sidebar from "./header/Sidebar";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import { useEffect } from "react";
import BrowserContext from "../../context/BrowserContext";

export function DashboardLayout({ children }) {
  const { auth } = useAuth();
  const [userRole, setUserRole] = useState("");
  // eslint-disable-next-line
  const isSafari = useContext(BrowserContext);

  useEffect(() => {
    const role = auth?.roles ? auth?.roles.toString() : "";
    setUserRole(role);
  }, [auth]);

  return (
    <div className="flex  h-screen w-full ">
      {/* <div className="sticky top-0 z-75 w-full bg-primary-primary">
          <NavbarDashboard />
        </div> */}
      <div>
        <Sidebar userRole={userRole} />
      </div>
      <div className="flex flex-col   h-screen w-full">
        <header className=" text-center border-b border-grey sticky z-100 ">
          <NavbarDashboard />
        </header>
        <main className="flex-grow overflow-y-scroll md:z-75 overflow-x-hidden ">
          <div className="h-full w-full ">{children}</div>
        </main>
      </div>
    </div>
  );
}
