import { Typography } from "@material-tailwind/react";
import React from "react";

export default function DotSpinner({label}) {
  return (
    <div className="flex flex-row items-end  animate-pulse gap-2 p-3 mx-4">
      {label &&<Typography variant="small" className="text-primary-primary">
        {label}
      </Typography>}
      <div className="flex items-center justify-center space-x-2 animate-pulse mb-1">
        <div className="w-2 h-2 bg-primary-primary rounded-full"></div>
        <div className="w-2 h-2 bg-primary-primary rounded-full"></div>
        <div className="w-2 h-2 bg-primary-primary rounded-full"></div>
      </div>
    </div>
  );
}
