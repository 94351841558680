import React, { useState, useEffect } from "react";
import { IconButton } from "@material-tailwind/react";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        // Left arrow
        navigate(-1);
      } else if (event.keyCode === 39) {
        // Right arrow
        navigate(1);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }; // eslint-disable-next-line
  }, [currentIndex]);

  const navigate = (direction) => {
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < images.length) {
      setCurrentIndex(newIndex);
      // Adjust image size to cover full screen
      const img = document.querySelector(".carousel-img");
      if (img) {
        img.style.maxHeight = "100vh";
        img.style.maxWidth = "100vw";
      }
    }
  };

  const canNavigatePrev = currentIndex > 0;
  const canNavigateNext = currentIndex < images.length - 1;

  return (
    <div
      style={{ position: "relative", height: "100vh" }}
      className="relative  lg:min-h-full max-h-96 lg:max-h-full"
    >
      <IconButton
        color="white"
        size="md"
        variant="outlined"
        onClick={(e) => {
          navigate(-1);
        }}
        disabled={!canNavigatePrev}
        className="!absolute !top-1/2 !left-4 border-2 bg-black/50 rounded-full "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          strokeWidth="3.1"
          stroke="white"
          viewBox="0 0 24 24"
          className="h-6 w-6"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          ></path>
        </svg>{" "}
      </IconButton>
      <img
        src={images[currentIndex]}
        className="min-h-full w-full  carousel-img carousel-img object-cover"
        alt={`projectImage ${currentIndex}`}
        style={{ maxHeight: "100%", minHeight: "100%" }}
      />
      <IconButton
        variant="outlined"
        color="white"
        className="!absolute !top-1/2 !right-4 rounded-full border-2 bg-black/50 "
        size="md"
        onClick={(e) => {
          navigate(1);
        }}
        disabled={!canNavigateNext}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          strokeWidth="3.1"
          stroke="white"
          viewBox="0 0 24 24"
          className="h-6 w-6"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </IconButton>
    </div>
  );
};

export default ImageCarousel;
