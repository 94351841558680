/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import React from "react";

const PublicHearings = ({ meetings, setMeetings }) => {
  // console.log(meetings,"meetings")
  const handleInputChange = (index, field, value) => {
    const updatedMeetings = [...meetings];
    updatedMeetings[index][field] = value;
    setMeetings(updatedMeetings);
  };
  const handleMeetingTypeClick = (url) => {
    if (url) {
      window.open(url, "_blank"); // Open the URL in a new tab
    }
  };

  // console.log(meetings,"meetings")
  return (
    <div className="gap-4 flex flex-col">
      {meetings.map((meeting, index) => (
        <div key={index} className="flex md:flex-row gap-4 flex-col">
          {/* <input
              className={`border border-gray-300 peer w-full md:w-1/2 px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
              type="text"
              placeholder={`Type for Meeting ${index + 1}`}
              value={meeting?.MeetingType || ""}
              onChange={(e) =>
                handleInputChange(index, "MeetingType", e.target.value)
              }
              onClick={(e)=>console.log("click")}
            /> */}

          <div className="relative md:w-1/2">
            <input
              type="text"
              placeholder="Type here..."
              className="border border-gray-300 peer w-full  px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm p-2  pr-10"
              defaultValue={meeting?.MarketingType || ""}
              onChange={(e) =>
                handleInputChange(index, "MarketingType", e.target.value)
              }
            />
            <a
              className="absolute right-2 top-3 text-primary-primary   rounded"
              onClick={() => handleMeetingTypeClick(meeting?.UrlLink)}
            >
              <ArrowUpRightIcon
                strokeWidth={2}
                className="h-4 w-4 cursor-pointer"
              />
            </a>
          </div>
          <input
            className={`border border-gray-300 peer w-full md:w-1/2 px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
            type="text"
            placeholder={`Status for Meeting ${index + 1}`}
            defaultValue={meeting?.status || ""}
            onChange={(e) => handleInputChange(index, "status", e.target.value)}
          />
          <input
            className={`border border-gray-300 peer w-full md:w-1/2 px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
            type="date"
            placeholder={`Date for Meeting ${index + 1}`}
            defaultValue={meeting?.MeetingDate || ""}
            onChange={(e) =>
              handleInputChange(index, "MeetingDate", e.target.value)
            }
          />
          <input
            className={`border border-gray-300 peer w-full md:w-1/2 px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
            type="url"
            placeholder={`URL for Meeting ${index + 1}`}
            defaultValue={meeting?.UrlLink || ""}
            onChange={(e) =>
              handleInputChange(index, "UrlLink", e.target.value)
            }
          />
        </div>
      ))}
      {/* {[...Array(meetings.length)].map((_, index) => (
        <div key={index} className="flex flex-col gap-4 md:flex-row">
          <input
            type="text"
            className={`border border-gray-300 peer w-full md:w-1/2 px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
            placeholder={`Type for Meeting ${index + 1}`}
            defaultValue={meetings[index]?.MarketingType || ""}
            onChange={(e) =>
              handleInputChange(index, "MarketingType", e.target.value)
            }
          />
          <a
            href="#"
            onClick={() => handleMeetingTypeClick(meeting?.url)}
            onChange={(e) =>
              handleInputChange(index, "MeetingType", e.target.value)
            }
          >
            {meeting?.type || "Type"}
          </a>
          <input
            className={`border border-gray-300 peer w-full md:w-1/2 px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
            type="text"
            placeholder={`Status for Meeting ${index + 1}`}
            defaultValue={meetings[index]?.status || ""}
            onChange={(e) => handleInputChange(index, "status", e.target.value)}
          />
          <input
            className={`border border-gray-300 peer w-full md:w-1/2 px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
            type="date"
            placeholder={`Date for Meeting ${index + 1}`}
            defaultValue={meetings[index]?.MeetingDate || ""}
            onChange={(e) =>
              handleInputChange(index, "MeetingDate", e.target.value)
            }
          />
          <input
            className={`border border-gray-300 peer w-full md:w-1/2 px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
            type="text"
            placeholder={`URL for Meeting ${index + 1}`}
            defaultValue={meetings[index]?.UrlLink || ""}
            onChange={(e) =>
              handleInputChange(index, "UrlLink", e.target.value)
            }
          />
        </div>
      ))} */}
    </div>
  );
};

export default PublicHearings;
