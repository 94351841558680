import React, {  useState } from "react";
import { RichTextarea } from "rich-textarea";
import { errorFieldMessage } from "../../utils/Constant";

const ProjectSummaryTextarea = ({
  textSummary,
  setTextSummary,
  formErrors,
  setFormErrors,
}) => {
  const [characterCount, setCharacterCount] = useState(textSummary?.length);
  const [isFocused, setIsFocused] = useState(false);

  // const contentEditableRef = useRef(null);
  // useEffect(() => {
  //   if (contentEditableRef.current && document.activeElement === contentEditableRef.current) {
  //     const selection = window.getSelection();
  //     const range = document.createRange();
  //     range.selectNodeContents(contentEditableRef.current);
  //     range.collapse(false);
  //     selection.removeAllRanges();
  //     selection.addRange(range);
  //   }
  // }, [textSummary]);

  const summaryHandlerOnChange = (e) => {
    const newText = e.target.value;
    setTextSummary(newText);
    setTextSummary(newText);
    setCharacterCount(newText.length);
    setFormErrors({ summary: false });
  };

  // const renderText = () => {
  //   if (characterCount <= 1000) {
  //     return textSummary;
  //   } else {
  //     const overLimitText = textSummary.substring(1000);
  //     const regularText = textSummary.substring(0, 1000);
  //     return regularText + `<span style="color: red;">${overLimitText}</span>`;
  //   }
  // };

  return (
    <div>
      <RichTextarea
        // className={`mt-1 peer w-full z-0 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:z-10 sm:text-sm 2xl:text-base`}
        value={textSummary}
        onChange={(e) => {
          summaryHandlerOnChange(e);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{ width: "100%", height: "100px", padding: "10px" }}
        className={` placeholder-gray-500 border ${
          isFocused ? "border-gray-300" : "border-gray-300"
        } rounded-md focus:outline-none focus:z-10 sm:text-sm 2xl:text-base`}
      >
        {(v) => {
          return v.split("").map((t, i) => (
            <span key={i} style={{ color: i >= 1000 ? "red" : undefined }}>
              {t}
            </span>
          ));
        }}
      </RichTextarea>
      {characterCount > 1000 && (
        <div className={errorFieldMessage}>Character limit exceeded!</div>
      )}
    </div>
  );
};

export default ProjectSummaryTextarea;
