import { ArrowSmallUpIcon } from "@heroicons/react/24/outline";
import { IconButton } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";

export default function JumpToTop() {
    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
  return (
    <div className="fixed bottom-5 right-12 z-100">
     {showTopBtn &&
      <IconButton
        ripple={false}
        className="shadow-none hover:shadow-none  md:bg-primary-primary/20 bg-primary-light/80"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <ArrowSmallUpIcon
          strokeWidth={5}
          className="h-5 w-5 text-primary-primary"
        />
      </IconButton> }
    </div>
  );
}
