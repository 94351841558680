import * as React from "react";
import { useState, useEffect } from "react";
import { render } from "react-dom";
import Map, {
  Marker,
  // Source,
  // Layer,
  GeolocateControl,
  FullscreenControl,
  NavigationControl,
  Popup,
} from "react-map-gl";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import "mapbox-gl/dist/mapbox-gl.css";

import MapStyle from "../../../../components/ui/Map/MapStyle";
import { showMessage } from "../../../../components/ui/showMessage";
import { MAP_STREETS } from "../../../../utils/Constant";
import {
  PROJECTLIST,
  PROJECT_ARTICLE_STATUS,
} from "../../../../services/APIGlobal";
import axiosInstance from "../../../../services/APIInstance";
import MapOnly from "../../../../components/ui/Map/MapOnly";
import MapLedgend from "../../../../components/ui/Map/MapLedgend";
import { useRef } from "react";
import moment from "moment";
import logo from "../../../../assets/Logo/OnlyBlacklogo.png";
import BluePin from "../../../../components/ui/Map/BluePin";

export default function AdminProjectMap({ articles, APItype }) {
  const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN; // Set your mapbox token here

  const componentRef = useRef(null);
  const [data, setData] = useState([]);
  const [popupInfo, setPopupInfo] = useState(null); // eslint-disable-next-line
  const [zoomInfo, setZoomInfo] = useState(false); // eslint-disable-next-line
  const [viewType, setViewType] = useState("");
  const [showPopup, setShowpopup] = useState(false);
  // const [allData, setAllData] = useState(null);
  const [mapStyle, setMapStyle] = useState(MAP_STREETS); // eslint-disable-next-line
  const [projectStatusOptions, setProjectStatusOptions] = useState([]); // eslint-disable-next-line
  const [projectById, setProjectById] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setViewType("Map"); // eslint-disable-line
    GETPROJECTS();
    GET_PROJECT_STATUS();
  }, []);

  const GETPROJECTS = () => {
    axiosInstance
      .get(`${PROJECTLIST}?flag=1&page=${1}&limit=${1000}`)
      .then((response) => {
        setData(response?.data?.projects);
        // console.log(response?.data?.projects);
        localStorage.setItem(
          "totalProjects",
          response?.data?.pagination?.totalProjects
        );

        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {}, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  const GET_PROJECT_STATUS = () => {
    axiosInstance
      .get(`${PROJECT_ARTICLE_STATUS}`)
      .then((response) => {
        setProjectStatusOptions(response?.data?.getArticleProjectStatus);
      })
      .catch((error) => {});
  };
  // Project by id show
  // eslint-disable-next-line
  const handleOpen = (strory) => {
    setProjectById(strory);
    setOpen(!open);
  };
  return (
    <>
      {!loading ? (
        <>
          {data?.length > 0 ? (
            <>
              {/* <ControlPanel /> */}
              <div className="lg:main-content map-content w-full" ref={componentRef}>
                {/* {data.length > 0 ? ( */}
                <>
                  {viewType === "Map" && (
                    <Map
                      // reuseMaps
                      initialViewState={{
                        latitude: 33.195777,
                        longitude: -96.638031,
                        zoom: 10,
                      }}
                      onMove={(evt) => {
                        // setLongitude(evt.viewState.longitude);
                        // setLatitude(evt.viewState.latitude);
                        setShowpopup(false);
                        setZoomInfo(true);
                      }}
                      // className="-z-1"
                      // mapLib={window.mapboxgl}
                      mapStyle={MAP_STREETS}
                      mapboxAccessToken={TOKEN}
                      onClick={() => {
                        setShowpopup(false);
                        setZoomInfo(false);
                      }}
                    >
                      <Map
                        mapStyle={MAP_STREETS}
                        mapboxAccessToken={TOKEN}
                        initialViewState={{
                          latitude: 32.790260898226535,
                          longitude: -96.85111224441096,
                          zoom: 8,
                        }}
                      >
                        {data.map((marker, index) => (
                          <div key={`marker-${index}`}>
                            {marker?.location?.Longitude && (
                              <Marker
                                longitude={marker?.location?.Longitude}
                                latitude={marker?.location?.Latitude}
                                anchor="bottom"
                                onClick={(e) => {
                                  setZoomInfo(false);
                                  e.originalEvent.stopPropagation();
                                  setPopupInfo(null);
                                  setShowpopup(true);
                                  setPopupInfo(marker);
                                }}
                              >
                                <BluePin
                                  data={marker}
                                  projectHighlightId=""
                                  projectStatusOptions={projectStatusOptions}
                                />
                                {showPopup && (
                                  <Popup
                                    anchor="top"
                                    longitude={Number(
                                      popupInfo?.location?.Longitude
                                    )}
                                    latitude={Number(
                                      popupInfo?.location?.Latitude
                                    )}
                                    onClose={() => setShowpopup(false)}
                                    className="m-0 p-0 z-0"
                                    style={{
                                      zIndex: 2,
                                    }}
                                  >
                                    <Card className=" m-0 p-0 shadow-none border-none  hover:cursor-pointer ">
                                      <CardHeader
                                        floated={false}
                                        shadow={false}
                                        color="transparent"
                                        className="m-0 rounded-none p-0 h-40 relative"
                                      >
                                        <div className="h-full flex items-center justify-center">
                                          <img
                                            src={
                                              popupInfo?.imageUrls[0]
                                                ? popupInfo?.imageUrls[0]
                                                : logo
                                            }
                                            alt="project map"
                                            className={`h-full  ${
                                              !popupInfo?.imageUrls[0]
                                                ? "py-2 px-5 w-auto"
                                                : "w-full"
                                            }`}
                                          />
                                        </div>
                                      </CardHeader>
                                      <CardBody className="p-2 m-0">
                                        <div className="flex flex-col gap-2 ">
                                          <Typography
                                            variant="h6"
                                            color="blue-gray"
                                            className="leading-snug text-black font-semibold font-serif"
                                          >
                                            {popupInfo?.title}
                                          </Typography>
                                          <p className="text-xs text-gray-600 ">
                                            Posted Date:{" "}
                                            {moment(
                                              popupInfo?.created_at,
                                              "YYYY-MM-DD hh:mm:ss"
                                            ).format("MM/DD/YYYY")}
                                            {popupInfo?.Status && (
                                              <>
                                                {" "}
                                                | Project Status:{" "}
                                                {popupInfo?.Status}
                                              </>
                                            )}
                                          </p>
                                          <div className=" ">
                                            {popupInfo?.AssetType !== null &&
                                              popupInfo?.AssetType !==
                                                undefined && (
                                                <div className="flex flex-col max-w-fit ">
                                                  <p
                                                    className=" rounded-md p-1 tracking-wider font-medium antialiased text-xs bg-primary-light text-black"
                                                    variant="filled"
                                                    color="gray"
                                                  >
                                                    {popupInfo.AssetType.replace(
                                                      /,/g,
                                                      ", "
                                                    )}
                                                  </p>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Popup>
                                )}
                              </Marker>
                            )}
                          </div>
                        ))}
                        <div>
                          <GeolocateControl
                            position="top-right"
                            style={{
                              zIndex: 99999,
                            }}
                          />
                          <FullscreenControl position="top-right" />
                          <NavigationControl position="top-right" />
                        </div>
                      </Map>
                    </Map>
                  )}
                  <div className="relative w-full">
                    <MapStyle
                      setMapStyle={setMapStyle}
                      mapStyle={mapStyle}
                      setShowpopup={setShowpopup}
                    />
                  </div>

                  <div className="relative w-full">
                    <MapLedgend />
                  </div>

                  {/* <ProjectDetails
                    open={open}
                    handleOpen={handleOpen}
                    projectDetails={projectById}
                    handleCardOpertaion={handleCardOpertaion}
                  /> */}
                </>
              </div>
            </>
          ) : (
            <div className="mt-10">
              <MapOnly />
            </div>
          )}
        </>
      ) : (
        <MapOnly />
      )}
    </>
  );
}

export function renderToDom(container) {
  render(<AdminProjectMap />, container);
}
