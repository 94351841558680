import React from "react";
import {
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
} from "@material-tailwind/react";
import {
  Cog6ToothIcon,
  PowerIcon,
  // BookmarkIcon,
} from "@heroicons/react/24/outline";
import useAuth from "../../../hooks/useAuth";
import {
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";

export default function ProfileMenu() {
  const history = useHistory();
  const location = useLocation();

  const { setAuth } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const userDetails = localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails"))
    : undefined;
  // console.log(userDetails);
  return (
    <>
      {/* {localStorage.getItem("userDetails") && ( */}
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <IconButton
            variant="text"
            className="text-white hover:bg-primary-revado-white/25 active:bg-primary-revado-white/25"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
              />
            </svg>
          </IconButton>
          {/* <Button
              variant="text"
              color="white"
              className="flex items-center gap-1 py-0.5 pr-2 pl-0.5 lg:ml-auto"
            > */}

          {/* <ChevronDownIcon
                strokeWidth={2.5}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              /> */}
          {/* </Button> */}
        </MenuHandler>
        <MenuList className="p-1">
      
        {userDetails && userDetails.FirstName !== undefined && userDetails.LastName !== undefined && (
            <div
              className={`flex p-2  items-center gap-2 rounded text-primary-primary  `}
            >
              <p className="text-xs font-bold ml-1">
                {" "}
                {userDetails.FirstName !== undefined &&
                  userDetails.FirstName + " " + userDetails.LastName}
              </p>
            </div>
          )}
          <hr className="bg-primary-primary border-primary-primary border" />
          {/* <NavLink
            to="/favourites"
            className={`flex p-2 mt-2  items-center gap-2 rounded text-primary-primary hover:bg-primary-hover/10`}
            exact
            //   className=" bg-white text-primary-primary hover:bg-primary-revado-hover bg-primary-revado  focus:ring-4 focus:ring-primary-hover font-bold rounded-lg lg:text-md text-sm px-2 lg:px-5 py-2.5 lg:py-2.5 mr-2 "
          >
            {React.createElement(BookmarkIcon, {
              className: `h-4 w-4 `,
              strokeWidth: 2,
            })}
            <Typography
              as="span"
              variant="small"
              className="font-semibold"
              color="inherit"
            >
              Favourites
              Favorites
            </Typography>
          </NavLink> */}
          {location?.pathname !== "/setting" &&
            location?.pathname !== "/settings" && (
              // localStorage.getItem("authDetails") !== undefined &&
              // JSON.parse(localStorage.getItem("authDetails")).roles[0] ===
              //   "User" (
              <NavLink
                to={
                  JSON.parse(localStorage.getItem("authDetails")).roles[0] ===
                  "User"
                    ? "/setting"
                    : "/settings"
                }
                className={`flex p-2  items-center gap-2 rounded text-primary-primary hover:bg-primary-hover/10`}
                exact
                //   className=" bg-white text-primary-primary hover:bg-primary-revado-hover bg-primary-revado  focus:ring-4 focus:ring-primary-hover font-bold rounded-lg lg:text-md text-sm px-2 lg:px-5 py-2.5 lg:py-2.5 mr-2 "
              >
                {React.createElement(Cog6ToothIcon, {
                  className: `h-4 w-4 `,
                  strokeWidth: 2,
                })}
                <Typography
                  as="span"
                  variant="small"
                  className="font-semibold"
                  color="inherit"
                >
                  Settings
                </Typography>
              </NavLink>
            )}
          <MenuItem className="p-2 flex flex-col gap-2 hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10">
            <button
              onClick={(e) => {
                if (location.pathname === "/") {
                  window.location.reload();
                } else {
                  history.push("/");
                }
                setAuth({});
                localStorage.setItem("authDetails", "");
                localStorage.setItem("userDetails", "");
                localStorage.removeItem("zoom");
              }}
              className={`flex w-full text-red-500 items-center gap-2 rounded `}
              //   className=" bg-white text-primary-primary hover:bg-primary-revado-hover bg-primary-revado  focus:ring-4 focus:ring-primary-hover font-bold rounded-lg lg:text-md text-sm px-2 lg:px-5 py-2.5 lg:py-2.5 mr-2 "
            >
              {React.createElement(PowerIcon, {
                className: `h-4 w-4 `,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color="inherit"
              >
                Sign Out
              </Typography>
            </button>
          </MenuItem>
        </MenuList>
      </Menu>
      {/* )} */}
    </>
  );
}
