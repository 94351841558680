import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { VERIFY } from "../../services/APIGlobal";
import { showMessage } from "../../components/ui/showMessage";
import axiosInstance from "../../services/APIInstance";

export default function Verify() {
  const history = useHistory();
  const [loading, setLoading] = useState(true); // Initialize loading as true

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('token');
   

    if (token) {
      axiosInstance
        .post(`${VERIFY}?${urlSearchParams.toString()}`)
        .then((response) => {
          setLoading(false); // Set loading to false when the request is complete

          if (response.data) {
            showMessage(response.data.message, "success", "#576d69", false, false, false);
            setTimeout(() => {
              history.push("/");
            }, 3000);
          } else {
            showMessage("Token verification failed.", "error", "red", false, false, false);
          }
        })
        .catch((error) => {
          setLoading(false);
           // Set loading to false when there's an error
         
          showMessage(error?.response?.data?.error, "error", "red", false, false, false);
          setTimeout(() => {
            history.push("/");
          }, 3000);
        });
    } else {
      setLoading(false); // Set loading to false if there's no token in the URL
      showMessage("Token not found in URL.", "error", "red", false, false, false);
    }
  }, [history]);

  if (loading) {
    // Display the loader while loading is true
    return (
      <div className="flex items-center justify-center md:m-5 lg:m-5">
        <p>Loading...</p>
      </div>
    );
  } else {
    // Loading is false, so return whatever content you want here
    return (
      <div className="flex items-center justify-center md:m-5 lg:m-5">
        {/* Your content */}
      </div>
    );
  }
}
