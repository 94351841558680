import { Typography } from "@material-tailwind/react";
import React from "react";
import { ExtractFilenameFromUrl } from "../../store/helper/Helper";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

export default function PDFListing({ pdfList }) {
  const downloadPdf = (pdfUrl) => {
    const filename = ExtractFilenameFromUrl(pdfUrl) + ".pdf"; // Add .pdf extension
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  const downloadUploadedPdf = (pdfUrl) => {
    // console.log("newmethod");
    const filename = pdfUrl.substring(pdfUrl.lastIndexOf("/") + 1) + ".pdf"; // Extract filename from URL
    fetch(pdfUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };
  const createBlob = (filename) => {
    const blob = new Blob([filename], { type: filename.type });
    return URL.createObjectURL(blob);
  };
  const openPdfInNewWindow = (pdfUrl, type) => {
    let pdfValue;
    // Check if pdfUrl has the .type property
    if (typeof pdfUrl.type !== "undefined") {
      pdfValue = createBlob(pdfUrl);
    } else {
      pdfValue = pdfUrl;
    }

    // Open the Blob file in a new tab/window
    if (type === "download") {
      if (typeof pdfUrl.type !== "undefined") {
        downloadPdf(pdfValue);
      } else {
        downloadUploadedPdf(pdfValue);
      }
    } else {
      window.open(pdfValue, "_blank");
    }
  };

  return (
    <>
      {pdfList?.length > 0 && (
        <Typography
          variant="small"
          color="gray"
          className=" md:text-sm 2xl:text-base"
        >
          <span className="font-bold text-black"> ATTACHMENTS</span>
          <br />
          <span className="flex flex-col gap-1 justify-center items-start">
            {pdfList?.map((pdfUrl, index) => (
              <div className="flex flex-row gap-2">
                <p
                  className="text-blue-700 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openPdfInNewWindow(pdfUrl, "view");
                  }}
                >
                  {pdfUrl.name ? pdfUrl.name : ExtractFilenameFromUrl(pdfUrl)}
                </p>
                <ArrowDownTrayIcon
                  className="text-blue-700 w-4 h-4 cursor-pointer"
                  strokeWidth={2}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openPdfInNewWindow(pdfUrl, "download");
                  }}
                />
              </div>
            ))}

            {/* <button onClick={openPdfInNewWindow}>Open PDF in New Window</button>
  <PdfViewer pdfUrl={pdfUrl} /> */}
          </span>
        </Typography>
      )}
    </>
  );
}
