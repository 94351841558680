import React, { createContext } from "react";
import { useState } from "react";

const FilterParaContext = createContext({});

export const FilterParaProvider = ({ children }) => {
  const [filterCategory,setFilterCategory] = useState({});

  return (
    <FilterParaContext.Provider value={{filterCategory,setFilterCategory}}>
      {children}
    </FilterParaContext.Provider>
  );
};

export default FilterParaContext;
