import {
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  EyeIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { Card, Tooltip, Typography } from "@material-tailwind/react";
import React from "react";
import { useState } from "react";
import AllArticlesDB from "./AllArticlesDB";
import ActionCol from "../functionality/table/ActionCol";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useLastLocation } from "react-router-last-location";
import {
  locationString,
  removeQueryParameters,
} from "../../store/helper/Helper";
import { ProjectPopUp } from "../functionality/ProjectPopup";
import ImageWithFallback from "../ui/ImageWithFallback";
import logo from "../../assets/Logo/OnlyBlacklogo.png";
export default function AllProjectDB(props) {
  // eslint-disable-next-line
  const [expandedIndex, setExpandedIndex] = useState(null);
  const history = useHistory();
  // const scrollRef = useRef(null);
  const lastLocation = useLastLocation();

  const handleReadmore = (project, index) => {
    localStorage.setItem("project", JSON.stringify(project?.projectId));
    history.push({
      pathname: `/ProjectDetail/${project?.projectId}`,
      data: project,
      title: "Edit",
    });

    localStorage.setItem("lastProjectPage", props.pageNo);
    localStorage.setItem("lastProjectRecords", props.showRecord);

    //  localStorage.setItem("lastProjectRecord", index);
    // if (expandedIndex === index) {
    //   setExpandedIndex(null);
    // } else {
    //   setExpandedIndex(index);
    // }
  };

  useEffect(() => {
    // Call scrollToRow inside useEffect to scroll to a specific row when the component mounts
    // console.log(localStorage.getItem("lastVisitedRow"),"lastVisitedRow")
    // eslint-disable-next-line
    const regex = /(\d+)/; // Match one or more digits
    if (!lastLocation?.pathname?.includes("/ProjectDetail")) {
      localStorage.removeItem("lastVisitedProjectDetails");
      localStorage.removeItem("lastProjectPage");
      localStorage.removeItem("lastProjectRecords");
    }
    // } else {
    //   if (
    //     localStorage.getItem("lastVisitedProjectDetails") !== null &&
    //     scrollRef.current
    //   ) {
    //     const match = localStorage
    //       .getItem("lastVisitedProjectDetails")
    //       .match(regex);
    //     let number = parseInt(match[0]);
    //     const elementToScrollTo = scrollRef.current.querySelector(
    //       `#item${number - 1}`
    //     );
    //     if (elementToScrollTo) {
    //       elementToScrollTo.scrollIntoView({ behavior: "smooth" });
    //     }
    //   }
    // }
    // eslint-disable-next-line
  }, [history, props]);
  return (
    <section className="flex flex-col gap-2" >
      <>
        {!props.loading &&
          props?.articles?.map((project, index) => (
            <Card
              key={index}
              // id={`item${index}`}
              className=" border-t-2 px-5 py-4 min-h-full "
            >
              <section>
                <section className="flex md:flex-row flex-col gap-4 w-full ">
                  <Card className="lg:w-32 lg:h-28   md:flex items-center lg:justify-center p-0 lg:shadow-lg shadow-none">
                    {/* <SingleMarkerMap
                      style={{ width: 100, height: 100, borderRadius: "10px" }}
                      longitude={project?.location?.Longitude}
                      latitude={project?.location?.Latitude}
                      noControls
                      interactive={false}
                      public_hearings={project?.location?.public_hearings}
                      AssetType={project?.AssetType}
                      projectstatus={project?.projectstatus}
                    /> */}
                    <ImageWithFallback
                      url={
                        project?.imageUrls?.length > 0
                          ? removeQueryParameters(
                              project?.imageUrls[0],
                              project?.weburl
                            )
                          : project?.weburl
                      }
                      className="rounded-lg    object-cover "
                      style={{
                        maxHeight: "100px",
                        minWidth: "100px",
                        maxWidth: "100px",
                        minHeight: "100px",
                      }}
                      fallbackUrl={logo}
                      source={project?.weburl}
                    />
                  </Card>
                  {/* <Card className="md:hidden  text-primary-primary font-bold border-t-2 flex items-center justify-center">
                    <SingleMarkerMap
                      style={{ width: 300, height: 260, borderRadius: "10px" }}
                      longitude={project?.location?.Longitude}
                      latitude={project?.location?.Latitude}
                      noControls
                      interactive={false}
                      AssetType={project?.AssetType}
                      projectstatus={project?.projectstatus}
                      public_hearings={project?.location?.public_hearings}
                    />
                  </Card> */}
                  <section className="flex flex-col gap-2 w-full">
                    <div className="flex lg:flex-row flex-col gap-2 ma:gap-0 lg:justify-between   ">
                      {/* 1st col */}
                      <div className="flex flex-col gap-2  lg:w-1/2">
                        <Tooltip
                          className="border shadow-lg bg-white text-black w-fit "
                          content="View Project Details"
                          placement="top-start"
                        >
                          <p
                            variant="small"
                            className=" font-bold cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              handleReadmore(project, `item${index}`);
                            }}
                          >
                            {project?.title}
                          </p>
                        </Tooltip>
                        <Typography className="text-xs">
                          Asset Type:{" "}
                          <span className="capitalize ">
                            {project?.AssetType ? project?.AssetType : "--"}
                          </span>
                        </Typography>
                        {(project?.location?.StreetIntersections ||
                          project?.location?.StreetAddres ||
                          project?.location?.City ||
                          project?.location?.State ||
                          project?.location?.ZipCode) && (
                          <Typography className="text-xs flex gap-1">
                            <MapPinIcon className="h-3.5 w-3.5 " />
                            <span>
                              {" "}
                              {locationString(
                                project?.location?.StreetAddress,
                                project?.location?.StreetIntersections,
                                project?.location?.City,
                                project?.location?.State,
                                project?.location?.ZipCode
                              )}
                            </span>
                          </Typography>
                        )}
                      </div>
                      {/* 2nd col */}
                      {/* <div className="flex flex-col gap-2">
                    <Typography variant="small" className="font-bold">
                      Date
                    </Typography>
                    <Typography variant="small">
                      {moment(
                        article?.created_at,
                        "YYYY-MM-DD hh:mm:ss"
                      ).format("DD-MM-YYYY")}
                    </Typography>
                  </div> */}
                      {/* 3rd col */}
                      {/* <div className="flex flex-col gap-2">
                    <Typography variant="small" className="font-bold">
                      Status
                    </Typography>
                    <Typography variant="small">
                      <StatusChip values={article?.Status} />
                    </Typography>
                  </div> */}
                      {/* 4th col */}
                      <div className="flex lg:flex-col flex-row gap-2 ">
                        <Typography variant="small" className="font-bold">
                          Draft
                        </Typography>
                        <Typography variant="small" className="text-center">
                          {project?.isDraft}
                        </Typography>
                      </div>
                      <div className="flex lg:flex-col flex-row gap-2 ">
                        <Typography variant="small" className="font-bold">
                          Project Self Created
                        </Typography>
                        <Typography variant="small" className="text-center">
                          {project?.type === 0 ? "Yes" : "No"}
                        </Typography>
                      </div>

                      <div className="flex flex-col gap-2 ">
                        {/* <div>
                    <Button
                      variant="text"
                      ripple={false}
                      className="text-primary-primary flex items-center gap-2 p-0 hover:bg-white active:bg-white focus:bg-white"
                      onClick={(e) => {
                        e.preventDefault();
                        handleReadmore(article);
                      }}
                    >
                      View Project  */}
                        {/* <Icon strokeWidth={2} className="h-5 w-5" /> */}
                        {/* </Button>
                  </div> */}
                        <div className="flex flex-row gap-4">
                          <Tooltip
                            className="border shadow-lg bg-white text-black w-fit "
                            content="View Project Details"
                          >
                            <EyeIcon
                              onClick={(e) => {
                                e.preventDefault();
                                handleReadmore(project, `item${index}`);
                              }}
                              className="w-5 cursor-pointer flex items-center  text-primary-hover font-extralight"
                            />
                          </Tooltip>
                          {project?.IsPublish !== "Yes" ? (
                            <Tooltip
                              className="border shadow-lg bg-white text-black w-fit "
                              content="Publish Project"
                            >
                              <DocumentArrowUpIcon
                                onClick={() => {
                                  ProjectPopUp(
                                    project,
                                    `Publish`,
                                    `#40514e`,
                                    `Are you sure you want to Publish this ${project?.title}`,
                                    `publish`,
                                    props.GET_STORIES
                                    // restoreStories,
                                    // restoreRole
                                  );
                                }}
                                className="w-5 cursor-pointer text-light-green-700"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              className="border shadow-lg bg-white text-black w-fit "
                              content="Unpublished Project"
                            >
                              <DocumentArrowDownIcon
                                onClick={() => {
                                  ProjectPopUp(
                                    project,
                                    `Unpublish`,
                                    `#40514e`,
                                    `Are you sure you want to Publish this ${project?.title}`,
                                    `unpublish`,
                                    props.GET_STORIES
                                    // restoreStories,
                                    // restoreRole
                                  );
                                }}
                                className="w-5 cursor-pointer text-red-900	"
                              />
                            </Tooltip>
                          )}
                          <ActionCol
                            data={project}
                            GET_API={props?.GET_STORIES}
                            project
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex flex-row justify-between items-center">
                  <div>
                    <Button
                      variant="text"
                      ripple={false}
                      className="text-primary-primary flex items-center gap-2 p-0 hover:bg-white active:bg-white focus:bg-white"
                      onClick={(e) => {
                        e.preventDefault();
                        handleReadmore(article);
                      }}
                    >
                      View Project
                      <Icon strokeWidth={2} className="h-5 w-5" />
                    </Button>
                  </div>
                  <div className="">
                    <ActionCol
                      deletedStories
                      jumpToArticle
                      showResubmit
                      data={article}
                      GET_API={props?.GET_STORIES}
                    />
                  </div>
                </div> */}
                  </section>
                </section>
              </section>
              {index === expandedIndex && (
                <div className="mt-3">
                  <AllArticlesDB />
                </div>
              )}
            </Card>
          ))}
      </>
    </section>
  );
}
