import React from "react";
import HomeCard from "../../components/functionality/HomeCard";

export default function ListViewStories({stories,latestStories,GETAPI,APItype}) {

  return (
    <div className="  ">
      <HomeCard latestStories={latestStories} stories={stories} GETAPI={GETAPI} APItype={APItype}/>
      {/* {!props.latestStories && (
        <div className="flex justify-center mt-6">
          <Button
            size="sm"
            variant="filled"
            className="bg-primary-primary text-white  shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
          >
            Load More
          </Button>
        </div>
      )} */}
    </div>
  );
}
