import React, { useEffect, useState } from "react";
import { CARDDATA, TooltipStlye } from "../../utils/Constant";
import Table from "../functionality/table/Table";
import ActionCol from "../functionality/table/ActionCol";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-tailwind/react";
import axiosInstance from "../../services/APIInstance";
import { STORIES } from "../../services/APIGlobal";
import { showMessage } from "../ui/showMessage";
import Loader from "../ui/Loader";
import moment from "moment";
import { ArrayToString } from "../../store/helper/Helper";

export default function PublishArticlesDB() {
  let history = useHistory();
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showRecord, setShowRecord] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();
  const [assetFillter, setAssetFillter] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    (async () => {
      const result = CARDDATA.filter((record) => record.status !== "");
      setData(result);
    })();
  }, []);
  const columns = [
    {
      Header: "#",
      accessor: "id",
      Cell: (cell) => cell.row.index + 1,
      maxWidth: 10,
      minWidth: 10,
      width: 10,
    },
    {
      Header: "Article Title",
      accessor: "article_title",
      Cell: ({ value, row }) => {
        return (
          <Tooltip className={TooltipStlye} content="Edit & view Article">
            <p
              className="line-clamp-1 cursor-pointer text-left	ml-4"
              onClick={(e) => handleClick(e, row)}
            >
              {value}
            </p>
          </Tooltip>
        );
      },
      headerStyle: { textAlign: "center" },
      width: 300,
    },
    {
      Header: "Date",
      accessor: "publishdate",
      headerStyle: { textAlign: "center" },
      Cell: ({ cell: { value } }) => (
        <span>
          {value
            ? moment(value, "YYYY-MM-DD hh:mm:ss").format("MM-DD-YYYY")
            : "--"}
        </span>
      ),
    },
    {
      Header: "Project Name",
      accessor: "projectName",
      Cell: ({ cell: { value } }) => (
        <span className="capitalize line-clamp-1">{value ? value : "--"}</span>
      ),
    },
    // {
    //   Header: "Time",
    //   accessor: "",
    //   Cell: ({ cell: { value } }) => {
    //     if (value === undefined) {
    //       return "NA";
    //     } else {
    //       return value;
    //     }
    //   },
    // },
    {
      Header: "Location",
      accessor: "City",
      headerStyle: { textAlign: "right" },
      Cell: ({ cell: { value } }) => (
        <span className="capitalize ">{value ? value : "--"}</span>
      ),
    },
    {
      Header: "Asset Type",
      accessor: "AssetType",
      Cell: ({ cell: { value } }) => (
        <span className="capitalize ">{value ? value : "--"}</span>
      ),
    },
    // {
    //   Header: "Status",
    //   accessor: "status",
    //   Cell: ({ cell: { value } }) => <Status values={value} />,
    // },
    // {
    //   Header: "Comment",
    //   accessor: "comment",
    //   Cell: ({ cell: { value } }) => {
    //     if (value === undefined) {
    //       return "NA";
    //     } else {
    //       return value;
    //     }
    //   },
    //   width: 50,
    // },
    {
      id: "action",
      accessor: "",
      disableSortBy: true,
      Cell: (row) => (
        <ActionCol
          data={row.row.original}
          jumpToArticle
          deletedStories
          unPublished
          GET_API={GET_STORIES}
        />
      ),
    },
  ];
  useEffect(() => {
    GET_STORIES();
    localStorage.removeItem("rowdata"); // eslint-disable-next-line
  }, [showRecord, pageNo,  assetFillter,globalFilter]);

  // Get API
  const GET_STORIES = () => {
    axiosInstance(
      `${STORIES}?limit=${showRecord}&status=&isPublish=Yes&assetType=${
        assetFillter.length > 0 ?  ArrayToString(assetFillter) : ""
      }&page=${pageNo}&flag=1&filter=${
        globalFilter == null ? "" : globalFilter
      }`
    )
      .then((response) => {
        setData(response?.data?.stories);
        setTotalRecord(response?.data?.pagination?.totalStories);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        setTotalRecord(1);
        setCurrentPage(1);
        setTotalPage(1);
        setData([])
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.message,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  // Handle on title click
  const handleClick = (e, row) => {
    localStorage.setItem(
      "rowId",
      JSON.stringify(row?.original?.ProjectDetailsId)
    );
    history.push({
      pathname: `/Article/Update/${row?.original?.ProjectDetailsId}`,
      data: row,
    });
  };
  return (
    <div className="m-4">
      <Loader loading={loading} />
      <Table
        setPageNo={setPageNo}
        pageNo={pageNo}
        showRecord={showRecord}
        setShowRecord={setShowRecord}
        totalRecord={totalRecord}
        currentPage={currentPage}
        totalPage={totalPage}
        columns={columns}
        data={data}
        assetFillter={assetFillter}
        setAssetFillter={setAssetFillter}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      />
    </div>
  );
}
