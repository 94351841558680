import Swal from "sweetalert2";
import { showMessage } from "../ui/showMessage";
import axiosInstance from "../../services/APIInstance";
import {
  ARTICLEREVIEW,
  DELETEROLE,
  PROJECTLIST,
  PUBLISH,
  SOFTDELETE,
  SOFTDELETEROJECT,
  STORIES,
  ASSETTYPE,
  AMETIES,
  PROJECT_STATUS_TYPE,
  USERS,
} from "../../services/APIGlobal";

export const Popup = (
  data,
  confirmButtonText,
  confirmButtonColor,
  title,
  event,
  setLoading,
  GET_API,
  project,
  deletedStories,
  deleteRole,
  deleteAssetType,
  deleteAmenities,
  deleteProjectStatus,
  deleteTypeofuser
) => {
  Swal.fire({
    icon: "warning",
    title: title,
    // showClass: {
    //   backdrop: 'swal2-noanimation', // disable backdrop animation
    //   popup: '',                     // disable popup animation
    //   icon: ''                       // disable icon animation
    // },
    confirmButtonText: confirmButtonText,
    showCancelButton: true,
    confirmButtonColor: confirmButtonColor,
    customClass: "Custom_Cancel",
    cancelButtonColor: "#d6e2e2",
    cancelButtonText: "<span style='color:black'>Cancel</span>",
    hideClass: {
      popup: "", // disable popup fade-out animation
    }, // eslint-disable-next-line
    customClass: {
      loader: "custom-loader",
      popup: "bg-swal",
    },
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      // console.log(event);
      // Delete event
      if (event === "delete") {
        // console.log(
        //   deleteProjectStatus,
        //   deleteTypeofuser,
        //   deleteAssetType,
        //   deleteAmenities
        // );
        setLoading(true);
        if (deletedStories === true) {
          if (project) {
            axiosInstance
              .delete(`${PROJECTLIST}/${data?.projectId}`)
              .then((response) => {
                setLoading(false);
                showMessage(
                  response?.data?.message,
                  "success",
                  "#576d69",
                  false,
                  false,
                  false
                );
                GET_API();
              })
              .catch((error) => {
                setTimeout(() => {
                  setLoading(false);
                }, 1600);
                setTimeout(() => {
                  showMessage(
                    error?.response?.data?.error,
                    "error",
                    "red",
                    false,
                    false,
                    false
                  );
                }, 2500);
              });
          } else if (!project) {
            axiosInstance
              .delete(`${STORIES}/${data?.ProjectDetailsId}`)
              .then((response) => {
                setLoading(false);
                showMessage(
                  response?.data?.message,
                  "success",
                  "#576d69",
                  false,
                  false,
                  false
                );
                GET_API();
              })
              .catch((error) => {
                setTimeout(() => {
                  setLoading(false);
                }, 1600);
                setTimeout(() => {
                  showMessage(
                    error?.response?.data?.error,
                    "error",
                    "red",
                    false,
                    false,
                    false
                  );
                }, 2500);
              });
          }
        } else if (deleteRole === true) {
          axiosInstance
            .delete(`${DELETEROLE}/${data?.UserId}`)
            .then((response) => {
              setLoading(false);
              showMessage(
                response?.data?.message,
                "success",
                "#576d69",
                false,
                false,
                false
              );
              GET_API();
            })
            .catch((error) => {
              setTimeout(() => {
                setLoading(false);
              }, 1600);
              setTimeout(() => {
                showMessage(
                  error?.response?.data?.error,
                  "error",
                  "red",
                  false,
                  false,
                  false
                );
              }, 2500);
            });
        }
        if (deleteAssetType === true) {
          // console.log(data);
          axiosInstance
            .delete(`${ASSETTYPE}/${data?.PatId}`)
            .then((response) => {
              setLoading(false);
              showMessage(
                response?.data?.message,
                "success",
                "#576d69",
                false,
                false,
                false
              );
              GET_API();
            })
            .catch((error) => {
              setTimeout(() => {
                setLoading(false);
              }, 1600);
              setTimeout(() => {
                showMessage(
                  error?.response?.data?.error,
                  "error",
                  "red",
                  false,
                  false,
                  false
                );
              }, 2500);
            });
        }
        if (deleteAmenities === true) {
          // console.log(data);
          axiosInstance
            .delete(`${AMETIES}/${data?.AmenitiesId}`)
            .then((response) => {
              setLoading(false);
              showMessage(
                response?.data?.message,
                "success",
                "#576d69",
                false,
                false,
                false
              );
              GET_API();
            })
            .catch((error) => {
              setTimeout(() => {
                setLoading(false);
              }, 1600);
              setTimeout(() => {
                showMessage(
                  error?.response?.data?.error,
                  "error",
                  "red",
                  false,
                  false,
                  false
                );
              }, 2500);
            });
        }
        if (deleteProjectStatus === true) {
          // console.log(data);
          axiosInstance
            .delete(`${PROJECT_STATUS_TYPE}/${data?.PStatusTypeId}`)
            .then((response) => {
              setLoading(false);
              showMessage(
                response?.data?.message,
                "success",
                "#576d69",
                false,
                false,
                false
              );
              GET_API();
            })
            .catch((error) => {
              setTimeout(() => {
                setLoading(false);
              }, 1600);
              setTimeout(() => {
                showMessage(
                  error?.response?.data?.error,
                  "error",
                  "red",
                  false,
                  false,
                  false
                );
              }, 2500);
            });
        }
        if (deleteTypeofuser === true) {
          // console.log(data);
          axiosInstance
            .delete(`${USERS}/${data?.TypeUserId}`)
            .then((response) => {
              setLoading(false);
              showMessage(
                response?.data?.message,
                "success",
                "#576d69",
                false,
                false,
                false
              );
              GET_API();
            })
            .catch((error) => {
              setTimeout(() => {
                setLoading(false);
              }, 1600);
              setTimeout(() => {
                showMessage(
                  error?.response?.data?.error,
                  "error",
                  "red",
                  false,
                  false,
                  false
                );
              }, 2500);
            });
        }
      }
      // Publish event
      else if (event === "publish") {
        setLoading(true);
        let DATA = {
          ProjectDetailsId: data?.ProjectDetailsId,
          Status: "Accept & Publish",
          comment: null,
          IsPublish: "Yes",
        };
        axiosInstance
          .put(PUBLISH, DATA)
          .then((response) => {
            setLoading(false);
            showMessage(
              response?.data?.message,
              "success",
              "#576d69",
              false,
              false,
              false
            );
            GET_API();
          })
          .catch((error) => {
            setTimeout(() => {
              setLoading(false);
            }, 1600);
            setTimeout(() => {
              showMessage(
                error?.response?.data?.error,
                "error",
                "red",
                false,
                false,
                false
              );
            }, 2500);
          });
      }
      // Archive event
      else if (event === "archive") {
        setLoading(true);
        if (project) {
          let DATA = {
            projectId: data?.projectId,
            flag: 0,
          };
          axiosInstance
            .put(SOFTDELETEROJECT, DATA)
            .then((response) => {
              setLoading(false);
              showMessage(
                response?.data?.message,
                "success",
                "#576d69",
                false,
                false,
                false
              );
              GET_API();
            })
            .catch((error) => {
              setTimeout(() => {
                setLoading(false);
              }, 1600);
              setTimeout(() => {
                showMessage(
                  error?.response?.data?.error,
                  "error",
                  "red",
                  false,
                  false,
                  false
                );
              }, 2500);
            });
        } else if (!project) {
          let DATA = {
            ProjectDetailsId: data?.ProjectDetailsId,
            flag: 0,
          };
          axiosInstance
            .put(SOFTDELETE, DATA)
            .then((response) => {
              setLoading(false);
              showMessage(
                response?.data?.message,
                "success",
                "#576d69",
                false,
                false,
                false
              );
              GET_API();
            })
            .catch((error) => {
              setTimeout(() => {
                setLoading(false);
              }, 1600);
              setTimeout(() => {
                showMessage(
                  error?.response?.data?.error,
                  "error",
                  "red",
                  false,
                  false,
                  false
                );
              }, 2500);
            });
        }
      }
      // Restore event
      else if (event === "restore") {
        setLoading(true);
        if (!project) {
          let DATA = {
            ProjectDetailsId: data?.ProjectDetailsId,
            flag: 1,
          };
          axiosInstance
            .put(SOFTDELETE, DATA)
            .then((response) => {
              setLoading(false);
              showMessage(
                "Restore successfully",
                "success",
                "#576d69",
                false,
                false,
                false
              );
              GET_API();
            })
            .catch((error) => {
              setTimeout(() => {
                setLoading(false);
              }, 1600);
              setTimeout(() => {
                showMessage(
                  error?.response?.data?.error,
                  "error",
                  "red",
                  false,
                  false,
                  false
                );
              }, 2500);
            });
        } else if (project) {
          let DATA = {
            projectId: data?.projectId,
            flag: 1,
          };
          axiosInstance
            .put(SOFTDELETEROJECT, DATA)
            .then((response) => {
              setLoading(false);
              showMessage(
                "Restore successfully",
                "success",
                "#576d69",
                false,
                false,
                false
              );
              GET_API();
            })
            .catch((error) => {
              setTimeout(() => {
                setLoading(false);
              }, 1600);
              setTimeout(() => {
                showMessage(
                  error?.response?.data?.error,
                  "error",
                  "red",
                  false,
                  false,
                  false
                );
              }, 2500);
            });
        }
      }
      // unPublish event
      else if (event === "unPublish") {
        setLoading(true);
        let DATA = {
          ProjectDetailsId: data?.ProjectDetailsId,
          Status: "Pending",
          comment: null,
          IsPublish: "No",
        };
        axiosInstance
          .put(PUBLISH, DATA)
          .then((response) => {
            setLoading(false);
            showMessage(
              response?.data?.message,
              "success",
              "#576d69",
              false,
              false,
              false
            );
            GET_API();
          })
          .catch((error) => {
            setTimeout(() => {
              setLoading(false);
            }, 1600);
            setTimeout(() => {
              showMessage(
                error?.response?.data?.error,
                "error",
                "red",
                false,
                false,
                false
              );
            }, 2500);
          });
      }
      // review event
      else if (event === "review") {
        setLoading(true);
        let DATA = {
          ProjectDetailsId: data?.ProjectDetailsId,
          IsReview: "Yes",
        };
        axiosInstance
          .put(ARTICLEREVIEW, DATA)
          .then((response) => {
            axiosInstance
              .put(PUBLISH, {
                ProjectDetailsId: data?.ProjectDetailsId,
                Status: "Pending",
                comment: null,
                IsPublish: "No",
              })
              .then((response) => {
                setLoading(false);
                showMessage(
                  response?.data?.message,
                  "success",
                  "#576d69",
                  false,
                  false,
                  false
                );
                GET_API();
              })
              .catch((error) => {
                setTimeout(() => {
                  setLoading(false);
                }, 1600);
                setTimeout(() => {
                  showMessage(
                    error?.response?.data?.error,
                    "error",
                    "red",
                    false,
                    false,
                    false
                  );
                }, 2500);
              });
          })
          .catch((error) => {
            setTimeout(() => {
              setLoading(false);
            }, 1600);
            setTimeout(() => {
              showMessage(
                error?.response?.data?.error,
                "error",
                "red",
                false,
                false,
                false
              );
            }, 2500);
          });
      }
      // Other event
      else if (
        event !== "delete" ||
        event !== "publish" ||
        event !== "unPublish" ||
        event !== "review"
      ) {
        showMessage(
          `Succefully ${confirmButtonText}!`,
          "success",
          "#576d69",
          false,
          false,
          false
        );
      }
      // console.log(data,"data")
    }
  });
};
