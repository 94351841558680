import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchContext from "../../context/SearchContext";
import ProjectMap from "./ProjectListMap";

export default function MapHome({
  APItype,
  assetFillter,
  dateRangeValue,
  startDateValue,
  endDateValue,
  setStories,
  city,
  projectHighlightId,
  GET_STORIES,
  stories,
  onViewportChange,
  initialViewport
}) {
  // eslint-disable-next-line
  const location = useLocation(); // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const { searchValue } = useContext(SearchContext); // eslint-disable-next-line

  return (
    <div className=" flex justify-center h-96 min-h-full">
      <ProjectMap
        stories={stories}
        loading={loading}
        setStories={setStories}
        APItype={APItype}
        assetFillter={assetFillter}
        dateRangeValue={dateRangeValue}
        startDateValue={startDateValue}
        endDateValue={endDateValue}
        searchValue={searchValue}
        projectHighlightId={projectHighlightId}
        GETPROJECTS={GET_STORIES}
        onViewportChange={onViewportChange} // Pass the handler to the MapHome component
        initialViewport={initialViewport} // Pass the initial viewport from the ref

      />
    </div>
  );
}
