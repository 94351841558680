import React from "react";
import styled from "styled-components";
import Select from "react-dropdown-select";
import { Chip } from "@material-tailwind/react";

export class AutoCompleteMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchValue: "" };
  }

  customContentRenderer = ({ props, state, methods }) => {
    return (
      <div className="flex gap-2">
        <p className="text-black">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="black"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
            />
          </svg>
        </p>{" "}
        Fillters
        {state?.values.length < 1 ? (
          ""
        ) : (
          <Chip
            className="w-min rounded-full tracking-wider font-bold antialiased text-xs py-1 px-2 bg-primary-light text-primary-revado-dark"
            variant="filled"
            color="gray"
            size="sm"
            value={state?.values.length}
          />
        )}
      </div>
    );
  };

  customDropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, "i");
    return (
      <div>
        <SearchAndToggle>
          <Buttons>
            {state.values.length === props.options.length ? (
              <Button className="clear" onClick={methods.clearAll}>
                Clear all
              </Button>
            ) : (
              <Button onClick={methods.selectAll}>Select all</Button>
            )}
          </Buttons>
        </SearchAndToggle>
        <Items>
          {props.options
            .filter((item) => regexp.test(item[props.searchBy] || item.label))
            .map((option) => (
              <Item className="cursor-pointer" key={option.value} onClick={() => methods.addItem(option)}>
                <input
                  className="accent-primary-primary	text-white cursor-pointer"
                  type="checkbox"
                  onChange={() => {
                    methods.addItem(option);
                    // this.setSelectedOption(option);
                  }}
                  checked={state.values.indexOf(option) !== -1}
                />
                <ItemLabel>{option.label}</ItemLabel>
              </Item>
            ))}
        </Items>
      </div>
    );
  };

  render() {
    return (
      <div onMouseEnter={this.changeView}>
        <StyledSelect
          dropdownHandle={true}
          color="#576d69"
          style={{
            borderRadius: "5px",
          }}
          className="font-thin border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
          placeholder="All"
          multi
          // contentRenderer={this.customContentRenderer}
          dropdownRenderer={this.customDropdownRenderer}
          onChange={(value) => {
            if (this.props.name === "Locations") {
              this.props.setSelectLocations(value);
            } else if (this.props.name === "Topics") {
              this.props.setSelectTopics(value);
            } else if (this.props.name === "projectType") {
              this.props.setSelectTypes(value);
            }
          }}
          values={this.props.value}
          options={this.props.options}
        />
      </div>
    );
  }
}

const StyledSelect = styled(Select)`
  .react-dropdown-select-dropdown {
    overflow: initial;
  }
  .react-dropdown-select-dropdown {
    position: absolute;
  }
`;

const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin: 10px 10px 0;
    line-height: 30px;
    padding: 0px 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
      border: 1px solid red;
    }
  }
`;

const Items = styled.div`
  overflow: auto;
  min-height: 10px;
  max-height: 150px;
`;

const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
`;

const ItemLabel = styled.div`
  margin: 5px 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;

  & div {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
`;

const Button = styled.button`
  background: none;
  border: 1px solid #555;
  color: #555;
  border-radius: 3px;
  margin: 10px 10px 0;
  padding: 3px 5px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;

  &.clear {
    color: tomato;
    border: 1px solid tomato;
  }

  :hover {
    border: 1px solid deepskyblue;
    color: deepskyblue;
  }
`;
