import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import HomeLayout from "./components/layouts/HomeLayout";
import Home from "./pages/home/Home";
import PublicLayout from "./components/layouts/PublicLayout";
import NewsDetailView from "./pages/home/NewsDetailView";
// import Cities from "./pages/cities/Cities";
// import ScrollToTop from "./hooks/ScrollToTop";
import MapHome from "./pages/home/MapHome";
import FeedBackForm from "./pages/FeedBackForm";
import Setting from "./pages/settings/Setting";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Verify from "./pages/auth/Verify";
import ChangePassword from "./pages/auth/ChangePassword";
import SignUp from "./pages/auth/SignUp";
import Login from "./pages/auth/Login";
import { AuthProvider } from "./context/AuthContext";
import Unauthorized from "./components/functionality/Unauthorized";
import RequireAuth from "./components/functionality/RequireAuth";
import { DashboardLayout } from "./components/layouts/DashboardLayout";
import Articles from "./pages/userPages/common/Articles/Articles";
import Status from "./pages/userPages/common/Status/Status";
import Role from "./pages/userPages/common/Role/Role";
import AddRole from "./pages/userPages/common/Role/AddRole";
import Review from "./pages/userPages/admin/Review/Review";
import PublishArticle from "./pages/userPages/admin/Publish/PublishArticle";
import ViewArticle from "./pages/userPages/common/Articles/ViewArticle";
import UpdateArticle from "./pages/userPages/common/Articles/UpdateArticle";
import Archives from "./pages/userPages/common/Archives/Archives";
import Project from "./pages/userPages/common/Project/Project";
import ProjectHome from "./pages/home/ProjectHome";
import ScrollToTop from "./hooks/ScrollToTop";
import { SearchProvider } from "./context/SearchContext";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect } from "react";
import ReactGA from "react-ga";
import Analytics from "./pages/userPages/common/Analytics/Analytics";
import AssetType from "./pages/userPages/common/AssetType/AssetType";
import AddAssetType from "./pages/userPages/common/AssetType/AddAssetype";
import Amenities from "./pages/userPages/common/Amenities/Amenities";
import AddAmenities from "./pages/userPages/common/Amenities/AddAmenities";
import ProjectStatus from "./pages/userPages/common/ProjectStatus/ProjectStatus";
import AddProjectStatus from "./pages/userPages/common/ProjectStatus/AddProjectStatus";
import TypeofUser from "./pages/userPages/common/TypeofUser/TypeofUser";
import AddTypeofUser from "./pages/userPages/common/TypeofUser/AddTypeofUser";
import { LastLocationProvider } from "react-router-last-location";
import { FilterProvider } from "./context/FilterContext";
import { ScrollToIndexProvider } from "./context/ScrollToIndex";
import { FilterParaProvider } from "./context/FilterParaContext";
// import CreateProject from "./pages/userPages/common/Project/CreateProject";
import NewCreateProject from "./pages/userPages/common/Project/NewCreateProject";
import NewProjectDetails from "./pages/userPages/common/Project/NewProjectDetails";
import  { ShowLayoutProvider } from "./context/ShowLayoutContext";
import { BrowserProvider } from "./context/BrowserContext";

// import NotFound from "./pages/NotFound";
const TRACKING_ID = "G-FX7LT24XX1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <BrowserProvider>
    <AuthProvider>
      <ShowLayoutProvider>
      <ScrollToIndexProvider>
        <SearchProvider>
          <FilterProvider>
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  {/* Dashboard Pages */}

                  <Route
                    path={[
                      "/Articles",
                      "/Article/:id",
                      "/Article/Update/:id",
                      "/Status",
                      "/Role",
                      "/addRole",
                      "/Review",
                      "/Publish",
                      "/Archives",
                      "/ProjectDetail/:id",
                      "/Projects",
                      "/settings",
                      "/Analytics",
                      "/AssetType",
                      "/addAssetType",
                      "/Amenities",
                      "/addAmenities",
                      "/ProjectStatus",
                      "/addProjectstatus",
                      "/TypeofUser",
                      "/addTypeofUser",
                      "/CreateProject",
                      "/NewCreateProject",
                    ]}
                  >
                    <FilterParaProvider>
                      <LastLocationProvider>
                        <DashboardLayout>
                          <Switch>
                            <RequireAuth
                              // <Route
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Articles"
                              component={Articles}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              // <Route
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Projects"
                              component={Project}
                            />
                          </Switch>
                          {/* <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/CreateProject"
                              component={CreateProject}
                              render={(props) => <CreateProject {...props} />}
                            />
                          </Switch> */}
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/CreateProject"
                              component={NewCreateProject}
                              render={(props) => (
                                <NewCreateProject {...props} />
                              )}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Article/:id"
                              component={ViewArticle}
                              render={(props) => <ViewArticle {...props} />}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/ProjectDetail/:id"
                              component={NewProjectDetails}
                              render={(props) => <NewProjectDetails {...props} />}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Archives"
                              component={Archives}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Article/Update/:id"
                              component={UpdateArticle}
                              render={(props) => <UpdateArticle {...props} />}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Status"
                              component={Status}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Admin"]}
                              path="/Role"
                              component={Role}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Role/addRole"
                              component={AddRole}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/settings"
                              component={Setting}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Role/editRole/:id"
                              component={AddRole}
                              render={(props) => <AddRole {...props} />}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Admin"]}
                              path="/Review"
                              component={Review}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Admin"]}
                              path="/Publish"
                              component={PublishArticle}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/AssetType"
                              component={AssetType}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/AssetType/addAssetType"
                              component={AddAssetType}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/AssetType/editAssetType/:id"
                              component={AddAssetType}
                              render={(props) => <AddAssetType {...props} />}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Amenities"
                              component={Amenities}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Amenities/addAmenities"
                              component={AddAmenities}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Amenities/editAmenities/:id"
                              component={AddAmenities}
                              render={(props) => <AddAmenities {...props} />}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/ProjectStatus"
                              component={ProjectStatus}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/ProjectStatus/addProjectstatus"
                              component={AddProjectStatus}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/ProjectStatus/editProjectstatus/:id"
                              component={AddProjectStatus}
                              render={(props) => (
                                <AddProjectStatus {...props} />
                              )}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/TypeofUser"
                              component={TypeofUser}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/TypeofUser/addTypeofUser"
                              component={AddTypeofUser}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/TypeofUser/editTypeofUser/:id"
                              component={AddTypeofUser}
                              render={(props) => <AddTypeofUser {...props} />}
                            />
                          </Switch>
                          <Switch>
                            <RequireAuth
                              exact
                              allowedRoles={["Reviewer", "Admin"]}
                              path="/Analytics"
                              component={Analytics}
                            />
                          </Switch>
                        </DashboardLayout>
                      </LastLocationProvider>
                    </FilterParaProvider>
                  </Route>

                  {/* Home pages */}
                  <Route
                    path={[
                      "/home",
                      "/projectStories",
                      "/map",
                      "/dashboard",
                      "/details/:id",
                      "/citiesNews/:id",
                      "/setting",
                      "/favourites",
                      "/projectList",
                      "/project/:id",
                      "/followed",
                    ]}
                  >
                    <LastLocationProvider>
                      <HomeLayout>
                        <Switch>
                          <RequireAuth
                            allowedRoles={["Reviewer", "Admin", "User"]}
                            exact
                            path={[
                              "/home",
                              "/favourites",
                              "/followed",
                              "/projectList",
                              "/citiesNews/:id",
                            ]}
                            component={Home}
                          />
                        </Switch>
                        <Switch>
                          <RequireAuth
                            allowedRoles={["Reviewer", "Admin", "User"]}
                            exact
                            path="/details/:id"
                            component={NewsDetailView}
                          />
                        </Switch>
                        <Switch>
                          <RequireAuth
                            allowedRoles={["Reviewer", "Admin", "User"]}
                            exact
                            path="/project/:id"
                            component={ProjectHome}
                          />
                        </Switch>
                        <Switch>
                          <RequireAuth
                            allowedRoles={["Reviewer", "Admin", "User"]}
                            exact
                            path="/map"
                            component={MapHome}
                          />
                        </Switch>
                        {/* <RequireAuth
                  allowedRoles={["Reviewer", "Admin", "User"]}
                  exact
                  path="/settings"
                  component={Settings}
                /> */}
                        {/* <Route exact path="/citiesNews" component={Cities} /> */}
                        {/* <Switch>
                    <RequireAuth
                      allowedRoles={["Reviewer", "Admin", "User"]}
                      exact
                      path="/citiesNews/:id"
                      component={Cities}
                      render={(props) => <Cities {...props} />}
                    />
                  </Switch> */}
                        <Switch>
                          <RequireAuth
                            allowedRoles={["Reviewer", "Admin", "User"]}
                            exact
                            path="/setting"
                            component={Setting}
                          />
                        </Switch>
                        {/* <Switch>
                    <RequireAuth
                      allowedRoles={["Reviewer", "Admin", "User"]}
                      exact
                      path="/favourites"
                      component={Favourites}
                    />
                  </Switch> */}
                      </HomeLayout>
                    </LastLocationProvider>
                  </Route>

                  {/* Public Pages */}
                  <Route exact path={["/signin"]} component={Login} />
                  <Route exact path={["/"]} component={Login} />
                  <Route
                    path={[
                      "/",
                      "/forgotpassword",
                      "/signUp",
                      "/feedBackForm",
                      "/unauthorized",
                      "/verify-email",
                      "/change-password",
                    ]}
                  >
                    <PublicLayout>
                      {/* <Switch>
                        <Route exact path="/" component={Login} />
                      </Switch> */}
                      <Switch>
                        <Route
                          exact
                          path="/forgotpassword"
                          component={ForgotPassword}
                        />
                      </Switch>
                      <Switch>
                        <Route path="/unauthorized" component={Unauthorized} />
                      </Switch>
                      <Switch>
                        <Route exact path="/signUp" component={SignUp} />
                      </Switch>
                      <Switch>
                        <Route exact path="/verify-email" component={Verify} />
                      </Switch>
                      <Switch>
                        <Route
                          exact
                          path="/change-password"
                          component={ChangePassword}
                        />
                      </Switch>
                      <Switch>
                        <RequireAuth
                          allowedRoles={["Reviewer", "Admin", "User"]}
                          exact
                          path="/feedBackForm"
                          component={FeedBackForm}
                        />
                      </Switch>
                    </PublicLayout>
                  </Route>
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </FilterProvider>
        </SearchProvider>
      </ScrollToIndexProvider>
      </ShowLayoutProvider>
    </AuthProvider>
    </BrowserProvider>
  );
}

export default App;
