import {  Typography } from "@material-tailwind/react";

const Unauthorized = () => {

  return (
    <section className="mt-5">
      <Typography variant="h1">Unauthorized</Typography>
      <p>You do not have access to the requested page.</p>{" "}
    </section>
  );
};

export default Unauthorized;
