import React from "react";

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-xs font-medium">
    {props.label}
    {props.isRequired && (
      <>
        {" "}
        <span className="ml-1 text-warn-error block text-sm font-medium ">
          *
        </span>
      </>
    )}
  </p>
);
export default function TextField(props) {
  return (
    <div className="flex flex-col gap-1">
      <Label label={props?.label} isRequired={props?.isRequired} />
      <textarea
      rows={props.rows}
        type={props?.type}
        id={props.name}
        name={props.name}
        label={props.label}
        defaultValue={props.defaultValue}
        onChange={props?.onChange}
        className={`${
          props.error ? "border-2 border-warn-error" : "border border-gray-300"
        } peer w-full px-3 py-1 z-0   placeholder-gray-500  border border-gray-300 rounded-md focus:outline-none  focus:z-10 text-xs`}
      />
      <p className="font-semibold text-warn-error text-xs">
        {props.helpertext}
      </p>
    </div>
  );
}
