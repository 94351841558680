/* eslint-disable no-unused-vars */
import React from "react";
import InputwithMenu from "../../../../components/ui/InputwithMenu";
import { showMessage } from "../../../../components/ui/showMessage";
import { PROJECT_ATTRIBUTES } from "../../../../services/APIGlobal";
import axiosInstance from "../../../../services/APIInstance";

export default function ProjectAttribute(props) {
  const [projectAttributes, setProjectAttributes] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    GET_VALUES(); // eslint-disable-next-line
  }, []);

  // Get API
  const GET_VALUES = () => {
    setLoading(true);
    axiosInstance
      .get(`${PROJECT_ATTRIBUTES}`)
      .then((response) => {
        setProjectAttributes(response?.data?.getProjectAttribute);
        setLoading(false);
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  const handleSelectionChange = (selectedOptions, setAttributeName) => {
    setAttributeName(selectedOptions);
  };

  const handleInputChange = (event, setValue) => {
    setValue((prevValue) => ({
      ...prevValue,
      [event.target.name]: event.target.value,
    }));
  };

  const generateInputWithMenu = (
    name,
    value,
    setValue,
    attributeName,
    setAttributeName,
    defaultValue,
    defaultName
  ) => (
    <div className="w-full" key={name}>
      <InputwithMenu
        menuOptions={projectAttributes}
        name={name}
        defaultValue={defaultValue}
        value={value[attributeName]}
        defaultName={defaultName}
        menuOption={attributeName}
        setMenuOption={(e) => handleSelectionChange(e, setAttributeName)}
        onChange={(e) => handleInputChange(e, setValue, attributeName)}
      />
    </div>
  );

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <div className="flex md:flex-row flex-col md:gap-5 gap-3 flex-wrap">
          {generateInputWithMenu(
            "projectValue1",
            props?.attributeValue,
            props?.setAttributeValue,
            props?.attributeName,
            props?.setAttributeName,
            props?.attributeDefaultValues? props?.attributeDefaultValues[0]:"",
            props?.attributeDefaultName?props?.attributeDefaultName[0]:""
          )}
          {generateInputWithMenu(
            "projectValue2",
            props?.attributeValue2,
            props?.setAttributeValue2,
            props?.attributeName2,
            props?.setAttributeName2,
            props?.attributeDefaultValues?props?.attributeDefaultValues[1]:"",
            props?.attributeDefaultName? props?.attributeDefaultName[1]:""
          )}
          {generateInputWithMenu(
            "projectValue3",
            props?.attributeValue3,
            props?.setAttributeValue3,
            props?.attributeName3,
            props?.setAttributeName3,
            props?.attributeDefaultValues?props?.attributeDefaultValues[2]:"",
            props?.attributeDefaultName? props?.attributeDefaultName[2]:""
          )}
          {generateInputWithMenu(
            "projectValue4",
            props?.attributeValue4,
            props?.setAttributeValue4,
            props?.attributeName4,
            props?.setAttributeName4,
            props?.attributeDefaultValues?props?.attributeDefaultValues[3]:"",
            props?.attributeDefaultName? props?.attributeDefaultName[3]:""
          )}
        </div>
      )}
    </>
  );
}
