import React from "react";

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-xs font-medium items-start justify-start">
    {props.label}
    {props.isRequired && (
      <>
        <span className="ml-1 pb-0 text-warn-error  text-xs font-medium ">
          *
        </span>
      </>
    )}
  </p>
);
export default function InputField(props) {
  return (
    <div className="flex flex-col gap-1">
      <Label label={props?.label} isRequired={props?.isRequired} />
      <input
        type={props?.type}
        id={props.name}
        name={props.name}
        label={props.label}
        defaultValue={props.defaultValue}
        // value={props.value}
        disabled={props.disable}
        onChange={props?.onChange}
        className={`${
          props.error ? "border-2 border-warn-error" : "border border-gray-300 "
        } peer w-full px-3 py-2 z-0   placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
      />
      {props.helpertext &&<p className="font-semibold text-warn-error text-xs">
        {props.helpertext}
      </p>}
    </div>
  );
}
