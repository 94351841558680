import React from "react";
import RoleDB from "../../../../components/datatables/RoleDB";
import { Button, Card } from "@material-tailwind/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useHistory } from "react-router-dom";
import SearchField from "../../../../components/ui/SearchField";
import { useState } from "react";

export default function Role() {
  const history = useHistory();
  const [globalFilter, setGlobalFilter ] = useState();

  return (
    <Card className="h-full w-full border-t p-4">
      {/* <div className=" absolute cursor-pointer right-5 top-2 z-100 "> */}
      <div className="flex md:flex-row flex-col justify-between gap-2">
        <div className="md:w-72 md:order-first order-last">
        <SearchField filter={globalFilter} setfilter={setGlobalFilter} />
        </div>
        <div >
        <Button
          size="sm"
          variant="outlined"
          onClick={(e) => {
            history.push("/Role/addRole");
          }}
          className="w-full text-primary-primary border-primary-primary flex items-center gap-3  cursor-pointer ring-0 active:ring-0 focus:ring-0"
        >
          <PlusIcon strokeWidth={2} className="h-5 w-5" /> Add Role
        </Button>
        </div>
      </div>
      <RoleDB   filter={globalFilter}/>
    </Card>
  );
}
