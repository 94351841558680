import { Button, Card } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { AutoCompleteMenu } from "../../components/functionality/AutoCompleteMenu";
import { STATES } from "../../utils/State";
// import NewsLetterAlerts from "./NewsLetterAlerts";
import {
  ADDPREFERENCES,
  ASSETTYPE,
  PROJECT_STATUS_TYPE,
} from "../../services/APIGlobal";
import { showMessage } from "../../components/ui/showMessage";
import axiosInstance from "../../services/APIInstance";
import Loader from "../../components/ui/Loader";

const Label = (props) => (
  <span className="flex flex-row text-gray-500 text-xs font-medium">
    {props.label}
  </span>
);

const returnValues = (values) => {
  const subValues = values.map((value) => value?.label);
  return subValues.toString();
};
export default function TopicSetting(props) {
  const [statusOptions, setStatusOptions] = useState([]);
  const [assetOption, setAssetOption] = React.useState();
  const [locationOption, setLocationOption] = React.useState();
  const [selectLocations, setSelectLocations] = useState([]);
  const [selectTopics, setSelectTopics] = useState([]);
  const [selectTypes, setSelectTypes] = useState([]); // eslint-disable-next-line
  const [defaultSelectLocations, setDefaultSelectLocations] = useState([]); // eslint-disable-next-line
  const [defaultSelectTopics, setDefaultSelectTopics] = useState([]); // eslint-disable-next-line
  const [defaultSelectTypes, setDefaultSelectTypes] = useState([]); // eslint-disable-next-line
  const [loading, setLoading] = useState(true); // eslint-disable-next-line
  const [alerts, setAlerts] = useState("Daily"); // eslint-disable-next-line
  const [selectedDays, setSelectedDays] = useState([]); // eslint-disable-next-line
  const [defaultSelected, setDefaultSelected] = useState([]);

  useEffect(() => {
 // eslint-disable-next-line
    GET_STATUS(); // eslint-disable-next-line
  }, []);

  // Get API
  const GET_STATUS = () => {
    const locationArray = STATES.map((item) => {
      return { label: item?.label, value: item?.label };
    });
    setLocationOption(locationArray);
    axiosInstance
      .get(`${PROJECT_STATUS_TYPE}`)
      .then((response) => {
        const Options = response?.data?.getArticleProjectStatusType.map((item) => {
          return { label: item?.at_name, value: item?.at_name };
        });
        setStatusOptions(Options);
      })
      .catch((error) => {
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
    axiosInstance
      .get(`${ASSETTYPE}`)
      .then((response) => {
        const Options = response?.data?.getAssetType.map((item) => {
          return { label: item?.Name, value: item?.Name };
        });
        setAssetOption(Options);
        setLoading(false)
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };
  // Get API
  // const GET_PREFERENCES = () => {
  //   axiosInstance
  //     .get(`${ADDPREFERENCES}/${localStorage.getItem("userId")}`)
  //     .then((response) => {
  //       setAlerts(response?.data?.userPreferences?.alerttype);
  //       setDefaultSelected(response?.data?.userPreferences?.alerts);
  //       let locationArray,
  //         topicArray,
  //         projectTypeArray = [];
  //       if (response?.data?.userPreferences?.loc) {
  //         const dataArray = response?.data?.userPreferences?.loc.split(",");
  //         locationArray = dataArray?.map((item) => {
  //           const trimmedItem = item?.trim(); // Remove any leading/trailing spaces
  //           return { label: trimmedItem, value: trimmedItem };
  //         });
  //         setDefaultSelectLocations(locationArray);
  //       }
  //       if (response?.data?.userPreferences?.topics) {
  //         const dataArray = response?.data?.userPreferences?.topics.split(",");
  //         topicArray = dataArray.map((item) => {
  //           const trimmedItem = item.trim(); // Remove any leading/trailing spaces
  //           return { label: trimmedItem, value: trimmedItem };
  //         });
  //         setDefaultSelectTopics(topicArray);
  //       }
  //       if (response?.data?.userPreferences?.projectTypes) {
  //         const dataArray =
  //           response?.data?.userPreferences?.projectTypes.split(",");
  //         projectTypeArray = dataArray.map((item) => {
  //           const trimmedItem = item.trim(); // Remove any leading/trailing spaces
  //           return { label: trimmedItem, value: trimmedItem };
  //         });
  //         setDefaultSelectTypes(projectTypeArray);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       // setTimeout(() => {
  //       //   showMessage(
  //       //     error?.response?.data?.error,
  //       //     "error",
  //       //     "red",
  //       //     false,
  //       //     false,
  //       //     false
  //       //   );
  //       // }, 2500);
  //       setLoading(false);
  //     });
  // };
  // const options = () =>

  //   STATES.map((option) => {
  //     label: option.label,
  //     value: option.label,
  //   });

  // Submit
  const submitPreference = (e) => {
    e.preventDefault();
    // console.log(selectedDays)
    // const days= selectedDays.filter(e=>e.value === true)
    // const arrayofDays = days.map(e => {return e.label})
    let Data = {
      userId: parseInt(localStorage.getItem("userId")),
      topics:
        !!selectTopics.length === true
          ? returnValues(selectTopics)
          : returnValues(defaultSelectTopics),
      projectTypes:
        !!selectTypes.length === true
          ? returnValues(selectTypes)
          : returnValues(defaultSelectTypes),
      location:
        !!selectLocations.length === true
          ? returnValues(selectLocations)
          : returnValues(defaultSelectLocations),
      alerttype: "",
      alerts:  "",
    };
    axiosInstance
      .post(ADDPREFERENCES, Data)
      .then((response) => {
        setLoading(false);
        showMessage(
          response?.data?.message,
          "success",
          "#576d69",
          false,
          false,
          false
        );
        setTimeout(() => {
          setLoading(false);
        }, 2500);
        props.openNext(3);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };
  return (
    <div className="flex justify-center   pb-4   z-0">
      <Loader loading={loading} />
      {!loading && (
        <Card className="p-4  h-96  w-full border-t-2 ">
          <section className="flex     flex-col md:flex-row flex-wrap gap-5">
            <div className="flex flex-col gap-1 z-100 md:w-96 md:min-w-[1000px] ">
              {/* Locations */}
              <Label label="Locations" />
              <AutoCompleteMenu
                name="Locations"
                setSelectLocations={setSelectLocations}
                options={locationOption}
                value={defaultSelectLocations}
              />
            </div>
            <div className="flex flex-col gap-1md:w-96 md:min-w-[1000px]  ">
              {/* Following Topics */}
              <Label label="Following Topics" />
              <AutoCompleteMenu
                name="Topics"
                setSelectTopics={setSelectTopics}
                options={assetOption}
                value={defaultSelectTopics}
              />
            </div>
            <div className="flex flex-col gap-1 md:w-96 md:min-w-[1000px] ">
              {/* Following Topics */}
              <Label label="Following Project Types" />
              <AutoCompleteMenu
                name="projectType"
                setSelectTypes={setSelectTypes}
                options={statusOptions}
                value={defaultSelectTypes}
              />
            </div>
          </section>

          <div className="flex flex-col gap-1">
            {/* <NewsLetterAlerts
              alerts={alerts}
              setAlerts={setAlerts}
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
              defaultSelected={defaultSelected}
              submitPreference={submitPreference}
            /> */}
            <div className="flex justify-center mt-14">
              <Button
                onClick={(e) => submitPreference(e)}
                variant="filled"
                type="submit"
                className=" bg-primary-primary  text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
              >
                Update
              </Button>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
}
