import { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react";
import { showMessage } from "../../../../components/ui/showMessage";
import Select from "react-dropdown-select";
import { PROJECT_STATUS, PUBLISH } from "../../../../services/APIGlobal";
import axiosInstance from "../../../../services/APIInstance";
import { useEffect } from "react";
import StatusChip from "../../../../components/functionality/StatusChip";
import {
  articleStatus,
  articleStatusforPost,
} from "../../../../store/helper/Helper";
function sortOptionsByLabel(options) {
  return options.sort((a, b) => {
    const numA = parseInt(a.label.split(". ")[0]);
    const numB = parseInt(b.label.split(". ")[0]);
    return numA - numB;
  });
}
export default function ChangeStatus(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusValue, setStatusValue] = useState();
  const [statusOptions, setStatusOption] = useState([]);
  const [comment, setComment] = useState("");
  const [showComment, setShowComment] = useState();
  const handleOpen = () => setOpen((cur) => !cur);

  useEffect(() => {
    GET_VALUES(); // eslint-disable-next-line
    if (props?.value === "3. Denied") {
      setShowComment(true);
    } // eslint-disable-next-line
  }, []);

  // Get API
  const GET_VALUES = () => {
    setLoading(true);
    axiosInstance
      .get(`${PROJECT_STATUS}`)
      .then((response) => {
        setStatusOption(response?.data?.getProjectStatus);
        let label = response?.data?.getProjectStatus.map((item) => {
          return { label: articleStatus(item.Name), value: item.PstatusId };
        });
        sortOptionsByLabel(label);
        setStatusOption(label);
        setLoading(false);
        // showMessage(
        //   response?.data?.message,
        //   "success",
        //   "#576d69",
        //   false,
        //   false,
        //   false
        // );
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  //Submit button click
  const submitPreference = () => {
    setLoading(true);
    // console.log(statusValue);
    let publish = statusValue === "4. Publish" ? "Yes" : "No";
    let DATA = {
      ProjectDetailsId: props?.row?.original?.ProjectDetailsId,
      Status: articleStatusforPost(statusValue),
      comment: comment ? comment : props?.row?.original?.comment,
      IsPublish: publish,
    };
    axiosInstance
      .put(PUBLISH, DATA)
      .then((response) => {
        showMessage(
          response?.data?.message,
          "success",
          "#576d69",
          false,
          false,
          false
        );
        props?.GET_STORIES();
      })
      .catch((error) => {
        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  return (
    <Fragment>
      {!loading && (
        <>
          <div className=" ">
            <Tooltip
              className="border shadow-lg bg-white text-black"
              content="Click on status to change"
            >
              <div size="sm" className="cursor-pointer" onClick={handleOpen}>
                <StatusChip values={articleStatus(props?.value)} icon />
                {/* <span> {props?.value ? props?.value : "Not Submitted"}</span> */}
              </div>
            </Tooltip>
          </div>
          <Dialog
            size="sm"
            open={open}
            handler={handleOpen}
            className="bg-transparent shadow-none  "
          >
            <Card className="mx-auto w-96 ">
              <CardHeader
                variant="gradient"
                className="mb-4 grid h-28 place-items-center bg-primary-light text-primary-primary"
              >
                <Typography variant="h3">Change Status</Typography>
              </CardHeader>
              <CardBody className="flex flex-col gap-4 items-start  ">
                <div className="w-full flex  flex-col gap-2">
                  <p className="flex  justify-start text-gray-500 text-sm font-medium">
                    Status
                  </p>
                  <Select
                    onChange={(value) => {
                      setStatusValue(value[0].label);
                      if (value[0].label === "3. Denied") {
                        setShowComment(true);
                      }
                    }}
                    options={statusOptions}
                    values={[
                      {
                        label: articleStatus(props?.value),
                        value: props?.value,
                      },
                    ]}
                    color="#576d69"
                    style={{
                      borderRadius: "5px",
                    }}
                    className="font-thin border border-primary-primary w-full flex items-center gap-4 text-primary-primary  shadow-none "
                  />
                </div>
                {showComment && (
                  <div className="flex flex-col gap-2 w-full">
                    <p className="flex  justify-start text-gray-500 text-sm font-medium">
                      Comment
                    </p>
                    <textarea
                      defaultValue={props?.row?.original?.comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      className={`peer w-full px-3 py-2 z-0   placeholder-gray-500  border border-gray-300 rounded-md focus:outline-none  focus:z-10 sm:text-sm`}
                    />
                  </div>
                )}
                <div className="w-full"></div>
              </CardBody>
              <CardFooter className="pt-0 flex justify-center">
                <Button
                  variant="text"
                  color="red"
                  onClick={handleOpen}
                  className="mr-1"
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={submitPreference}
                  variant="filled"
                  type="submit"
                  className="bg-primary-primary  text-white shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                >
                  Update Status
                </Button>
              </CardFooter>
            </Card>
          </Dialog>
        </>
      )}
    </Fragment>
  );
}
