import { MapIcon, QueueListIcon } from "@heroicons/react/24/outline";
export const errorFieldMessage = "text-xs text-warn-error text-bold p-0 m-0"
// Home
export const homepara1 =
  "Knowing your markets is more crucial than ever. Redova's proprietary GIS database provide you with an effective method of  seeing the projects you need to know about as you grow your portfolio.";
export const homepara2 =
  "By aggregating numerous information sources into one  database, our tools enable you to see the local pipelines you care about - saving you countless hours of scouring the web, sifting through articles, maps, and trying to stumble across the right information";
export const timeandcost = `By compiling information sources, we put the due diligence at your 
fingertips. Allowing you to do more in less time!
`;
export const fashandeasyUI = `We've designed our platform to be user friendly so you can find the 
project and location information you need.`;
export const ReliableInformation = `By curating specific articles from across the web we are working to 
provide you with the most accurate information available. `;
export const Informed = `Cataloging articles by project and location allows us to give you a 
better view of the progress of each project and keep you updated on 
new information as soon as it becomes available.`;
// side nav for user
export const TXCITIES = [
  {
    id: 0,
    main: "Abbott",
    isActive: true,
    path: "citiesNews/Abbott",
  },
  {
    id: 1,
    main: "Abernathy",
    isActive: true,
    path: "citiesNews/Abernathy",
  },
  {
    id: 2,
    main: "Abilene",
    isActive: true,
    path: "citiesNews/Abilene",
  },
  {
    id: 3,
    main: "Ackerly",
    isActive: true,
    path: "citiesNews/Ackerly",
  },
];
export const TooltipStlye =
  "border shadow-lg  bg-white text-black text-md cursor-pointer";
export const TooltipStlyeForData =
  "bg-black/95 text-white mb-2 2xl:text-base md:text-xs  max-w-xs break-words ";

// User Tab
export const USERTAB = [
  {
    label: "Reviewer",
    value: "Reviewer",
  },
  {
    label: "Admin",
    value: "Admin",
  },
];
// meeting
export const DEFAULTMEETINGS = [
  {
    MeetingType: "",
    status: "",
    MeetingDate: "",
    UrlLink: "",
  },
  {
    MeetingType: "",
    status: "",
    MeetingDate: "",
    UrlLink: "",
  },
  {
    MeetingType: "",
    status: "",
    MeetingDate: "",
    UrlLink: "",
  },
  {
    MeetingType: "",
    status: "",
    MeetingDate: "",
    UrlLink: "",
  },
];
// View Toggle
export const VIEWTOGGLE = [
  {
    label: "Map",
    value: "Map",
    icon: MapIcon,
  },
  {
    label: "List",
    value: "List",
    icon: QueueListIcon,
  },
];

export const CARDDATA = [
  {
    id: 1,
    title: "Dallas is a city for Those who are Seeking Opportunity",
    img: "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    date: "25 March 2023",
    description:
      "The neighborhood townhome project will bring more than 150 rental units ahead of city’s convention center makeover.",
    type: "Apartment",
    reference: "http://www.dallas.com",
    images: [
      "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      "https://s.hdnux.com/photos/01/32/51/15/23761756/3/ratio3x2_1200.jpg",
      "https://photos.zillowstatic.com/fp/114925393f9316b4700ba0e97bd6fbe3-p_e.jpg",
    ],
    location: "Dallas",
    longDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In consequat quam quam. Integer hendrerit aliquam laoreet. Sed sollicitudin urna sit amet justo lobortis, eget fringilla lectus tincidunt. Donec luctus egestas libero eget tempus. Suspendisse ut ullamcorper tellus. Suspendisse convallis, nibh ac luctus sollicitudin, magna eros convallis ipsum, nec elementum lorem tortor eget lectus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In hac habitasse platea dictumst. Curabitur placerat placerat posuere. Aliquam at nunc id nisi luctus fringilla eget sit amet enim. Suspendisse vel lobortis tortor. Aliquam consequat diam ac metus sollicitudin consequat.",
    favorite: true,
    status: "Pending",
    reviewer: "Mr. XYZ",
  },
  {
    id: 2,
    title: "Dallas is a city for Those who are Seeking Opportunity",
    img: "https://s.hdnux.com/photos/01/32/51/15/23761756/3/ratio3x2_1200.jpg",
    date: "25 March 2023",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In consequat quam quam. Integer hendrerit aliquam laoreet. Sed sollicitudin urna sit amet justo lobortis, eget fringilla lectus tincidunt. Donec luctus egestas libero eget tempus. Suspendisse ut ullamcorper tellus. Suspendisse convallis, nibh ac luctus sollicitudin, magna eros convallis ipsum, nec elementum lorem tortor eget lectus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In hac habitasse platea dictumst. Curabitur placerat placerat posuere. Aliquam at nunc id nisi luctus fringilla eget sit amet enim. Suspendisse vel lobortis tortor. Aliquam consequat diam ac metus sollicitudin consequat.",
    type: "Land",
    reference: "http://www.dallas.com",
    images: [
      "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      "https://s.hdnux.com/photos/01/32/51/15/23761756/3/ratio3x2_1200.jpg",
      "https://photos.zillowstatic.com/fp/114925393f9316b4700ba0e97bd6fbe3-p_e.jpg",
    ],
    location: "Dallas",
    longDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In consequat quam quam. Integer hendrerit aliquam laoreet. Sed sollicitudin urna sit amet justo lobortis, eget fringilla lectus tincidunt. Donec luctus egestas libero eget tempus. Suspendisse ut ullamcorper tellus. Suspendisse convallis, nibh ac luctus sollicitudin, magna eros convallis ipsum, nec elementum lorem tortor eget lectus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In hac habitasse platea dictumst. Curabitur placerat placerat posuere. Aliquam at nunc id nisi luctus fringilla eget sit amet enim. Suspendisse vel lobortis tortor. Aliquam consequat diam ac metus sollicitudin consequat.",
    favorite: false,
    status: "Accept",
    reviewer: "Mr. ABC",
  },
  {
    id: 3,
    title: "Dallas is a city for Those who are Seeking Opportunity",
    img: "https://photos.zillowstatic.com/fp/114925393f9316b4700ba0e97bd6fbe3-p_e.jpg",
    date: "25 March 2023",
    description:
      "The neighborhood townhome project will bring more than 150 rental units ahead of city’s convention center makeover.",
    type: "Shop",
    reference: "http://www.dallas.com",
    images: [
      "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      "https://s.hdnux.com/photos/01/32/51/15/23761756/3/ratio3x2_1200.jpg",
      "https://photos.zillowstatic.com/fp/114925393f9316b4700ba0e97bd6fbe3-p_e.jpg",
    ],
    location: "Dallas",
    longDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In consequat quam quam. Integer hendrerit aliquam laoreet. Sed sollicitudin urna sit amet justo lobortis, eget fringilla lectus tincidunt. Donec luctus egestas libero eget tempus. Suspendisse ut ullamcorper tellus. Suspendisse convallis, nibh ac luctus sollicitudin, magna eros convallis ipsum, nec elementum lorem tortor eget lectus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In hac habitasse platea dictumst. Curabitur placerat placerat posuere. Aliquam at nunc id nisi luctus fringilla eget sit amet enim. Suspendisse vel lobortis tortor. Aliquam consequat diam ac metus sollicitudin consequat.",
    favorite: false,
    status: "",
    reviewer: "Mr. XYZ",
  },
  {
    id: 4,
    title: "Dallas is a city for Those who are Seeking Opportunity",
    img: "https://photos.zillowstatic.com/fp/114925393f9316b4700ba0e97bd6fbe3-p_e.jpg",
    date: "25 March 2023",
    description:
      "The neighborhood townhome project will bring more than 150 rental units ahead of city’s convention center makeover.",
    type: "Shop",
    reference: "http://www.dallas.com",
    images: [
      "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      "https://s.hdnux.com/photos/01/32/51/15/23761756/3/ratio3x2_1200.jpg",
      "https://photos.zillowstatic.com/fp/114925393f9316b4700ba0e97bd6fbe3-p_e.jpg",
    ],
    location: "Dallas",
    longDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In consequat quam quam. Integer hendrerit aliquam laoreet. Sed sollicitudin urna sit amet justo lobortis, eget fringilla lectus tincidunt. Donec luctus egestas libero eget tempus. Suspendisse ut ullamcorper tellus. Suspendisse convallis, nibh ac luctus sollicitudin, magna eros convallis ipsum, nec elementum lorem tortor eget lectus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In hac habitasse platea dictumst. Curabitur placerat placerat posuere. Aliquam at nunc id nisi luctus fringilla eget sit amet enim. Suspendisse vel lobortis tortor. Aliquam consequat diam ac metus sollicitudin consequat.",
    favorite: false,
    status: "Denied",
    reviewer: "Mr. ABC",
  },
];
// FIllter options
export const FillterOptions = [
  { id: "1", Name: "Apartments" },
  { id: "2", Name: "Land" },
  { id: "3", Name: "Shops" },
];
// Following topics
export const Topics = [
  { id: "1", value: "Construction" },
  { id: "2", value: "Lease" },
  { id: "3", value: "Sale" },
];
// Following Project Type
export const ProjectType = [
  { id: "1", value: "Office" },
  { id: "2", value: "Multi-Family" },
  { id: "3", value: "Single-Family" },
];

export const WeekdaysOptions = [
  {
    label: "Sunday",
  },
  {
    label: "Monday",
  },
  {
    label: "Tuesday",
  },
  {
    label: "Thursday",
  },
  {
    label: "Friday",
  },
  {
    label: "Saturday",
  },
];

// FeedBack form Title
export const FeedBackTitle =
  "Have a comment, question, or grievence? Let us know!";
export const FeedBackContent =
  "Please let us know with detail what you think. We're always looking for ways to improve our site, our features, and our sources. ";
export const txtLabel1 = "What comments do you have?";
export const txtLabel2 = "Is there a news outlet you would like us to add?";
export const txtLabel3 =
  "Is there a missing feature you'd like to see or a feature we could improve?";

// Map
export const MAP_SATELLITE_STREETS =
  "mapbox://styles/redova-dev/clkucz6rg000a01po5zrkeha1";
export const MAP_STREETS = "mapbox://styles/redova-dev/clqzy0ndv00f701pz2aib0wxb";
export const DARK = "mapbox://styles/redova-dev/clkuee6xh000501oj468e4ghn";
export const LAYER_DATA =
  "https://raw.githubusercontent.com/glynnbird/usstatesgeojson/master/oregon.geojson";

// Article form array removed field
export const RemovedField = [
  "UserId",
  "AssetTypeId",
  "IsPublish",
  "PsId",
  "PsValue",
  "PstatusId",
  "PvId",
  "Status",
  "TypeUserId",
  "Value",
  "updated_at",
  "publishdate",
  "IsReview",
];
// LoginToken
export const LOGIN_TOKEN = `TEM4Uk0ad8NP=8fyr2dQaETAAEhxF=zWQobKoMzxRt20!9eJCXLwiFzwvr5Rr?qouAkNeTYZ0baD3SE2MU/!rveI7EAZJgkTHtnuk2TMYvZ6jbUGTTU8pOKJgqhIoMKF2qxNhy6FgWIEK2tEiQcDdv0Fx2sXFoRfb0DKhbU818ZoW-w=Y/CO/fuLmI74LVSao?WmgFOJOB6P07O8O2hNj2NL7R/J!OyMENmEkpZ9Deriz/xQW=Clu7jUt?poI0kB`;

// Add articles
export const ArticleSource = [
  { name: "Website", value: "" },
  { name: "Article Link", value: "" },
  { name: "Article Author", value: "" },
  { name: "Article Author Image", value: "" },
];
export const ArticleLocation = [
  { name: "Street Address", value: "" },
  { name: "Parcel number or parcel ID", value: "" },
  { name: "Street intersections ", value: "" },
  { name: "City", value: "" },
  { name: "Zip", value: "" },
];
export const projectTeam = [
  { name: "Developer", value: "" },
  { name: "General Contractor", value: "" },
  { name: "Contractor ", value: "" },
  { name: "Owner", value: "" },
  { name: "Architect", value: "" },
  { name: "Planner", value: "" },
  { name: "Investor", value: "" },
  { name: "Lender", value: "" },
  { name: "Bank", value: "" },
  { name: "Civil Engineer", value: "" },
  { name: "Engineer", value: "" },
  { name: "Broker", value: "" },
  { name: "Brokerage", value: "" },
];
export const ProjectStatus = [
  { Name: "For Sale" },
  { Name: "Financed" },
  { Name: "Refinance" },
  { Name: "Development" },
  { Name: "Under development" },
  { Name: "Redevelopment" },
  { Name: "Under construction" },
  { Name: "Construction" },
  { Name: "Plans" },
  { Name: "Closed" },
  { Name: "Registered" },
  { Name: "Complete" },
  { Name: "Completed" },
  { Name: "Open" },
  { Name: "Opening" },
  { Name: "Grand opening" },
  { Name: "Public hearing" },
  { Name: "Planning" },
  { Name: "Zoning" },
  { Name: "Planning commission" },
  { Name: "City council" },
  { Name: "Approved" },
  { Name: "Zoned" },
  { Name: "Rezone" },
  { Name: "Vote" },
  { Name: "Tabled" },
  { Name: "Rejected" },
  { Name: "Listed" },
  { Name: "Announced" },
  { Name: "Released" },
];

export const ProjectValue = [
  { Name: "Undisclosed" },
  { Name: "Cost" },
  { Name: "Value" },
  { Name: "Loan amount" },
  { Name: "LTV" },
  { Name: "LTC" },
  { Name: "$ symbol" },
  { Name: "Million" },
  { Name: "Thousand" },
  { Name: "Billion" },
];

export const ProjectSize = [
  { Name: "Square feet" },
  { Name: "sqft" },
  { Name: "Acre" },
];

export const AssetType = [
  { Name: "Multifamily" },
  { Name: "Apartment" },
  { Name: "Office" },
  { Name: "Hospitality" },
  { Name: "Self-Storage" },
  { Name: "Retail" },
  { Name: "Industrial" },
  { Name: "Flex" },
  { Name: "Healthcare" },
  { Name: "Senior Housing" },
  { Name: "Residential" },
  { Name: "House" },
  { Name: "Home" },
  { Name: "Single-Family-Rentals" },
  { Name: "SFR" },
  { Name: "Lots" },
  { Name: "Land" },
  { Name: "Neighborhood" },
  { Name: "Airport" },
  { Name: "Bond" },
  { Name: "DART" },
  { Name: "Train" },
  { Name: "Station" },
  {
    Name: `Public improvements (such as street or highway expansions, water line extension, etc)`,
  },
];

export const Amenities = [
  { Name: "Air Conditioning" },
  { Name: "Amenity Center" },
  { Name: "Balcony" },
  { Name: "Basement" },
  { Name: "Business Center" },
  { Name: "Cafe" },
  { Name: "Cat Friendly" },
  { Name: "Clubhouse" },
  { Name: "Concierge" },
  { Name: "Controlled Access" },
  { Name: "Den" },
  { Name: "Dishwasher" },
  { Name: "Dog Friendly" },
  { Name: "Dog Park" },
  { Name: "Doorman" },
  { Name: "Elevator" },
  { Name: "Fireplace" },
  { Name: "Fitness Center" },
  { Name: "Furnished" },
  { Name: "Garage" },
  { Name: "Gated" },
  { Name: "Gym" },
  { Name: "Hardwood Floors" },
  { Name: "In Unit Washer & Dryer" },
  { Name: "Laundry Facilities" },
  { Name: "Lobby" },
  { Name: "Lofts" },
  { Name: "Office" },
  { Name: "Parking" },
  { Name: "Patio" },
  { Name: "Playground" },
  { Name: "Pool" },
  { Name: "Utilities Included" },
  { Name: "Walk-In Closets" },
  { Name: "Washer & Dryer Hookups" },
  { Name: "Wheelchair Access" },
  { Name: "Yard" },
];

export const businessUsers = [
  { Name: "Tenant" },
  { Name: "Flag" },
  { Name: "Brand" },
];

// Role
export const ROLEDB = [
  {
    id: 0,
    name: "Mr. ABC",
    role: "Reviewer",
  },
  {
    id: 1,
    name: "Mr. XYZ",
    role: "Admin",
  },
];

export const RoleForm = [
  { name: "First Name", value: "" },
  { name: "Last Name", value: "" },
  { name: "Email", value: "" },
  { name: "Assign Role", value: "", type: "dropdown" },
];

export const RoleOptions = [
  { label: "Reviewer", value: 1 },
  { label: "Admin", value: 2 },
];

export const StatusOptions = [
  { label: "Pending", value: "Pending" },
  { label: "Accept", value: "Accept" },
  { label: "Accept & Publish", value: "AcceptPublish" },
  { label: "Denied", value: "Denied" },
];
export const TexasCities = [
  { Name: "Houston" },
  { Name: "San Antonio" },
  { Name: "Dallas" },
  { Name: "Austin" },
  { Name: "Fort Worth" },
  { Name: "El Paso" },
  // "Arlington",
  // "Corpus Christi",
  // "Plano",
  // "Laredo",
  // "Lubbock",
  // "Garland",
  // "Irving",
  // "Amarillo",
  // "Grand Prairie",
  // "Brownsville",
  // "Pasadena",
  // "McKinney",
  // "Mesquite",
  // "McAllen",
  // "Killeen",
  // "Frisco",
  // "Waco",
  // "Carrollton",
  // "Denton",
  // "Midland",
  // "Abilene",
  // "Beaumont",
  // "Round Rock",
  // "Odessa",
  // "Wichita Falls",
  // "Richardson",
  // "Lewisville",
  // "Tyler",
  // "College Station",
  // "Pearland",
  // "San Angelo",
  // "Allen",
  // "League City",
  // "Sugar Land",
  // "Longview",
  // "Edinburg",
  // "Mission",
  // "Bryan",
  // "Baytown",
  // "Pharr",
  // "Temple",
  // "Missouri City",
  // "Flower Mound",
  // "Harlingen",
  // "North Richland Hills",
  // "Victoria",
  // "Conroe",
  // "New Braunfels",
  // "Mansfield",
  // "Cedar Park",
  // "Rowlett",
  // "Port Arthur",
  // "Euless",
  // "Georgetown",
  // "Pflugerville",
  // "DeSoto",
  // "San Marcos",
  // "Grapevine",
  // "Bedford",
  // "Galveston",
  // "Cedar Hill",
  // "Texas City",
  // "Wylie",
  // "Haltom City",
  // "Keller",
  // "Coppell",
  // "Rockwall",
  // "Huntsville",
  // "Duncanville",
  // "Sherman",
  // "The Colony",
  // "Burleson",
  // "Hurst",
  // "Lancaster",
  // "Texarkana",
  // "Friendswood",
  // "Weslaco"
];
