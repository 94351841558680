import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  // TabsHeader,
  // Tabs,
  // Tab,
} from "@material-tailwind/react";
import { useFormik } from "formik";
import { useState } from "react";
// import { USERTAB } from "../utils/Constant";
import { useHistory } from "react-router-dom";
import { ForgotPassSchema } from "../../store/Validations/Validation";
import Loader from "../../components/ui/Loader";
import { FORGOTPASS } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../../components/ui/showMessage";
import InputField from "../../components/ui/InputField";

export default function ForgotPassword() {
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  // Reset email
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPassSchema,
    onSubmit: (values) => {
      setLoading(true);
      let DATA = {
        email: values.email,
      };
      axiosInstance
        .post(FORGOTPASS, DATA)
        .then((response) => {
          setTimeout(() => {
            setLoading(false);
          }, 1600);
          setTimeout(() => {
            showMessage(
              response?.body?.message,
              "success",
              "#576d69",
              false,
              false,
              false
            );
          }, 1750);
          setTimeout(() => {
            history.push("/");
          }, 1900);
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error?.response?.data?.error, "error", "red", false, false, false);
        });
    },
  });

  return (
    <div className="flex items-center justify-center md:m-5 lg:m-5">
      <Loader loading={loading} />
      <Card className="w-96 m-2 lg:m-0 border-t-2" shadow={true}>
        <CardHeader
          floated={false}
          variant="gradient"
          shadow={true}
          className="bg-primary-light  mb-1 grid h-28 place-items-center"
        >
          <Typography variant="h3" className="text-primary-primary font-black text-center">
            Forgot Password ?
          </Typography>
        </CardHeader>
        <CardBody>
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-1">
            <div >
              <InputField
                name="email"
                label="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helpertext={formik.touched.email && formik.errors.email}
                isRequired
              />
            </div>
            {/* Action Button */}
            <div className="flex justify-between gap-5 mt-5">
              <Button
                variant="filled"
                type="submit"
                className="bg-primary-primary text-white w-full shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
              >
                Submit
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}
