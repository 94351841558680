import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP__PUBLIC_API_URL,
  headers: {
    'Accept':'*/*',
    "Content-Type": "application/json",
  },
});

// axiosInstance.interceptors.request.use(
//   (config) => {
//     let token = localStorage.getItem("token");
//     if (token !== null) config.headers.Authorization = "Bearer " + token;
//     return config;
//   },
//   (error) => {
//     console.log(error)
//   }
// );

export default axiosInstance;
