import React from "react";
import CheckboxArray from "../../../../components/functionality/CheckboxArray";
import { showMessage } from "../../../../components/ui/showMessage";
import { ASSETTYPE } from "../../../../services/APIGlobal";
import axiosInstance from "../../../../services/APIInstance";

export default function ProjectAssetsType(props) {
  const [assetOption, setAssetOption] = React.useState();
  const [defaultAssetType, setDefaultAssetType] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    GET_VALUES();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {}, [props?.data?.AssetType]);

  // Get API
  const GET_VALUES = () => {
    setLoading(true);
    axiosInstance
      .get(`${ASSETTYPE}`)
      .then((response) => {
        setAssetOption(response?.data?.getAssetType);
        // Check assettype is available or not
        if (props?.data?.AssetType) {
          let AssetDefaultValueListing = props?.data?.AssetType.split(",").map(
            (value) => value.trim()
          );
          let defaultValues = AssetDefaultValueListing.filter((item) => {
            return response?.data?.getAssetType.some((value) =>
              value.Name.includes(item)
            );
          });
          setDefaultAssetType(defaultValues);
        }
        setLoading(false);
      })
      .catch((error) => {
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  const handleCheckboxChange = (selectedOptions, name) => {
    let result = selectedOptions.length < 1 ? null : selectedOptions;
    props?.setAssetType(result);
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <div className="flex flex-col gap-2">
          <div
            className={` border rounded-md ${
              (props?.formErrors?.assetTypeError && (props?.assetType?.length === 0|| !props?.assetType))
                ? "border-warn-error"
                : "border-gray-300"
            }`}
          >
            <CheckboxArray
              name="Assets"
              textareaRefs={props?.textareaRefs}
              options={assetOption}
              defaultChecked={defaultAssetType ? defaultAssetType : []}
              onChange={handleCheckboxChange}
              className="grid gap-3 !grid-cols-2 md:!grid-cols-4 2xl:!grid-cols-4"
            />
          </div>
        </div>
      )}
    </>
  );
}
