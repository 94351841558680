import React, { useContext, useEffect, useState } from "react";
import FilterContext from "../../../context/FilterContext";
import SearchContext from "../../../context/SearchContext";
import { useApiGETRequest } from "../../../hooks/useApiHook";
import FeedBackForm from "../../../pages/FeedBackForm";
import { PROJECT_STATUS_TYPE } from "../../../services/APIGlobal";
import FillterDropDown from "../../functionality/FillterMenu";
import ProjectStatusFilter from "../../functionality/ProjectStatusFilter";
import UpdateTimeFilter from "../../functionality/UpdateTimeFilter";
import MapboxGeocoderInput from "../../ui/Map/MapboxGeeocoderInput";
import SubHeader from "./SubHeader";

export default function IpdadHeader() {
  const { filterArray, setFilterArray } = useContext(FilterContext); // eslint-disable-next-line
  const [load, setLoading] = useState(false);
  const [assetFilter, setAssetFilter] = useState([]);
  const [projectStatusFilter, setProjectStatusFilter] = useState([]);
  const [dateRangeValue, setDateRangeValue] = useState(0); // eslint-disable-next-line
  const { setSearchValue, searchValue, setSearchDefaultValue } =
    useContext(SearchContext); // eslint-disable-next-line
  const [defaultProjectStatusFiltter, setDefaultProjectStatusFiltter] =
    useState([]); // eslint-disable-next-line
  const [defaultAssetValues, setDefaultAssetValues] = useState([]); // eslint-disable-next-line
  const [defaultTimeValue, seDefaultTimeValue] = useState("");
  const { Apidata } = useApiGETRequest(PROJECT_STATUS_TYPE);
  const [isFavourite, setIsFavourite] = useState(filterArray?.FAVOURITE);
  const handleSelectChange = (values) => {
    setProjectStatusFilter(values);
    // Perform logic based on the selected values in the multi-select dropdown
  };
  const [isIpad, setIsIpad] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsIpad(window.innerWidth >= 768 && window.innerWidth < 1024);
    };

    // Initial check on component mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleToggleChange = (e) => {
    e.preventDefault();
    setIsFavourite(!isFavourite);

    // if (isFavourite) {
    //   history.push("/projectList");
    // } else if (!isFavourite) {
    //   history.push("/favourites");
    // }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setFilterArray((prevFilterArray) => ({
      ...prevFilterArray,
      ASSETTYPE: assetFilter,
      DATE: dateRangeValue,
      SEARCH: searchValue,
      PROJECTSTATUS: projectStatusFilter,
      FAVOURITE: isFavourite,
    }));
    // eslint-disable-next-line
  }, [
    assetFilter,
    dateRangeValue,
    searchValue,
    projectStatusFilter,
    isFavourite,
  ]);

  return (
    <>
      {isIpad ? (
        <div className="sm:flex  z-100 gap-3 flex-col   lg:px-5 text-lg font-medium p-2 text-center text-black bg-white  border-b shadow-md h-full">
          {" "}
          <div className="flex flex-row justify-between w-full">
            <ProjectStatusFilter
              options={Apidata?.getArticleProjectStatusType}
              onCheckboxChange={handleSelectChange}
              onSelectChange={handleSelectChange}
              defaultValues={defaultProjectStatusFiltter}
            />
            {load ? (
              <p>loading</p>
            ) : (
              <FillterDropDown
                assetFillter={assetFilter}
                setAssetFillter={setAssetFilter}
                defaultValues={defaultAssetValues}
              />
            )}
            <UpdateTimeFilter
              setDateRangeValue={setDateRangeValue}
              dateRangeValue={dateRangeValue}
              // setStartDateValue={setStartDateValue}
              // setEndtDateValue={setEndtDateValue}
              defaultTimeValue={defaultTimeValue}
            />
            <FeedBackForm />
          </div>
          <div className="flex flex-row justify-start items-center gap-5">
            {" "}
            <div className=" justify-center  w-full  ">
              <MapboxGeocoderInput
                setSearchValue={setSearchValue}
                searchValue={searchValue}
              />
            </div>
            <div
              className={`hover:cursor-pointer rounded-lg   justify-center items-center px-3  text-center md:flex hidden  text-sm   h-10 ${
                filterArray?.FAVOURITE
                  ? "font-bold bg-primary-redova-favorite text-white"
                  : "bg-primary-primary text-white border border-primary-primary "
              }`}
              onClick={handleToggleChange}
            >
              Favorite
            </div>
          </div>
        </div>
      ) : (
        <SubHeader />
      )}{" "}
    </>
  );
}
