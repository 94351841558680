import React, { useState, useEffect, useRef, useContext } from "react";
import { Card, List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import {
  DocumentMagnifyingGlassIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import FilterContext from "../../../context/FilterContext";

const BASEURL = process.env.REACT_APP__PUBLIC_API_URL;

const MapboxGeocoderInput = ({
  onPlaceSelect,
  setSearchValue,
  searchValue,
}) => {
  const [query, setQuery] = useState("");
  const { setIsSuggestion, setSugestionLocation } = useContext(FilterContext);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1); // Track selected item index
  const inputRef = useRef(null);
  const suggestionRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        suggestionRef.current &&
        !suggestionRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = async (event) => {
    setIsSuggestion(false);
    const inputValue = event.target.value;
    setQuery(inputValue);
    try {
      const mapboxResponse = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          inputValue
        )}.json?types=place&country=US&access_token=pk.eyJ1IjoicmVkb3ZhLWRldiIsImEiOiJjbGt1ZG40MWcwaHJ6M2NwZjdobjI3MjNkIn0.sAzBSriLd-x3w6zRWFV5YA`
      );
      const mapboxData = await mapboxResponse.json();

      const sortedSuggestions = mapboxData.features.sort((a, b) => {
        const isInTexas = (feature) =>
          feature.context.find((context) => context.text === "Texas");
        const aIsInTexas = isInTexas(a);
        const bIsInTexas = isInTexas(b);

        if (aIsInTexas && !bIsInTexas) return -1;
        if (!aIsInTexas && bIsInTexas) return 1;

        return 0; // Default to no sorting
      });

      const mapboxSuggestions = sortedSuggestions.map((feature) => ({
        name: feature.place_name,
        isPlace: true, // Indicates that it's a place suggestion
        latitude: feature.center[1],
        longitude: feature.center[0],
      }));

      const projectResponse = await fetch(
        `${BASEURL}/projects/project-suggestion?flag=1&searchQuery=${encodeURIComponent(
          inputValue
        )}&isPublish=yes&sortOrder=desc&sortColumn=created_at&isDraft=no`
      );
      const projectData = await projectResponse.json();
      const projectSuggestions = projectData.projects.map((project) => ({
        name: project.title,
        isPlace: project.isLocation,
        latitude: project.latitude,
        longitude: project.longitude,
      }));

      let mergedArray = [...mapboxSuggestions, ...projectSuggestions];
      setSuggestions(mergedArray);
      setShowSuggestions(true);
      // Conditionally set searchValue only if mapboxResponse is successful
      if (mapboxResponse.ok) {
        setSearchValue(inputValue);
      }
      setSelectedItem(0); // Reset selected item to the first one when suggestions update
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handlePlaceSelect = (place) => {
    if(!place.isPlace){
      setSearchValue(place.name);
    }
    else if(place.isPlace){
      setSearchValue("");
    }
    setTimeout(() => {
      setSugestionLocation({
        longitude: place.longitude,
        latitude: place.latitude,
        isPlace: place.isPlace,
      });
    },500);
    setQuery(place.name);
    setShowSuggestions(false);
    setIsSuggestion(true);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      setShowSuggestions(false);
      if (selectedItem !== -1 && suggestions.length > 0) {
        const selectedPlace = suggestions[selectedItem];
        if(!selectedPlace.isPlace){
          setSearchValue(selectedPlace.name);
        }
        else if(selectedPlace.isPlace){
          setSearchValue("");
        }
        setSugestionLocation({
          longitude: selectedPlace.longitude,
          latitude: selectedPlace.latitude,
          isPlace: selectedPlace.isPlace,
        });

        setQuery(selectedPlace.name);
        setIsSuggestion(true);
      }
    }
  };

  const handleArrowNavigation = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault(); // Prevent cursor from moving in input field
      setSelectedItem((prev) =>
        prev < suggestions.length - 1 ? prev + 1 : suggestions.length - 1
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault(); // Prevent cursor from moving in input field
      setSelectedItem((prev) => (prev > 0 ? prev - 1 : 0));
    }
  };

  return (
    <div className="w-full">
      <div className="relative w-full" ref={inputRef}>
        <div className="absolute inset-y-0 left-0 pl-3 pt-2">
          <svg
            className="h-6 w-6 text-primary-primary mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <input
          value={query || searchValue}
          onChange={handleInputChange}
          placeholder="Search for a location..."
          className="w-full pl-10 pr-12 h-10 text-sm rounded-lg border focus:outline-none border-primary-primary"
          onKeyDown={(e) => {
            handleArrowNavigation(e);
            handleEnterKeyPress(e);
          }}
        />
        {query && (
          <div
            className="absolute inset-y-0 right-0 pr-3 pt-2 cursor-pointer"
            onClick={(e) => {
              setQuery("");
              setSearchValue("");
              setSuggestions([]);
              setIsSuggestion(false);
            }}
          >
            <svg
              className="h-6 w-6 text-gray-400 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        )}
      </div>

      {showSuggestions && suggestions.length > 0 && (
        <Card className="w-96 fixed h-48 overflow-y-scroll" ref={suggestionRef}>
          <List className="!items-start">
            {suggestions.map((place, index) => {
              const isPlace = place.isPlace;
              let nameElement;
              const nameParts = place.name.split(new RegExp(`(${query})`, "i"));
              nameElement = (
                <div className="w-full flex-wrap font-extralight">
                  {nameParts.map((part, i) => (
                    <span
                      key={i}
                      className={
                        part.toLowerCase() === query.toLowerCase()
                          ? "font-bold"
                          : "font-normal"
                      }
                    >
                      {part}
                    </span>
                  ))}
                </div>
              );

              return (
                <ListItem
                  onClick={() => handlePlaceSelect(place)}
                  className={`text-black text-sm cursor-pointer p-1 !items-start ${
                    index === selectedItem ? "bg-gray-200" : ""
                  }`}
                  key={index}
                >
                  <ListItemPrefix className="mr-1 flex">
                    {isPlace ? (
                      <MapPinIcon className="h-4 w-4" />
                    ) : (
                      <DocumentMagnifyingGlassIcon className="h-5 w-5" />
                    )}
                  </ListItemPrefix>
                  {nameElement}
                </ListItem>
              );
            })}
          </List>
        </Card>
      )}
    </div>
  );
};

export default MapboxGeocoderInput;
