import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import { useState } from "react";
import AssetTypeFilter from "./AssetTypeFilter";
import { useApiGETRequest } from "../../hooks/useApiHook";
import { ASSETTYPE, GETFILLTERLOCATION } from "../../services/APIGlobal";
import checked from "../../assets/Images/check.svg";
export default function FillterDropDown({
  options,
  selectedOptions,
  setSelectedOptions,
  setAssetFillter,
  statusFillter,
  location,
  defaultValues
}) {// eslint-disable-next-line 
  const [openPopover, setOpenPopover] = useState(false);

  const { Apidata, isLoaded } = useApiGETRequest(!location ? ASSETTYPE : GETFILLTERLOCATION);

  // const handleCheckboxChange = (values) => {
  //   setCheckboxValues(values);
  //   console.log(values,"valuesvaluesvalues")
  //   // Perform logic based on the checkbox values
  // };

  const handleSelectChange = (values) => {
    setAssetFillter(values);
    // Perform logic based on the selected values in the multi-select dropdown
  };

  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };

  return (
    <section className="flex flex-row gap-2">
      {!isLoaded && (
        <AssetTypeFilter
          options={!location ? Apidata?.getAssetType : Apidata?.location}
          onCheckboxChange={handleSelectChange}
          onSelectChange={handleSelectChange}
          location={location}
          defaultValues={defaultValues}
        />
      )}
      {statusFillter && (
        <Popover placement="bottom" >
          <PopoverHandler >
            <Button
              variant="outlined"
              size="sm"
              className="text-xs  h-10  normal-case flex items-center gap-2 text-primary-primary border-primary-primary focus:ring-0"
            >
              Status
              <ChevronUpDownIcon strokeWidth={2} className="h-5 w-5" />
            </Button>
          </PopoverHandler>
          <PopoverContent {...triggers} className=" z-100 md:w-48">
            <div className="flex flex-col ml-2">
              <p
                onClick={(event) => {
                  event.preventDefault();
                  setOpenPopover(false);
                  setSelectedOptions("All");
                }}
                variant="small"
                key="All"
                className={
                  selectedOptions === "All"
                    ? "text-primary-hover font-medium flex flex-row gap-2  w-full rounded-sm p-1 hover:cursor-pointer"
                    : "p-1 hover:cursor-pointer text-black w-full flex flex-row gap-2 "
                }
              >
                <span className="w-4">
                  {selectedOptions === "All" && (
                    <img alt="checkbox" src={checked} width={20} />
                  )}
                </span>
                <span>All</span>
              </p>
              {options?.map(({ Name }) => {
                // Display "Publish" instead of "Accept & Publish" when selected
                const displayName =
                  Name === "Accept & Publish" ? "Published" : Name;

                return (
                  <p
                    onClick={() => {
                      setSelectedOptions(Name);
                      setOpenPopover(false);
                    }}
                    variant="small"
                    key={Name}
                    className={
                      selectedOptions === Name
                        ? "w-full text-primary-hover font-medium flex flex-row gap-2 rounded-sm p-1 hover:cursor-pointer"
                        : "w-full p-1 hover:cursor-pointer text-black flex flex-row gap-2"
                    }
                  >
                    <span className="w-4">
                      {selectedOptions === Name && (
                        <img alt="checkbox" src={checked} width={20} />
                      )}
                    </span>
                    <span>{displayName}</span>{" "}
                    {/* Use the conditional display name */}
                  </p>
                );
              })}
            </div>
          </PopoverContent>
        </Popover>
      )}
    </section>
  );
}
