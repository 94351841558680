import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  // TabsHeader,
  // Tabs,
  // Tab,
} from "@material-tailwind/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
// import { USERTAB } from "../utils/Constant";
import { LoginValidationSchema } from "../../store/Validations/Validation";
import { useHistory } from "react-router-dom";
import Loader from "../../components/ui/Loader";
import { showMessage } from "../../components/ui/showMessage";
// import { LOGIN_TOKEN } from "../../utils/Constant";
import useAuth from "../../hooks/useAuth";
import Checkbox from "../../components/ui/Checkbox";
import { LOGIN } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import axios from "axios";
// import { LOGIN } from "../../services/APIGlobal";
// import axiosInstance from "../../services/APIInstance";

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-xs font-medium">
    {props.label}
    {props.isRequired && (
      <>
        {" "}
        <span className="ml-1 text-warn-error block text-sm font-medium ">
          *
        </span>
      </>
    )}
  </p>
);

export default function Login() {
  const { setAuth } = useAuth();
  let history = useHistory();

  const [loading, setLoading] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const [togglePasswordType, setTogglePasswordType] = useState(false);

  useEffect(() => {
    // Retrieve stored login credentials and pre-fill the form, if "Remember me" was checked previously
    const storedUsername = localStorage.getItem("rememberedUsername");
    const storedPassword = localStorage.getItem("rememberedPassword");
    if (storedUsername && storedPassword) {
      setRememberMe(true);
    }
  }, []);

  function togglePasswordVisibility() {
    setTogglePasswordType((prevState) => !prevState);
  }

  //   Login Button Click
  const LoginClick = (values) => {
    setLoading(true);
    let DATA = {
      email: values.email,
      password: values.password,
    };
    // If "Remember me" is checked, store the login credentials
    if (rememberMe) {
      localStorage.setItem("rememberedUsername", values.email);
      localStorage.setItem("rememberedPassword", values.password);
    } else {
      // If not checked, remove any previously stored credentials
      localStorage.removeItem("rememberedUsername");
      localStorage.removeItem("rememberedPassword");
    }
    axiosInstance
      .post(LOGIN, DATA)
      .then((response) => {
        GET_USER(response?.data?.token);
        let email, pwd, Token, roles, RefreshToken, UserId;
        if (response?.data?.roleId === 1) {
          email = values.email;
          pwd = values.password;
          Token = response?.data?.token;
          RefreshToken = response?.data?.refreshToken;
          roles = ["Reviewer"];
          UserId = response?.data?.UserId;
          setAuth({ email, pwd, roles, Token, RefreshToken, UserId });
          // setTimeout(() => {
          //   setLoading(true);
          // }, 2000);
          // showMessage(
          //   `Successfully Login into Reviewer`,
          //   "success",
          //   "#576d69",
          //   false,
          //   false,
          //   false
          // );
          setTimeout(() => {
            setLoading(false);
            history.push("/Projects");
          }, 500);
        } else if (response?.data?.roleId === 2) {
          email = values.email;
          pwd = values.password;
          Token = response?.data?.token;
          roles = ["Admin"];
          RefreshToken = response?.data?.refreshToken;
          UserId = response?.data?.UserId;
          setAuth({ email, pwd, roles, Token, RefreshToken, UserId });
          // setTimeout(() => {
          //   setLoading(true);
          // }, 2000);
          // showMessage(
          //   `Successfully Login into Admin`,
          //   "success",
          //   "#576d69",
          //   false,
          //   false,
          //   false
          // );
          setTimeout(() => {
            setLoading(false);
            history.push("/Projects");
          }, 500);
        } else if (response?.data?.roleId === 3) {
          email = values.email;
          pwd = values.password;
          Token = response?.data?.token;
          roles = ["User"];
          UserId = response?.data?.UserId;
          RefreshToken = response?.data?.refreshToken;
          setAuth({ email, pwd, roles, Token, UserId ,RefreshToken});
          // showMessage(
          //   `Successfully Login into User`,
          //   "success",
          //   "#576d69",
          //   false,
          //   false,
          //   false
          // );
          // setTimeout(() => {
          //   setLoading(true);
          // }, 2000);
          setTimeout(() => {
            setLoading(false);
            history.push("/projectList");
          }, 500);
        }
        localStorage.setItem(
          "authDetails",
          JSON.stringify({ email, pwd, roles, Token, UserId,RefreshToken })
        );
      })
      .catch((error) => {
        setLoading(false);

        setLoading(false);
        showMessage(
          error?.response?.data?.error,
          "error",
          "red",
          false,
          false,
          false
        );
      });
  };

  // Get User
  const GET_USER = (Token) => {
    const headers = { Authorization: `Bearer ${Token}` }; // auth header with bearer token
    // props.update
    axios
      .get("https://dev-api.redova.net:3002/api/v1/auth/getuser", {
        headers,
      })
      .then((response) => {
        localStorage.setItem(
          "userDetails",
          JSON.stringify(response?.data?.userDetails[0])
        );
        localStorage.setItem("userId", response?.data?.userDetails[0].UserId);
      });
  };
  return (
    <div className="bg-primary-revado-dark flex items-center justify-center h-screen">
      <Loader loading={loading} />
      <Card className="w-96 m-4 lg:m-0" shadow={true}>
        <CardHeader
          floated={false}
          variant="gradient"
          shadow={true}
          className="bg-primary-light  mb-1 grid h-28 place-items-center"
        >
          <Typography variant="h1" className="text-primary-primary font-black">
            SIGN IN
          </Typography>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              email: localStorage.getItem("rememberedUsername")
                ? localStorage.getItem("rememberedUsername")
                : "",
              password: localStorage.getItem("rememberedPassword")
                ? localStorage.getItem("rememberedPassword")
                : "",
            }}
            validationSchema={LoginValidationSchema}
            onSubmit={(e) => LoginClick(e)}
          >
            {({ errors, touched }) => (
              <Form className="flex flex-col gap-2">
                {/* <Tabs
                  value="Reviewer"
                  onClick={(e) => {
                    e.preventDefault();
                    setUserValue(e.target.textContent);
                  }}
                >
                  <TabsHeader>
                    {USERTAB.map(({ label, value }) => (
                      <Tab key={value} value={value}>
                        {label}
                      </Tab>
                    ))}
                  </TabsHeader>
                </Tabs> */}
                {/* Email */}
                <div>
                  <Label label="Email" isRequired />
                  <Field
                    id="email"
                    name="email"
                    className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                  />
                  <ErrorMessage
                    component="div"
                    className="font-semibold text-warn-error text-xs"
                    name="email"
                  />
                </div>
                <div>
                  <Label label="Password" isRequired />
                  {/* Password */}
                  <div className="relative ">
                    <Field
                      type={togglePasswordType ? "text" : "password"}
                      id="password"
                      name="password"
                      className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                      onClick={togglePasswordVisibility}
                    >
                      {togglePasswordType ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                  <ErrorMessage
                    component="div"
                    className="font-semibold text-warn-error text-xs"
                    name="password"
                  />
                </div>
                {/* Remeber me and forgot passwprd */}
                <div className="flex justify-between gap-5 mt-2">
                  <span
                    variant="small"
                    className=" flex justify-center items-center"
                  >
                    <Checkbox
                      isChecked={rememberMe}
                      checkHandler={(e) => setRememberMe(e.target.checked)}
                    />
                    <Typography
                      variant="small"
                      color="blue"
                      className="ml-1 font-bold text-primary-revado-dark"
                    >
                      Remember Me
                    </Typography>
                  </span>
                  <Typography variant="small" className=" flex justify-center">
                    <Typography
                      as="button"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/forgotpassword");
                      }}
                      variant="small"
                      color="blue"
                      className="ml-1 font-bold text-primary-revado-dark"
                    >
                      Forgot Password?
                    </Typography>
                  </Typography>
                </div>
                {/* Action Button */}
                <div className="flex justify-between gap-5 mt-2">
                  <Button
                    variant="filled"
                    type="submit"
                    className="bg-primary-primary text-white w-full shadow-none hover:shadow-lg hover:shadow-primary-primary/40 "
                  >
                    Sign in
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/signUp");
                    }}
                    variant="outlined"
                    className="border-primary-primary outline-none focus:ring-0 text-primary-primary  w-full  shadow-none hover:shadow-lg hover:shadow-primary-primary/40 hover:border-primary-revado-dark"
                  >
                    Sign up
                  </Button>
                  {/* <Button variant="text">text</Button> */}
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
}
