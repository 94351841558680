import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import moment from "moment";
import { isNotNull } from "../../../../store/helper/Helper";
import SingleMarkerMap from "../../../../components/ui/Map/SingleMarkerMap";
import ImageCarousel from "../../../../components/ui/ImageCarousel";
import logo from "../.././../../assets/Logo/OnlyBlacklogo.png";
// import PdfViewer from "../../../../components/functionality/PDFViewer";
import PDFListing from "../../../../components/functionality/PDFListing";

export default function AdminSideProjectDetails({
  open,
  handleOpen,
  projectDetails,
  handleCardOpertaion,
}) {
  // console.log(projectDetails, "projectDetails");
  const todayDate = moment().format("MM/DD/YYYY");
  const [projectFav, setProjectFav] = useState(projectDetails?.favorite);
  const [imageOpen, setImageOpen] = useState(false); // eslint-disable-next-line
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [rearrangedImageUrls, setRearrangedImageUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [pdf, setPdf] = useState("");

  // console.log(projectDetails,"projectDetails")

  useEffect(() => {
    getAttributeValue();
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        // Left arrow
        setCurrentIndex(0);
      } else if (event.keyCode === 39) {
        // Right arrow
        setCurrentIndex(1);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }; // eslint-disable-next-line
  }, [currentIndex]);
  const getAttributeValue = () => {
    if (
      Array.isArray(projectDetails?.projectAttributes?.attribute_names) &&
      Array.isArray(projectDetails?.projectAttributes?.attribute_values) &&
      projectDetails?.projectAttributes?.attribute_names?.filter(Boolean)
        .length > 0 &&
      projectDetails?.projectAttributes?.attribute_values?.filter(Boolean)
        .length > 0
    ) {
      const attributesList =
        projectDetails?.projectAttributes?.attribute_names.map(
          (name, index) => (
            <li key={index}>
              {projectDetails?.projectAttributes?.attribute_values[index]}{" "}
              {name}
            </li>
          )
        );
      return <ul className="list-disc ml-3">{attributesList}</ul>;
    }
    return <p>-</p>; // Return null if there are no attributes
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    const clickedImageUrl = projectDetails?.imageUrls[index];
    const rearrangedUrls = [...projectDetails?.imageUrls];
    const clickedImageIndex = rearrangedUrls.indexOf(clickedImageUrl);

    // Move the clicked image URL to the front of the array
    rearrangedUrls.splice(clickedImageIndex, 1);
    rearrangedUrls.unshift(clickedImageUrl);

    setRearrangedImageUrls(rearrangedUrls);
    setImageOpen(true);
    setImageOpen(true);
  };

  const handleCarouselClose = () => {
    setImageOpen(false);
  };

  useEffect(() => {
    setProjectFav(projectDetails?.favorite);
  }, [projectDetails]);
  // Rearrange the imageUrls array to start with the specified image URL

  // Pdf Button click

  return (
    <Dialog
      open={open}
      className="h-full md:w-96 w-full overflow-x-hidden overflow-y-scroll lg:pb-0 pb-10"
      size="lg"
      handler={handleOpen}
    >
      <div className="fixed md:right-32 2xl:right-[475px] -right-1">
        <IconButton
          color="white"
          size="sm"
          variant="text"
          onClick={(e) => {
            e.preventDefault();
            handleOpen();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
            strokeWidth={2}
            className="h-6 w-6 2xl:h-9 2xl:w-9"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <DialogHeader className="flex flex-row justify-between gap-5">
        <p
          color="black"
          className="text-2xl font-bold lg:min-w-80 min-w-64 capitalize
          "
        >
          {projectDetails?.title}
        </p>
        <div className="w-5">
          <div
            variant="text"
            size="sm"
            className={` hover:cursor-pointer !absolute border-none  top-4 right-0 w-9 max-w-9 min-h-9 max-h-9 hover:bg-white mr-5 ${
              projectFav === 1
                ? "text-primary-redova-favorite"
                : "text-gray-400 "
            }`}
            onClick={(e) => {
              setProjectFav(projectFav === 0 ? 1 : 0);
              e.preventDefault();
              handleCardOpertaion(projectDetails, projectFav, "Favorite");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-9 h-9  stroke-2 p-0 m-0"
            >
              <path
                fillRule="evenodd"
                stroke="white"
                d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </DialogHeader>

      <DialogBody className=" flex flex-col gap-2  mt-2  mb-20 lg:mb-2">
        {/* handleImageClick(index) */}
        <div className="hidden lg:block">
          {" "}
          {projectDetails?.imageUrls?.length > 0 && (
            <div className="image-grid min-h-48 2xl:min-h-64">
              {projectDetails?.imageUrls && (
                <img
                  className="image-grid-col-2 image-grid-row-2 cursor-pointer"
                  src={projectDetails?.imageUrls[0]}
                  alt="ProjectImage"
                  onClick={() => handleImageClick(0)}
                />
              )}
              {projectDetails?.imageUrls &&
                projectDetails?.imageUrls.slice(1, 5).map((imageUrl, index) => (
                  <img
                    key={index} // Ensure to provide a unique key when mapping over arrays in React
                    src={imageUrl}
                    onClick={() => handleImageClick(index + 1)}
                    alt="ProjectImage"
                  />
                ))}
            </div>
          )}
        </div>
        <div className="block lg:hidden">
          {" "}
          {projectDetails?.imageUrls?.length > 0 && (
            <div className="md:image-grid  2xl:min-h-64">
              {projectDetails?.imageUrls &&
                projectDetails?.imageUrls.map((imageUrl, index) => (
                  <img
                    key={index} // Ensure to provide a unique key when mapping over arrays in React
                    src={imageUrl}
                    onClick={() => handleImageClick(index)}
                    alt="ProjectImage"
                    className="w-full h-full object-cover cursor-pointer"
                    style={{ minHeight: "300px", maxHeight: "300px" }} // Add fixed dimensions if needed
                  />
                ))}
            </div>
          )}
        </div>
        {/* Summary and Date */}
        <div className="flex md:flex-row flex-col md:justify-between gap-5">
          <Typography
            variant="small"
            color="gray"
            className=" md:text-sm 2xl:text-base "
          >
            <span className="font-bold text-black"> Project Summary</span>
            <br />
            {isNotNull(projectDetails?.ProjectSummary)
              ? projectDetails?.ProjectSummary
              : "-"}{" "}
            {/* {projectDetails?.link_to_source && (
              <span
                className=" font-bold cursor-pointer text-black"
                onClick={() =>
                  window.open(projectDetails?.link_to_source, "_blank")
                }
              >
                .. Read More
              </span>
            )} */}
          </Typography>
          <Typography
            variant="small"
            color="gray"
            className="text-sm 2xl:text-base md:text-left min-w-max"
          >
            <span className="font-bold text-black "> Date Updated</span>
            <br />
            {todayDate}
          </Typography>
        </div>
        {/* AssetType */}
        <div className="flex md:flex-row flex-col gap-2 md:gap-4">
          <div className="md:w-1/2">
            <Typography
              variant="small"
              color="gray"
              className=" text-sm 2xl:text-base  overflow-wrap break-word "
            >
              <span className="font-bold text-black">Asset Type</span> <br />
              {projectDetails?.AssetType
                ? projectDetails?.AssetType.split(",").map((asset, index) => (
                    <span key={index}>
                      {asset.trim()}
                      {index <
                        projectDetails?.AssetType.split(",").length - 1 && ", "}
                    </span>
                  ))
                : "-"}
            </Typography>
          </div>
          <div className="md:w-1/2">
            <Typography
              variant="small"
              color="gray"
              className="text-sm 2xl:text-base  overflow-wrap break-word"
            >
              <span className="font-bold text-black">Project Status</span>{" "}
              <br />{" "}
              {projectDetails?.project_status_type
                ? projectDetails?.project_status_type
                : "-"}
            </Typography>
          </div>
          <div className="md:w-1/2">
            <Typography
              variant="small"
              color="gray"
              className="text-sm 2xl:text-base  overflow-wrap break-word"
            >
              <span className="font-bold text-black md:text-sm 2xl:text-base ">
                Address
              </span>{" "}
              <br />
              {projectDetails?.StreetAddress && projectDetails?.StreetAddress}
            </Typography>
          </div>
        </div>
        <div className="w-full">
          {projectDetails?.location?.Longitude || projectDetails?.Longitude ? (
            <>
              {" "}
              <SingleMarkerMap
                style={{
                  width: "100vw",
                  height: "50vh",
                  borderRadius: "10px",
                }}
                longitude={
                  projectDetails?.Longitude
                    ? projectDetails?.Longitude
                    : projectDetails?.location?.Longitude
                }
                latitude={
                  projectDetails?.Latitude
                    ? projectDetails?.Latitude
                    : projectDetails?.location?.Latitude
                }
                noMapStyle
                interactive={true}
                AssetType={projectDetails?.AssetType}
                projectstatus={projectDetails?.projectstatus}
                public_hearings={projectDetails?.public_hearings}
                data={projectDetails}
              />
            </>
          ) : (
            <div className="flex items-center justify-center border-2">
              <img
                alt="Redova Logo"
                style={{ width: 250, height: 350, borderRadius: "10px" }}
                src={logo}
              />
            </div>
          )}
        </div>
        <div className="flex  flex-col  gap-2">
          <div className="flex md:flex-row flex-col md:justify-between gap-2 md:gap-0">
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base md:w-1/2"
            >
              <span className="font-bold text-black"> Project Start Date</span>
              <br />
              {isNotNull(projectDetails?.startdate)
                ? moment(projectDetails.startdate, "YYYY-MM-DD").format(
                    "MM/DD/YYYY"
                  )
                  ? moment(projectDetails.startdate, "YYYY-MM-DD").format(
                      "MM/DD/YYYY"
                    )
                  : projectDetails.startdate
                : "-"}
            </Typography>
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base md:w-1/2"
            >
              <span className="font-bold text-black">
                {" "}
                Project Completion Date
              </span>
              <br />
              {isNotNull(projectDetails?.enddate)
                ? moment(projectDetails.enddate, "YYYY-MM-DD").format(
                    "MM/DD/YYYY"
                  )
                : "-"}
            </Typography>
          </div>
          <div className="flex md:flex-row flex-col md:justify-between gap-2 md:gap-0">
            {isNotNull(projectDetails?.TypeUser) && (
              <Typography
                variant="small"
                color="gray"
                className=" md:text-sm 2xl:text-base md:w-1/2"
              >
                <span className="font-bold text-black"> Type of business</span>
                <br />
                {isNotNull(projectDetails?.TypeUser)
                  ? projectDetails?.TypeUser
                  : "-"}
              </Typography>
            )}

            {projectDetails?.projectAttributes && (
              <Typography
                variant="small"
                color="gray"
                className=" md:text-sm 2xl:text-base md:w-1/2"
              >
                <span className="font-bold text-black">
                  {" "}
                  Project Attributes
                </span>
                <br />
                {getAttributeValue()}
                {/* {isNotNull(projectDetails?.projectAttributes)
              ? projectDetails?.projectAttributes
              : "-"} */}
              </Typography>
            )}
          </div>

          {projectDetails?.public_hearings?.length > 0 && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Public Hearings</span>
              <br />
              {projectDetails?.public_hearings?.length > 0
                ? projectDetails?.public_hearings.map((item, i) => (
                    <div className="mb-2">
                      <span>
                        <b className="font-bold text-black text-xs">
                          Meeting Type:
                        </b>{" "}
                        <span className=" text-xs">{item?.MarketingType},</span>
                      </span>
                      <span className="ml-1">
                        <b className="font-bold text-black text-xs">Status:</b>
                        <span className="ml-1 text-xs">{item?.status}</span>,
                      </span>
                      <span className="ml-1">
                        <b className="font-bold text-black text-xs">
                          Meeting Date:
                        </b>{" "}
                        <span className=" text-xs">{item?.MeetingDate},</span>
                      </span>
                      <span className="ml-1">
                        <b className="font-bold text-black text-xs break-all">
                          URL link:
                        </b>
                      </span>
                      <span className=" text-xs">
                        <a
                          href={item?.UrlLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.UrlLink},
                        </a>{" "}
                      </span>
                    </div>
                  ))
                : "-"}
            </Typography>
          )}
          {/* <Typography
            variant="small"
            color="gray"
            className=" md:text-sm 2xl:text-base"
          >
            <span className="font-bold text-black underline">
              {" "}
              Project Team
            </span>
          </Typography> */}
          {isNotNull(projectDetails?.Developer) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Developer</span>
              <br />
              {isNotNull(projectDetails?.Developer)
                ? projectDetails?.Developer
                : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.GeneralContractor) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> General Contractor</span>
              <br />
              {isNotNull(projectDetails?.GeneralContractor)
                ? projectDetails?.GeneralContractor
                : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Contractor) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Contractor</span>
              <br />
              {isNotNull(projectDetails?.Contractor)
                ? projectDetails?.Contractor
                : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Owner) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Owner</span>
              <br />
              {isNotNull(projectDetails?.Owner) ? projectDetails?.Owner : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Architect) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Architect</span>
              <br />
              {isNotNull(projectDetails?.Architect)
                ? projectDetails?.Architect
                : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Planner) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Planner</span>
              <br />
              {isNotNull(projectDetails?.Planner)
                ? projectDetails?.Planner
                : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Investor) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Investor</span>
              <br />
              {isNotNull(projectDetails?.Investor)
                ? projectDetails?.Investor
                : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Lender) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Lender</span>
              <br />
              {isNotNull(projectDetails?.Lender) ? projectDetails?.Lender : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Bank) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Bank</span>
              <br />
              {isNotNull(projectDetails?.Bank) ? projectDetails?.Bank : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.CivilEngineer) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Civil Engineer</span>
              <br />
              {isNotNull(projectDetails?.CivilEngineer)
                ? projectDetails?.CivilEngineer
                : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Engineer) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Engineer</span>
              <br />
              {isNotNull(projectDetails?.Engineer)
                ? projectDetails?.Engineer
                : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Broker) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Broker</span>
              <br />
              {isNotNull(projectDetails?.Broker) ? projectDetails?.Broker : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Brokerage) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Brokerage</span>
              <br />
              {isNotNull(projectDetails?.Brokerage)
                ? projectDetails?.Brokerage
                : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.Tenant) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Tenant</span>
              <br />
              {isNotNull(projectDetails?.Tenant) ? projectDetails?.Tenant : "-"}
            </Typography>
          )}
          {isNotNull(projectDetails?.link_to_source) && (
            <Typography
              variant="small"
              color="gray"
              className=" md:text-sm 2xl:text-base"
            >
              <span className="font-bold text-black"> Sources</span>
              <br />
              {isNotNull(projectDetails?.link_to_source) ? (
                <span
                  className="  cursor-pointer "
                  onClick={() =>
                    window.open(projectDetails?.link_to_source, "_blank")
                  }
                >
                  {projectDetails?.link_to_source}
                </span>
              ) : (
                "-"
              )}
            </Typography>
          )}
        </div>
        <PDFListing pdfList={projectDetails?.pdfUrls} />
      </DialogBody>
      {/* Modal for displaying full-size image */}
      {imageOpen && (
        <Dialog
          open={true}
          size="lg"
          handler={handleCarouselClose}
          className="lg:min-h-full"
        >
          <div className="absolute z-100 md:-right-10 right-0">
            <IconButton
              color="blue-gray"
              size="sm"
              variant="text"
              onClick={(e) => {
                e.preventDefault();
                setImageOpen(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
                strokeWidth={2}
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </IconButton>
          </div>
          <ImageCarousel images={rearrangedImageUrls} />
          {/* <div className="absolute z-100 md:-right-10 right-0">
            <IconButton
              color="blue-gray"
              size="sm"
              variant="text"
              onClick={(e) => {
                e.preventDefault();
                setImageOpen(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
                strokeWidth={2}
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </IconButton>
          </div>
          <Carousel
            className="md:h-full md:w-full "
            selectedIndex={currentIndex}
          >
            {rearrangedImageUrls.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={`project ${index}`}
                className="md:h-full md:w-full md:object-cover"
              />
            ))}
          </Carousel> */}
        </Dialog>
      )}
    </Dialog>
  );
}
