import React, { useContext, useEffect, useState } from "react";
import FilterContext from "../../../context/FilterContext";
import SearchContext from "../../../context/SearchContext";
import { useApiGETRequest } from "../../../hooks/useApiHook";
import FeedBackForm from "../../../pages/FeedBackForm";
import { PROJECT_STATUS_TYPE } from "../../../services/APIGlobal";
import FillterDropDown from "../../functionality/FillterMenu";
import ProjectStatusFilter from "../../functionality/ProjectStatusFilter";
import UpdateTimeFilter from "../../functionality/UpdateTimeFilter";
import MapboxGeocoderInput from "../../ui/Map/MapboxGeeocoderInput";

export default function SubHeader() {
  const { filterArray, setFilterArray } = useContext(FilterContext); // eslint-disable-next-line
  const [load, setLoading] = useState(false);
  const [assetFilter, setAssetFilter] = useState([]);
  const [projectStatusFilter, setProjectStatusFilter] = useState([]);
  const [dateRangeValue, setDateRangeValue] = useState(0); // eslint-disable-next-line
  const { setSearchValue, searchValue, setSearchDefaultValue } =
    useContext(SearchContext); // eslint-disable-next-line
  const [defaultProjectStatusFiltter, setDefaultProjectStatusFiltter] =
    useState([]); // eslint-disable-next-line
  const [defaultAssetValues, setDefaultAssetValues] = useState([]); // eslint-disable-next-line
  const [defaultTimeValue, seDefaultTimeValue] = useState("");
  const { Apidata } = useApiGETRequest(PROJECT_STATUS_TYPE);
  const [isFavourite, setIsFavourite] = useState(filterArray?.FAVOURITE);
  const handleSelectChange = (values) => {
    setProjectStatusFilter(values);
    // Perform logic based on the selected values in the multi-select dropdown
  };

  const handleToggleChange = (e) => {
    e.preventDefault();
    setIsFavourite(!isFavourite);

    // if (isFavourite) {
    //   history.push("/projectList");
    // } else if (!isFavourite) {
    //   history.push("/favourites");
    // }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setFilterArray((prevFilterArray) => ({
      ...prevFilterArray,
      ASSETTYPE: assetFilter,
      DATE: dateRangeValue,
      SEARCH: searchValue,
      PROJECTSTATUS: projectStatusFilter,
      FAVOURITE: isFavourite,
    }));
    // eslint-disable-next-line
  }, [
    assetFilter,
    dateRangeValue,
    searchValue,
    projectStatusFilter,
    isFavourite,
  ]);

  return (
    <div className="flex w-full gap-5 py-2 z-100 lg:flex px-5 text-lg font-medium text-center text-black bg-white md:justify-between items-center justify-center border-b shadow-md h-full">
      <div className="flex md:flex-row  w-full flex-col gap-3">
        {/* part-1 */}
        <div className="flex flex-row  gap-3">
          <div className="w-1/2">
            <ProjectStatusFilter
              options={Apidata?.getArticleProjectStatusType}
              onCheckboxChange={handleSelectChange}
              onSelectChange={handleSelectChange}
              defaultValues={defaultProjectStatusFiltter}
            />
          </div>
          <div className="w-1/2">
            {" "}
            {load ? (
              <p>loading</p>
            ) : (
              <FillterDropDown
                assetFillter={assetFilter}
                setAssetFillter={setAssetFilter}
                defaultValues={defaultAssetValues}
              />
            )}
          </div>
        </div>
        {/* part-1 */}
        {/* Part-2 */}
        <div className="flex flex-row gap-3">
          <div className="w-1/2">
            <UpdateTimeFilter
              setDateRangeValue={setDateRangeValue}
              dateRangeValue={dateRangeValue}
              // setStartDateValue={setStartDateValue}
              // setEndtDateValue={setEndtDateValue}
              defaultTimeValue={defaultTimeValue}
            />
          </div>
          <div className="w-1/2">
            <div
              className={`hover:cursor-pointer rounded-lg md:w-[160px] sm:w-[150px] xs:w-[160px] w-[175px] md:hidden flex justify-center items-center px-3  text-center   text-sm   h-10 ${filterArray?.FAVOURITE
                ? "font-bold bg-primary-redova-favorite text-white"
                : "bg-primary-primary text-white border border-primary-primary "
                }`}
              onClick={handleToggleChange}
            >
              Favorite
            </div>
          </div>

        </div>
        {/* part-2 */}
        {/* Part-3 */}
        <div className="flex flex-row gap-3">
          <div className=" md:flex w-full md:min-w-fit ">
            <MapboxGeocoderInput
              setSearchValue={setSearchValue}
              searchValue={searchValue}
            />
          </div>
          <div className="md:hidden flex">
            <FeedBackForm />
          </div>
          <div
            className={`hover:cursor-pointer rounded-lg   justify-center items-center px-3  text-center md:flex hidden  text-sm   h-10 ${filterArray?.FAVOURITE
              ? "font-bold bg-primary-redova-favorite text-white"
              : "bg-primary-primary text-white border border-primary-primary "
              }`}
            onClick={handleToggleChange}
          >
            Favorite
          </div>
        </div>
        {/* Part-3 */}
      </div>

      <div className="hidden md:flex">
        <FeedBackForm />
      </div>
    </div>
  );
}
