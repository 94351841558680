import { Card, CardBody } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { PROJECT_STATUS_TYPE } from "../../../services/APIGlobal";
import axiosInstance from "../../../services/APIInstance";
import { showMessage } from "../showMessage";

export default function MapLedgend() {
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    GET_STATUS(); // eslint-disable-next-line
  }, []);
  // Get API
  const GET_STATUS = () => {
    axiosInstance
      .get(`${PROJECT_STATUS_TYPE}`)
      .then((response) => {
        const Options = response?.data?.getArticleProjectStatusType.map(
          (item) => {
            return { Name: item.at_name, pinColor: item.MarkerColor };
          }
        );
        setStatusOptions(Options);
      })
      .catch((error) => {
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };
  // const getColorByStatus = (statusName) => {
  //   const colorMap = {
  //     Announced: "#da0063",
  //     "Under Construction": "#ff7901",
  //     "In Development": "#6bf908",
  //     Completed: "#2d9bf0",
  //   };

  //   return colorMap[statusName] || "gray";
  // };
  return (
    <div className="md:absolute fixed md:bottom-5 xs:bottom-14 sm:bottom-24 bottom-16 left-2 md:z-75 z-25">
      {" "}
      {statusOptions.length > 0 && (
        <Card className=" w-max p-0 m-0">
          <CardBody className=" flex flex-col gap-1 p-1 m-1">
            <p className="text-sm text-black font-semibold underline ">
              Legend
            </p>
            {statusOptions.length &&
              statusOptions.map((status, index) => (
                <div className="w-full" key={status.Name}>
                  <>
                    <label key={index} className="flex items-center gap-1">
                      <svg
                        display="block"
                        height="17px"
                        width="17px"
                        viewBox="0 0 27 41"
                      >
                        <defs>
                          <radialGradient id="shadowGradient">
                            <stop offset="10%" stopOpacity="0.4"></stop>
                            <stop offset="100%" stopOpacity="0.05"></stop>
                          </radialGradient>
                        </defs>
                        <ellipse
                          cx="13.5"
                          cy="34.8"
                          rx="10.5"
                          ry="5.25"
                          fill="url(#shadowGradient)"
                        ></ellipse>
                        <path
                          fill={status?.pinColor}
                          d="M27,13.5C27,19.07 20.25,27 14.75,34.5C14.02,35.5 12.98,35.5 12.25,34.5C6.75,27 0,19.22 0,13.5C0,6.04 6.04,0 13.5,0C20.96,0 27,6.04 27,13.5Z"
                        ></path>
                        <path
                          opacity="0.25"
                          d="M13.5,0C6.04,0 0,6.04 0,13.5C0,19.22 6.75,27 12.25,34.5C13,35.52 14.02,35.5 14.75,34.5C20.25,27 27,19.07 27,13.5C27,6.04 20.96,0 13.5,0ZM13.5,1C20.42,1 26,6.58 26,13.5C26,15.9 24.5,19.18 22.22,22.74C19.95,26.3 16.71,30.14 13.94,33.91C13.74,34.18 13.61,34.32 13.5,34.44C13.39,34.32 13.26,34.18 13.06,33.91C10.28,30.13 7.41,26.31 5.02,22.77C2.62,19.23 1,15.95 1,13.5C1,6.58 6.58,1 13.5,1Z"
                        ></path>
                        <circle
                          fill={status?.pinColor}
                          cx="13.5"
                          cy="13.5"
                          r="5.5"
                        ></circle>
                      </svg>

                      <span className="ml-2" style={{ fontSize: " 0.81rem" }}>
                        {status.Name}
                      </span>
                    </label>
                  </>
                </div>
              ))}
          </CardBody>
        </Card>
      )}
    </div>
  );
}
