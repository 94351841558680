import React from 'react'
import ArticleStatusDB from '../../../../components/datatables/ArticleStatusDB'
import { Card } from "@material-tailwind/react"

export default function Status() {
  return (
    <Card className="h-full w-full border-t">
      <ArticleStatusDB />
    </Card>
  )
}
