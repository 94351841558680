import {
  Card,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import ArchivesDB from "../../../../components/datatables/ArchivesDB";
import {
  FolderOpenIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import ProjectArchiveDB from "../../../../components/datatables/ProjectArchiveDB";

export default function Articles() {
  // data state to store the TV Maze API data. Its initial value is an empty array
  const category = [
    {
      label: "Projects",
      value: "projects",
      icon: FolderOpenIcon,
      desc: <div><ProjectArchiveDB /></div>,
    },
    {
      label: "Articles",
      value: "articles",
      icon: RectangleStackIcon,
      desc: <ArchivesDB />,
    },
  ];
  return (

      <Card className="border-t p-0  max-w-5xl 2xl:max-w-full">
      <Tabs value="projects">
        <TabsHeader className="md:absolute md:left-80 md:top-4">
          {category.map(({ label, value, icon }) => (
            <Tab key={value} value={value} >
              <div className="flex items-center gap-2 text-sm">
                {React.createElement(icon, {
                  className: "w-4 h-4",
                })}
                <span className="text-sm">{label}</span>
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {category.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
      {/* <ArchivesDB /> */}
    </Card>
 
  );
}
