import React, { useState, useEffect } from "react";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

export default function InputWithMenu({
  label,
  name,
  placeholder,
  defaultValue,
  onChange,
  menuOptions,
  menuOption,
  setMenuOption,
  defaultName,
}) {
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const [error, setError] = useState("");

  useEffect(() => {
    if (menuOption && !inputValue) {
      setError("This field is required.");
    } else {
      setError("");
    }
  }, [menuOption, inputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleMenuItemClick = (name) => {
    setMenuOption(name);
    if (!inputValue) {
      setError("This field is required.");
    } else {
      setError("");
    }
  };

  return (
    <div className="w-full">
      {label && (
        <p className="flex flex-row text-gray-500 text-xs font-medium mb-1">
          {label}
        </p>
      )}
      <div className="flex w-full">
        <input
          name={name}
          placeholder={placeholder}
          className={`rounded-r-none px-3 py-2 border ${error ? "border-red-500" : "border-gray-300"
            } rounded-md focus:outline-none focus:z-10 sm:text-sm`}
          style={{ width: "130px" }}
          value={inputValue}
          onChange={handleInputChange}
        />
        <Menu placement="bottom">
          <MenuHandler>
            <Button
              ripple={false}
              variant="text"
              color="blue-gray"
              className="flex justify-center md:w-72 w-28 h-10 items-center gap-4 rounded-l-none border border-l-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
            >
              <span>{menuOption || defaultName}</span>
              <ChevronUpDownIcon className="w-5 cursor-pointer text-primary-primary" />
            </Button>
          </MenuHandler>
          <MenuList>
            {menuOptions?.map((option) => (
              <MenuItem
                key={option.Name}
                value={option.Name}
                className="flex items-center gap-2"
                onClick={() => handleMenuItemClick(option.Name)}
              >
                {option.Name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
}
