import { Card } from "@material-tailwind/react";
import { useEffect } from "react";
import { GETVIEWCOUNT } from "../../../../services/APIGlobal";
import { useState } from "react";
import axiosInstance from "../../../../services/APIInstance";
import { showMessage } from "../../../../components/ui/showMessage";
import AllAnalyticsDB from "../../../../components/datatables/AllAnalyticsDB";
import Loader from "../../../../components/ui/Loader";

export default function Analytics() {
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]); // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showRecord, setShowRecord] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();
  useEffect(() => {
    GET_STORIES(); // eslint-disable-next-line
  }, []);
  const GET_STORIES = () => {
    setLoading(true);

    axiosInstance
      .get(
        `${GETVIEWCOUNT}?limit=${showRecord}&page=${pageNo}`
      )
      .then((response) => {
        setData(response?.data?.viewCounts);
        setTotalRecord(response?.data?.pagination?.totalCount);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };
  return (
    <Card className="overflow-x-scroll h-full  border-t p-4">
       {loading ?
       <Loader loading={loading} />
      :
        <AllAnalyticsDB
          articles={data}
          GET_STORIES={GET_STORIES}
          setPageNo={setPageNo}
          pageNo={pageNo}
          showRecord={showRecord}
          setShowRecord={setShowRecord}
          totalRecord={totalRecord}
          currentPage={currentPage}
          totalPage={totalPage}
        />
       }
    
    </Card>
  );
}
