import React, { useEffect, useState } from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Card } from "@material-tailwind/react";
import logo from "../../../assets/Logo/OnlyBlacklogo.png";
import { PROJECT_STATUS_TYPE } from "../../../services/APIGlobal";
import axiosInstance from "../../../services/APIInstance";
import { MAP_SATELLITE_STREETS } from "../../../utils/Constant";
import MapStyle from "./MapStyle";
import ProjectPin from "./ProjectPin";

export default function SingleMarkerMap(props) {
  const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const [mapStyle, setMapStyle] = useState(MAP_SATELLITE_STREETS);
  const [projectStatusOptions, setProjectStatusOptions] = useState([]);
  const [scrollZoomEnabled, setScrollZoomEnabled] = useState(false);

  useEffect(() => {
    GET_PROJECT_STATUS();
  }, []);

  const GET_PROJECT_STATUS = () => {
    axiosInstance
      .get(`${PROJECT_STATUS_TYPE}`)
      .then((response) => {
        setProjectStatusOptions(response?.data?.getArticleProjectStatusType);
      })
      .catch((error) => {});
  };

  const handleViewportChange = (viewport) => {
    // console.log("call")
    // console.log(viewport.target.transform.height);
    // Check if the zoom level has changed
    setScrollZoomEnabled((prevState) => !prevState);
  };
  return (
    <>
      {props.longitude ? (
        <Card className="max-w-fit flex flex-row bg-white z-90 rounded-lg">
          <Map
            initialViewState={{
              latitude: props.latitude,
              longitude: props.longitude,
              zoom: 16,
            }}
            onResize={handleViewportChange} // Listen for viewport changes
            scrollZoom={scrollZoomEnabled} // Use the scrollZoom state variable
            style={props?.style}
            mapStyle={MAP_SATELLITE_STREETS}
            mapboxAccessToken={TOKEN}
            interactive={props?.interactive}
          >
            {props.longitude !== undefined && props.latitude !== undefined && (
              <Marker
                longitude={props.longitude}
                latitude={props.latitude}
                anchor="bottom"
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                }}
              >
                <ProjectPin
                  data={props.data}
                  projectStatusOptions={projectStatusOptions}
                />
              </Marker>
            )}
            {!props?.noControls && (
              <>
                <GeolocateControl position="top-right" />
                <FullscreenControl position="top-right" />
                <NavigationControl position="top-right" />
                {!props.noMapStyle && (
                  <MapStyle setMapStyle={setMapStyle} mapStyle={mapStyle} />
                )}
              </>
            )}
          </Map>
        </Card>
      ) : (
        <img src={logo} alt="Redova Logo" style={props?.style} />
      )}
    </>
  );
}
