import React from 'react'

export default function TitleDivider(props) {
  return (
    <div className="relative mr-2 my-2">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t-2 border-primary-hover"></div>
    </div>
    <div className="relative flex justify-center">
      <span className="px-3 bg-white text-2xl  font-bold text-primary-primary font-serif">
        {props.title}
      </span>
    </div>
    
  </div>
  )
}
