import React from "react";
import { useEffect } from "react";
import Loader from "../../../../components/ui/Loader";
import AddInformation from "./AddInformation";
import { useState } from "react";
import axiosInstance from "../../../../services/APIInstance";
import { STORIES } from "../../../../services/APIGlobal";
import { showMessage } from "../../../../components/ui/showMessage";

export default function UpdateArticle() {
  const [loading, setLoading] = useState(true); // eslint-disable-next-line
  const StoryId = JSON.parse(localStorage.getItem("rowId"));
  const [allData, setAllData] = useState({});

  useEffect(() => {
    GET_STORIEById(); // eslint-disable-next-line
  }, []);


  // Get API
  const GET_STORIEById = () => {
    // setLoading(true);
    axiosInstance
      .get(`${STORIES}/${JSON.parse(localStorage.getItem("rowId"))}`)
      .then((response) => {
        // setStories(response?.data[0]);
        setAllData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };


  return (
    <div className="z-75  ">
      <Loader loading={loading} />
      {!loading && (
        <>
          <AddInformation
          formArr={allData}
          allData={allData}
          api={GET_STORIEById}
          setLoading={setLoading}
        />
        </>
      )}
      {/* <DragableMarker /> */}
      {/* {location?.category?.id !== 2 ? <DragableMarker /> : <DrawableMap />} */}
    </div>
  );
}
