import React, { useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars-2";

import {
  //   useFilters,
  usePagination,
  useRowSelect,
  useTable,
  //   useSortBy,
  useSortBy,
} from "react-table";
import SearchField from "../../ui/SearchField";
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import Loader from "../../ui/Loader";
import { PROJECT_STATUS } from "../../../services/APIGlobal";
import { useApiGETRequest } from "../../../hooks/useApiHook";
import FillterDropDown from "../FillterMenu";

const themes = ({ theme, type, tight, isLink }) => {
  const classes = {
    common: {
      td: `break-normal  break-words flex-nowrap  border-y py-2 border-slate-300 space-y-2 space-x-2 text-xs 2xl:text-sm  text-center
      } `,
      th: `py-3 bg-primary-revado-white  ${
        tight ? "px-2" : "px-6"
      } text-center text-xs  uppercase sticky `,
      thead: `z-70 sticky top-0 `,
      footer: `z-70 bg-white sticky bottom-0 `,
    },
    primary: {
      thead: "bg-green-clearbox top-0 relative ",
      th: `text-grey `,
    },
    secondary: {
      th: "font-bold",
      td: "w-max-content break-normal ",
      thead: "bg-white font-bold  bg-opacity-100 border-b-2  z-30",
    },
    light: {
      thead: "bg-green-500 bg-opacity-20 ",
      th: "text-gray-500",
    },
    dark: {
      thead: "bg-white  bg-opacity-100 border-b-2  z-30",
      th: "text-sm font-bold text-black-700 normal-case z-30 ",
    },
    dark_aligntop: {
      thead: "bg-white  bg-opacity-100 border-b-2 ",
      th: "text-sm font-bold text-black-700 normal-case ",
      td: "align-top break-normal ",
    },
  };

  return classes["common"][type] + " " + classes[theme][type];
};
export default function Table({
  columns,
  data,
  maxHeight = 800,
  theme = "secondary",
  noPagination,
  isFooter,
  setPageNo,
  pageNo,
  showRecord,
  setShowRecord,
  totalRecord,
  currentPage,
  totalPage,
  noSearch,
  selectedOptions,
  setSelectedOptions,
  statusFillter,
  assetFillter,
  setAssetFillter,
  globalFilter,
  setGlobalFilter,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,

    // page,
    // nextPage,
    // previousPage,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // gotoPage,
    // pageCount,
    // setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    // useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const { Apidata, isLoaded } = useApiGETRequest(PROJECT_STATUS);
  // const { globalFilter } = state;

  const tableRef = useRef(null);

  // Function to scroll to a specific row index
  const scrollToRow = (index) => {
    if (
      tableRef.current &&
      tableRef.current.rows &&
      tableRef.current.rows[index]
    ) {
      tableRef.current.rows[index].scrollIntoView({
        behavior: "auto", // Optional: You can adjust scrolling behavior
      });
    }
  };

  useEffect(() => {
    // Call scrollToRow inside useEffect to scroll to a specific row when the component mounts
    // console.log(localStorage.getItem("lastVisitedRow"),"lastVisitedRow")
    if (localStorage.getItem("lastVisitedRow")) {
      // console.log(localStorage.getItem("lastVisitedRow"),"lastVisitedRow")
      scrollToRow(localStorage.getItem("lastVisitedRow")); // Replace row index
    }
  else  if (localStorage.getItem("lastVisitedProjectRow")) {
      // console.log(localStorage.getItem("lastVisitedRow"),"lastVisitedRow")
      scrollToRow(localStorage.getItem("lastVisitedProjectRow")); // Replace row index
    }
  });

  return (
    <section className="flex flex-col gap-5  ">
      <div className="flex md:flex-row flex-col md:justify-between gap-2 w-full">
        {!noSearch && (
          <div className="md:w-72 md:order-first order-last">
            <SearchField filter={globalFilter} setfilter={setGlobalFilter} />
          </div>
        )}
        {isLoaded === false && !noSearch && (
          <FillterDropDown
            defaultChecked="All"
            options={Apidata?.getProjectStatus}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            assetFillter={assetFillter}
            setAssetFillter={setAssetFillter}
            statusFillter={statusFillter}
          />
        )}
      </div>
      {data.length > 0 ? (
        <div>
          <Scrollbars
            universal
            autoHeight
            autoHeightMax={maxHeight}
            className=" shadow border-b border-gray-200 rounded-lg "
          >
            <table
              className="max-h-72 w-full overflow-y-scroll  text-left"
              ref={tableRef}
              {...getTableProps()}
            >
              <thead className={themes({ theme, type: "thead" })}>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className={themes({ theme, type: "th" })}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                          {
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                            },
                          }
                        )}
                      >
                        <div className="flex gap-1 items-center justify-center">
                          <span>{column.render("Header")}</span>
                          <span>
                            {!column.disableSortBy && (
                              <>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowLongDownIcon
                                      strokeWidth={2}
                                      className="h-4 w-4"
                                    />
                                  ) : (
                                    <ArrowLongUpIcon
                                      strokeWidth={2}
                                      className="h-4 w-4"
                                    />
                                  )
                                ) : (
                                  <ChevronUpDownIcon
                                    strokeWidth={2}
                                    className="h-4 w-4"
                                  />
                                )}
                              </>
                            )}
                          </span>
                        </div>
                        {/* {index !== headerGroup.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="bg-white " {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={` hover:bg-primary-light hover:cursor-pointer`}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className={themes({ theme, type: "td" })}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              {isFooter && (
                <tfoot>
                  {footerGroups.map((group) => (
                    <tr
                      className={themes({ theme, type: "footer" })}
                      {...group.getFooterGroupProps()}
                    >
                      {group.headers.map((column) => (
                        <td {...column.getFooterProps()}>
                          {column.render("Footer")}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              )}
            </table>
          </Scrollbars>
        </div>
      ) : (
        <Loader loader={true} />
      )}
      {!!rows.length || (
        <p className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600 text-center">
          No data available
        </p>
      )}
      {noPagination === undefined && (
        <div className="w-full text-sm flex   md:flex-row justify-between items-center  ">
          <div className="no-wrap md:w-1/2 flex md:flex-row flex-col  md:space-x-2  md:items-center">
            <div className="text-left">
              Page{" "}
              <span className="font-semibold">
                {currentPage} of {totalPage}
              </span>{" "}
            </div>
            <div className="flex space-x-2 no-wrap items-center text-left">
              <span>| Show </span>
              <select
                className=" rounded-md border border-gray-300 bg-white py-1 px-1 text-left shadow-sm focus:border-primary-primary focus:outline-none focus:ring-1 focus:ring-primary-primary sm:text-sm"
                value={showRecord}
                onChange={(e) => {
                  setShowRecord(Number(e.target.value));
                  localStorage.setItem("showRecords", Number(e.target.value));
                  localStorage.removeItem("");
                }}
              >
                {[10, 15, 50].map((showRecord) => (
                  <option
                    className="option hover:bg-primary-hover focous:bg-primary-primary"
                    key={showRecord}
                    value={showRecord}
                  >
                    {showRecord} of {totalRecord}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="  space-x-0">
            <button
              className="  rounded-l-md border border-gray-300 bg-white md:px-2 p-1 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={() => {
                setPageNo(1);
                localStorage.setItem("pageNoArticle", 1);
                localStorage.removeItem("lastVisitedRow");
              }}
            >
              {"<<"}
            </button>
            <button
              className=" relative inline-flex items-center border border-gray-300 bg-white p-1 md:px-4 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={() => {
                setPageNo(Number(pageNo) - 1);
                localStorage.setItem("pageNoArticle", Number(pageNo) - 1);
                localStorage.removeItem("lastVisitedRow");
              }}
            >
              Previous
            </button>
            <button
              className="relative inline-flex items-center border border-gray-300 bg-white p-1 md:px-4 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={() => {
                setPageNo(Number(pageNo)+ 1);
                // console.log(Number(pageNo) + 1)
                localStorage.setItem("pageNoArticle", Number(pageNo) + 1);
                localStorage.removeItem("lastVisitedRow");
              }}
            >
              Next
            </button>
            <button
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white p-1 md:px-2 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={() => {
                setPageNo(totalPage);
                localStorage.setItem("pageNoArticle", totalPage);
                localStorage.removeItem("lastVisitedRow");
              }}
            >
              {">>"}
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
