import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { ExtractFilenameFromUrl } from "../../store/helper/Helper";

const PdfUploader = ({ setPdfFiles, defaultPdf, setDefaultPdf,pdfUploaderValue }) => {
  // Initial files
  // specify upload params and url for your files
  // eslint-disable-next-line
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      setPdfFiles((prevFiles) => [...prevFiles, file]);
      // postFile(file);
    } else if (status === "removed") {
      setPdfFiles((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile !== file)
      );
    }
  };
  // Function to remove a file from the default PDF list
  const removeDefaultPdf = (urlToRemove) => {
    setDefaultPdf((prevDefaultPdf) =>
      prevDefaultPdf.filter((prevUrl) => prevUrl !== urlToRemove)
    );
  };
  // const postFile = (file) => {
  //   const formData = new FormData();
  //   formData.append('pdf', file);
  //   multiPartAxiosInstance
  //   .post(`${UPLOAD_PDF}`, formData)
  //   .then(response => response.json())
  //   .then(data => console.log(data))
  //   .catch(error => console.error('Error:', error));
  // };
  // receives array of files that are done uploading when submit button is clicked

  return (
<div className="border-2 rounded-md pt-1">
      {defaultPdf &&
        defaultPdf.map((file) => (
          <div
            className="py-[2%] px-[1%] border-b flex flex-row justify-between dzu-previewContainer "
            key={file.name}
          >
            <p className=" dzu-previewFileName">
              {" "}
              {ExtractFilenameFromUrl(file)}
            </p>
            <span
              className="dzu-previewButton pr-6 cursor-pointer"
              onClick={() => removeDefaultPdf(file)}
              style={{
                backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUuMCwgMC4wKSIgZmlsbD0iIzMzMzMzMyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNC4wLCAwLjApIj48cG9seWdvbiBwb2ludHM9IjcuNzE5IDQuOTY0IDEyLjY5MiAwLjAxNyAxNC4zODkgMS43MTUgOS40MTIgNi42NjYgMTQuMzU0IDExLjYzNCAxMi42NTcgMTMuMzMxIDYuMDE3IDYuNjU3IDcuNzE1IDQuOTYwIj48L3BvbHlnb24+PHBvbHlnb24gcG9pbnRzPSI3LjYxMiA0Ljk2NCA3LjYxNiA0Ljk2MCA5LjMxMyA2LjY1NyAyLjY3NCAxMy4zMzEgMC45NzcgMTEuNjM0IDUuOTE5IDYuNjY2IDAuOTQyIDEuNzE1IDIuNjM5IDAuMDE3Ij48L3BvbHlnb24+PC9nPjwvZz48L3N2Zz4K")`,
              }}
            ></span>
          </div>
        ))}
      <Dropzone
        styles={{
          dropzone: {
            border: "0px",
          },
        }}
        // getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        //   onSubmit={handleSubmit}
        accept="application/pdf"
        maxFiles={5- (defaultPdf?.length ?defaultPdf?.length :0 )}
      />
    </div>
  );
};
export default PdfUploader;
