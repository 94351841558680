import { Card, IconButton, Collapse , Navbar } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import ProfileMenu from "./UserMenu";
import ToggleView from "../ToggleView";
import Whitelogo from "../../../assets/Logo/Whitelogo.png";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

const NavbarDashboard = () => {
  const history= useHistory()
  const [openNav, setOpenNav] = useState(false);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
  const userDetails = JSON.parse(localStorage.getItem("authDetails"));
  // Responsive nav list
  const closePopup = () => {
    setOpenNav(false);
  };
  const navList = (
    <ul className="bg-transparent divide-y flex flex-col lg:mb-0  lg:mt-0 lg:flex-row lg:items-center lg:gap-6 ">
      <NavLink
        to="/Projects"
        exact
        onClick={closePopup}
        className="p-2 text-primary-revado-black font-bold mt-1 "
        style={(isActive) => ({
          color: isActive ? "#40514e" : "",
          borderBottomColor: isActive ? "#40514e" : "",
        })}
      >
        Projects
      </NavLink>
      {/* <NavLink
        to="/Articles"
        exact
        onClick={closePopup}
        className="p-2 text-primary-revado-black font-bold mt-1 "
        style={(isActive) => ({
          color: isActive ? "#40514e" : "",
          borderBottomColor: isActive ? "#40514e" : "",
        })}
      >
        Articles
      </NavLink> */}
     {userDetails.roles[0] ==="Admin" && <NavLink
        to="/Role"
        exact
        onClick={closePopup}
        className="p-2 text-primary-revado-black font-bold mt-1 "
        style={(isActive) => ({
          color: isActive ? "#40514e" : "",
          borderBottomColor: isActive ? "#40514e" : "",
        })}
      >
        Role
      </NavLink>}
      <NavLink
        to="/Archives"
        exact
        onClick={closePopup}
        className="p-2 text-primary-revado-black font-bold mt-1 "
        style={(isActive) => ({
          color: isActive ? "#40514e" : "",
          borderBottomColor: isActive ? "#40514e" : "",
        })}
      >
        Archives
      </NavLink>
      {/* <NavLink
        to="/settings"
        exact
        onClick={closePopup}
        className="p-2 text-primary-revado-black font-bold mt-1 "
        style={(isActive) => ({
          color: isActive ? "#40514e" : "",
          borderBottomColor: isActive ? "#40514e" : "",
        })}
      >
        Settings
      </NavLink> */}
    </ul>
  );
  return (
    <>
      <Navbar
        color="transparent"
        className=" px-4 lg:px-4 py-2 border-none min-w-full ml-0 rounded-none md:bg-primary-hover bg-primary-primary flex flex-row md:justify-end justify-between lg:mb-0 p-2 lg:mt-0 lg:flex-row items-center lg:gap-6"
      >
        <IconButton
          variant="text"
          className="h-6 w-6 text-white hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden md:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
        <img
          src={Whitelogo}
          className=" lg:hidden block w-36"
          alt="Revado Logo"
          onClick={()=>history.push("/projectList")}
        />
        <div className="flex flex-row md:gap-6 gap-2 items-center">
          <ToggleView label="User" value={false} role={userDetails.roles[0]} />{" "}
          <ProfileMenu />
        </div>
        {/* <div className=" w-6 hover:text-primary-revado-dark">
          <Menu>
            <MenuHandler className="cursor-pointer">
              <BellIcon />
            </MenuHandler>
            <MenuList>
              <MenuItem>Notification 1</MenuItem>
              <hr className="my-3" />
              <MenuItem>Notification 2</MenuItem>
              <hr className="my-3" />
              <MenuItem>Notification 3</MenuItem>
            </MenuList>
          </Menu>
        </div> */}

        {/* <div className=" w-6 hover:text-primary-revado-dark">
          <Menu>
            <MenuHandler className="cursor-pointer">
              <ChatBubbleBottomCenterIcon />
            </MenuHandler>
            <MenuList>
              <MenuItem className="flex gap-2 items-center">
                <Avatar
                  variant="circular"
                  alt="candice"
                  className="w-7 h-7"
                />
                please check new Article
              </MenuItem>
            </MenuList>
          </Menu>
        </div> */}
        {/* <div className=" w-6 hover:text-primary-revado-dark">
          <Link to="/setting">
            <Cog8ToothIcon />
          </Link>
        </div> */}
      </Navbar>
      <Collapse  open={openNav} className=" border-none">
        <Card className="border-none m-1 p-1 flex flex-col  shadow-none ">{navList}</Card>
      </Collapse >
    </>
  );
};

export default NavbarDashboard;
