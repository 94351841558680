import React, { useEffect } from "react";
import fileUpload from "./../../assets/Images/image-upload.png";
import ImageUploading from "react-images-uploading";
import { Button, Tooltip } from "@material-tailwind/react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { TooltipStlye, errorFieldMessage } from "../../utils/Constant";

const FileUploader = (props) => {
  // console.log(props?.defaultImages?.length,"props")
  const [images, setImages] = React.useState([]);
  const maxNumber = 5;
  // To fetch Data from previous route
  useEffect(() => {
    // console.log(location?.state?.data?.data);
    let result =
      props?.defaultImages &&
      props?.defaultImages.map((option) => {
        return {
          data_url: option,
        };
      });
    // console.log(result, "result");
    setImages(result);
    // console.log(result, "results");
    // const newFile = result.map((file) => {
    //   return { data_url: file };
    // });
    // setImages(result)
    // eslint-disable-next-line
  }, []);
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    // console.log(imageList, "imageList");
    props.setImages(imageList);
  };
  // eslint-disable-next-line
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const selectedImages = files.slice(0, 5);
    props.setImages(selectedImages);
  };

  const numImages = images && images.length;

  let numColumns, numRows;
  if (numImages <= 3) {
    numColumns = numImages;
    numRows = 1;
  } else if (numImages === 4) {
    numColumns = 2;
    numRows = 2;
  } else {
    numColumns = 3;
    numRows = 2;
  }

  return (
    <div className="flex flex-col gap-2 w-full items-center justify-center">
      {/* <label className="w-full h-[265px] flex flex-col justify-center items-center px-4 py-4 bg-white border border-gray-300 cursor-pointer">
        {!!numImages ? (
          <div
            className={`grid grid-cols-${numColumns} grid-rows-${numRows} gap-4`}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          >
            {props.images.map((image, index) => (
              <div key={index} className="flex justify-center items-center">
                <img
                  src={
                    props?.defaultImages?.length > 0
                      ? image
                      : URL.createObjectURL(image)
                  }
                  alt={`Uploaded ${index}`}
                  className="max-h-full object-cover"
                  width={150}
                  height={150}
                />
              </div>
            ))}
          </div>
        ) : (
          <>
            <img src={fileUpload} alt="upload" />
            <span className="mt-2 text-base leading-normal">
              Upload an Image
            </span>
          </>
        )}

        <input
          type="file"
          className="hidden"
          multiple
          onChange={handleImageChange}
        />
      </label> */}
      <div
        className={`w-full h-[285px] flex flex-col ${
          !images?.length > 0
            ? "justify-center items-center"
            : "justify-start items-center"
        } px-4 py-2 bg-white border rounded-md ${
          (props?.showImageError && !images?.length > 0) ? "border-red-500" : "border-gray-300"
        } cursor-pointer`}
      >
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors,
          }) => (
            // write your building UI

            <div
              {...dragProps}
              className={`w-full h-[285px] flex  flex-col  items-center ${
                !imageList.length > 0 ? "justify-center" : "   "
              }`}
            >
              {!imageList.length > 0 && (
                <div>
                  <Button
                    variant={!imageList.length > 0 ? `text` : `outlined`}
                    size="sm"
                    className="justify-center flex flex-col items-center text-center text-primary-primary "
                    color="blue-gray"
                    style={isDragging ? { color: "red" } : null}
                    onClick={() => {
                      onImageUpload();
                      props?.setShowImageError(false)
                    }}
                    {...dragProps}
                    type="button"
                  >
                    {!imageList.length > 0 && (
                      <img
                        src={fileUpload}
                        alt="upload"
                        className=" h-[105px]"
                      />
                    )}
                    Click here to upload image
                  </Button>
                </div>
              )}
              {imageList.length > 0 && (
                <div className="flex flex-row justify-center items-center gap-3  pb-3 ">
                  <Button
                    variant={!imageList.length > 0 ? `text` : `outlined`}
                    size="sm"
                    className="justify-center flex flex-col items-center text-center text-primary-primary "
                    color="blue-gray"
                    style={isDragging ? { color: "red" } : null}
                    onClick={onImageUpload}
                    {...dragProps}
                    type="button"
                  >
                    Click here to upload image
                  </Button>
                  {imageList.length > 0 && (
                    <Button
                      variant="outlined"
                      size="sm"
                      className="justify-center flex flex-col items-center text-center  "
                      color="red"
                      tton
                      type="button"
                      onClick={onImageRemoveAll}
                    >
                      Remove all images
                    </Button>
                  )}
                </div>
              )}
              <div
                className={`grid grid-cols-${numColumns} grid-rows-${numRows} gap-2 w-full `}
              >
                {imageList.map((image, index) => (
                  <div
                    key={index}
                    className="flex flex-col justify-center items-center gap-2"
                  >
                    <img
                      src={image["data_url"]}
                      alt=""
                      className="max-w-[100px] h-[80px]" // Set width and height here
                      style={{ objectFit: "cover" }}
                    />
                    <div className="flex flex-row gap-4 justify-center items-center">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          onImageUpdate(index);
                        }}
                      >
                        <Tooltip
                          className={TooltipStlye}
                          content="Replace Image"
                        >
                          <PencilSquareIcon className="w-5 cursor-pointer text-primary-primary" />
                        </Tooltip>
                      </button>
                      <button
                        type="button"
                        onClick={(e) => {
                          onImageRemove(index);
                        }}
                      >
                        <Tooltip
                          className={TooltipStlye}
                          content="Remove Image"
                        >
                          <TrashIcon className="w-5 cursor-pointer text-warn-error" />
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {errors && (
                <div className="mt-1">
                  {(errors.maxNumber  && images?.length >4) && (
                    <span className={errorFieldMessage}>
                      Number of selected images exceed maxNumber
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </ImageUploading>
      </div>
    </div>
  );
};

export default FileUploader;
