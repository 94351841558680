import React, { useEffect, useState } from "react";
import Table from "../functionality/table/Table";
import moment from "moment";
import ActionCol from "../functionality/table/ActionCol";
import { useHistory } from "react-router-dom";
import { AMETIES } from "../../services/APIGlobal";
import axiosInstance from "../../services/APIInstance";
import { showMessage } from "../ui/showMessage";
import Loader from "../ui/Loader";

export default function AllAmenitiesDB(props) {
  let history = useHistory();
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showRecord, setShowRecord] = useState(10);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalPage, setTotalPage] = useState();
  const [selectedOptions, setSelectedOptions] = useState("All");
 
  const [isShort] = useState(false);

  // console.log(props,"props")
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    GET_AMENITIES(); // eslint-disable-next-line
  }, [
    showRecord,
    pageNo,
    selectedOptions,
    isShort,
    setSelectedOptions,
    setLoading,
    history,
   
  ]);

  useEffect(() => {
    localStorage.removeItem("rowId");
  }, [history]);

  // Get API
  const GET_AMENITIES = () => {
    axiosInstance
      .get(
        `${AMETIES}?limit=${showRecord}&page=${pageNo}`
      )
      .then((response) => {
        setData(response?.data?.geAmenities);
        setTotalRecord(response?.data?.pagination?.totalCount);
        setCurrentPage(response?.data?.pagination?.currentPage);
        setTotalPage(response?.data?.pagination?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        setTotalRecord(1);
        setCurrentPage(1);
        setTotalPage(1);
        setData([]);
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.message,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  const columns = [
    {
    Header: "Sr No",
    accessor: (row, index) => index + 1, // Auto-incremented serial number
    headerStyle: { textAlign: "center" },
    width: 100, // Adjust the width as needed
    disableSortBy: true,
  },
 
    {
      Header: (
        <div className="flex flex-row gap-2">
          
            <span >
              Name
            </span>
  

        </div>
      ),
      accessor: "Name",
      Cell: ({ value, row }) => {
        return (
        
            <p
              className="line-clamp-1 cursor-pointer text-left	ml-4"
              
            >
              {value}
            </p>
         
        );
      },
      headerStyle: { textAlign: "center" },
      minWidth: 200,
     
    
      disableSortBy: true,
    },
    {
      Header: "Date",
      accessor: "CreatedDate",
      Cell: ({ cell: { value } }) => (
        <span>
          {value
            ? moment(value, "YYYY-MM-DD hh:mm:ss").format("MM-DD-YYYY")
            : "--"}
        </span>
      ),
      disableSortBy: true,
    },
   
    {
      id: "action",
     
      accessor: "",
      disableSortBy: true,
      Cell: (row) => <ActionCol data={row.row.original} editRowAmenities  deleteAmenities isDelete type="amenities"  GET_API={GET_AMENITIES} />,
    },
  
  ];


  return (
    
    <div>
  
     {loading ?

      <Loader loading={loading} />
      :
      
      <Table
        setPageNo={setPageNo}
        pageNo={pageNo}
        showRecord={showRecord}
        setShowRecord={setShowRecord}
        totalRecord={totalRecord}
        currentPage={currentPage}
        totalPage={totalPage}
        columns={columns}
        data={!props?.project ? data : props?.data}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        maxHeight={props?.height}
        noPagination={props?.project}
        noSearch
      />}
      
    </div>
  );
}
