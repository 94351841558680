import * as React from "react";
import PinSvg from "../../../assets/Images/PinSvg.svg";

// const ICON = `M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z`;

// const pinStyle = {
//   cursor: 'pointer',
//   fill: '#052CA3',
//   stroke: '2'
// };

function BluePin({ size = "30", data }) {
  // console.log(data)

  return (
    <>
      {data !== undefined && (
        <>
          <img width={size} src={PinSvg} alt="location pin" />
        </>
      )}
    </>
    //           data?.ProjectStatus.includes("Under Entitlement") ?<img width={size} src={PinOrange} alt="location pin" />:<img width={size} src={PinSvg} alt="location pin" />}

    // <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
    //   <path d={ICON} />
    // </svg>
  );
}

export default BluePin;
