// Article view label rephase
import PinSvg from "../../assets/Images/PinSvg.svg";
import apartmentpin from "../../assets/Images/Markers/apartmentpin.png";
import shoppin from "../../assets/Images/Markers/shoppin.png";
import landPin from "../../assets/Images/Markers/landPin.png";
import multifamilypin from "../../assets/Images/Markers/multifamilypin.png";
import officePin from "../../assets/Images/Markers/officePin.png";
import hospitalityPin from "../../assets/Images/Markers/hospitalityPin.png";
import storagePin from "../../assets/Images/Markers/storagePin.png";
import IndustrialPin from "../../assets/Images/Markers/IndustrialPin.png";
import flexPin from "../../assets/Images/Markers/flexPin.png";
import hosptialPin from "../../assets/Images/Markers/hosptialPin.png";
import seniorHomePin from "../../assets/Images/Markers/seniorHomePin.png";
import Residental from "../../assets/Images/Markers/Residental.png";
import housePin from "../../assets/Images/Markers/housePin.png";
import SingleFamilyRentPin from "../../assets/Images/Markers/SingleFamilyRentPin.png";
import neighborhoodPin from "../../assets/Images/Markers/neighborhoodPin.png";
import airportPin from "../../assets/Images/Markers/airportPin.png";
import bondsPin from "../../assets/Images/Markers/bondsPin.png";
import DartPin from "../../assets/Images/Markers/DartPin.png";
import trainPin from "../../assets/Images/Markers/trainPin.png";
import stationPin from "../../assets/Images/Markers/stationPin.png";
import highwayPin from "../../assets/Images/Markers/highwayPin.png";
import articleLogo from "../../assets/Logo/OnlyBlacklogo.png";
import LocalProfile from "../../assets/Images/source logo/LocalProfile.png";
import bisnow from "../../assets/Images/source logo/bisnow.jpg";
import CommunityImpact from "../../assets/Images/source logo/CommunityImpact.png";
import DallasInovates from "../../assets/Images/source logo/dallasInnovates.png";
import DallasBusinessJournal from "../../assets/Images/source logo/DallasBusinessJournal.png";
import dMagazine from "../../assets/Images/source logo/dMagazine.png";
import TDLR from "../../assets/Images/source logo/TDLR.jpg";
import The_Dallas_Morning_News from "../../assets/Images/source logo/The_Dallas_Morning_News.png";
import TheRealDeals from "../../assets/Images/source logo/TheRealDeals.png";

// Marker color
export function MarkerColor({ assetType, size }) {
  switch (assetType) {
    case "Apartment":
      return <img width={size} src={apartmentpin} alt="apartmentpin pin" />;
    case "Shops":
      return <img width={size} src={shoppin} alt="shoppin pin" />;
    case "Retail":
      return <img width={size} src={shoppin} alt="shoppin pin" />;
    case "Land":
      return <img width={size} src={landPin} alt="Land pin" />;
    case "Multifamily":
      return <img width={size} src={multifamilypin} alt="multifamilypin pin" />;
    case "Hospitality":
      return <img width={size} src={officePin} alt="officePin pin" />;
    case "Office":
      return <img width={size} src={hospitalityPin} alt="hospitalityPin " />;
    case "Self-Storage":
      return <img width={size} src={storagePin} alt="storagePin" />;
    case "Industrial":
      return <img width={size} src={IndustrialPin} alt="Industria pin" />;
    case "Flex":
      return <img width={size} src={flexPin} alt="flexPin" />;
    case "Healthcare":
      return <img width={size} src={hosptialPin} alt="hosptialPin" />;
    case "Senior Housing":
      return <img width={size} src={seniorHomePin} alt="seniorHomePin" />;
    case "Residential":
      return <img width={size} src={Residental} alt="Residental pin" />;
    case "House":
      return <img width={size} src={housePin} alt="housePin" />;
    case "Home":
      return <img width={size} src={housePin} alt="housePin" />;
    case "Single-Family-Rentals":
      return <img width={size} src={SingleFamilyRentPin} alt="officePin pin" />;
    case "SFR":
      return (
        <img width={size} src={SingleFamilyRentPin} alt="SingleFamilyRentPin" />
      );
    case "Lots":
      return <img width={size} src={landPin} alt="landPin" />;
    case "Neighborhood":
      return <img width={size} src={neighborhoodPin} alt="neighborhoodPin" />;
    case "Airport":
      return <img width={size} src={airportPin} alt="Airportpin" />;
    case "Bond":
      return <img width={size} src={bondsPin} alt="BondPin" />;
    case "DART":
      return <img width={size} src={DartPin} alt="DartPin" />;
    case "Train":
      return <img width={size} src={trainPin} alt="Trainpin" />;
    case "Station":
      return <img width={size} src={stationPin} alt="StationPin" />;
    case "Public improvements (such as street or highway expansions, water line extension, etc)":
      return <img width={size} src={highwayPin} alt="highwayPin pin" />;
    default:
      return <img width={size} src={PinSvg} alt="location" />;
  }
}

export function rephaseName(name) {
  switch (name) {
    case "created_at":
      return "Created Date";
    case "cleanedUrl":
      return "Article URL";
    case "weburl":
      return "Website URL";
    case "authorMail":
      return "Author email";
    case "articlegallerydesc":
      return "Article photo description";
    case "streetAddress":
      return "Street Address";
    case "authordesc":
      return "Author Descrition";
    case "articlegallery":
      return "Article Image";
    case "websitelogo":
      return "Website Logo";
    case "authImage":
      return "Author Image";
    case "StreetAddress":
      return "Street Address";
    case "StreetIntersections":
      return "Street Intersections";
    case "ZipCode":
      return "Zip Code";
    case "ProjectValue":
      return "Project Value";
    case "Valuetype":
      return "Project Value Type";
    case "ProjectSize":
      return "Project Size";
    case "Sizetype":
      return "Project Size Type";
    case "ProjectUnit":
      return "Project Unit";
    case "ProjectBeds":
      return "Project Beds";
    case "ProjectDoors":
      return "Project Doors";
    case "ProjectKeys":
      return "ProjectKeys";
    case "ProjectBays":
      return "Project Bays";
    case "ProjectLots":
      return "Project Lots";
    case "AssetType":
      return "Asset Type";
    case "TypeUser":
      return "Type User";
    case "articleprojectstatus":
      return "Article project status";
    default:
      return name;
  }
}

// Statue
export function articleStatus(status) {
  switch (status) {
    case "Pending":
      return "2. Pending";
    case "Accept":
      return "3. Accept";
    case "Denied":
      return "3. Denied";
    case "Publish":
      return "4. Publish";
    case "":
      return "4. Publish";
    case "Accept & Publish":
      return "4. Publish";
    default:
      return "1. Not Submitted";
  }
}
export function SortedList({ options }) {
  // Use the Array.sort() method to sort the items based on the number
  return options.sort((a, b) => {
    const numA = parseInt(a.label.split(". ")[0]);
    const numB = parseInt(b.label.split(". ")[0]);
    return numA - numB;
  });
}
export function articleStatusforPost(status) {
  switch (status) {
    case "2. Pending":
      return "Pending";
    case "3. Accept":
      return "Accept";
    case "3. Denied":
      return "Denied";
    case "4. Publish":
      return "Publish";
    case "":
      return "Publish";
    case "Accept & Publish":
      return "Publish";
    default:
      return "Not Submitted";
  }
}

// Checkbox return selected values name into string
export function checkboxValues(value) {
  let name = value
    .filter((e) => e.value === true)
    .map((item) => {
      return item["Name"];
    });
  return name.toString();
}

// return values name into string
export function returnName(value) {
  value.map((item) => {
    return item["Name"];
  });
}

// API compare two values and return
export function APIReturnValue(value1, value2) {
  let result = value1 ? value1 : value2;
  return result;
}
// API compare two values and return
export function APIReturnWithCondition(value1, value2, condition) {
  let result = condition ? value1 : value2;
  return result;
}
export function ArrayToString(value) {
  var strung = JSON.stringify(value);
  return strung.substring(1, strung.length - 1).replace(/['"]+/g, "");
}

export const DATE_TIME_FORMAT = "DD.MM.YYYY";

// Form Converter
export const formValueConvertor = (formObj) => ({
  ...formObj,
});
export const getFormJSON = (form) => {
  const data = new FormData(form);
  return Array.from(data.keys()).reduce((result, key) => {
    // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
    var value = data.get(key);
    if (value) result[key] = value;
    result[key] = value || " ";
    return result;
  }, {});
};
// Not Null value
export const isNotNull = (value) => {
  if (
    value !== null &&
    value !== undefined &&
    value !== " " &&
    value !== "" &&
    value !== " "
  ) {
    return true;
  } else {
    return "";
  }
};
// Attach comma
export const attachComma = (value) => {
  if (value !== null && value !== undefined && value !== " " && value !== "") {
    return <span>,</span>;
  }
};
// Attach comma
export const locationString = (street, intersection, city, state, zipcode) => {
  const addressComponents = [street, intersection, city, state];
  const formattedAddress = addressComponents
    .filter((value) => value && value?.trim() !== "")
    .join(", ");

  const formattedZipcode = zipcode ? ` - ${zipcode}` : "";

  return `${formattedAddress}${formattedZipcode}`;
};
// Attach comma

// Website Image Link
export const removeQueryParameters = (url, websiteUrl) => {
  if (!url) {
    return appendSourceLogo(websiteUrl);
  }
  try {
    const urlParts = new URL(url);
    // console.log("urlParts",urlParts)
    if (urlParts.hostname === "i0.wp.com") {
      return urlParts;
    }
    if (urlParts.origin === "https://s24806.pcdn.co") {
      return urlParts;
    }
    if (urlParts.origin === "https://assets.dmagstatic.com") {
      return urlParts;
    } else {
      const specificPart = urlParts.searchParams.get("url");
      if (specificPart) {
        return specificPart;
      } else {
        return url;
      }
    }
  } catch (error) {
    // Handle invalid URL here (e.g., log the error or provide a default URL)
    return appendSourceLogo(websiteUrl); // Return the original URL if it's invalid
  }
};
// website source logo
export const appendSourceLogo = (url) => {
  switch (url) {
    case "https://www.bisnow.com":
      return bisnow;
    case "https://communityimpact.com":
      return CommunityImpact;
    case "https://localprofile.com":
      return LocalProfile;
    case "https://dallasinnovates.com":
      return DallasInovates;
    case "https://www.bizjournals.com":
      return DallasBusinessJournal;
    case "https://www.dmagazine.com":
      return dMagazine;
    case "https://www.tdlr.texas.gov":
      return TDLR;
    case "https://www.dallasnews.com":
      return The_Dallas_Morning_News;
    case "https://therealdeal.com":
      return TheRealDeals;
    default:
      return articleLogo;
  }
};
// website source logo
export const appendSourceThumbnail = (url) => {
  switch (url) {
    case "https://www.bisnow.com":
      return bisnow;
    case "https://communityimpact.com":
      return CommunityImpact;
    case "https://localprofile.com":
      return LocalProfile;
    case "https://dallasinnovates.com":
      return DallasInovates;
    case "https://www.bizjournals.com":
      return DallasBusinessJournal;
    case "https://www.dmagazine.com":
      return dMagazine;
    case "https://www.tdlr.texas.gov":
      return TDLR;
    case "https://www.dallasnews.com":
      return The_Dallas_Morning_News;
    case "https://therealdeal.com":
      return TheRealDeals;
    default:
      return "";
  }
};
export function TransformData(data) {
  const { project, location, images, attributes ,pdfUrls} = data;
  const { projectId } = project;

  return {
    projectId,
    ...project,
    ...location,
    StreetAddress: location.StreetAddress,
    State: location.State,
    City: location.City,
    imageUrls: images,
    pdfUrls: pdfUrls,
    projectAttributes: {
      attribute_names:
        attributes.attribute_names?.length > 1
          ? attributes.attribute_names?.split(",")
          : attributes.attribute_names,
      attribute_values:
        attributes.attribute_values?.length > 1
          ? attributes.attribute_values?.split(",")
          : attributes.attribute_values,
    },
    location: {
      ...location,
      Latitude: String(location.Latitude),
      Longitude: String(location.Longitude),
      polygon_coordinates: null,
    },
  };
}
export function ExtractFilenameFromUrl(url) {
  // Get the filename without the extension
  const filenameWithExtension = url.substring(url.lastIndexOf("/") + 1);
  const filename = filenameWithExtension.split(".")[0];

  // Remove prefix "pdf-" and timestamp
  const parts = filename.split("-");
  parts.shift(); // Remove "pdf" prefix
  parts.shift(); // Remove timestamp
  const title = parts.join(" ");
  const cleanedTitle = title.replace(/[^a-zA-Z0-9\s]/g, " ");

  // Replace "%20" with spaces
  const titleWithSpaces = cleanedTitle.replace(/%20/g, " ");

  return titleWithSpaces;
}
