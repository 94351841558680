import React, { useEffect, useState } from "react";
import Table from "../functionality/table/Table";
import ActionCol from "../functionality/table/ActionCol";
import axiosInstance from "../../services/APIInstance";
import { GETROLES } from "../../services/APIGlobal";
import { showMessage } from "../ui/showMessage";
import Loader from "../ui/Loader";

export default function RoleDB({ filter}) {
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    GET_ROLES(); // eslint-disable-next-line
  }, [filter]);

  // Get API
  const GET_ROLES = () => {
    setLoading(true);
    axiosInstance
      .get(`${GETROLES}?filter=${filter?filter:""}`)
      .then((response) => {
        setData(response?.data?.roles);
        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
        }, 1600);
        setTimeout(() => {
          showMessage(
            error?.response?.data?.error,
            "error",
            "red",
            false,
            false,
            false
          );
        }, 2500);
      });
  };

  const columns = [
    {
      Header: "#",
      accessor: "id",
      Cell: (cell) => cell.row.index + 1,
      maxWidth: 10,
      minWidth: 10,
      width: 20,
      headerStyle: { textAlign: "center" },
    },
    {
      Header: "First Name",
      accessor: "firstName",
      width: 300,
      headerStyle: { textAlign: "center" },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      width: 300,
      headerStyle: { textAlign: "center" },
    },
    {
      Header: "Email",
      accessor: "email",
      width: 300,
      headerStyle: { textAlign: "center" },
    },
    {
      Header: "Role",
      accessor: "roleName",
      width: 300,
      headerStyle: { textAlign: "center" },
    },
    {
      id: "action",
      accessor: "",
      disableSortBy: true,
      Cell: (row) => <ActionCol data={row.row.original} editRow  deleteRole isDelete GET_API={GET_ROLES} />,
    },
  ];
  useEffect(() => {
    localStorage.removeItem("rowdata");
  }, []);

  return (
    <div >
      {loading ?
      <Loader loading={loading}></Loader>
      : <Table noPagination columns={columns} data={data} noSearch  />}
    </div>
  );
}
