import Swal from "sweetalert2";
import { PUBLISHPROJECT } from "../../services/APIGlobal";
import { showMessage } from "../ui/showMessage";
import axiosInstance from "../../services/APIInstance";

export const ProjectPopUp = (
  data,
  confirmButtonText,
  confirmButtonColor,
  title,
  event,
  setLoading,
  GET_API
) => {
  Swal.fire({
    icon: "warning",
    title: title,
    // showClass: {
    //   backdrop: 'swal2-noanimation', // disable backdrop animation
    //   popup: '',                     // disable popup animation
    //   icon: ''                       // disable icon animation
    // },
    confirmButtonText: confirmButtonText,
    showCancelButton: true,
    confirmButtonColor: confirmButtonColor,
    customClass: "Custom_Cancel",
    cancelButtonColor: "#d6e2e2",
    cancelButtonText: "<span style='color:black'>Cancel</span>",
    hideClass: {
      popup: "", // disable popup fade-out animation
    }, // eslint-disable-next-line
    customClass: {
      loader: "custom-loader",
      popup: "bg-swal",
    },
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      let DATA;
      if (event === "publish") {
        setLoading(true);
        DATA = {
          projectId: data.projectId,
          // Status: "Accept & Publish",
          comment: "",
          IsPublish: "Yes",
        };
      } else if (event === "unpublish") {
        DATA = {
          projectId: data.projectId,
          // Status: "Denied",
          comment: "",
          IsPublish: "No",
        };
      }
      axiosInstance
        .put(`${PUBLISHPROJECT}`, DATA)
        .then((response) => {
          showMessage(
            response?.data?.message,
            "success",
            "#576d69",
            false,
            false,
            false
          );
          setLoading(true);
          GET_API();
          setLoading(false);
        })
        .catch((error) => {

        });
    }
  });
};
