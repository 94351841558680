import React, { createContext } from "react";
import { useState } from "react";

const FilterContext = createContext({});

export const FilterProvider = ({ children }) => {
  const [projectAssetType, setProjectAssetType] = useState([]);
  const [filterCategory, setFilterCategory] = useState();
  const [isFavouriteTab, setIsFavouriteTab] = useState(false);
  const [filterArray,setFilterArray] =  useState({
    PROECTSTATUS:"",
    ASSETTYPE:"",
    DATE:"",
    SEARCH:"",
    FAVOURITE:false,
  });
  const [suggestionLocation, setSugestionLocation] = useState({
    longitude:-96.85111224441096,
    latitude:32.790260898226535,
  });
  const [isSuggestion, setIsSuggestion] = useState(false);
  // const [filterArray, setFilterArray] = useState({ arrayOfFilters: [] });

  //   console.log(filterCategory);

  return (
    <FilterContext.Provider
      value={{
        projectAssetType,
        setProjectAssetType,
        filterCategory,
        setFilterCategory,
        setFilterArray,
        filterArray,
        isFavouriteTab,
        setIsFavouriteTab,
        setSugestionLocation,
        suggestionLocation,
        setIsSuggestion,
        isSuggestion
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContext;
