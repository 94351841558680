import axios from "axios";

const multiPartAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP__PUBLIC_API_URL,
  headers: {
    Accept: "*/*",
  },
});

export default multiPartAxiosInstance;
