import React, {  useState } from "react";
import { Link, useLocation, } from "react-router-dom";
import Whitelogo from "../../../assets/Logo/Whitelogo.png";
import OnlyWhiteLogo from "../../../assets/Logo/OnlyWhiteLogo.png";
import {
  ArchiveBoxXMarkIcon,
  // ArrowLeftOnRectangleIcon,
  ChevronRightIcon,
  // Cog8ToothIcon,
  FolderOpenIcon,
  // PowerIcon,
  // RectangleStackIcon,
  UserIcon,
  ArrowTrendingUpIcon,
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  UserGroupIcon,
  ChartBarIcon
} from "@heroicons/react/24/outline";
import { Card } from "@material-tailwind/react";
// import AuthContext from "../../../context/AuthContext";

const Sidebar = (props) => {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  // const history = useHistory();
  // const { setAuth } = useContext(AuthContext);
  const authDetails = localStorage.getItem("authDetails");
  let authDetailsArray = JSON.parse(authDetails);
  const Menus = [
    { title: "Projects", path: "/Projects", src: <FolderOpenIcon /> },
    // { title: "Articles", path: "/Articles", src: <RectangleStackIcon /> },
    // { title: "Status", path: "/Status", src: <TicketIcon /> },
    { title: "Role", path: "/Role", src: <UserIcon />, admin: "admin" },
    // {
    //   title: "Review Article",
    //   path: "/Review",
    //   src: <DocumentTextIcon />,
    //   admin: "admin",
    // },
    // {
    //   title: "Publish Article",
    //   path: "/Publish",
    //   src: <ClipboardDocumentCheckIcon />,
    //   admin: "admin",
    // },
    {
      title: "Archives",
      path: "/Archives",
      src: <ArchiveBoxXMarkIcon />,
    },
    {
      title: "Analytics",
      path: "/Analytics",
      src: <ArrowTrendingUpIcon />,
    },
    {
      title: "AssetType",
      path: "/AssetType",
      src: <BuildingOffice2Icon />,
    },
    {
      title: "Amenities",
      path: "/Amenities",
      src: <BuildingOfficeIcon />,
    },
    {
      title: "ProjectStatus",
      path: "/ProjectStatus",
      src: <ChartBarIcon />,
    },
    {
      title: "TypeofUser",
      path: "/TypeofUser",
      src: <UserGroupIcon />,
    },
    // {
    //   title: "Settings",
    //   path: "/settings",
    //   src: <Cog8ToothIcon />,
    // },
    // {
    //   title: "Sign out",
    //   path: "/signIn",
    //   src: <ArrowLeftOnRectangleIcon />,
    //   gap: "true",
    // },
  ];

  return (
    <>
      <div
        className={`${
          open ? "w-60 2xl:w-80" : "w-fit"
        } hidden md:block relative h-screen duration-300 bg-primary-primary   p-5 `}
      >
        <Card
          className={` flex outline-double  outline-offset-0  outline-2 outline-primary-primary justify-center transition-transform absolute  rounded-sm  bg-white/90 cursor-pointer top-9 -right-0 z-100 `}
        >
          {/* {open ? ( */}
            <ChevronRightIcon
             className={` text-primary-primary transition-transform  ${
              open ? "rotate-180 h-6 w-6" : "h-4 w-4"
            }`}
              // className="h-7 w-7 text-primary-primary"
              onClick={() => setOpen(!open)}
              strokeWidth={2}
            />

        </Card>

        <Link to="/projectList">
          <div className={`flex ${open && "gap-x-4"} items-center`}>
            {open ? (
           <>
              <img className="block 2xl:hidden pl-2" width={150} src={Whitelogo} alt="logo"  />
              <img className="hidden  2xl:block pl-2" width={200} src={Whitelogo} alt="logo"  /></>
            ) : (
              <img width={50} src={OnlyWhiteLogo} alt="logo" className="pl-2" />
            )}
          </div>
        </Link>

        <ul className="pt-2 w-full">
          {Menus.map((menu, index) => (
            <div key={menu.title}>
              {!menu.admin && (
                <Link to={menu.path} key={menu.title}>
                  <li
                    className={`flex items-center gap-x-6 2xl:p-6 p-2 text-base font-normal rounded-lg cursor-pointer text-white hover:bg-primary-hover 
                      ${menu.gap ? "mt-9" : "mt-2"} ${
                      location.pathname === menu.path &&
                      "bg-primary-hover  shadow-sm"
                    }`}
                  >
                    <span className="text-2xl w-7">{menu.src}</span>
                    <span
                      className={`${
                        !open && "hidden"
                      } origin-left duration-300 hover:block`}
                    >
                      {menu.title}
                    </span>
                  </li>
                </Link>
              )}
              {menu.admin && authDetailsArray.roles[0] === "Admin" && (
                <Link to={menu.path} key={menu.title}>
                  <li
                    className={`flex items-center gap-x-6 2xl:p-6 p-2 text-base font-normal rounded-lg cursor-pointer text-white hover:bg-primary-hover 
                      ${menu.gap ? "mt-9" : "mt-2"} ${
                      location.pathname === menu.path && "bg-primary-hover "
                    }`}
                  >
                    <span className="text-2xl w-7">{menu.src}</span>
                    <span
                      className={`${
                        !open && "hidden"
                      } origin-left duration-300 hover:block`}
                    >
                      {menu.title}
                    </span>
                  </li>
                </Link>
              )}
            </div>
          ))}
          {/* <button
            className={`w-full flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer text-white hover:bg-primary-hover 
                      mt-9 `}
            onClick={() => {
              setAuth({});
              localStorage.setItem("authDetails", "");
              localStorage.setItem("userDetails", "");
              history.push("/");
            }}
          >
            <span className="text-2xl w-7">
              <PowerIcon />
            </span>
            <span
              className={`${
                !open && "hidden"
              } origin-left duration-300 hover:block`}
            >
              Sign Out
            </span>
          </button> */}
        </ul>
      </div>

    </>
  );
};

export default Sidebar;
