import React, { useState } from "react";

const CheckboxArray = ({
  options,
  defaultChecked,
  onChange,
  name,
  className,
  notApplicable,
  textareaRefs,
}) => {
  // console.log(defaultChecked,"defaultChecked")
  const [selectedOptions, setSelectedOptions] = useState(defaultChecked);
  const handleCheckboxChange = (option) => {
    let updatedOptions;

    if (option === "notApplicable") {
      updatedOptions = ["notApplicable"];
    } else {
      if (selectedOptions.includes(option)) {
        updatedOptions = selectedOptions.filter((item) => item !== option);
      } else {
        updatedOptions = [...selectedOptions, option];
      }
      updatedOptions = updatedOptions.filter(
        (item) => item !== "notApplicable"
      );
    }
    setSelectedOptions(updatedOptions);
    // console.log(updatedOptions, "updatedOptions");
    onChange(updatedOptions, name);
  };

  return (
    <section className="my-2 mx-2">
      <div
        className={`grid gap-3 md:grid-flow-row-dense md:grid-cols-4 2xl:grid-cols-8 ${className}`}
      >
        {notApplicable && (
          <label key="notApplicable" className="inline-block align-center">
            <input
              className="inline-block align-top cursor-pointer h-4 w-4 rounded accent-primary-hover	text-white"
              type="checkbox"
              name="NA"
              value="notApplicable"
              checked={selectedOptions.includes("notApplicable")}
              onChange={() => handleCheckboxChange("notApplicable")}
            />
            <span className="ml-2 ">NA</span>
          </label>
        )}
        {options && (
          <>
            {options.map(({ Name }) => (
              <label key={Name} className="flex items-center text-sm">
                <input
                  ref={(el) => (textareaRefs.current[2] = el)}
                  className="inline-block align-top cursor-pointer h-4 w-4 text-xs rounded accent-primary-hover	text-white"
                  type="checkbox"
                  name={name}
                  value={Name}
                  checked={selectedOptions.includes(Name)}
                  onChange={() => handleCheckboxChange(Name)}
                />
                <span className="ml-2 ">{Name}</span>
              </label>
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default CheckboxArray;
