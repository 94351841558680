import { Card } from "@material-tailwind/react";
import AllArticlesDB from "../../../../components/datatables/AllArticlesDB";
import AdminArticles from "../../../../components/datatables/AdminArticles";

export default function Articles() {
 
  return (
    <Card className="overflow-x-scroll h-full  border-t p-4">
      {JSON.parse(localStorage.getItem("authDetails")).roles[0] === "Admin" ? (
        <AdminArticles />
      ) : (
        <AllArticlesDB />
      )}
    </Card>
  );
}
