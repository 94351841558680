import React from "react";
import { Tooltip } from "@material-tailwind/react";
import {
  TrashIcon,
  ArrowTopRightOnSquareIcon,
  PencilSquareIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  DocumentCheckIcon,
  ArchiveBoxIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Popup } from "../Popup";
import { useHistory } from "react-router-dom";
import Loader from "../../ui/Loader";
import { useState } from "react";
import MoveToAnotherProject from "../MoveToAnotherProject";

const TooltipStlye = "border shadow-lg bg-white text-black w-fit ";
export default function ActionCol({
  data,
  showResubmit,
  jumpToArticle,
  editRow,
  publish,
  unPublished,
  GET_API,
  deletedStories,
  deleteRole,
  component,
  isDelete,
  isRestore,
  project,
  shiftToProject,
  Projectloading,
  setProjectloading,
  editRowAssetType,
  deleteAssetType,
  editRowAmenities,
  deleteAmenities,
  isDeleteAssetType,
  editRowProjectStatus,
  deleteProjectStatus,
  editRowUser,
  deleteUser,
  type,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  //  Remove event

  const onDeleteEvent = () => {
    Popup(
      data,
      `Delete`,
      `#d33`,
      `Are you sure you want to delete this?`,
      `delete`,
      setLoading,
      GET_API,
      project,
      deletedStories,
      deleteRole
    );
  };
  const onDeleteAssetType = () => {
    Popup(
      data,
      `Delete`,
      `#d33`,
      `Are you sure you want to delete this?`,
      `delete`,
      setLoading,
      GET_API,
      project,
      deletedStories,
      deleteRole,
      deleteAssetType
    );
  };

  const onDeleteAmenities = () => {
    Popup(
      data,
      `Delete`,
      `#d33`,
      `Are you sure you want to delete this?`,
      `delete`,
      setLoading,
      GET_API,
      project,
      deletedStories,
      deleteRole,
      deleteAssetType,
      deleteAmenities
    );
  };
  const onDeleteProjectStatus = () => {
    Popup(
      data,
      `Delete`,
      `#d33`,
      `Are you sure you want to delete this?`,
      `delete`,
      setLoading,
      GET_API,
      project,
      deletedStories,
      deleteRole,
      deleteAssetType,
      deleteAmenities,
      deleteProjectStatus
    );
  };
  const onDeleteUser = () => {
    // console.log("deleteProjectStatus", deleteProjectStatus);
    Popup(
      data,
      `Delete`,
      `#d33`,
      `Are you sure you want to delete this?`,
      `delete`,
      setLoading,
      GET_API,
      project,
      deletedStories,
      deleteRole,
      deleteAssetType,
      deleteAmenities,
      deleteProjectStatus,
      deleteUser
    );
  };

  //  Remove event
  const onArchiveEvent = () => {
    Popup(
      data,
      `Archive`,
      `#d33`,
      `Are you sure you want to Archive this?`,
      `archive`,
      setLoading,
      GET_API,
      project
      // archiveStories,
      // archiveRole
    );
  };

  //  Remove event
  const onRestoreEvent = () => {
    Popup(
      data,
      `Restore`,
      `#40514e`,
      `Are you sure you want to Restore this?`,
      `restore`,
      setLoading,
      GET_API,
      project
      // restoreStories,
      // restoreRole
    );
  };

  //  Resubmit event
  const onResubmitevent = () => {
    Popup(
      data,
      `Submit`,
      `#40514e`,
      `Are you sure you want to Submit this Article to publish?`,
      `review`,
      setLoading,
      GET_API
    );
  };

  //  edit event
  const onEditEvent = () => {
    history.push({
      pathname: `/Role/editRole/${data.UserId}`,
      data: data,
      title: "edit",
    });
  };
  const onAssetEditEvent = () => {
    history.push({
      pathname: `/AssetType/editAssetType/${data.PatId}`,
      data: data,
      title: "edit",
    });
  };

  const onAmeniEditEvent = () => {
    history.push({
      pathname: `/Amenities/editAmenities/${data.AmenitiesId}`,
      data: data,
      title: "edit",
    });
  };
  const onProjectStatusEditEvent = () => {
    history.push({
      pathname: `/ProjectStatus/editProjectstatus/${data.PStatusTypeId}`,
      data: data,
      title: "edit",
    });
  };
  const onStatusEditEvent = () => {
    history.push({
      pathname: `/TypeofUser/editTypeofUser/${data.TypeUserId}`,
      data: data,
      title: "edit",
    });
  };

  //  publish event
  const onPublishEvent = () => {
    Popup(
      data,
      `Publish`,
      `#40514e`,
      `Are you sure you want to Publish "${data.article_title}"?`,
      `publish`,
      setLoading,
      GET_API
    );
  };

  //  unpublish event
  const onUnpublishEvent = () => {
    Popup(
      data,
      `Unpublish`,
      `#d33`,
      `Are you sure you want to Unpublish this?`,
      `unPublish`,
      setLoading,
      GET_API
    );
  };

  return (
    <section className="flex gap-4 justify-start min-w-fit  items-center md:mr-2">
      <Loader loading={loading} />
      {/* Jump to link */}
      {jumpToArticle && (
        <Tooltip className={TooltipStlye} content="Visit reference link">
          <a
            href={data?.cleanedUrl ? data?.cleanedUrl : data.websiteUrl}
            target="_blank"
            rel="noreferrer"
          >
            <ArrowTopRightOnSquareIcon className="flex items-center w-5 cursor-pointer text-blue-700" />
          </a>
        </Tooltip>
      )}
      {/* Resubmit if article decline  */}
      {showResubmit &&
        (component === "Status" ? (
          data.Status === "Denied" && (
            <Tooltip
              className={TooltipStlye}
              content="Submit Article for Review"
            >
              <DocumentCheckIcon
                onClick={onResubmitevent}
                className="w-5 cursor-pointer flex items-center  text-green-500 font-extralight"
              />
            </Tooltip>
          )
        ) : (
          <Tooltip className={TooltipStlye} content="Submit Article to Publish">
            <DocumentCheckIcon
              onClick={onResubmitevent}
              className="w-5 cursor-pointer flex items-center text-green-500 font-extralight"
            />
          </Tooltip>
        ))}
      {/* editRow   */}
      {editRow && (
        <Tooltip className={TooltipStlye} content="Update">
          <PencilSquareIcon
            onClick={onEditEvent}
            className="w-5 cursor-pointer text-blue-gray-400"
          />
        </Tooltip>
      )}
      {editRowAssetType && (
        <Tooltip className={TooltipStlye} content="Edit">
          <PencilSquareIcon
            onClick={onAssetEditEvent}
            className="w-5 cursor-pointer text-blue-gray-400"
          />
        </Tooltip>
      )}

      {editRowAmenities && (
        <Tooltip className={TooltipStlye} content="Edit">
          <PencilSquareIcon
            onClick={onAmeniEditEvent}
            className="w-5 cursor-pointer text-blue-gray-400"
          />
        </Tooltip>
      )}
      {editRowProjectStatus && (
        <Tooltip className={TooltipStlye} content="Edit">
          <PencilSquareIcon
            onClick={onProjectStatusEditEvent}
            className="w-5 cursor-pointer text-blue-gray-400"
          />
        </Tooltip>
      )}
      {editRowUser && (
        <Tooltip className={TooltipStlye} content="Edit">
          <PencilSquareIcon
            onClick={onStatusEditEvent}
            className="w-5 cursor-pointer text-blue-gray-400"
          />
        </Tooltip>
      )}

      {/* publish   */}
      {/* {publish && data.Status === "Accept" && ( */}
      {publish && data.IsPublish === "No" && (
        <Tooltip className={TooltipStlye} content="Publish Article">
          <DocumentArrowUpIcon
            onClick={onPublishEvent}
            className="w-5 cursor-pointer text-light-green-700"
          />
        </Tooltip>
      )}
      {/* unpublish   */}
      {(unPublished && data.IsPublish === "Yes") && (
        <Tooltip className={TooltipStlye} content="Unpublished Article">
          <DocumentArrowDownIcon
            onClick={onUnpublishEvent}
            className="w-5 cursor-pointer text-red-900	"
          />
        </Tooltip>
      )}
      {/* Change project   */}
      {shiftToProject && (
        <MoveToAnotherProject
          setProjectloading={setProjectloading}
          GET_API={GET_API}
          data={data}
        />
      )}
      {/* ArchiveBox */}
      {!isDelete ? (
        <Tooltip
          className={TooltipStlye}
          content={project ? "Archive Project" : "Archive Article"}
        >
          <ArchiveBoxIcon
            onClick={onArchiveEvent}
            className="w-5 cursor-pointer text-warn-error"
          />
        </Tooltip>
      ) : type === "assetype" ? (
        <Tooltip className={TooltipStlye} content="Delete">
          <TrashIcon
            onClick={onDeleteAssetType}
            className="w-5 cursor-pointer text-warn-error"
          />
        </Tooltip>
      ) : type === "amenities" ? (
        <Tooltip className={TooltipStlye} content="Delete">
          <TrashIcon
            onClick={onDeleteAmenities}
            className="w-5 cursor-pointer text-warn-error"
          />
        </Tooltip>
      ) : type === "projectstatus" ? (
        <Tooltip className={TooltipStlye} content="Delete">
          <TrashIcon
            onClick={onDeleteProjectStatus}
            className="w-5 cursor-pointer text-warn-error"
          />
        </Tooltip>
      ) : type === "users" ? (
        <Tooltip className={TooltipStlye} content="Delete">
          <TrashIcon
            onClick={onDeleteUser}
            className="w-5 cursor-pointer text-warn-error"
          />
        </Tooltip>
      ) : (
        <Tooltip className={TooltipStlye} content="Delete">
          <TrashIcon
            onClick={onDeleteEvent}
            className="w-5 cursor-pointer text-warn-error"
          />
        </Tooltip>
      )}

      {/* Restore */}
      {isRestore && (
        <Tooltip
          className={TooltipStlye}
          content={project ? "Restore Project" : "Restore Article"}
        >
          <ArrowPathIcon
            onClick={onRestoreEvent}
            className="w-5 cursor-pointer text-green-700"
          />
        </Tooltip>
      )}
    </section>
  );
}
