import * as yup from "yup";

export const LoginValidationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("The Email field is required"),
    // password: yup
    //   .string("Enter your password")
    //   .min(8, "Password should be of minimum 8 characters length")
    //   .required("The Password field is required"),
  });
export const ForgotPassSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("The Email field is required"),
  });

export const roleSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("The Email field is required"),
      F_Name: yup
      .string('')
      .required("This field is required"),
    L_Name: yup
      .string("")
      .required("This field is required"),
  });

export const SignupValidationSchema =(props)=> yup.object({
    F_Name: yup
      .string('')
      .required("This field is required"),
    L_Name: yup
      .string("")
      .required("This field is required"),
      // .oneOf(props?.JobLevels, 'The profession you chose does not exist'),
    // Organization: yup
    //   .string("Enter your Organization")
    //   .required("This field is required"),
    // Industry: yup
    //   .string("Enter your Industry")
    //   .required("This field is required"),
      UserName: yup
      .string("")
      .required("The User Name field is required"),
      Password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("The Password field is required"),
  });
  export const assetSchema = yup.object({
    
    Name: yup
    .string('')
    .required("This field is required"),
  
});