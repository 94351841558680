import React, { createContext } from "react";
import { useState } from "react";

const ShowLayoutContext = createContext({});

export const ShowLayoutProvider= ({ children }) => {
  const [showLayout,setShowLayout] =  useState("map");
// console.log(scrollToIndex)
  return (
    <ShowLayoutContext.Provider value={{ showLayout, setShowLayout}}>
      {children}
    </ShowLayoutContext.Provider>
  );
};

export default ShowLayoutContext;
