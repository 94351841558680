import React from "react";
import {
  FullscreenControl,
  GeolocateControl,
  Map,
  NavigationControl,
} from "react-map-gl";
import { MAP_SATELLITE_STREETS } from "../../../utils/Constant";

export default function MapOnly() {
  const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN; // Set your mapbox token here

  return (
    <div className="w-full h-full">
      <Map
      // reuseMaps
      initialViewState={{
        latitude: 33.195777,
        longitude: -96.638031,
        zoom: 10,
      }}
      // className="-z-1"
      // mapLib={window.mapboxgl}
      mapStyle={MAP_SATELLITE_STREETS}
      mapboxAccessToken={TOKEN}
    >
      <Map
        // mapLib={window.mapboxgl}
        mapStyle={MAP_SATELLITE_STREETS}
        mapboxAccessToken={TOKEN}
        initialViewState={{
          latitude: 33.195777,
          longitude: -96.638031,
          zoom: 10,
        }}
      >
        <GeolocateControl position="top-right" />
        <FullscreenControl position="top-right" />
        <NavigationControl position="top-right" />
      </Map>
    </Map>
    </div>
  );
}
