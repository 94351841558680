import React, { createContext,  useEffect, useState } from 'react';

// Create a context to manage the browser information
const BrowserContext = createContext();

// Provider component to manage the browser state
export const BrowserProvider = ({ children }) => {
  const [isSafari, setIsSafari] = useState(false);
// console.log(isSafari,"isSafari")
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsSafari(userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1);
  }, []);

  return (
    <BrowserContext.Provider value={isSafari}>
      {children}
    </BrowserContext.Provider>
  );
};

// Custom hook to consume the browser context
export default BrowserContext;
